const observeLangChange = (callback) => {
    const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.type === 'attributes' && mutation.attributeName === 'lang') {
            const newLang = document.documentElement.lang;
            
            if (callback) {
              callback(newLang);
            }
          }
        });
      });
    
    observer.observe(document.documentElement, {
        attributes: true,
        attributeFilter: ['lang'],
    });

    return () => {
        observer.disconnect();
    };
};

export { observeLangChange };