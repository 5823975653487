exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".progress-bar_container_1Vl82 {\n    width: 100%;\n    padding: 20px 10px;\n}\n\n.progress-bar_progress-bar_Gy5Mx {\n    position: relative;\n    width: 100%;\n    height: 22px;\n    padding-top: 2px;\n    padding-left: 3px;\n    padding-right: 3px;\n    border-radius: 10px;\n    background: -webkit-gradient(linear, left top, right top, from(#28DEB4), to(#4A93F6));\n    background: linear-gradient(90deg, #28DEB4 0%, #4A93F6 100%);\n}\n\n.progress-bar_progress-bar-inner_3Ws2k {\n    width: 100%;\n    height: 16px;\n    margin-top: 1px;\n    padding: 2px;\n    border-radius: 10px;\n    background: white;\n}\n\n.progress-bar_loading_37dGh {\n    position: relative;\n    height: 12px;\n    border-radius: 10px;\n    background: -webkit-gradient(linear, left top, right top, from(#28DEB4), to(#4A93F6));\n    background: linear-gradient(90deg, #28DEB4 0%, #4A93F6 100%);\n    -webkit-transition: width 0.5s ease;\n    transition: width 0.5s ease;\n}\n\n.progress-bar_progress-text_1ly8w {\n    position: absolute;\n    top: 18%;\n    right: 2%;\n    margin: 0;\n    font-size: 13px;\n    color: #4A94F7;\n}\n\n.progress-bar_white-text_FFL0v {\n    color: white;\n}", ""]);

// exports
exports.locals = {
	"container": "progress-bar_container_1Vl82",
	"progress-bar": "progress-bar_progress-bar_Gy5Mx",
	"progressBar": "progress-bar_progress-bar_Gy5Mx",
	"progress-bar-inner": "progress-bar_progress-bar-inner_3Ws2k",
	"progressBarInner": "progress-bar_progress-bar-inner_3Ws2k",
	"loading": "progress-bar_loading_37dGh",
	"progress-text": "progress-bar_progress-text_1ly8w",
	"progressText": "progress-bar_progress-text_1ly8w",
	"white-text": "progress-bar_white-text_FFL0v",
	"whiteText": "progress-bar_white-text_FFL0v"
};