import React, { useState } from "react";
import dropDown from './dropDown.svg';
import dropUp from './dropUp.svg';
import styles from './dropdown.css';
import classNames from "classnames";

const defaultOptionList = [{
    label: '',
    value: 'selectOption'
}];

function Dropdown({
    optionList,
    onChange,
    valueIndex,
    className
}) {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    const onClick = (e) => {
        toggle();
        onChange(e.target.value);
    };

    const showOptionList = optionList?.filter((_, index) => index !== valueIndex);

    return (
        <div className={styles.dropdownContainer}>
            <div
                className={classNames(styles.dropdown, isOpen && styles.open)}
                onClick={toggle}
            >
                <div className={styles.selected}>
                    <span>{optionList[valueIndex]?.label || defaultOptionList[0].label}</span>
                    <img src={isOpen ? dropUp : dropDown} alt="dropdown" />
                </div>
            </div>
            {isOpen && (
                <div className={styles.dropdownList}>
                    {showOptionList?.map(({label, value}) =>
                        <button
                            key={value}
                            value={value}
                            onClick={onClick}
                            className={classNames(styles.button, className)}
                        >
                            {label}
                        </button>
                    )}
                </div>
            )}
        </div>
    );
};

export default Dropdown;