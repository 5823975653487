function bindMethodsToTarget(source, target, methodNames) {
    for (const methodName of methodNames) {
        const method = source[methodName];
        if (typeof method !== 'function') {
            throw new Error(`bindMethodsToTarget: '${methodName}' is not a function`);
        }

        source[methodName] = source[methodName].bind(target);
    }
}

export {
    bindMethodsToTarget
}