import PropTypes from 'prop-types';
import React from 'react';
import styles from './starting-screen.css';
import errorBoundaryHOC from '../../lib/error-boundary-hoc.jsx';

const StartingScreenComponent = () => {
    return <></>;
};

export default errorBoundaryHOC('starting-screen', false, true)(StartingScreenComponent);
