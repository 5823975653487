const SET_VISIBLE_MODAL = 'scratch-gui/custom-TM-modal/VISIBLE_MODAL';
const SET_SELECTED_CAMERA = 'scratch-gui/custom-TM-modal/SELECTED_CAMERA';
const SET_AVAILABLE_VIDEO = 'scratch-gui/custom-TM-modal/AVAILABLE_VIDEO';

const initialState = {
    visible: false,
    selectedCamera: null,
    isAvailableVideo: true
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
    case SET_VISIBLE_MODAL:
        return Object.assign({}, state, {
            visible: action.visible
        });
    case SET_SELECTED_CAMERA:
        return Object.assign({}, state, {
            selectedCamera: action.selectedCamera
        });
    case SET_AVAILABLE_VIDEO:
        return Object.assign({}, state, {
            isAvailableVideo: action.isAvailableVideo
        });
    default:
        return state;
    }
};

const setVisibleTMModal = isVisible => ({
    type: SET_VISIBLE_MODAL,
    visible: isVisible
});

const setSelectedCamera = selectedCamera => ({
    type: SET_SELECTED_CAMERA,
    selectedCamera: selectedCamera
});

const setAvailableVideo = isAvailableVideo => ({
    type: SET_AVAILABLE_VIDEO,
    isAvailableVideo
});

export {
    reducer as default,
    initialState as customTMModalInitState,
    setVisibleTMModal,
    setSelectedCamera,
    setAvailableVideo
};
