import React from 'react';
import styles from './tutorial-modal.css';
import classNames from 'classnames';

const TutorialSuccessModalComponent = ({
    onClickTutorialNextStepButton,
    onClickTutorialRestartButton,
    sendSuccessTutorial,
    isLastTutorial,
}) => {
    sendSuccessTutorial();

    return (
        <div className={styles.dialogWrapper}>
            <img src={
                isLastTutorial ?
                    require("../../assets/tutorial-dialog/success_last_tutorial.svg")
                :
                    require("../../assets/tutorial-dialog/success.svg")
                }
            />
            {isLastTutorial ? null : (
                <button
                    onClick={onClickTutorialNextStepButton}
                    className={classNames(
                        styles.buttonCommon,
                        styles.nextStepButton
                    )}
                />
            )}
            <button
                onClick={onClickTutorialRestartButton}
                className={classNames(
                    styles.buttonCommon,
                    styles.successRetryButton,
                    isLastTutorial ? styles.lastStepSuccessRetryButton : ''
                )}
            />
        </div>
    );
}

export default TutorialSuccessModalComponent;