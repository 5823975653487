import { BlockXMLBase } from './BlockXMLBase';

export class Sound extends BlockXMLBase {
    constructor (isInitialSetup, isStage, targetId, soundName) {
        super();
    
        this.sound_playuntildone = `
            <block id="${targetId}_sound_playuntildone" type="sound_playuntildone">
                <value name="SOUND_MENU">
                    <shadow type="sound_sounds_menu">
                        <field name="SOUND_MENU">${soundName}</field>
                    </shadow>
                </value>
            </block>`;

        this.sound_play = `
            <block id="${targetId}_sound_play" type="sound_play">
                <value name="SOUND_MENU">
                    <shadow type="sound_sounds_menu">
                        <field name="SOUND_MENU">${soundName}</field>
                    </shadow>
                </value>
            </block>`;

        this.sound_volume = `
            <block id="${targetId}_volume" type="sound_volume"/>`;
    }

    blockOptions = {
        sound_playuntildone: {
            sprite: true,
            stage: true,
            order: 0
        },
        sound_play: {
            sprite: true,
            stage: true,
            order: 1
        },
        sound_stopallsounds: {
            sprite: true,
            stage: true,
            order: 2
        },
        sound_changeeffectby: {
            sprite: true,
            stage: true,
            order: 3
        },
        sound_seteffectto: {
            sprite: true,
            stage: true,
            order: 4
        },
        sound_cleareffects: {
            sprite: true,
            stage: true,
            order: 5
        },
        sound_changevolumeby: {
            sprite: true,
            stage: true,
            order: 6
        },
        sound_setvolumeto: {
            sprite: true,
            stage: true,
            order: 7
        },
        sound_volume: {
            sprite: true,
            stage: true,
            order: 8
        }
    };

    sound_stopallsounds = `
        <block type="sound_stopallsounds"/>`;

    sound_changeeffectby = `
        <block type="sound_changeeffectby">
            <value name="VALUE">
                <shadow type="math_number">
                    <field name="NUM">10</field>
                </shadow>
            </value>
        </block>`;

    sound_seteffectto = `
        <block type="sound_seteffectto">
            <value name="VALUE">
                <shadow type="math_number">
                    <field name="NUM">100</field>
                </shadow>
            </value>
        </block>`;

    sound_cleareffects = `
        <block type="sound_cleareffects"/>`;

    sound_changevolumeby = `
        <block type="sound_changevolumeby">
            <value name="VOLUME">
                <shadow type="math_number">
                    <field name="NUM">-10</field>
                </shadow>
            </value>
        </block>`;

    sound_setvolumeto = `
        <block type="sound_setvolumeto">
            <value name="VOLUME">
                <shadow type="math_number">
                    <field name="NUM">100</field>
                </shadow>
            </value>
        </block>`;
}