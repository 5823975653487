import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {defineMessages, injectIntl, intlShape} from 'react-intl';
import omit from 'lodash.omit';

import GreenFlag from '../green-flag/green-flag.jsx';
import StopAll from '../stop-all/stop-all.jsx';
import TurboMode from '../turbo-mode/turbo-mode.jsx';
import Camera from '../camera/camera.jsx';
import Mic from '../mic/mic.jsx';

import styles from './controls.css';
import { STAGE_SIZE_MODES } from '../../lib/layout-constants.js';
import { connect } from 'react-redux';
import MousePosition from '../mouse-position/mouse-position.jsx';

const messages = defineMessages({
    goTitle: {
        id: 'gui.controls.go',
        defaultMessage: 'Go',
        description: 'Green flag button title'
    },
    stopTitle: {
        id: 'gui.controls.stop',
        defaultMessage: 'Stop',
        description: 'Stop button title'
    },
    cameraTurnOffTitle: {
        id: 'gui.controls.camera.turnOff',
        defaultMessage: 'turn off camera',
        description: 'turn off camera'
    },
    cameraTurnOnTitle: {
        id: 'gui.controls.camera.turnOn',
        defaultMessage: 'turn on camera',
        description: 'turn on camera'
    },
    micTurnOffTitle: {
        id: 'gui.controls.mic.turnOff',
        defaultMessage: 'turn off microphone',
        description: 'turn off microphone'
    },
    micTurnOnTitle: {
        id: 'gui.controls.mic.turnOn',
        defaultMessage: 'turn on microphone',
        description: 'turn on microphone'
    }
});

const Controls = function (props) {
    const {
        active,
        className,
        intl,
        onGreenFlagClick,
        onStopAllClick,
        onCameraClick,
        isCameraOn,
        isCameraConnected,
        onMicClick,
        isMicOn,
        isMicConnected,
        turbo,
        stageSizeMode,
        isFullScreen,
        ...componentProps
    } = omit(props, 'dispatch');

    const isSmallScreen = stageSizeMode === STAGE_SIZE_MODES.small && !isFullScreen;

    React.useEffect(() => {
        window.dispatchEvent(new Event('resize'));
    }, [stageSizeMode, isFullScreen]);

    return (
        <div
            className={classNames(styles.controlsContainer, className)}
            {...componentProps}
        >
            <GreenFlag
                active={active}
                title={intl.formatMessage(messages.goTitle)}
                onClick={onGreenFlagClick}
            />
            <StopAll
                active={active}
                title={intl.formatMessage(messages.stopTitle)}
                onClick={onStopAllClick}
            />
            {(isCameraConnected && !isSmallScreen) ? <Camera
                title={isCameraOn ? intl.formatMessage(messages.cameraTurnOffTitle) : intl.formatMessage(messages.cameraTurnOnTitle)}
                onClick={onCameraClick}
                isCameraOn={isCameraOn}
            /> : null}
            {(isMicConnected && !isSmallScreen) ? <Mic
                title={isMicOn ? intl.formatMessage(messages.micTurnOffTitle) : intl.formatMessage(messages.micTurnOnTitle)}
                onClick={onMicClick}
                isMicOn={isMicOn}
            /> : null}
            {turbo ? (
                <TurboMode isOnlyIcon={isSmallScreen} />
            ) : null}
            {
                <MousePosition />
            }
        </div>
    );
};

Controls.propTypes = {
    active: PropTypes.bool,
    className: PropTypes.string,
    intl: intlShape.isRequired,
    onGreenFlagClick: PropTypes.func.isRequired,
    onStopAllClick: PropTypes.func.isRequired,
    onCameraClick: PropTypes.func.isRequired,
    isCameraOn: PropTypes.bool,
    isCameraConnected: PropTypes.bool,
    turbo: PropTypes.bool,
    stageSizeMode: PropTypes.oneOf(Object.keys(STAGE_SIZE_MODES)),
    isFullScreen: PropTypes.bool.isRequired,
};

Controls.defaultProps = {
    active: false,
    turbo: false
};

const mapStateToProps = state => ({
    stageSizeMode: state.scratchGui.stageSize.stageSize,
    isFullScreen: state.scratchGui.mode.isFullScreen,
});

export default injectIntl(connect(
    mapStateToProps
)(Controls));