const SET_SCRATCH_BLOCKS = 'scratch-gui/scratch-blocks/SET_SCRATCH_BLOCKS';

const initialState = {
    scratchBlocks: null
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
    case SET_SCRATCH_BLOCKS:
        return Object.assign({}, state, {
            scratchBlocks: action.scratchBlocks
        });
    default:
        return state;
    }
}

const setScratchBlocks = function (scratchBlocks) {
    return {
        type: SET_SCRATCH_BLOCKS,
        scratchBlocks: scratchBlocks
    };
};

export {
    reducer as default,
    initialState as scratchBlocksInitialState,
    setScratchBlocks
};