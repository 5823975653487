const SET_BLOCKS_USED = 'scratch-gui/remove-extension-state/SET_BLOCKS_USED';
const SET_PERIPHERAL_CONNECTED = 'scratch-gui/remove-extension-state/SET_PERIPHERAL_CONNECTED';
const SET_POSITION_Y_OF_ALERT = 'scratch-gui/remove-extension-state/SET_POSITION_Y_OF_ALERT';

const initialState = {
    anyBlockUsedInExtension: false,
    anyPeripheralConnected: false,
    positionYofAlert: 0
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
    case SET_BLOCKS_USED:
        return Object.assign({}, state, {
            anyBlockUsedInExtension: action.anyBlockUsedInExtension
        });
    case SET_PERIPHERAL_CONNECTED:
        return Object.assign({}, state, {
            anyPeripheralConnected: action.anyPeripheralConnected
        });
    case SET_POSITION_Y_OF_ALERT:
        return Object.assign({}, state, {
            positionYofAlert: action.positionYofAlert
        });
    default:
        return state;
    }
};

const setAnyBlockUsedInExtension = isUsed => ({
    type: SET_BLOCKS_USED,
    anyBlockUsedInExtension: isUsed
});

const setAnyPeripheralConnected = isConnected => ({
    type: SET_PERIPHERAL_CONNECTED,
    anyPeripheralConnected: isConnected
});

const setPositionYofAlert = positionYofAlert => ({
    type: SET_POSITION_Y_OF_ALERT,
    positionYofAlert: positionYofAlert
});

const getAlertVisible = state => Object.keys(state).find(key => state[key] === true);

const getNeededAlert = state => {
    if (state.anyBlockUsedInExtension) return 'blockIsUsed';
    if (state.anyPeripheralConnected) return 'peripheralIsConnected';
};

export {
    reducer as default,
    initialState as removeExtensionInitialState,
    setAnyBlockUsedInExtension,
    setAnyPeripheralConnected,
    setPositionYofAlert,
    getNeededAlert,
    getAlertVisible
};
