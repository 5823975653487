import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import RejectRemoveExtensionAlertComponent from '../components/reject-remove-extension-alert/reject-remove-extension-alert.jsx';
class RejectRemoveExtensionAlert extends React.Component {

    constructor (props) {
        super(props);
    
        this.state = {
            isVisible: true
        };
    }

    componentDidMount () {
        setTimeout(() => {
            this.setState({isVisible: false});
        }, 5000);
    }

    render () {
        return this.state.isVisible && <RejectRemoveExtensionAlertComponent message={this.props.message} />;
    }
}

RejectRemoveExtensionAlert.propTypes = {
    message: PropTypes.string
};

export default connect()(RejectRemoveExtensionAlert);
