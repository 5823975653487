import PropTypes from 'prop-types';
import React from 'react';
import styles from './work-loader.css';
import {FormattedMessage} from 'react-intl';
import loadingSpinner from './loading-spinner.gif';

const WorkLoaderComponent = ({message}) => (
    <div className={styles.background}>
        <div className={styles.wrapper}>
            <div className={styles.imageWrapper}>
                <img
                    src={loadingSpinner}
                    className={styles.spinner}
                />
            </div>
            <div className={styles.message}>
                <div>{message}</div>
            </div>
        </div>
    </div>
);

WorkLoaderComponent.propTypes = {
    message: PropTypes.string
};

WorkLoaderComponent.defaultProps = {
};

export default WorkLoaderComponent;
