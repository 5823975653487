import React from 'react';
import TechnicUpdateHex from './technic-update-hex.jsx';
import MalrangUpdateHex from './malrang-update-hex.jsx';
import NemoUpdateHex from './nemo-update-hex.jsx';

const UpdateHexStep = ({ updateHex, handleHexUpdated, vm, extensionId, onScanning, onCancel, onConnected }) => {
    
    const extensionIdToModal = {
        'technic': <TechnicUpdateHex
            extensionId={extensionId}
            handleHexUpdated={handleHexUpdated}
            onScanning={onScanning}
        />,
        'malrang': <MalrangUpdateHex
            vm={vm}
            extensionId={extensionId}
            updateHex={updateHex}
            handleHexUpdated={handleHexUpdated}
            onScanning={onScanning}
        />,
        'nemo': <NemoUpdateHex
            vm={vm}
            extensionId={extensionId}
            updateHex={updateHex}
            handleHexUpdated={handleHexUpdated}
            onCancel={onCancel}
            onConnected={onConnected}
        />
    };
    
    return extensionIdToModal[extensionId];
}

export default UpdateHexStep;
