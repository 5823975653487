import ScratchBlocks from 'scratch-blocks';
import { BlockXMLBase } from './BlockXMLBase';
export class Looks extends BlockXMLBase {
    constructor (isInitialSetup, isStage, targetId, costumeName, backdropName) {
        super();

        const hello = ScratchBlocks.ScratchMsgs.translate('LOOKS_HELLO', 'Hello!');
        const hmm = ScratchBlocks.ScratchMsgs.translate('LOOKS_HMM', 'Hmm...');

        this.looks_sayforsecs = `
            <block type="looks_sayforsecs">
                <value name="MESSAGE">
                    <shadow type="text">
                        <field name="TEXT">${hello}</field>
                    </shadow>
                </value>
                <value name="SECS">
                    <shadow type="math_number">
                        <field name="NUM">2</field>
                    </shadow>
                </value>
            </block>`;

        this.looks_say = `
            <block type="looks_say">
                <value name="MESSAGE">
                    <shadow type="text">
                        <field name="TEXT">${hello}</field>
                    </shadow>
                </value>
            </block>`;

        this.looks_thinkforsecs = `
            <block type="looks_thinkforsecs">
                <value name="MESSAGE">
                    <shadow type="text">
                        <field name="TEXT">${hmm}</field>
                    </shadow>
                </value>
                <value name="SECS">
                    <shadow type="math_number">
                        <field name="NUM">2</field>
                    </shadow>
                </value>
            </block>`;

        this.looks_think = `
            <block type="looks_think">
                <value name="MESSAGE">
                    <shadow type="text">
                        <field name="TEXT">${hmm}</field>
                    </shadow>
                </value>
            </block>`;

        this.looks_switchbackdropto = `
            <block type="looks_switchbackdropto">
                <value name="BACKDROP">
                    <shadow type="looks_backdrops">
                        <field name="BACKDROP">${backdropName}</field>
                    </shadow>
                </value>
            </block>`;

        this.looks_switchbackdroptoandwait = `
            <block type="looks_switchbackdroptoandwait">
                <value name="BACKDROP">
                    <shadow type="looks_backdrops">
                        <field name="BACKDROP">${backdropName}</field>
                    </shadow>
                </value>
            </block>`;

        this.looks_switchcostumeto = `
            <block id="${targetId}_switchcostumeto" type="looks_switchcostumeto">
                <value name="COSTUME">
                    <shadow type="looks_costume">
                        <field name="COSTUME">${costumeName}</field>
                    </shadow>
                </value>
            </block>`;

        this.looks_costumenumbername = `
            <block id="${targetId}_costumenumbername" type="looks_costumenumbername"/>`;

        this.looks_size = `
            <block id="${targetId}_size" type="looks_size"/>`;
    }

    blockOptions = {
        looks_sayforsecs: {
            order: 0,
            stage: false,
            sprite: true
        },
        looks_say: {
            order: 1,
            stage: false,
            sprite: true
        },
        looks_thinkforsecs: {
            order: 2,
            stage: false,
            sprite: true
        },
        looks_think: {
            order: 3,
            stage: false,
            sprite: true
        },
        looks_switchcostumeto: {
            order: 4,
            stage: false,
            sprite: true
        },
        looks_nextcostume: {
            order: 5,
            stage: false,
            sprite: true
        },
        looks_switchbackdropto: {
            order: 6,
            stage: true,
            sprite: true
        },
        looks_switchbackdroptoandwait: {
            order: 7,
            stage: true,
            sprite: false
        },
        looks_nextbackdrop: {
            order: 8,
            stage: true,
            sprite: true
        },
        looks_changesizeby: {
            order: 9,
            stage: false,
            sprite: true
        },
        looks_setsizeto: {
            order: 10,
            stage: false,
            sprite: true
        },
        looks_changeeffectby: {
            order: 11,
            stage: true,
            sprite: true
        },
        looks_seteffectto: {
            order: 12,
            stage: true,
            sprite: true
        },
        looks_cleargraphiceffects: {
            order: 13,
            stage: true,
            sprite: true
        },
        looks_show: {
            order: 14,
            stage: false,
            sprite: true
        },
        looks_hide: {
            order: 15,
            stage: false,
            sprite: true
        },
        looks_gotofrontback: {
            order: 16,
            stage: false,
            sprite: true
        },
        looks_goforwardbackwardlayers: {
            order: 17,
            stage: false,
            sprite: true
        },
        looks_costumenumbername: {
            order: 18,
            stage: false,
            sprite: true
        },
        looks_backdropnumbername: {
            order: 19,
            stage: true,
            sprite: true
        },
        looks_size: {
            order: 20,
            stage: false,
            sprite: true
        }
    };
    
    looks_nextbackdrop = `
        <block type="looks_nextbackdrop"></block>`;
        
    looks_nextcostume = `
        <block type="looks_nextcostume"/>`;

    looks_changesizeby = `
        <block type="looks_changesizeby">
            <value name="CHANGE">
                <shadow type="math_number">
                    <field name="NUM">10</field>
                </shadow>
            </value>
        </block>`;

    looks_setsizeto = `
        <block type="looks_setsizeto">
            <value name="SIZE">
                <shadow type="math_number">
                    <field name="NUM">100</field>
                </shadow>
            </value>
        </block>`;

    looks_changeeffectby = `
        <block type="looks_changeeffectby">
            <value name="CHANGE">
                <shadow type="math_number">
                    <field name="NUM">25</field>
                </shadow>
            </value>
        </block>`;

    looks_seteffectto = `
        <block type="looks_seteffectto">
            <value name="VALUE">
                <shadow type="math_number">
                    <field name="NUM">0</field>
                </shadow>
            </value>
        </block>`;

    looks_cleargraphiceffects = `
        <block type="looks_cleargraphiceffects"/>`;

    looks_show = `
        <block type="looks_show"/>`;

    looks_hide = `
        <block type="looks_hide"/>`;

    looks_gotofrontback = `
        <block type="looks_gotofrontback"/>`;

    looks_goforwardbackwardlayers = `
        <block type="looks_goforwardbackwardlayers">
            <value name="NUM">
                <shadow type="math_integer">
                    <field name="NUM">1</field>
                </shadow>
            </value>
        </block>`;

    looks_backdropnumbername = `
        <block id="backdropnumbername" type="looks_backdropnumbername"/>`;
}