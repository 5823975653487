import { useCallback, useEffect, useMemo, useState } from "react";

/**
 * @param {{
 *  initLineDataset: { name: string, sensor: number }[],
 *  option: {
 *      maxDataLength?: number,
 *      drawInitialLine?: boolean,
 *      name?: string,
 *  }
 * }} param0
 * @returns 
 */
export const useLineGraph = ({
    initLineDataset,
    option = {
        maxDataLength: 20,
        drawInitialLine: true,
        name: 'line',
    }
}) => {
    const [lineData, setLineData] = useState(initLineDataset);

    useEffect(() => {
        if (option.drawInitialLine) {
            let initialData = [...initLineDataset];

            while (initialData.length <= option.maxDataLength) {
                initialData = [...initialData, { name: option.name, sensor: 0 }];
            }

            setLineData(initialData);
        }
    }, [option.drawInitialLine, setLineData]);

    const addData = useCallback((data) => {
        setLineData((prevData) => {
            const newData = [...prevData, data];
            if (newData.length > option.maxDataLength) {
                newData.shift();
            }
            return newData;
        });
    }, [option.maxDataLength, setLineData]);
    
    const clearData = useCallback(() => {
        if (option.drawInitialLine) {
            let initialData = [];

            while (initialData.length <= option.maxDataLength) {
                initialData = [...initialData, { name: option.name, sensor: 0 }];
            }

            setLineData(initialData);
        } else {
            setLineData([]);
        }
    }, [option.drawInitialLine, setLineData]);

    return {
        lineData,
        addData,
        clearData
    };
};