import React from 'react';
import PropTypes from 'prop-types';
import bindAll from 'lodash.bindall';
import CustomInputMenuItemComponent from '../components/custom-input-popup/custom-input-menu-item.jsx';

class CustomInputMenuItem extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleOnClick'
        ]);
    }

    handleOnClick () {
        this.props.onClickItemHandler(this.props.item);
    }

    render () {
        return (
            <CustomInputMenuItemComponent
                item={this.props.itemName}
                onClickHandler={this.handleOnClick}
            />
        );
    }
};

CustomInputMenuItem.propTypes = {
    item: PropTypes.string.isRequired,
    itemName: PropTypes.string.isRequired,
    onClickItemHandler: PropTypes.func.isRequired
};

export default CustomInputMenuItem;