exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".mouse-position_mouse-position-container_2OEC2 {\n    display: -webkit-box;\n    display: -webkit-flex;\n    display: -ms-flexbox;\n    display: flex;\n    -webkit-box-orient: vertical;\n    -webkit-box-direction: normal;\n    -webkit-flex-direction: column;\n        -ms-flex-direction: column;\n            flex-direction: column;\n    -webkit-box-align: start;\n    -webkit-align-items: flex-start;\n        -ms-flex-align: start;\n            align-items: flex-start;\n    padding: 0.25rem;\n    -webkit-user-select: none;\n       -moz-user-select: none;\n        -ms-user-select: none;\n            user-select: none;\n    font-size: 0.6rem;\n    font-weight: bold;\n    color: #5cb1d6;\n    text-wrap: nowrap;\n}", ""]);

// exports
exports.locals = {
	"mouse-position-container": "mouse-position_mouse-position-container_2OEC2",
	"mousePositionContainer": "mouse-position_mouse-position-container_2OEC2"
};