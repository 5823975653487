export default function initMissionMode (vm) {
    const initialEditTargetId = vm.getInitialEditTargetId();
    const initialEditTarget = vm.runtime.getTargetById(initialEditTargetId);

    vm.runtime.targets.forEach(target => {
        if (vm.runtime.hiddenSpriteIdsFromStage.has(target.id)) {
            target.setEffect('ghost', 100);
            target.goToBack();
        }
    })

    vm.runtime.isMissionMode = true;
    vm.runtime.shouldPreventExecuteBlockWithClick = vm.runtime.shouldPreventExecuteBlockWithClick ?? true;

    if (initialEditTarget) {
        if (initialEditTarget !== vm.runtime.getEditingTarget()) {
            vm.setEditingTarget(initialEditTarget.id);
        }
        vm.setInitialEditTarget(initialEditTarget.id);
    }
}
