import PropTypes from 'prop-types';
import React from 'react';
import Box from '../box/box.jsx';
import styles from './mic-snackbar.css';
import classNames from 'classnames';
import propTypes from 'prop-types';

const SNACKBAR_STICK_HEIGHT = 30;

const MicSnackbarComponent = ({waveData, isRecording, isSnackbarShown, recordProgress, fadeout}) => (
    <>
        {
            isSnackbarShown || fadeout ? <Box className={[styles.snackbarContainer, styles.preventDrag]}>
                <div
                    className={classNames(styles.snackbar, fadeout ? styles.hide : styles.show)}
                >
                    {waveData.map((item, idx) => (
                        <div
                            key={idx}
                            className={styles.stick}
                            style={{
                                height: Math.round(((item / 255) * (SNACKBAR_STICK_HEIGHT - 1)) + 1),
                                background: isRecording && '#FFFFFF'
                            }}
                        />
                    ))}
                    <div
                        className={styles.guard}
                    />
                    <div
                        className={styles.animationBackground}
                        style={{width: recordProgress * 2}}
                    />
                </div>
            </Box> : null
        }
    </>
);

MicSnackbarComponent.propTypes = {
    waveData: PropTypes.arrayOf(PropTypes.number),
    isRecording: PropTypes.bool,
    isSnackbarShown: PropTypes.bool,
    recordProgress: propTypes.number,
    fadeout: propTypes.bool
};

MicSnackbarComponent.defaultProps = {
};

export default MicSnackbarComponent;
