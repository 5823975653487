import React from 'react';
import PropTypes from 'prop-types';
import bindAll from 'lodash.bindall';
import VM from 'scratch-vm';
import ConnectionCheckAlertComponent from '../components/connection-check-alert/connection-check-alert.jsx';
import {setConnectionModalExtensionId} from '../reducers/connection-modal';
import {connect} from 'react-redux';
import {openConnectionModal} from '../reducers/modals.js';
import { closeAlertWithId, closeExtensionAlert, showCustomAlert, showCustomAlertWithTimeout, showExtensionAlert } from '../reducers/alerts.js';
import { AlertLevels } from '../lib/alerts/index.jsx';
import successImage from '../lib/assets/icon--success.svg'
import {FormattedMessage} from 'react-intl';

class ConnectionCheckAlert extends React.Component {

    constructor (props) {
        super(props);
        
        bindAll(this, [
            'handleConnected',
            'handleDisconnected',
            'handleClickAlert',
            'handleClickCloseButton',
            'handleHideAlerIfExtensionRemoved'
        ]);

        this.state = {
            extensionId: undefined,
            isAlertVisible: false,
            alertShouldHide: false,
            deviceState: undefined
        };
    }

    componentDidMount () {
        this.props.vm.addListener('PERIPHERAL_CONNECTED', this.handleConnected);
        this.props.vm.addListener('PERIPHERAL_DISCONNECTED', this.handleDisconnected);
        this.props.vm.addListener('REMOVE_EXTENSION', this.handleHideAlerIfExtensionRemoved);
    }
    componentWillUnmount () {
        this.props.vm.removeListener('PERIPHERAL_CONNECTED', this.handleConnected);
        this.props.vm.removeListener('PERIPHERAL_DISCONNECTED', this.handleDisconnected);
        this.props.vm.removeListener('REMOVE_EXTENSION', this.handleHideAlerIfExtensionRemoved);
    }

    isRemovedExtension (extensionId) {
        return extensionId === this.props.extensionId;
    }

    handleHideAlerIfExtensionRemoved (extensionId) {
        if (this.isRemovedExtension(extensionId)){
            this.setState({
                isAlertVisible: false,
                alertShouldHide: true
            });
        }
    }

    handleConnected (extensionId) {
        this.props.onCloseExtensionAlert(this.props.extensionId);

        const content = extensionId === 'teachableMachine' ? 
            <FormattedMessage
                defaultMessage="The trained model has been successfully applied."
                id="gui.connection.check-alert.tmConnected"
            /> : 
            <FormattedMessage
                defaultMessage="The device is connected successfully."
                id="gui.connection.check-alert.connected"
            />;

        this.props.onShowCustomAlert({
            level: AlertLevels.SUCCESS,
            closeButton: false,
            iconURL: successImage,
            content: content,
            alertId: 'connectionCheckAlert',
            maxDisplaySecs: 3,
        });
    }

    handleDisconnected () {
        this.props.onCloseExtensionAlert(this.props.extensionId);
        this.props.onShowDisconnectAlert({
            extensionId: this.props.extensionId,
        });
    }

    handleClickAlert () {
        if (this.state.deviceState === 'connected'){
            this.setState({isAlertVisible: false});
        } else {
            // 연결 모달창 띄우기
            this.props.onOpenConnectionModal(this.props.extensionId);
        }
    }

    handleClickCloseButton () {
        if (this.state.deviceState === 'connected'){
            this.setState({
                isAlertVisible: false
            });
        } else {
            this.setState({
                isAlertVisible: false,
                alertShouldHide: true
            });
        }
    }

    render () {
        if (this.state.isAlertVisible){
            return (
                <ConnectionCheckAlertComponent
                    extensionId={this.state.extensionId}
                    deviceState={this.state.deviceState}
                    onClickAlert={this.handleClickAlert}
                    onClickCloseButton={this.handleClickCloseButton}
                />
            );
        }
        return (<></>);
    }
}

ConnectionCheckAlert.propTypes = {
    vm: PropTypes.instanceOf(VM),
    extensionId: PropTypes.string,
    onOpenConnectionModal: PropTypes.func
};

const mapStateToProps = state => ({
    extensionId: state.scratchGui.connectionModal.extensionId
});

const mapDispatchToProps = dispatch => ({
    onOpenConnectionModal: id => {
        dispatch(setConnectionModalExtensionId(id));
        dispatch(openConnectionModal());
    },
    onShowCustomAlert: alertData => {
        showCustomAlertWithTimeout(dispatch, alertData)
    },
    onCloseCustomAlert: alertId => {
        dispatch(closeAlertWithId(alertId));
    },
    onShowDisconnectAlert: extensionId => {
        dispatch(showExtensionAlert(extensionId));
    },
    onCloseExtensionAlert: extensionId => {
        dispatch(closeExtensionAlert(extensionId));
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectionCheckAlert);
