const UPDATE_TOOLBOX = 'scratch-gui/toolbox/UPDATE_TOOLBOX';
const START_EDIT_TOOLBOX = 'scratch-gui/toolbox/START_EDIT_TOOLBOX';
const STOP_EDIT_TOOLBOX = 'scratch-gui/toolbox/STOP_EDIT_TOOLBOX';

import makeToolboxXML from '../lib/make-toolbox-xml';

const initialState = {
    toolboxXML: makeToolboxXML(true),
    isEditable: false
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
    case UPDATE_TOOLBOX:
        return Object.assign({}, state, {
            toolboxXML: action.toolboxXML
        });
    case START_EDIT_TOOLBOX:
        return Object.assign({}, state, {
            isEditable: true
        });
    case STOP_EDIT_TOOLBOX:
        return Object.assign({}, state, {
            isEditable: false
        });
    default:
        return state;
    }
};

const startEditToolbox = function () {
    return {
        type: START_EDIT_TOOLBOX,
        isEditable: true
    };
};

const stopEditToolbox = function () {
    return {
        type: STOP_EDIT_TOOLBOX,
        isEditable: false
    };
};

const isToolboxEditable = function (state) {
    return state.isEditable;
};

const updateToolbox = function (toolboxXML) {
    return {
        type: UPDATE_TOOLBOX,
        toolboxXML: toolboxXML
    };
};

export {
    reducer as default,
    initialState as toolboxInitialState,
    updateToolbox,
    startEditToolbox,
    stopEditToolbox,
    isToolboxEditable
};
