import React from 'react';
import bindAll from 'lodash.bindall';
import CodingPlayButtonComponent from '../components/coding-play-button/coding-play-button.jsx';
import VM from 'scratch-vm';
import PropTypes from 'prop-types';
import log from '../lib/log.js';

class CodingPlayButton extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            'playLinkCoding',
            'stopLinkCoding',
            'handleBlockDragUpdate'
        ]);
        this.state = {
            isPlaying: false
        };
    }

    componentDidMount() {
        this.props.vm.addListener('BLOCK_DRAG_UPDATE', this.handleBlockDragUpdate);
        this.props.vm.addListener('MARK_CODING_DISABLED', () => {
            this.setState({ isPlaying: false });
        });
    }
    componentWillUnmount() {
        this.props.vm.removeListener('BLOCK_DRAG_UPDATE', this.handleBlockDragUpdate);
    }

    handleBlockDragUpdate() {
        if (this.state.isPlaying) {
            this.stopLinkCoding();
        }
    }

    playLinkCoding() {
        this.props.vm.extensionManager.playLinkCoding().then(() => {
            this.setState({ isPlaying: true });
        })
            .catch(error => {
                this.setState({ isPlaying: false });
                log.error(error);
            });
        document.activeElement.blur();
    }

    stopLinkCoding() {
        this.props.vm.extensionManager.stopLinkCoding().then(() => {
            this.setState({ isPlaying: false });
        })
            .catch(error => {
                log.error(error);
            });
        document.activeElement.blur();
    }

    render() {
        return (
            <CodingPlayButtonComponent
                isPlaying={this.state.isPlaying}
                playLinkCoding={this.playLinkCoding}
                stopLinkCoding={this.stopLinkCoding}
            />
        );
    }
}

CodingPlayButton.propTypes = {
    vm: PropTypes.PropTypes.instanceOf(VM)
};

export default CodingPlayButton;
