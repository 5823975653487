import React from 'react';
import PropTypes from 'prop-types';
import WorkLoaderComponent from '../components/work-loader/work-loader.jsx';

class WorkLoader extends React.Component {

    render () {
        return (<WorkLoaderComponent
            message={this.props.message}
        />);
    }
}

WorkLoader.propTypes = {
    message: PropTypes.string
};


export default WorkLoader;
