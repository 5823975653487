const LOADING_STATE_VISIBLE = 'scratch-gui/work-loading-state/LOADING_STATE_VISIBLE';
const LOADING_STATE_HIDDEN = 'scratch-gui/work-loading-state/LOADING_STATE_HIDDEN';

const initialState = {
    teachableMachine: false,
    message: ''
};

const workLoadingStateReducer = (state = initialState, action) => {
    switch (action.type) {
    case LOADING_STATE_VISIBLE:
        return Object.assign({}, state, {
            [action.loadingState]: true,
            message: action.message
        });
    case LOADING_STATE_HIDDEN:
        return initialState;
    default:
        return state;
    }
};

const loadingStateVisible = (workType, message) => ({
    type: LOADING_STATE_VISIBLE,
    loadingState: workType,
    message: message
});

const loadingStateHidden = () => ({
    type: LOADING_STATE_HIDDEN,
    message: ''
});

const setVisible = (workType, message) => loadingStateVisible(workType, message);

const setHidden = () => loadingStateHidden();

const getLoadingWorkState = state => Object.values(state).find(value => value === true);

const getLoadingStateMessage = state => state.message;

export {
    workLoadingStateReducer as default,
    initialState as workLoadingStateInitialState,
    setVisible,
    setHidden,
    getLoadingWorkState,
    getLoadingStateMessage
};
