import ScratchBlocks from 'scratch-blocks';
import { BlockXML } from './classes';
import { BlockXMLUtils } from './utils';
import { getWorkspaceProcCodes, getWorkspaceVariableNames, hasVisibleOpcode } from './utils/malrangHideBlock';

const categorySeparator = '<sep gap="36"/>';

const blockSeparator = '<sep gap="36"/>'; // At default scale, about 28px

const emptyCategory = `<category name="" colour="#E9EEF2" secondaryColour="#E9EEF2" />`;

/* eslint-disable no-unused-vars */
const motion = function (isInitialSetup, isStage, targetId) {
    const stageSelected = ScratchBlocks.ScratchMsgs.translate(
        'MOTION_STAGE_SELECTED',
        'Stage selected: no motion blocks'
    );
    const { Motion } = BlockXML.motion;
    const motion = new Motion(isInitialSetup, isStage, targetId);
    return `
    <category name="%{BKY_CATEGORY_MOTION}" id="motion" colour="#4C97FF" secondaryColour="#3373CC">
        ${isStage ? `
        <label text="${stageSelected}"></label>
        ` : `
        ${motion.motion_movesteps}
        ${motion.motion_turnright}
        ${motion.motion_turnleft}
        ${blockSeparator}
        ${motion.motion_goto}
        ${motion.motion_gotoxy}
        ${motion.motion_glideto}
        ${motion.motion_glidesecstoxy}
        ${blockSeparator}
        ${motion.motion_pointindirection}
        ${motion.motion_pointtowards}
        ${blockSeparator}
        ${motion.motion_changexby}
        ${motion.motion_setx}
        ${motion.motion_changeyby}
        ${motion.motion_sety}
        ${blockSeparator}
        ${motion.motion_ifonedgebounce}
        ${blockSeparator}
        ${motion.motion_setrotationstyle}
        ${blockSeparator}
        ${motion.motion_xposition}
        ${motion.motion_yposition}
        ${motion.motion_direction}
        `}
        ${categorySeparator}
    </category>
    `;
};

const xmlEscape = function (unsafe) {
    return unsafe.replace(/[<>&'"]/g, c => {
        switch (c) {
        case '<': return '&lt;';
        case '>': return '&gt;';
        case '&': return '&amp;';
        case '\'': return '&apos;';
        case '"': return '&quot;';
        }
    });
};

const looks = function (isInitialSetup, isStage, targetId, costumeName, backdropName) {
    const { Looks } = BlockXML.looks;
    const looks = new Looks(isInitialSetup, isStage, targetId, costumeName, backdropName);
    return `
    <category name="%{BKY_CATEGORY_LOOKS}" id="looks" colour="#9966FF" secondaryColour="#774DCB">
        ${isStage ? '' : `
        ${looks.looks_sayforsecs}
        ${looks.looks_say}
        ${looks.looks_thinkforsecs}
        ${looks.looks_think}
        ${blockSeparator}
        `}
        ${isStage ? `
            ${looks.looks_switchbackdropto}
            ${looks.looks_switchbackdroptoandwait}
            ${looks.looks_nextbackdrop}
        ` : `
            ${looks.looks_switchcostumeto}
            ${looks.looks_nextcostume}
            ${looks.looks_switchbackdropto}
            ${looks.looks_nextbackdrop}
            ${blockSeparator}
            ${looks.looks_changesizeby}
            ${looks.looks_setsizeto}
        `}
        ${blockSeparator}
        ${looks.looks_changeeffectby}
        ${looks.looks_seteffectto}
        ${looks.looks_cleargraphiceffects}
        ${blockSeparator}
        ${isStage ? '' : `
            ${looks.looks_show}
            ${looks.looks_hide}
        ${blockSeparator}
            ${looks.looks_gotofrontback}
            ${looks.looks_goforwardbackwardlayers}
        `}
        ${isStage ? `
            ${looks.looks_backdropnumbername}
        ` : `
            ${looks.looks_costumenumbername}
            ${looks.looks_backdropnumbername}
            ${looks.looks_size}
        `}
        ${categorySeparator}
    </category>
    `;
};

const sound = function (isInitialSetup, isStage, targetId, soundName) {
    const { Sound } = BlockXML.sound;
    const sound = new Sound(isInitialSetup, isStage, targetId, soundName);
    return `
    <category name="%{BKY_CATEGORY_SOUND}" id="sound" colour="#D65CD6" secondaryColour="#BD42BD">
        ${sound.sound_playuntildone}
        ${sound.sound_play}
        ${sound.sound_stopallsounds}
        ${blockSeparator}
        ${sound.sound_changeeffectby}
        ${sound.sound_seteffectto}
        ${sound.sound_cleareffects}
        ${blockSeparator}
        ${sound.sound_changevolumeby}
        ${sound.sound_setvolumeto}
        ${sound.sound_volume}
        ${categorySeparator}
    </category>
    `;
};

const events = function (isInitialSetup, isStage) {
    const { Events } = BlockXML.events;
    const event = new Events();
    return `
    <category name="%{BKY_CATEGORY_EVENTS}" id="events" colour="#FFD500" secondaryColour="#CC9900">
        ${event.event_whenflagclicked}
        ${event.event_whenkeypressed}
        ${isStage ? `
            ${event.event_whenstageclicked}
        ` : `
            ${event.event_whenthisspriteclicked}
        `}
        ${event.event_whenbackdropswitchesto}
        ${blockSeparator}
        ${event.event_whengreaterthan}
        ${blockSeparator}
        ${event.event_whenbroadcastreceived}
        ${event.event_broadcast_menu}
        ${event.event_broadcastandwait}
        ${categorySeparator}
    </category>
    `;
};

const control = function (isInitialSetup, isStage, _, isMissionMode) {
    const { Control } = BlockXML.control;
    const control = new Control();
    return `
    <category name="%{BKY_CATEGORY_CONTROL}" id="control" colour="#FFAB19" secondaryColour="#CF8B17">
        ${control.control_wait}
        ${blockSeparator}
        ${control.control_repeat}
        ${control.control_forever}
        ${isMissionMode ? control.control_break : ''}
        ${blockSeparator}
        ${control.control_if}
        ${control.control_if_else}
        ${control.control_wait_until}
        ${control.control_repeat_until}
        ${blockSeparator}
        ${control.control_stop}
        ${blockSeparator}
        ${isStage ? `
            ${control.control_create_clone_of}
        ` : `
            ${control.control_start_as_clone}
            ${control.control_create_clone_of}
            ${control.control_delete_this_clone}
        `}
        ${categorySeparator}
    </category>
    `;
};

const sensing = function (isInitialSetup, isStage) {
    const name = ScratchBlocks.ScratchMsgs.translate('SENSING_ASK_TEXT', 'What\'s your name?');
    const { Sensing } = BlockXML.sensing;
    const sensing = new Sensing();
    return `
    <category name="%{BKY_CATEGORY_SENSING}" id="sensing" colour="#4CBFE6" secondaryColour="#2E8EB8">
        ${isStage ? '' : `
            ${sensing.sensing_touchingobject}
            ${sensing.sensing_touchingcolor}
            ${sensing.sensing_coloristouchingcolor}
            ${sensing.sensing_distanceto}
            ${blockSeparator}
        `}
        ${isInitialSetup ? '' : `
            ${sensing.sensing_askandwait}
        `}
        ${sensing.sensing_answer}
        ${blockSeparator}
        ${sensing.sensing_keypressed}
        ${sensing.sensing_mousedown}
        ${sensing.sensing_mousex}
        ${sensing.sensing_mousey}
        ${isStage ? '' : `
            ${blockSeparator}
            '${sensing.sensing_setdragmode}'
            ${blockSeparator}
        `}
        ${blockSeparator}
        ${sensing.sensing_loudness}
        ${blockSeparator}
        ${sensing.sensing_timer}
        ${sensing.sensing_resettimer}
        ${blockSeparator}
        ${sensing.sensing_of}
        ${blockSeparator}
        ${sensing.sensing_current}
        ${sensing.sensing_dayssince2000}
        ${blockSeparator}
        ${sensing.sensing_username}
        ${categorySeparator}
    </category>
    `;
};

const operators = function (isInitialSetup) {
    const { Operators } = BlockXML.operators;
    const operators = new Operators();
    return `
    <category name="%{BKY_CATEGORY_OPERATORS}" id="operators" colour="#40BF4A" secondaryColour="#389438">
        ${operators.operator_add}
        ${operators.operator_subtract}
        ${operators.operator_multiply}
        ${operators.operator_divide}
        ${blockSeparator}
        ${operators.operator_random}
        ${blockSeparator}
        ${operators.operator_gt}
        ${operators.operator_lt}
        ${operators.operator_equals}
        ${blockSeparator}
        ${operators.operator_and}
        ${operators.operator_or}
        ${operators.operator_not}
        ${blockSeparator}
        ${isInitialSetup ? '' : `
            ${operators.operator_join}
            ${operators.operator_letter_of}
            ${operators.operator_length}
            ${operators.operator_contains}
        `}
        ${blockSeparator}
        ${operators.operator_mod}
        ${operators.operator_round}
        ${blockSeparator}
        ${operators.operator_mathop}
        ${categorySeparator}
    </category>
    `;
};

const variables = function () {
    return `
    <category
        name="%{BKY_CATEGORY_VARIABLES}"
        id="variables"
        colour="#FF8C1A"
        secondaryColour="#DB6E00"
        custom="VARIABLE">
    </category>
    `;
};

const myBlocks = function () {
    return `
    <category
        name="%{BKY_CATEGORY_MYBLOCKS}"
        id="myBlocks"
        colour="#FF6680"
        secondaryColour="#FF4D6A"
        custom="PROCEDURE">
    </category>
    `;
};
/* eslint-enable no-unused-vars */

const xmlOpen = '<xml style="display: none">';
const xmlClose = '</xml>';

/**
 * @param {!boolean} isInitialSetup - Whether the toolbox is for initial setup. If the mode is "initial setup",
 * blocks with localized default parameters (e.g. ask and wait) should not be loaded. (LLK/scratch-gui#5445)
 * @param {?boolean} isStage - Whether the toolbox is for a stage-type target. This is always set to true
 * when isInitialSetup is true.
 * @param {?string} targetId - The current editing target
 * @param {?Array.<object>} categoriesXML - optional array of `{id,xml}` for categories. This can include both core
 * and other extensions: core extensions will be placed in the normal Scratch order; others will go at the bottom.
 * @property {string} id - the extension / category ID.
 * @property {string} xml - the `<category>...</category>` XML for this extension / category.
 * @param {?string} costumeName - The name of the default selected costume dropdown.
 * @param {?string} backdropName - The name of the default selected backdrop dropdown.
 * @param {?string} soundName -  The name of the default selected sound dropdown.
 * @returns {string} - a ScratchBlocks-style XML document for the contents of the toolbox.
 */
const makeToolboxXML = function (isInitialSetup, isStage = true, targetId, categoriesXML = [],
    costumeName = '', backdropName = '', soundName = '', vm, showAllBlocks = false) {
    const runtime = vm?.runtime;

    isStage = isInitialSetup || isStage;
    const gap = [categorySeparator];

    costumeName = xmlEscape(costumeName);
    backdropName = xmlEscape(backdropName);
    soundName = xmlEscape(soundName);

    categoriesXML = categoriesXML.slice();
    const moveCategory = categoryId => {
        const index = categoriesXML.findIndex(categoryInfo => categoryInfo.id === categoryId);
        if (index >= 0) {
            // remove the category from categoriesXML and return its XML
            const [categoryInfo] = categoriesXML.splice(index, 1);
            return categoryInfo.xml;
        }
        // return `undefined`
    };

    const defaultCategoriesXML = {
        motion: moveCategory('motion') || motion(isInitialSetup, isStage, targetId),
        looks: moveCategory('looks') || looks(isInitialSetup, isStage, targetId, costumeName, backdropName),
        sound: moveCategory('sound') || sound(isInitialSetup, isStage, targetId, soundName),
        event: moveCategory('events') || events(isInitialSetup, isStage, targetId),
        control: moveCategory('control') || control(isInitialSetup, isStage, targetId, runtime?.isMissionMode),
        sensing: moveCategory('sensing') || sensing(isInitialSetup, isStage, targetId),
        operator: moveCategory('operators') || operators(isInitialSetup, isStage, targetId),
        data: moveCategory('data') || variables(isInitialSetup, isStage, targetId),
        procedures: moveCategory('procedures') || myBlocks(isInitialSetup, isStage, targetId),
    };

    const everything = [xmlOpen];
    const filterBlockCategories = runtime?.filterBlockCategories ?? false;
    if (
        runtime &&
        filterBlockCategories &&
        !showAllBlocks &&
        (runtime.isMissionMode || runtime.openMalrangModal)
    ) {
        const visibleBlocks = runtime.openMalrangModal ? runtime.allVisibleBlocks : runtime.visibleBlocks;
        const workspaceVariableNames = runtime.openMalrangModal ? getWorkspaceVariableNames(runtime, true) : vm.workspaceVariableNames;
        const workspaceProcCodes = runtime.openMalrangModal ? getWorkspaceProcCodes(runtime, true) : vm.workspaceProcCodes;

        if (hasVisibleOpcode(visibleBlocks, 'motion')) {
            everything.push(BlockXMLUtils.motion(isInitialSetup, isStage, targetId, visibleBlocks['motion'], showAllBlocks));
        }
        if (hasVisibleOpcode(visibleBlocks, 'looks')) {
            everything.push(BlockXMLUtils.looks(isInitialSetup, isStage, targetId, costumeName, backdropName, visibleBlocks['looks'], showAllBlocks));
        }
        if (hasVisibleOpcode(visibleBlocks, 'sound')) {
            everything.push(BlockXMLUtils.sound(isInitialSetup, isStage, targetId, soundName, visibleBlocks['sound'], showAllBlocks));
        }
        if (hasVisibleOpcode(visibleBlocks, 'event')) {
            everything.push(BlockXMLUtils.events(isInitialSetup, isStage, targetId, visibleBlocks['event'], showAllBlocks));
        }
        if (hasVisibleOpcode(visibleBlocks, 'control')) {
            everything.push(BlockXMLUtils.control(isInitialSetup, isStage, targetId, visibleBlocks['control'], showAllBlocks));
        }
        if (hasVisibleOpcode(visibleBlocks, 'sensing')) {
            everything.push(BlockXMLUtils.sensing(isInitialSetup, isStage, targetId, visibleBlocks['sensing'], showAllBlocks));
        }
        if (hasVisibleOpcode(visibleBlocks, 'operator')) {
            everything.push(BlockXMLUtils.operators(isInitialSetup, isStage, targetId, visibleBlocks['operator'], showAllBlocks));
        }
        if (hasVisibleOpcode(visibleBlocks, 'data', workspaceVariableNames, vm)) {
            everything.push(moveCategory('data') || variables(isInitialSetup, isStage, targetId));
        }
        if (hasVisibleOpcode(visibleBlocks, 'procedures', workspaceProcCodes, vm)) {
            everything.push(moveCategory('procedures') || myBlocks(isInitialSetup, isStage, targetId));
        }

        const noVisibleBlockInWorkspace = everything.length === 1;
        const noExtensionCategories = runtime._blockInfo.length === 0;

        if (noVisibleBlockInWorkspace && noExtensionCategories) {
            everything.push(emptyCategory);
        }
    }
     else {
        Object.values(defaultCategoriesXML).forEach((category) => {
            everything.push(category)
        });
    }

    everything.push(gap);
    if (everything.at(-1) === gap) {
        everything.pop();
    }

    for (const extensionCategory of categoriesXML) {
        everything.push(gap, extensionCategory.xml);
    }

    everything.push(xmlClose);
    return everything.join('\n');
};

export default makeToolboxXML;
