import bindAll from 'lodash.bindall';
import React from 'react';

import {connect} from 'react-redux';
import {setAutoClose} from '../reducers/auto-close';
import AutoCloseSwitchComponent from '../components/auto-close-switch/auto-close-switch.jsx';

class AutoCloseSwitch extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleAutoCloseChange'
        ]);
    }
    handleAutoCloseChange (autoClose) {
        this.props.setAutoClose(autoClose);
    }
    render () {
        return (
            <AutoCloseSwitchComponent
                autoClose={this.props.autoClose}
                onAutoCloseChange={this.handleAutoCloseChange}
            />
        );
    }
}

const mapStateToProps = state => ({
    autoClose: state.scratchGui.autoCloseState.autoClose
});

const mapDispatchToProps = dispatch => ({
    setAutoClose: autoClose => dispatch(setAutoClose(autoClose))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AutoCloseSwitch);
