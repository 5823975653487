import React from 'react';

const AddClassButtonIcon = props => (<svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <path d="M8.88889 11.1111V14.4444C8.88889 14.7593 8.99537 15.0231 9.20833 15.2361C9.4213 15.4491 9.68519 15.5556 10 15.5556C10.3148 15.5556 10.5787 15.4491 10.7917 15.2361C11.0046 15.0231 11.1111 14.7593 11.1111 14.4444V11.1111H14.4444C14.7593 11.1111 15.0231 11.0046 15.2361 10.7917C15.4491 10.5787 15.5556 10.3148 15.5556 10C15.5556 9.68519 15.4491 9.4213 15.2361 9.20833C15.0231 8.99537 14.7593 8.88889 14.4444 8.88889H11.1111V5.55556C11.1111 5.24074 11.0046 4.97685 10.7917 4.76389C10.5787 4.55093 10.3148 4.44444 10 4.44444C9.68519 4.44444 9.4213 4.55093 9.20833 4.76389C8.99537 4.97685 8.88889 5.24074 8.88889 5.55556V8.88889H5.55556C5.24074 8.88889 4.97685 8.99537 4.76389 9.20833C4.55093 9.4213 4.44444 9.68519 4.44444 10C4.44444 10.3148 4.55093 10.5787 4.76389 10.7917C4.97685 11.0046 5.24074 11.1111 5.55556 11.1111H8.88889ZM2.22222 20C1.61111 20 1.08796 19.7824 0.652778 19.3472C0.217593 18.912 0 18.3889 0 17.7778V2.22222C0 1.61111 0.217593 1.08796 0.652778 0.652778C1.08796 0.217593 1.61111 0 2.22222 0H17.7778C18.3889 0 18.912 0.217593 19.3472 0.652778C19.7824 1.08796 20 1.61111 20 2.22222V17.7778C20 18.3889 19.7824 18.912 19.3472 19.3472C18.912 19.7824 18.3889 20 17.7778 20H2.22222ZM2.22222 17.7778H17.7778V2.22222H2.22222V17.7778Z" />
</svg>
);

export default AddClassButtonIcon;
