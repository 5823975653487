import React from 'react';
import PropTypes from 'prop-types';
import CustomInputMenuItem from '../../containers/custom-input-menu-item.jsx';

import styles from './custom-input-popup.css';

const SENSOR_NAME = {
    SWITCH: '스위치',
    INFRARED: '적외선',
    MAGNET: '자석',
    ROTATION: '회전',
    COLOR: '컬러',
    ULTRASONIC: '초음파',
    HEARTRATE: '심박',
    ILLUMINANCE: '조도',
    SOUND: '소리',
    TILT: '기울기',
    PRESSURE: '압력',
    MULTIKEY: '멀티키',
    COMPASS: '나침반',
    TRIAXIS: '3가속',
    DIVIDER: '분배기',
    ACCELEROMETER: '가속도'
};

const CustomInputMonitorComponent = props => {
    const isTechnic = props.monitorName === 'technic_monitor';
    const categoryColor = isTechnic ? '#FFB13C' :'#0FBD8C';

    return (
        <div className={styles.column} >
            <div className={styles.defaultMonitor} >
                <div className={styles.row} >
                    <div className={styles.label} >
                        {props.label}
                    </div>
                    <div className={styles.menu} >
                        <div
                            className={styles.topItem}
                            onClick={props.onClickMenuHandler}
                        >
                            {SENSOR_NAME[props.sensorSelected] + '▼'}
                        </div>
                    </div>
                    <div
                        className={styles.value}
                        style={{background: categoryColor}}
                    >
                        {props.value}
                    </div>
                </div>
            </div>
            {props.isMenuOpen ? (
                <>
                    <div className={styles.verticalDivider} />
                    <div className={styles.itemContainer}>
                        {Object.keys(SENSOR_NAME).map(item => (
                            <CustomInputMenuItem
                                item={item}
                                itemName={SENSOR_NAME[item]}
                                onClickItemHandler={props.onClickItemHandler}
                            />
                        ))}
                    </div>
                </>
            ) : null}
        </div>
    );
};

CustomInputMonitorComponent.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    onClickMenuHandler: PropTypes.func.isRequired,
    onClickItemHandler: PropTypes.func.isRequired
};

export default CustomInputMonitorComponent;