import { connect } from 'react-redux';
import { closeMalrangTutorialModal } from '../reducers/modals.js';
import MalrangTutorialModalComponent from '../components/malrang-tutorial-modal/malrang-tutorial-modal.jsx';
import React from 'react';
import bindAll from 'lodash.bindall';
import { setWorkspace } from '../reducers/workspace.js';
import DragConstants from '../lib/drag-constants.js';
import { setMissionMode } from '../reducers/mode.js';
import {
    getCheckedblocks,
    getUnCheckedWorkspaceProcedureNames,
    getUnCheckedWorkspaceVariableNames,
    makeHideBlockCheckboxes,
    removeHideBlockCheckboxes
} from '../lib/utils/malrangHideBlock.js';
import { updateToolbox } from '../reducers/toolbox.js';
import { setMaximumGreenFlagClickCount, setShowAllBlockCategories, setHiddenSpriteFromStage } from '../reducers/malrang-tutorial.js';
import MalrangTutorialEventHandler from '../lib/classes/malrang-tutorial/malrang-tutorial-event-handler.js';
import MalrangTutorialBlockService from '../lib/classes/malrang-tutorial/malrang-tutorial-block-service.js';
import MalrangTutorialWorkspaceService from '../lib/classes/malrang-tutorial/malrang-tutorial-workspace-service.js';
import { SPRITE_LIST_TYPE } from '../lib/constant/malrangTutorial.js';

class MalrangTutorialModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            targetWorkspaceBlocks: [],
            hiddenSpriteIds: new Set(),
            initialSpriteId: this.props.vm.getInitialEditTargetId() ?? '',
            shouldPreventClickStage: this.props.vm.shouldPreventClickStage,
            shouldPreventAddStage: this.props.vm.shouldPreventAddStage,
            shouldPreventDeleteStage: this.props.vm.shouldPreventDeleteStage,
            shouldPreventDragSprite: this.props.vm.shouldPreventDragSprite(),
            shouldPreventEditSprite: this.props.vm.shouldPreventEditSprite(),
            shouldPreventAddSprite: this.props.vm.shouldPreventAddSprite(),
            shouldPreventDeleteSprite: this.props.vm.shouldPreventDeleteSprite(),
            shouldPreventSelectSprite: this.props.vm.shouldPreventSelectSprite(),
            shouldHideCostumeTab: this.props.vm.shouldHideCostumeTab(),
            shouldHideSoundTab: this.props.vm.shouldHideSoundTab(),
            shouldHideExtensionButton: this.props.vm.shouldHideExtensionButton(),
            shouldHideFileMenu: this.props.vm.shouldHideFileMenu(),
            shouldHideEditMenu: this.props.vm.shouldHideEditMenu(),
            checkedHideBlocks: [],
            blockSvgGroups: [],
            shouldPreventExecuteBlockWithClick: this.props.vm.runtime.shouldPreventExecuteBlockWithClick,
            tooltip: {
                message: '',
                spriteId: null,
                type: null,
            },
        };
        bindAll(this, [
            'toggleShowAllBlockCategories',
            'updateBlocksFiltering',
            'handleDrop',
            'handleRequestClose',
            'handleSetSpriteVisibleOnTargetPane',
            'isSpriteHidden',
            'handleSetInitialSprite',
            'handleDownloadProject',
            'onProjectSaveFinished',
        ]);

        this.malrangTutorialBlockService = new MalrangTutorialBlockService(this);
        this.malrangTutorialWorkspaceService = new MalrangTutorialWorkspaceService(this);
        this.malrangTutorialWorkspaceService.scrollWorkspaceToolboxToTop();
        this.eventHandler = new MalrangTutorialEventHandler(this);

        if (!this.props.vm.runtime.allVisibleBlocks) {
            this.props.vm.runtime.allVisibleBlocks = {};
        }

        if (Object.keys(this.props.vm.runtime.visibleBlocks).length === 0) {
            this.props.vm.runtime.visibleBlocks = this.malrangTutorialBlockService.visibleBlocks;
        }
    }

    componentDidMount() {
        this.props.vm.runtime.openMalrangModal = true;
        this.props.vm.runtime.filterBlockCategories = !this.props.showAllBlockCategories;
        const { visibleBlocks } = this.props.vm.runtime;

        this.malrangTutorialWorkspaceService.loadAllBlocks();

        if (Object.keys(visibleBlocks).length > 0) {
            setTimeout(() => {
                this.props.vm.emitWorkspaceUpdate();
            })
        }

        const hiddenSpriteIds = Object.keys(this.props.sprites).reduce((acc, spriteId) => {
            if (this.props.vm.isSpriteHidden(spriteId)) {
                acc.add(spriteId);
            }
            return acc;
        }, new Set());
        this.setState({ hiddenSpriteIds });

        if (!this.state.initialSpriteId) {
            const firstVisibleSpriteId = Object.keys(this.props.sprites).find(id => {
                const target = this.props.vm.runtime.getTargetById(id);
                return !target.isStage && !this.isSpriteHidden(id);
            });
            if (firstVisibleSpriteId) {
                this.setState({ initialSpriteId: firstVisibleSpriteId });
            }
        }

        if (!this.props.showAllBlockCategories) {
            makeHideBlockCheckboxes(this.props.vm);
        }

        if (this.props.hiddenSpriteIdsFromStage.size === 0) {
            this.props.vm.runtime.hiddenSpriteIdsFromStage.forEach((spriteId) => {
                this.props.handleClickHideFromStageCheckbox(spriteId);
            });
        }
    }

    componentDidUpdate(prevProps, nextState) {
        if (
            prevProps.showAllBlockCategories !== this.props.showAllBlockCategories &&
            this.props.showAllBlockCategories === false
        ) {
            this.props.vm.runtime.filterBlockCategories = !this.props.showAllBlockCategories;
            this.malrangTutorialWorkspaceService.scrollWorkspaceToolboxToTop();
        }

        if (prevProps.shouldPreventClickStage !== this.state.shouldPreventClickStage) {
            this.props.vm.shouldPreventClickStage = this.state.shouldPreventClickStage;
        }

        if (prevProps.shouldPreventAddStage !== this.state.shouldPreventAddStage) {
            this.props.vm.shouldPreventAddStage = this.state.shouldPreventAddStage;
        }

        if (prevProps.shouldPreventDeleteStage !== this.state.shouldPreventDeleteStage) {
            this.props.vm.shouldPreventDeleteStage = this.state.shouldPreventDeleteStage;
        }

        if (prevProps.maximumGreenFlagClickCount !== this.props.maximumGreenFlagClickCount) {
            this.props.vm.runtime.maximumGreenFlagClickCount = this.props.maximumGreenFlagClickCount;
        }

        if(this.props.showAllBlockCategories !== nextState.showAllBlockCategories) {
            this.props.showAllBlockCategories ?
                removeHideBlockCheckboxes(this.props.vm) :
                makeHideBlockCheckboxes(this.props.vm);
        }
    }

    componentWillUnmount() {
        this.props.vm.runtime.filterBlockCategories = false;
        this.malrangTutorialWorkspaceService.resetWorkspace();
        this.malrangTutorialWorkspaceService.scrollWorkspaceToolboxToTop();

        this.malrangTutorialWorkspaceService.injectionDiv.appendChild(this.malrangTutorialWorkspaceService.blocklyToolbox);
        this.malrangTutorialWorkspaceService.injectionDiv.appendChild(this.malrangTutorialWorkspaceService.blocklyFlyout);
        this.malrangTutorialWorkspaceService.injectionDiv.appendChild(this.malrangTutorialWorkspaceService.blocklyFlyoutScrollbar);

        this.props.vm.extensionManager.refreshBlocks();

        this.props.vm.runtime.openMalrangModal = false;

        removeHideBlockCheckboxes(this.props.vm);

        setTimeout(() => {
            this.props.vm.emitWorkspaceUpdate();
        });
    }

    toggleShowAllBlockCategories() {
        this.props.vm.runtime.filterBlockCategories = !this.props.showAllBlockCategories;
        this.props.updateShowAllBlockCategories(!this.props.showAllBlockCategories);
    }

    updateBlocksFiltering() {
        this.malrangTutorialWorkspaceService.onUpdateBlocksFiltering();
        this.props.vm.workspaceVariableNames = getUnCheckedWorkspaceVariableNames(
            this.malrangTutorialWorkspaceService.workspaceVariableNames,
            this.props.vm.runtime.checkedVariableBlocks
        );
        this.props.vm.workspaceProcCodes = getUnCheckedWorkspaceProcedureNames(
            this.malrangTutorialWorkspaceService.workspaceProcCodes,
            this.props.vm.runtime.checkedProcedureBlocks
        );
        this.props.vm.runtime.visibleBlocks = getCheckedblocks(this.malrangTutorialBlockService.visibleBlocks, this.props.vm.runtime.checkedHideBlocks);
        this.props.vm.runtime.allVisibleBlocks = this.malrangTutorialBlockService.visibleBlocks;
        this.props.vm.emitWorkspaceUpdate(true);
        this.malrangTutorialWorkspaceService.scrollWorkspaceToolboxToTop();
    }

    handleDrop (dragInfo) {
        const {sprite: targetId} = this.props.hoveredTarget;
        if (dragInfo.dragType === DragConstants.SPRITE) {
            // Add one to both new and target index because we are not counting/moving the stage
            this.props.vm.reorderTarget(dragInfo.index + 1, dragInfo.newIndex + 1);
        } else if (dragInfo.dragType === DragConstants.BACKPACK_SPRITE) {
            // TODO storage does not have a way of loading zips right now, and may never need it.
            // So for now just grab the zip manually.
            fetchSprite(dragInfo.payload.bodyUrl)
                .then(sprite3Zip => this.props.vm.addSprite(sprite3Zip));
        } else if (targetId) {
            // Something is being dragged over one of the sprite tiles or the backdrop.
            // Dropping assets like sounds and costumes duplicate the asset on the
            // hovered target. Shared costumes also become the current costume on that target.
            // However, dropping does not switch the editing target or activate that editor tab.
            // This is based on 2.0 behavior, but seems like it keeps confusing switching to a minimum.
            // it allows the user to share multiple things without switching back and forth.
            if (dragInfo.dragType === DragConstants.COSTUME) {
                this.props.vm.shareCostumeToTarget(dragInfo.index, targetId);
            } else if (targetId && dragInfo.dragType === DragConstants.SOUND) {
                this.props.vm.shareSoundToTarget(dragInfo.index, targetId);
            } else if (dragInfo.dragType === DragConstants.BACKPACK_COSTUME) {
                // In scratch 2, this only creates a new sprite from the costume.
                // We may be able to handle both kinds of drops, depending on where
                // the drop happens. For now, just add the costume.
                this.props.vm.addCostume(dragInfo.payload.body, {
                    name: dragInfo.payload.name
                }, targetId);
            } else if (dragInfo.dragType === DragConstants.BACKPACK_SOUND) {
                this.props.vm.addSound({
                    md5: dragInfo.payload.body,
                    name: dragInfo.payload.name
                }, targetId);
            } else if (dragInfo.dragType === DragConstants.BACKPACK_CODE) {
                fetchCode(dragInfo.payload.bodyUrl)
                    .then(blocks => this.shareBlocks(blocks, targetId))
                    .then(() => this.props.vm.refreshWorkspace());
            }
        }
    }

    updateProject () {
        this.props.vm.runtime.filterBlockCategories = !this.props.showAllBlockCategories;
        if (this.malrangTutorialBlockService.opcodesInRuntime.length === 0) {
            this.props.vm.runtime.filterBlockCategories = false;
        }

        this.props.vm.clearHiddenSprites();
        this.state.hiddenSpriteIds.forEach((spriteId) => {
            this.props.vm.hideSprite(spriteId);
        });

        if (this.state.initialSpriteId) {
            this.props.vm.setInitialEditTarget(this.state.initialSpriteId);
        }

        if (this.props.vm.shouldPreventDragSprite() && !this.state.shouldPreventDragSprite) {
            this.props.vm.allowDragSprite();
        } else if (!this.props.vm.shouldPreventDragSprite() && this.state.shouldPreventDragSprite) {
            this.props.vm.preventDragSprite();
        }

        if (this.props.vm.shouldPreventEditSprite() && !this.state.shouldPreventEditSprite) {
            this.props.vm.allowEditSprite();
        } else if (!this.props.vm.shouldPreventEditSprite() && this.state.shouldPreventEditSprite) {
            this.props.vm.preventEditSprite();
        }

        if (this.props.vm.shouldPreventAddSprite() && !this.state.shouldPreventAddSprite) {
            this.props.vm.allowAddSprite();
        } else if (!this.props.vm.shouldPreventAddSprite() && this.state.shouldPreventAddSprite) {
            this.props.vm.preventAddSprite();
        }

        if (this.props.vm.shouldPreventDeleteSprite() && !this.state.shouldPreventDeleteSprite) {
            this.props.vm.allowDeleteSprite();
        } else if (!this.props.vm.shouldPreventDeleteSprite() && this.state.shouldPreventDeleteSprite) {
            this.props.vm.preventDeleteSprite();
        }

        if (this.props.vm.shouldPreventSelectSprite() && !this.state.shouldPreventSelectSprite) {
            this.props.vm.allowSelectSprite();
        } else if (!this.props.vm.shouldPreventSelectSprite() && this.state.shouldPreventSelectSprite) {
            this.props.vm.preventSelectSprite();
        }

        if (this.state.shouldPreventSelectSprite) {
            const nonInitialSpriteIds = Object.keys(this.props.sprites).filter(id => {
                return id !== this.state.initialSpriteId;
            });
            this.props.vm.updateSealedSprites(nonInitialSpriteIds);
        } else {
            this.props.vm.updateSealedSprites([]);
        }

        if (this.props.hiddenSpriteIdsFromStage) {
            this.props.vm.runtime.hiddenSpriteIdsFromStage = this.props.hiddenSpriteIdsFromStage;
        }

        if (this.props.vm.shouldHideCostumeTab() && !this.state.shouldHideCostumeTab) {
            this.props.vm.showCostumeTab();
        } else if (!this.props.vm.shouldHideCostumeTab() && this.state.shouldHideCostumeTab) {
            this.props.vm.hideCostumeTab();
        }

        if (this.props.vm.shouldHideSoundTab() && !this.state.shouldHideSoundTab) {
            this.props.vm.showSoundTab();
        } else if (!this.props.vm.shouldHideSoundTab() && this.state.shouldHideSoundTab) {
            this.props.vm.hideSoundTab();
        }

        if (this.props.vm.shouldHideExtensionButton() && !this.state.shouldHideExtensionButton) {
            this.props.vm.showExtensionButton();
        } else if (!this.props.vm.shouldHideExtensionButton() && this.state.shouldHideExtensionButton) {
            this.props.vm.hideExtensionButton();
        }

        if (this.props.vm.shouldHideFileMenu() && !this.state.shouldHideFileMenu) {
            this.props.vm.showFileMenu();
        } else if (!this.props.vm.shouldHideFileMenu() && this.state.shouldHideFileMenu) {
            this.props.vm.hideFileMenu();
        }

        if (this.props.vm.shouldHideEditMenu() && !this.state.shouldHideEditMenu) {
            this.props.vm.showEditMenu();
        } else if (!this.props.vm.shouldHideEditMenu() && this.state.shouldHideEditMenu) {
            this.props.vm.hideEditMenu();
        }

        this.props.vm.workspaceVariableNames = getUnCheckedWorkspaceVariableNames(
            this.malrangTutorialWorkspaceService.workspaceVariableNames,
            this.props.vm.runtime.checkedVariableBlocks
        );

        this.props.vm.runtime.shouldPreventExecuteBlockWithClick = this.state.shouldPreventExecuteBlockWithClick;

        this.props.vm.runtime.hiddenBlockIdsInWorkspace = this.malrangTutorialWorkspaceService.hiddenBlockIdsInWorkspace;
    }

    handleRequestClose () {
        this.updateProject();
        this.props.onClose();
    }

    handleDownloadProject (downloadProject) {
        this.malrangTutorialWorkspaceService.resetWorkspace();

        setTimeout(() => {
            this.updateProject();
            downloadProject();
        })
    }

    handleSetSpriteVisibleOnTargetPane (spriteId, visible) {
        if (visible !== this.isSpriteHidden(spriteId)) {
            return;
        }

        const oldState = { ...this.state };
        const newHiddenSpriteIds = new Set(oldState.hiddenSpriteIds);

        if (visible) {
            newHiddenSpriteIds.delete(spriteId);
        } else {
            newHiddenSpriteIds.add(spriteId);
        }

        this.setState({ hiddenSpriteIds: newHiddenSpriteIds });

        if (this.shouldDisapproveCheck(spriteId, visible)) {
            this.disapproveCheck(() => {
                const { hiddenSpriteIds } = oldState;
                this.setState({ hiddenSpriteIds });
            });

            this.showSpriteTooltip('시작 스프라이트로 설정되어 숨길 수 없습니다.', spriteId, SPRITE_LIST_TYPE.HIDE);
        }
    }

    isSpriteHidden (spriteId) {
        return this.state.hiddenSpriteIds.has(spriteId);
    }

    shouldDisapproveCheck (spriteId, visible) {
        if (visible) {
            return false;
        }

        if (spriteId === this.state.initialSpriteId) {
            return true;
        }

        return Object.keys(this.props.sprites).every(id => {
            if (id === spriteId) {
                return true;
            }
            return this.isSpriteHidden(id);
        });
    }

    disapproveCheck (callback) {
        // wait animation to be finished
        setTimeout(callback, 100);
    }

    handleSetInitialSprite (initialSpriteId) {
        const oldInitialSpriteId = this.state.initialSpriteId;
        this.setState({ initialSpriteId });
        if (this.isSpriteHidden(initialSpriteId)) {
            this.disapproveCheck(() => {
                this.setState({ initialSpriteId: oldInitialSpriteId });
            });

            this.showSpriteTooltip('숨기기 스프라이트로 설정되어 시작 스프라이트로 설정할 수 없습니다.', initialSpriteId, SPRITE_LIST_TYPE.SET_INITIAL);
        }
    }

    isEverySpriteConfigsChecked () {
        const {
            shouldPreventDragSprite,
            shouldPreventEditSprite,
            shouldPreventAddSprite,
            shouldPreventDeleteSprite,
            shouldPreventSelectSprite
        } = this.state;

        return shouldPreventDragSprite && shouldPreventEditSprite
            && shouldPreventAddSprite && shouldPreventDeleteSprite
            && shouldPreventSelectSprite;
    }

    isEveryStageConfigsChecked() {
        const { shouldPreventClickStage, shouldPreventAddStage, shouldPreventDeleteStage } = this.state;
        return shouldPreventClickStage && shouldPreventAddStage && shouldPreventDeleteStage;
    }

    isEveryMenuConfigsChecked () {
        const {
            shouldHideCostumeTab,
            shouldHideSoundTab,
            shouldHideExtensionButton,
            shouldHideFileMenu,
            shouldHideEditMenu
        } = this.state;

        return shouldHideCostumeTab
            && shouldHideSoundTab
            && shouldHideExtensionButton
            && shouldHideFileMenu
            && shouldHideEditMenu;
    }

    isEveryPreventBlockConfigsChecked () {
        const {
            shouldPreventExecuteBlockWithClick
        } = this.state;

        return shouldPreventExecuteBlockWithClick;
    }

    onProjectSaveFinished () {
        this.malrangTutorialWorkspaceService.loadAllBlocks();
        this.props.vm.runtime.filterBlockCategories = true;
    }

    showSpriteTooltip (message, spriteId, type, duration = 1000) {
        this.setState({
            tooltip: {
                message,
                spriteId,
                type,
            }
        });

        setTimeout(() => {
            this.hideSpriteTooltip();
        }, duration);

    }

    hideSpriteTooltip () {
        this.setState({
            tooltip: {
                message: '',
                spriteId: null,
                type: null,
            }
        });
    }

    render() {
        return (<MalrangTutorialModalComponent
            onRequestClose={this.handleRequestClose}
            showAllBlockCategories={this.props.showAllBlockCategories}
            toggleShowAllBlockCategories={this.toggleShowAllBlockCategories}
            toolbox={this.malrangTutorialWorkspaceService.blocklyToolbox}
            palette={this.malrangTutorialWorkspaceService.blocklyFlyout}
            injectionDiv={this.malrangTutorialWorkspaceService.injectionDiv}
            paletteScrollbar={this.malrangTutorialWorkspaceService.blocklyFlyoutScrollbar}
            updateBlocksFiltering={this.updateBlocksFiltering}
            editingTarget={this.props.editingTarget}
            hoveredTarget={this.props.hoveredTarget}
            sprites={this.props.sprites}
            raised={this.props.raiseSprites}
            selectedId={''}
            onDrop={this.handleDrop}
            isSpriteHidden={this.isSpriteHidden}
            setSpriteVisibleOnTargetPane={this.handleSetSpriteVisibleOnTargetPane}
            initialSpriteId={this.state.initialSpriteId}
            setInitialSprite={this.handleSetInitialSprite}
            shouldPreventDragSprite={this.state.shouldPreventDragSprite}
            shouldPreventEditSprite={this.state.shouldPreventEditSprite}
            shouldPreventAddSprite={this.state.shouldPreventAddSprite}
            shouldPreventDeleteSprite={this.state.shouldPreventDeleteSprite}
            shouldPreventSelectSprite={this.state.shouldPreventSelectSprite}
            isEverySpriteConfigsChecked={this.isEverySpriteConfigsChecked()}
            isEveryStageConfigsChecked={this.isEveryStageConfigsChecked()}
            isEveryPreventBlockConfigsChecked={this.isEveryPreventBlockConfigsChecked()}
            shouldHideCostumeTab={this.state.shouldHideCostumeTab}
            shouldHideSoundTab={this.state.shouldHideSoundTab}
            shouldHideExtensionButton={this.state.shouldHideExtensionButton}
            shouldHideFileMenu={this.state.shouldHideFileMenu}
            shouldHideEditMenu={this.state.shouldHideEditMenu}
            isEveryMenuConfigsChecked={this.isEveryMenuConfigsChecked()}
            onRequestDownloadProject={this.handleDownloadProject}
            shouldPreventClickStage={this.state.shouldPreventClickStage}
            shouldPreventAddStage={this.state.shouldPreventAddStage}
            shouldPreventDeleteStage={this.state.shouldPreventDeleteStage}
            onSaveFinished={this.onProjectSaveFinished}
            maximumGreenFlagClickCount={this.props.maximumGreenFlagClickCount}
            blockSvgGroups={this.state.blockSvgGroups}
            hiddenBlockIdsInWorkspace={this.malrangTutorialWorkspaceService.hiddenBlockIdsInWorkspace}
            eventHandler={this.eventHandler}
            flyout={this.malrangTutorialWorkspaceService.blocklyFlyout}
            shouldPreventExecuteBlockWithClick={this.state.shouldPreventExecuteBlockWithClick}
            setShouldPreventExecuteBlockWithClick={this.setShouldPreventExecuteBlockWithClick}
            tooltip={this.state.tooltip}
        />);
    }
}

const mapStateToProps = state => ({
    workspace: state.scratchGui.workspaceState.scratchWorkspace,
    editingTarget: state.scratchGui.targets.editingTarget,
    hoveredTarget: state.scratchGui.hoveredTarget,
    sprites: state.scratchGui.targets.sprites,
    raiseSprites: state.scratchGui.blockDrag,
    isMissionMode: state.scratchGui.mode.isMissionMode,
    hiddenSpriteIdsFromStage: state.scratchGui.malrangTutorialState.hiddenSpriteIdsFromStage,
    toolboxXML: state.scratchGui.toolbox.toolboxXML,
    scratchBlocks: state.scratchGui.scratchBlocks.scratchBlocks,
    showAllBlockCategories: state.scratchGui.malrangTutorialState.showAllBlockCategories,
    maximumGreenFlagClickCount: state.scratchGui.malrangTutorialState.maximumGreenFlagClickCount,
});

const mapDispatchToProps = dispatch => ({
    onClose: () => {
        dispatch(closeMalrangTutorialModal());
    },
    onRequestCloseFile: () => {
        dispatch(closeSemobaeFileMenu());
    },
    updateWorkspace: workspace => {
        dispatch(setWorkspace(workspace));
    },
    enableMissionMode: () => {
        dispatch(setMissionMode(true));
    },
    disableMissionMode: () => {
        dispatch(setMissionMode(false));
    },
    updateToolboxState: toolboxXML => {
        dispatch(updateToolbox(toolboxXML));
    },
    updateShowAllBlockCategories: showAllBlockCategories => {
        dispatch(setShowAllBlockCategories(showAllBlockCategories));
    },
    handleClickHideFromStageCheckbox: (spriteId) => {
        dispatch(setHiddenSpriteFromStage(spriteId));
    },
    updateMaximumGreenFlagClickCount: maximumGreenFlagClickCount => {
        dispatch(setMaximumGreenFlagClickCount(maximumGreenFlagClickCount));
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MalrangTutorialModal);