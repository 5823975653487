import { bindMethodsToTarget } from "../../utils/bindMethodsToTarget";

class MalrangTutorialEventHandler {
    constructor (malrangTutorialModal) {
        bindMethodsToTarget(
            this,
            malrangTutorialModal, 
            [
            'onClickHideBlockStackCheckbox',
            'onChangeMaximumGreenFlagInput',
            'onUpdateHideEditMenu',
            'onClickPreventStageCheckbox',
            'onClickAddStageCheckbox',
            'onUpdateHideCostumeTab',
            'onUpdateHideSoundTab',
            'onUpdateHideExtensionButton',
            'onUpdateHideFileMenu',
            'onUpdateBatchSelectionMenuConfigs',
            'onUpdateBatchSelectionSpriteConfigs',
            'onUpdateBatchSelectionStageConfigs',
            'onUpdateBatchSelectionEtcConfigs',
            'onUpdatePreventDragSprite',
            'onUpdatePreventEditSprite',
            'onUpdatePreventAddSprite',
            'onUpdatePreventDeleteSprite',
            'onUpdatePreventSelectSprite',
            'onClickPreventExecuteBlockWithClick',
            ]
        );
    }

    onClickHideBlockStackCheckbox (blockId, show) {
        if (show) {
            this.malrangTutorialWorkspaceService.hiddenBlockIdsInWorkspace.set(blockId, true);
        } else {
            this.malrangTutorialWorkspaceService.hiddenBlockIdsInWorkspace.set(blockId, false);
        }
    }

    onChangeMaximumGreenFlagInput (event) {
        const maximumGreenFlagClickStr = event.target.value.trim();
        const isNumberReg = /^[0-9]*$/;
        if (!isNumberReg.test(maximumGreenFlagClickStr)) {
            return;
        }
        this.props.updateMaximumGreenFlagClickCount(Number(maximumGreenFlagClickStr).toString());
    }

    // 배경
    onClickPreventStageCheckbox () {
        this.setState({ shouldPreventClickStage: !this.state.shouldPreventClickStage });
    }

    onClickAddStageCheckbox () {
        this.setState({ shouldPreventAddStage: !this.state.shouldPreventAddStage });
    }

    onUpdateBatchSelectionStageConfigs () {
        const isEveryStageConfigsChecked = !this.isEveryStageConfigsChecked();
        this.setState({
            shouldPreventClickStage: isEveryStageConfigsChecked,
            shouldPreventAddStage: isEveryStageConfigsChecked,
        });
    }

    // 탭/메뉴
    onUpdateHideEditMenu () {
        this.setState({ shouldHideEditMenu: !this.state.shouldHideEditMenu });
    }

    onUpdateHideCostumeTab () {
        this.setState({ shouldHideCostumeTab: !this.state.shouldHideCostumeTab });
    }

    onUpdateHideSoundTab () {
        this.setState({ shouldHideSoundTab: !this.state.shouldHideSoundTab });
    }

    onUpdateHideExtensionButton () {
        this.setState({ shouldHideExtensionButton: !this.state.shouldHideExtensionButton });
    }

    onUpdateHideFileMenu () {
        this.setState({ shouldHideFileMenu: !this.state.shouldHideFileMenu });
    }

    onUpdateBatchSelectionMenuConfigs () {
        const isEveryMenuConfigsChecked = !this.isEveryMenuConfigsChecked();

        this.setState({
            shouldHideCostumeTab: isEveryMenuConfigsChecked,
            shouldHideSoundTab: isEveryMenuConfigsChecked,
            shouldHideExtensionButton: isEveryMenuConfigsChecked,
            shouldHideFileMenu: isEveryMenuConfigsChecked,
            shouldHideEditMenu: isEveryMenuConfigsChecked,
        });
    }

    // 스프라이트
    onUpdateBatchSelectionSpriteConfigs () {
        const isEverySpriteConfigsChecked = !this.isEverySpriteConfigsChecked();

        this.setState({
            shouldPreventDragSprite: isEverySpriteConfigsChecked,
            shouldPreventEditSprite: isEverySpriteConfigsChecked,
            shouldPreventAddSprite: isEverySpriteConfigsChecked,
            shouldPreventDeleteSprite: isEverySpriteConfigsChecked,
            shouldPreventSelectSprite: isEverySpriteConfigsChecked,
        });
    }

    onUpdatePreventDragSprite () {
        this.setState({ shouldPreventDragSprite: !this.state.shouldPreventDragSprite });
    }

    onUpdatePreventEditSprite () {
        this.setState({ shouldPreventEditSprite: !this.state.shouldPreventEditSprite });
    }

    onUpdatePreventAddSprite () {
        this.setState({ shouldPreventAddSprite: !this.state.shouldPreventAddSprite });
    }

    onUpdatePreventDeleteSprite () {
        this.setState({ shouldPreventDeleteSprite: !this.state.shouldPreventDeleteSprite });
    }

    onUpdatePreventSelectSprite () {
        this.setState({ shouldPreventSelectSprite: !this.state.shouldPreventSelectSprite });
    }

    // 블록 클릭 실행 방지
    onClickPreventExecuteBlockWithClick() {
        this.setState({ shouldPreventExecuteBlockWithClick: !this.state.shouldPreventExecuteBlockWithClick });
    }

    onUpdateBatchSelectionEtcConfigs() {
        const isEveryStageConfigsChecked = !this.isEveryPreventBlockConfigsChecked();
        this.setState({
            shouldPreventExecuteBlockWithClick: isEveryStageConfigsChecked,
        });
    }
}

export default MalrangTutorialEventHandler;