import React from 'react';

import styles from './malrang-tutorial-modal.css';
import CheckButton from '../check-button/check-button.jsx';
import classNames from 'classnames';

export const Direction = Object.freeze({
    Horizontal: 'horizontal',
    Vertical: 'vertical'
});

const RestrictionOptions = ({
    title,
    isEveryConfigsChecked,
    onClickSelectAll,
    restrictionOptions,
    direction = Direction.Horizontal
}) => {
    return (
        <div className={styles.restrictionOption}>
            <div className={styles.listHorizontal}>
                <div className={styles.restrictionTitleContainer}>
                    <h2 className={styles.restrictionTitle}>{title}</h2>
                </div>
                <div className={styles.restrictionAllCheck}>
                    <CheckButton
                        className={classNames(
                            styles.restrictionCheckbox,
                            isEveryConfigsChecked ? styles.restrictionLabelChecked : styles.restrictionLabel
                        )}
                        onClick={onClickSelectAll}
                        checked={isEveryConfigsChecked}
                        label="전체 선택"
                    />
                </div>
            </div>
            <hr className={styles.restrictionLine}></hr>
            <div className={classNames(styles.restrictionCheckboxContainer, styles[direction.toLowerCase()])}>
            {
                restrictionOptions.map((option, index) => (
                    <div key={index}>
                        <CheckButton
                            className={classNames(
                                styles.restrictionCheckbox,
                                styles.restrictionCheckboxElement,
                                option.isChecked ? styles.restrictionLabelChecked : styles.restrictionLabel,
                                styles[direction.toLowerCase()]
                            )}
                            onClick={option.onChange}
                            checked={option.isChecked}
                            label={option.label}
                        />
                    </div>
                ))
            }
            </div>
        </div>
    )
};

export default RestrictionOptions;