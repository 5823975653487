exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".camera-selector_camera-selector-wrapper_3W0yu {\n    width: 100%;\n    position: relative;\n    display: -webkit-box;\n    display: -webkit-flex;\n    display: -ms-flexbox;\n    display: flex;\n    -webkit-box-align: center;\n    -webkit-align-items: center;\n        -ms-flex-align: center;\n            align-items: center;\n    height: 40px;\n}\n.camera-selector_camera-selector_9oBnH {\n    all: unset;\n    -webkit-box-sizing: border-box;\n            box-sizing: border-box;\n    display: -webkit-box;\n    display: -webkit-flex;\n    display: -ms-flexbox;\n    display: flex;\n    -webkit-box-align: center;\n    -webkit-align-items: center;\n        -ms-flex-align: center;\n            align-items: center;\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    width: 100%;\n    height: 40px;\n    padding: 0 32px 0 8px;\n    color: #1F6DD0;\n    background-color: #D3E4FB;\n    border-radius: 5px;\n}\n.camera-selector_camera-selector_9oBnH > option {\n    background-color: white;\n}\n.camera-selector_arrow-down-icon_4NIoL {\n    pointer-events: none;\n    position: absolute;\n    right: 16px;\n    top: 16px;\n}\n", ""]);

// exports
exports.locals = {
	"camera-selector-wrapper": "camera-selector_camera-selector-wrapper_3W0yu",
	"cameraSelectorWrapper": "camera-selector_camera-selector-wrapper_3W0yu",
	"camera-selector": "camera-selector_camera-selector_9oBnH",
	"cameraSelector": "camera-selector_camera-selector_9oBnH",
	"arrow-down-icon": "camera-selector_arrow-down-icon_4NIoL",
	"arrowDownIcon": "camera-selector_arrow-down-icon_4NIoL"
};