import { BlockXMLBase } from './BlockXMLBase';

export class Motion extends BlockXMLBase {
    constructor (isInitialSetup, isStage, targetId) {
        super();
        this.motion_xposition = `
            <block id="${targetId}_xposition" type="motion_xposition"/>`;

        this.motion_yposition = `
            <block id="${targetId}_yposition" type="motion_yposition"/>`;

        this.motion_direction = `
            <block id="${targetId}_direction" type="motion_direction"/>`;
    }

    blockOptions = {
        motion_movesteps: {
            order: 0,
            stage: false,
            sprite: true
        },
        motion_turnright: {
            order: 1,
            stage: false,
            sprite: true
        },
        motion_turnleft: {
            order: 2,
            stage: false,
            sprite: true
        },
        motion_goto: {
            order: 3,
            stage: false,
            sprite: true
        },
        motion_gotoxy: {
            order: 4,
            stage: false,
            sprite: true
        },
        motion_glideto: {
            order: 5,
            stage: false,
            sprite: true
        },
        motion_glidesecstoxy: {
            order: 6,
            stage: false,
            sprite: true
        },
        motion_pointindirection: {
            order: 7,
            stage: false,
            sprite: true
        },
        motion_pointtowards: {
            order: 8,
            stage: false,
            sprite: true
        },
        motion_changexby: {
            order: 9,
            stage: false,
            sprite: true
        },
        motion_setx: {
            order: 10,
            stage: false,
            sprite: true
        },
        motion_changeyby: {
            order: 11,
            stage: false,
            sprite: true
        },
        motion_sety: {
            order: 12,
            stage: false,
            sprite: true
        },
        motion_ifonedgebounce: {
            order: 13,
            stage: false,
            sprite: true
        },
        motion_setrotationstyle: {
            order: 14,
            stage: false,
            sprite: true
        },
        motion_xposition: {
            order: 15,
            stage: false,
            sprite: true
        },
        motion_yposition: {
            order: 16,
            stage: false,
            sprite: true
        },
        motion_direction: {
            order: 17,
            stage: false,
            sprite: true
        }
    }

    motion_movesteps = `
        <block type="motion_movesteps">
            <value name="STEPS">
                <shadow type="math_number">
                    <field name="NUM">10</field>
                </shadow>
            </value>
        </block>`;

    motion_turnright = `
        <block type="motion_turnright">
            <value name="DEGREES">
                <shadow type="math_number">
                    <field name="NUM">15</field>
                </shadow>
            </value>
        </block>`;

    motion_turnleft = `
        <block type="motion_turnleft">
            <value name="DEGREES">
                <shadow type="math_number">
                    <field name="NUM">15</field>
                </shadow>
            </value>
        </block>`;

    motion_goto = `
        <block type="motion_goto">
            <value name="TO">
                <shadow type="motion_goto_menu">
                </shadow>
            </value>
        </block>`;

    motion_gotoxy = `
        <block type="motion_gotoxy">
            <value name="X">
                <shadow id="movex" type="math_number">
                    <field name="NUM">0</field>
                </shadow>
            </value>
            <value name="Y">
                <shadow id="movey" type="math_number">
                    <field name="NUM">0</field>
                </shadow>
            </value>
        </block>`;

    motion_glideto = `
        <block type="motion_glideto" id="motion_glideto">
            <value name="SECS">
                <shadow type="math_number">
                    <field name="NUM">1</field>
                </shadow>
            </value>
            <value name="TO">
                <shadow type="motion_glideto_menu">
                </shadow>
            </value>
        </block>`;

    motion_glidesecstoxy = `
        <block type="motion_glidesecstoxy">
            <value name="SECS">
                <shadow type="math_number">
                    <field name="NUM">1</field>
                </shadow>
            </value>
            <value name="X">
                <shadow id="glidex" type="math_number">
                    <field name="NUM">0</field>
                </shadow>
            </value>
            <value name="Y">
                <shadow id="glidey" type="math_number">
                    <field name="NUM">0</field>
                </shadow>
            </value>
        </block>`;

    motion_pointindirection = `
        <block type="motion_pointindirection">
            <value name="DIRECTION">
                <shadow type="math_angle">
                    <field name="NUM">90</field>
                </shadow>
            </value>
        </block>`;

    motion_pointtowards = `
        <block type="motion_pointtowards">
            <value name="TOWARDS">
                <shadow type="motion_pointtowards_menu">
                </shadow>
            </value>
        </block>`;

    motion_changexby = `
        <block type="motion_changexby">
            <value name="DX">
                <shadow type="math_number">
                    <field name="NUM">10</field>
                </shadow>
            </value>
        </block>`;

    motion_setx = `
        <block type="motion_setx">
            <value name="X">
                <shadow id="setx" type="math_number">
                    <field name="NUM">0</field>
                </shadow>
            </value>
        </block>`;

    motion_changeyby = `
        <block type="motion_changeyby">
            <value name="DY">
                <shadow type="math_number">
                    <field name="NUM">10</field>
                </shadow>
            </value>
        </block>`;

    motion_sety = `
        <block type="motion_sety">
            <value name="Y">
                <shadow id="sety" type="math_number">
                    <field name="NUM">0</field>
                </shadow>
            </value>
        </block>`;

    motion_ifonedgebounce = `
        <block type="motion_ifonedgebounce"/>`;

    motion_setrotationstyle = `
        <block type="motion_setrotationstyle"/>`;
}