import React, {Component} from 'react';
import style from './camera-view.css';

import cameraFlipIcon from './camera-flip-icon.svg';

class CameraView extends Component {

    constructor (props) {
        super(props);
        this.videoRef = React.createRef();
    }

    componentDidUpdate () {
        if (!this.props.video) {
            return;
        }

        const isChangeCamera = this.videoRef.current.srcObject?.id !== this.props.video.srcObject?.id;
        if (isChangeCamera) {
            this.initializeCamera();
        }
    }

    componentDidMount () {
        this.initializeCamera();
    }

    initializeCamera () {
        const {video} = this.props;

        if (video && this.videoRef.current) {
            this.videoRef.current.srcObject = video.srcObject;
            if (!this.videoRef.current.srcObject) {
                video.oncanplay = () => {
                    this.videoRef.current.srcObject = video.srcObject
                    video.oncanplay = null;
                };
            }
        }
    }

    render () {
        const { isMirrored, toggleMirror } = this.props;
        const mirrorClass = isMirrored ? style.mirror : style.normal;

        return (
            <div className={style.cameraViewWrapper}>
                <button onClick={toggleMirror} className={style.mirrorButton}>
                    <img
                        src={cameraFlipIcon}
                    />
                </button>
                <video
                    autoPlay
                    playsInline
                    className={`${style.cameraView} ${mirrorClass}`}
                    ref={this.videoRef}
                />
            </div>
        );
    }
}

export default CameraView;
