import React from 'react';

import styles from './mouse-position.css';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

function MousePosition(props) {
  const [mousePos, setMousePos] = React.useState({
    x: 0,
    y: 0,
  });

  React.useEffect(() => {
    const mouseMoveHandler = () => {
      setMousePos({
        x: props.vm.runtime.ioDevices.mouse._scratchX,
        y: props.vm.runtime.ioDevices.mouse._scratchY,
      });
    };

    window.addEventListener('mousemove', mouseMoveHandler);

    return () => {
      window.removeEventListener('mousemove', mouseMoveHandler);
    };
  }, []);

  return (
    <div className={styles.mousePositionContainer}>
      <span>x: {mousePos.x}</span>
      <span>y: {mousePos.y}</span>
    </div>
  );
}

const mapStateToProps = state => ({
  vm: state.scratchGui.vm
});

export default injectIntl(connect(
  mapStateToProps
)(MousePosition));