const DefaultBlockCategories = [
    'motion',
    'looks',
    'sound',
    'event',
    'control',
    'sensing',
    'operator',
    'data',
    'procedures'
];

export { DefaultBlockCategories };