import React from 'react';
import PropTypes from 'prop-types';
import {OrderedMap} from 'immutable';
import Draggable from 'react-draggable';
import CustomInputMonitor from '../../containers/custom-input-monitor.jsx';
import VM from 'scratch-vm';

import malringIcon from './malrang_icon.svg';
import technicIcon from './technic_icon.svg';

import styles from './custom-input-popup.css';

const CustomInputPopupComponent = props => {
    const isTechnic = props.splitMonitorKey === 'technic_monitor';
    const icon = isTechnic ? technicIcon : malringIcon;
    return (
        <Draggable>
            <div className={`${styles.monitorContainer} ${isTechnic && styles.technicMonitorBg}`} >
                <img src={icon} className={styles.icon} />
                <div className={styles.row} >
                    {props.monitors.map(monitor => (
                        <CustomInputMonitor
                            key={monitor.id}
                            id={monitor.id}
                            opcode={monitor.opcode}
                            params={monitor.params}
                            value={monitor.value}
                            vm={props.vm}
                            monitorName={props.splitMonitorKey}
                        />
                    )).toArray()}
                </div>
            </div>
        </Draggable>
    );
};

CustomInputPopupComponent.propTypes = {
    monitors: PropTypes.instanceOf(OrderedMap),
    vm: PropTypes.instanceOf(VM).isRequired
};

export default CustomInputPopupComponent;
