import React from 'react';
import styles from './tutorial-modal.css';
import classNames from 'classnames';
import { FAIL_TYPE } from '../../reducers/malrang-tutorial';

const failImage = {
    [FAIL_TYPE.normalFail]: require("../../assets/tutorial-dialog/fail.svg"),
    [FAIL_TYPE.blockCountFail]: require("../../assets/tutorial-dialog/block-count-fail.svg"),
    [FAIL_TYPE.none]: '',
}

const TutorialFailModalComponent = ({ 
    onClickTutorialRestartButton,
    onClickTutorialHelpButton,
    failType
}) => {
    return (
        <div className={classNames(styles.dialogWrapper)}>
            {failImage[failType] && <img src={failImage[failType]} />}
            <button
                onClick={onClickTutorialRestartButton}
                className={classNames(styles.buttonCommon, styles.failRetryButton)} 
            />
        </div>
    );
}

export default TutorialFailModalComponent;