exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".tutorial-modal_dialog-wrapper_2lsk7 {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    width: 90%;\n    height: calc(-webkit-max-content - 4px);\n    height: calc(-moz-max-content - 4px);\n    height: calc(max-content - 4px);\n    -webkit-transform: translate(-50%, -50%);\n        -ms-transform: translate(-50%, -50%);\n            transform: translate(-50%, -50%);\n}\n\n.tutorial-modal_dialog-wrapper_2lsk7 > img {\n    display: block;\n    width: 100%;\n    height: auto;\n    -webkit-user-drag: none;\n    -khtml-user-drag: none;\n    -moz-user-drag: none;\n    -o-user-drag: none;\n}\n\n.tutorial-modal_button-common_3PI9S {\n    position: absolute;\n    bottom: 10%;\n    height: 18%;\n    padding: 0;\n    border: none;\n    background-color: transparent;\n    cursor: pointer;\n}\n\n.tutorial-modal_next-step-button_1bcKj {\n    right: 4%;\n    width: 25%;\n}\n\n.tutorial-modal_success-retry-button_2ODOV {\n    right: 31%;\n    width: 21%;\n}\n\n.tutorial-modal_last-step-success-retry-button_1yp9x {\n    right: 5%;\n    width: 46%;\n}\n\n.tutorial-modal_fail-retry-button_2uCgc {\n    right: 4%;\n    width: 46%;\n    bottom: 6%;\n}", ""]);

// exports
exports.locals = {
	"dialog-wrapper": "tutorial-modal_dialog-wrapper_2lsk7",
	"dialogWrapper": "tutorial-modal_dialog-wrapper_2lsk7",
	"button-common": "tutorial-modal_button-common_3PI9S",
	"buttonCommon": "tutorial-modal_button-common_3PI9S",
	"next-step-button": "tutorial-modal_next-step-button_1bcKj",
	"nextStepButton": "tutorial-modal_next-step-button_1bcKj",
	"success-retry-button": "tutorial-modal_success-retry-button_2ODOV",
	"successRetryButton": "tutorial-modal_success-retry-button_2ODOV",
	"last-step-success-retry-button": "tutorial-modal_last-step-success-retry-button_1yp9x",
	"lastStepSuccessRetryButton": "tutorial-modal_last-step-success-retry-button_1yp9x",
	"fail-retry-button": "tutorial-modal_fail-retry-button_2uCgc",
	"failRetryButton": "tutorial-modal_fail-retry-button_2uCgc"
};