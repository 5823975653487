exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".image-sample_sample-container_418Av {\n    position: relative;\n    -webkit-flex-shrink: 0;\n        -ms-flex-negative: 0;\n            flex-shrink: 0;\n    width: 72px;\n    height: 72px;\n    overflow: hidden;\n    border-radius: 5px;\n}\n\n.image-sample_sample-container_418Av:hover .image-sample_delete-button_3n9ek {\n    display: block;\n}\n\n.image-sample_delete-button_3n9ek {\n    all: unset;\n    display: none;\n    position: absolute;\n    top: 8px;\n    left: 8px;\n    width: 20px;\n    height: 20px;\n    cursor: pointer;\n}\n\n.image-sample_delete-button_3n9ek img {\n    -webkit-box-shadow: 0px 4px 4px 0px #0000004D;\n            box-shadow: 0px 4px 4px 0px #0000004D;\n}\n\n.image-sample_image_2q_Kj {\n    width: 100%;\n    height: 100%;\n    -o-object-fit: cover;\n       object-fit: cover;\n}", ""]);

// exports
exports.locals = {
	"sample-container": "image-sample_sample-container_418Av",
	"sampleContainer": "image-sample_sample-container_418Av",
	"delete-button": "image-sample_delete-button_3n9ek",
	"deleteButton": "image-sample_delete-button_3n9ek",
	"image": "image-sample_image_2q_Kj"
};