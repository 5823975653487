import React from 'react';
import styles from './malrang-tutorial-modal.css';
import classNames from 'classnames';
import Sb3Downloader from '../../containers/sb3-downloader.jsx';

const SaveTutorialSb3 = ({ onSaveFinished, onRequestDownloadProject }) => {
    return <Sb3Downloader
                onSaveFinished={onSaveFinished}
            >
                {(className, downloadProject) => (
                    <button
                        className={classNames(className, styles.downloadSb3Button)}
                        onClick={() => onRequestDownloadProject(downloadProject)}
                    >
                        튜토리얼 저장하기
                    </button>
                )}
            </Sb3Downloader>
};

export default SaveTutorialSb3;