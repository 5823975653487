import projectData from './project-data';

/* eslint-disable import/no-unresolved */
import popWav from '!arraybuffer-loader!./83a9787d4cb6f3b7632b4ddfebf74367.wav';
import meowWav from '!arraybuffer-loader!./83c36d806dc92327b9e7049a565c6bff.wav';
import backdrop from '!raw-loader!./cd21514d0531fdffb22204e0ec5ed84a.svg';
import costume1 from '!raw-loader!./bcf454acf82e4504149f7ffe07081dbc.svg';
import costume2 from '!raw-loader!./0fb9be3e8397c983338cb71dc84d0b25.svg';

import dummy_100_75 from '!raw-loader!../../assets/custom-sprites/96f04159d9bd1701a9f2d936d217ad4c.svg';
import dummy_150_112 from '!raw-loader!../../assets/custom-sprites/b65bdf0864f5e4b6b93bdb0013133671.svg';
import dummy_200_150 from '!raw-loader!../../assets/custom-sprites/f1a0baa8c221cef78e543a69fbc7425e.svg';
import dummy_250_186 from '!raw-loader!../../assets/custom-sprites/4c788908f75b1fdef107a76f867a7114.svg';
import dummy_300_223 from '!raw-loader!../../assets/custom-sprites/a0691f318240a1ba7d64d5c025c6c99a.svg';
import dummy_350_260 from '!raw-loader!../../assets/custom-sprites/ad952d248b427b233980cebd7ce0f0f1.svg';
import dummy_400_297 from '!raw-loader!../../assets/custom-sprites/80e9856377cd176ae1a4e35e1a182602.svg';
import dummy_450_334 from '!raw-loader!../../assets/custom-sprites/1019f94340dc4fa84fc994fac4919176.svg';
import dummy_480_356 from '!raw-loader!../../assets/custom-sprites/2abb1a69e06dc43a6f0eee5380ad60b7.svg';

import nemoCostume01 from '!raw-loader!../../assets/custom-sprites/3696356a03a8d938318876a593572841.svg';
import nemoCostume02 from '!raw-loader!../../assets/custom-sprites/3696356a03a8d938318876a593572842.svg';
import nemoCostume03 from '!raw-loader!../../assets/custom-sprites/3696356a03a8d938318876a593572843.svg';
import nemoCostume04 from '!raw-loader!../../assets/custom-sprites/3696356a03a8d938318876a593572844.svg';
import nemoCostume05 from '!raw-loader!../../assets/custom-sprites/3696356a03a8d938318876a593572845.svg';
import nemoCostume06 from '!raw-loader!../../assets/custom-sprites/3696356a03a8d938318876a593572846.svg';
import nemoCostume07 from '!raw-loader!../../assets/custom-sprites/3696356a03a8d938318876a593572847.svg';
import nemoCostume08 from '!raw-loader!../../assets/custom-sprites/3696356a03a8d938318876a593572848.svg';
import nemoCostume09 from '!raw-loader!../../assets/custom-sprites/3696356a03a8d938318876a593572849.svg';
import nemoCostume10 from '!raw-loader!../../assets/custom-sprites/3696356a03a8d938318876a593572850.svg';

import nemoFaceCostume from '!raw-loader!../../assets/custom-sprites/3696356a03a8d938318876a593572851.svg';
/* eslint-enable import/no-unresolved */
import probo_racing_field_A_1 from '!raw-loader!../../assets/custom-sprites/3c54419cd76c0dab410ae78248c4850c.svg';
import probo_aerdrome from '!raw-loader!../../assets/custom-sprites/bfb5098160260c8bb640d0e2d7047ad7.svg';
import probo_lv3C7BG_beanstalk from '!raw-loader!../../assets/custom-sprites/430f2c6a220eeacdc3c7b7bdf043a26f.svg';
import probo_black_hole_11 from '!raw-loader!../../assets/custom-sprites/29dff81a0c06eb213f06f2e7aaeb7fc4.svg';
import probo_chaper11_gameBG from '!raw-loader!../../assets/custom-sprites/d3cf63ac0b29ab6918f9d27dedea4b43.svg';
import probo_Inside_the_car from '!raw-loader!../../assets/custom-sprites/65e8ee597859272e4e80432b3eb01a98.svg';
import probo_cave from '!raw-loader!../../assets/custom-sprites/ab3ba668aa9436b253d26601fe42580d.svg';
import probo_land from '!raw-loader!../../assets/custom-sprites/9239f2a86410d490d14f36578a40c402.svg';
import probo_chaper12_gameBG from '!raw-loader!../../assets/custom-sprites/20a3d3748662387579bd56a0cf130783.svg';
import probo_lv3C8BG_cloudtop from '!raw-loader!../../assets/custom-sprites/50c5fe3e1024891ea05f7ff90998396f.svg';
import probo_coke_festival_A from '!raw-loader!../../assets/custom-sprites/0c0a1b6d0f6d8b8415f4c484f6dd1d17.svg';
import probo_coke_festival_B from '!raw-loader!../../assets/custom-sprites/482c20dbcc701d78cd5a9d2bf414f134.svg';
import probo_lv3C3BG_cprbeach from '!raw-loader!../../assets/custom-sprites/77755176721fec5c48650dc7fa65af41.svg';
import probo_desert_road from '!raw-loader!../../assets/custom-sprites/012fb9fc9cc0d7736340ac8bcaf1b5cf.svg';
import probo_desert1 from '!raw-loader!../../assets/custom-sprites/10ab6469e9827c3e7653d46bc315bc23.svg';
import probo_desert2 from '!raw-loader!../../assets/custom-sprites/5a1a16399fd3fb662e0ba900e98cbd98.svg';
import probo_dino_radar0 from '!raw-loader!../../assets/custom-sprites/4bc334aa317fcb875d336e223955f398.svg';
import probo_energy_storage from '!raw-loader!../../assets/custom-sprites/47844b89d4b778cb1f9cb670a9f412d9.svg';
import probo_forest_Rain from '!raw-loader!../../assets/custom-sprites/fe923de1a0e75587c43a1b257739f8b1.svg';
import probo_free_field from '!raw-loader!../../assets/custom-sprites/8ec7c5afd9e3350b5c0da027553064ea.svg';
import probo_front_yard from '!raw-loader!../../assets/custom-sprites/b467dcba3ba6b0c83907b3db35a0d538.svg';
import probo_gardenA from '!raw-loader!../../assets/custom-sprites/222a29ae9537530b8eb5d0663bae889d.svg';
import probo_lv3C5BG_gardenA from '!raw-loader!../../assets/custom-sprites/222a29ae9537530b8eb5d0663bae889d.svg';
import probo_lv3C6BG_gardenB from '!raw-loader!../../assets/custom-sprites/49e488cb42130f7accb26dab6840d9a1.svg';
import probo_lv3C9BG_gianthouse from '!raw-loader!../../assets/custom-sprites/774fb01aa6273827839637ae843710cd.svg';
import probo_ground_Dino from '!raw-loader!../../assets/custom-sprites/05405ca562a05987d2d856db5ab318bf.svg';
import probo_ground_Fire from '!raw-loader!../../assets/custom-sprites/91c0484755cb9449c985045e573be982.svg';
import probo_ground_hit from '!raw-loader!../../assets/custom-sprites/73e0fd99f2cba6eb21bd359b6e64b900.svg';
import probo_heart_rate from '!raw-loader!../../assets/custom-sprites/66effbe2c07e5a94837fb5a25363555d.svg';
import probo_ice_country from '!raw-loader!../../assets/custom-sprites/06b364adb3a2eef6bd28f4af9994e1a8.svg';
import probo_interior_spaceship from '!raw-loader!../../assets/custom-sprites/560f314d56e68f5cd39089399a27ddfa.svg';
import probo_kitchen from '!raw-loader!../../assets/custom-sprites/d86a92486324fd7e6213c6686a16a9c8.svg';
import probo_lv3C1BG_LinerA from '!raw-loader!../../assets/custom-sprites/adba46a74b97344f6d7c55ac185e42d9.svg';
import probo_lv3C2BG_LinerB from '!raw-loader!../../assets/custom-sprites/e2094a2001bfd2a7953d46fc46518e16.svg';
import probo_m_assembly from '!raw-loader!../../assets/custom-sprites/4255b9dd5467d4c1d0a3c6477d0f40d7.svg';
import probo_assembly from '!raw-loader!../../assets/custom-sprites/cd3402eb69f672d6e45727f84ef48085.svg';
import probo_m_energy_storage from '!raw-loader!../../assets/custom-sprites/2e82f4a00c354c79f5eb327aa8d47cef.svg';
import probo_m_heart_rate from '!raw-loader!../../assets/custom-sprites/6b9cb3d67752497063446172151087ce.svg';
import probo_mars from '!raw-loader!../../assets/custom-sprites/9439aaf6a8dabc5b4573de8bbc0aef8a.svg';
import probo_maze1 from '!raw-loader!../../assets/custom-sprites/356c5f4ddfbdee5ae43663d377bc33f4.svg';
import probo_maze2 from '!raw-loader!../../assets/custom-sprites/dd1d84e2f1b067687e93b57def925140.svg';
import probo_maze3 from '!raw-loader!../../assets/custom-sprites/039baad3c3e4d78680cb2f194ff2e013.svg';
import probo_maze4 from '!raw-loader!../../assets/custom-sprites/558d6410c7d53eae617eb9a53b66e406.svg';
import probo_lv3C11BG_melodyroundA from '!raw-loader!../../assets/custom-sprites/e4b9be3c1b195a14a6fa2cb7667e2935.svg';
import probo_lv3C12BG_melodyroundB from '!raw-loader!../../assets/custom-sprites/2dc690cddd1dbcc1f870a4ff3fcb4cd8.svg';
import probo_music_hall from '!raw-loader!../../assets/custom-sprites/580907a326a70df461259fbffc1dbf96.svg';
import probo_music_hall2 from '!raw-loader!../../assets/custom-sprites/4c4dad285670e13b242547045f5de917.svg';
import probo_racing_field_A from '!raw-loader!../../assets/custom-sprites/d4bf67b1903d4960077f8560c896e458.svg';
import probo_racing_field_B from '!raw-loader!../../assets/custom-sprites/80300e971fda8424bc61de136c3538db.svg';
import probo_lv3C4BG_forest from '!raw-loader!../../assets/custom-sprites/97a7e23dc34ac5e92d79affbbe006bd5.svg';
import probo_sky1 from '!raw-loader!../../assets/custom-sprites/9fc0d86436292f9402cabfd19a3144b7.svg';
import probo_sky2 from '!raw-loader!../../assets/custom-sprites/fb7040782b37c6e0b364b4734e6cf4d2.svg';
import probo_space from '!raw-loader!../../assets/custom-sprites/355c9f8233c4959b5dc1cd1fabc5d057.svg';
import probo_Space_B from '!raw-loader!../../assets/custom-sprites/c536e98b0f6787a5b3c1eed455ddca52.svg';
import probo_stage3 from '!raw-loader!../../assets/custom-sprites/68432d66d515bb677935b8194a0e4195.svg';
import probo_lv3C10BG_sunsetsea from '!raw-loader!../../assets/custom-sprites/04a65c11fa6dc3219ffa26f298453d66.svg';
import probo_airplane_front from '!raw-loader!../../assets/custom-sprites/bbb9ad359a065f72af61df0ff5580515.svg';
import probo_airplane_left_trouble from '!raw-loader!../../assets/custom-sprites/d758b7a24117fc72ee253d575834071b.svg';
import probo_airplane_right_trouble from '!raw-loader!../../assets/custom-sprites/2d190d878485afc1628753af0a78c2cb.svg';
import probo_monkey1 from '!raw-loader!../../assets/custom-sprites/57c118f0dcddc33873dd018be4416a52.svg';
import probo_monkey2 from '!raw-loader!../../assets/custom-sprites/de9d73341bf83a72c39994026f5acf1b.svg';
import probo_monkey3 from '!raw-loader!../../assets/custom-sprites/2df6b1d11232c3e0e6d4a359ac043ad4.svg';
import probo_note from '!raw-loader!../../assets/custom-sprites/024704348e798ed5aa1b91bd7e493044.svg';
import probo_candy from '!raw-loader!../../assets/custom-sprites/f84bb4c45844bd7b20934009059ba003.svg';
import probo_car_back from '!raw-loader!../../assets/custom-sprites/3e02698416933b7e7ab07bbd829a8553.svg';
import probo_car_front from '!raw-loader!../../assets/custom-sprites/3de94894fb65507820f18dbb5682e53d.svg';
import probo_car_left_trouble from '!raw-loader!../../assets/custom-sprites/b80c487cc5ebf82c8bcf83648da1fb3d.svg';
import probo_car_right_trouble from '!raw-loader!../../assets/custom-sprites/a32058c6cb800623bbbfd84407c376d7.svg';
import probo_car_top_trouble from '!raw-loader!../../assets/custom-sprites/9e493dc635299f890e4ba9a7707800f2.svg';
import probo_pencilcase from '!raw-loader!../../assets/custom-sprites/fbce43d8460500782c1009b052823af9.svg';
import probo_crayon from '!raw-loader!../../assets/custom-sprites/318f60001d8c9fa5bd3643d9d0ef52b8.svg';
import probo_firstaid from '!raw-loader!../../assets/custom-sprites/8fcc50cea5202ad554805f243df95591.svg';
import probo_flashlihgt from '!raw-loader!../../assets/custom-sprites/cf3c63093ae2c7897b74e748cd959da6.svg';
import probo_socks from '!raw-loader!../../assets/custom-sprites/b5c54bb5bd0ec6535ef0aeb89e4189d5.svg';
import probo_submarine_front from '!raw-loader!../../assets/custom-sprites/3e5f882c5158e05e15c9566ec6705e23.svg';
import probo_submarine_left from '!raw-loader!../../assets/custom-sprites/dfb1251dfed550f95e70647d311c3375.svg';
import probo_submarine_right from '!raw-loader!../../assets/custom-sprites/c4fb51490737939c81d55daaf27bd427.svg';
import probo_yoyo from '!raw-loader!../../assets/custom-sprites/9e85f8c61f33a98294c70d4f6779a872.svg';
import probo_hairpin from '!raw-loader!../../assets/custom-sprites/4c967617cf2db1d603af796a1b9b123d.svg';
import probo_hat from '!raw-loader!../../assets/custom-sprites/a25b4375e516ea3619123c3a8f68d28d.svg';
import probo_ai_walking_a from '!raw-loader!../../assets/custom-sprites/b11a645ef06c6ab500469e06a7d27af1.svg';
import probo_ai_walking_b from '!raw-loader!../../assets/custom-sprites/37b69f02336e2814f760bb6f81f37e21.svg';
import probo_ai_walkdown_a from '!raw-loader!../../assets/custom-sprites/c31d713e0bcf5a7a98a192ccaa1ac359.svg';
import probo_ai_walkdown_b from '!raw-loader!../../assets/custom-sprites/ae51bfcec3141064b0e1eda7415e8a69.svg';
import probo_ai_back from '!raw-loader!../../assets/custom-sprites/cd422374fa8003a1d3c44ffe2c87431c.svg';
import probo_ai_cokeA from '!raw-loader!../../assets/custom-sprites/66725934df8641d602234874f43c62df.svg';
import probo_ai_cokeB from '!raw-loader!../../assets/custom-sprites/173dad3581ed6c43cbbd7b3997481afe.svg';
import probo_ai_damage from '!raw-loader!../../assets/custom-sprites/3d97dbe75bd9e0e707910fd9cddb3216.svg';
import probo_ai_dont_a from '!raw-loader!../../assets/custom-sprites/813665469e05ec19c5576795baeaa8d3.svg';
import probo_ai_dont_b from '!raw-loader!../../assets/custom-sprites/dbb985f8ac08eb1dcb6aff58b327a668.svg';
import probo_ai_find from '!raw-loader!../../assets/custom-sprites/d611f8bac1c53691bc018b6861a231cf.svg';
import probo_ai_front from '!raw-loader!../../assets/custom-sprites/c5772a52261e5dabe0ae29148a19b34f.svg';
import probo_ai_game from '!raw-loader!../../assets/custom-sprites/7202d5d6788332753791d9acf00b9c19.svg';
import probo_ai_hi_a from '!raw-loader!../../assets/custom-sprites/019964febeb745d12d69a469ef86c965.svg';
import probo_ai_hi_b from '!raw-loader!../../assets/custom-sprites/f038a05b416450ffd18f63bfc6a4cc0e.svg';
import probo_ai_lightning from '!raw-loader!../../assets/custom-sprites/10452e225d6fa8dab9cee955ed0f6006.svg';
import probo_ai_right from '!raw-loader!../../assets/custom-sprites/d072fc5d122bdc8d2c2a8db693594c0a.svg';
import probo_ai_run_a from '!raw-loader!../../assets/custom-sprites/61d51f6b403620a6f675a8420fbb5ad9.svg';
import probo_ai_run_b from '!raw-loader!../../assets/custom-sprites/cd970ef10946f7cea9c00fd5a80a026e.svg';
import probo_ai_thinking_a from '!raw-loader!../../assets/custom-sprites/4bd5d4e29a214e97d4c6f828c915cee9.svg';
import probo_ai_thinking_b from '!raw-loader!../../assets/custom-sprites/501d33069d70e4c74009fff375e79147.svg';
import probo_Ai_throw_A from '!raw-loader!../../assets/custom-sprites/195f58c9dccc6f22e09cbc6aaf5cb89a.svg';
import probo_Ai_throw_B from '!raw-loader!../../assets/custom-sprites/3596d21fb9f6595b66a69d23275b04c0.svg';
import probo_ai_topview from '!raw-loader!../../assets/custom-sprites/71a510f05d3555b3059d1fca5ed2f697.svg';
import probo_ai_walkup_a from '!raw-loader!../../assets/custom-sprites/bf3cef0928bfcd3eb438714704503ebe.svg';
import probo_ai_walkup_b from '!raw-loader!../../assets/custom-sprites/a41402815224bacac24b0de0a153c993.svg';
import probo_pumping_ai_A from '!raw-loader!../../assets/custom-sprites/955c9d5104ddf18822afc48fcf5d6a2d.svg';
import probo_pumping_ai_B from '!raw-loader!../../assets/custom-sprites/3c49e285384da190385cf4b2741b1f23.svg';
import probo_pumping_ai_C from '!raw-loader!../../assets/custom-sprites/1b917ca993c8b472e7530a2a322e463d.svg';
import probo_airplane_back from '!raw-loader!../../assets/custom-sprites/63f7167e3fa3fd3ab5c5a92878b76c34.svg';
import probo_airplane_left from '!raw-loader!../../assets/custom-sprites/b29f1948704bf358d19c522010335545.svg';
import probo_airplane_right from '!raw-loader!../../assets/custom-sprites/688ce809116603f63d9fa692c23f5478.svg';
import probo_airplane_top from '!raw-loader!../../assets/custom-sprites/9f27db197961419a1d40884727be414d.svg';
import probo_all_bar from '!raw-loader!../../assets/custom-sprites/e29dad3017f04e9e82a93078300aac5a.svg';
import probo_moving_point from '!raw-loader!../../assets/custom-sprites/942c7a0d0da1a53c61fd7f4e6f7e71f8.svg';
import probo_spaceship_energy from '!raw-loader!../../assets/custom-sprites/bc0e1d12d40df54386a242b7c5f79cb3.svg';
import probo_arrow from '!raw-loader!../../assets/custom-sprites/e61b9228ba46b41df4559bc6b2491227.svg';
import probo_left_attack1 from '!raw-loader!../../assets/custom-sprites/89b9854a23aacadcb0359637756abed1.svg';
import probo_left_attack2 from '!raw-loader!../../assets/custom-sprites/6fd3c8b511ccf8d688b310dbeccfba3d.svg';
import probo_right_attack1 from '!raw-loader!../../assets/custom-sprites/48b61b50415bc5254197adc4571f9354.svg';
import probo_right_attack2 from '!raw-loader!../../assets/custom-sprites/d9f0d3a0b0def2b21ba673eb7b3683e9.svg';
import probo_baby_dino from '!raw-loader!../../assets/custom-sprites/fbb91be3a3d8799be359d5cdb62b7afd.svg';
import probo_bait from '!raw-loader!../../assets/custom-sprites/9c1b8b4cd06b9804e9c4c8e23a65c819.svg';
import probo_batsA from '!raw-loader!../../assets/custom-sprites/6981f236166df42cdfc3651d0fa8b14f.svg';
import probo_batsB from '!raw-loader!../../assets/custom-sprites/fc25cb5061e706a1c2b545fe6875b27b.svg';
import probo_battery from '!raw-loader!../../assets/custom-sprites/a9a7c509f3170a387978c53e42d23673.svg';
import probo_battery_blue0 from '!raw-loader!../../assets/custom-sprites/b7cfce57957c44aa516a8e9b59f5eccc.svg';
import probo_battery_blue1 from '!raw-loader!../../assets/custom-sprites/9e9d3d1c4cf20941fa84779a4753103c.svg';
import probo_battery_blue2 from '!raw-loader!../../assets/custom-sprites/39d009f62bb43e53cb7b9dce95cade01.svg';
import probo_battery_blue3 from '!raw-loader!../../assets/custom-sprites/ed945614712c6c806d96a3adb667f46c.svg';
import probo_battery_red0 from '!raw-loader!../../assets/custom-sprites/1615bfc0bca9a17790b75e8db2f6d3bb.svg';
import probo_battery_red1 from '!raw-loader!../../assets/custom-sprites/fa720d0e2c05100d0fe98c31b63a9062.svg';
import probo_battery_red2 from '!raw-loader!../../assets/custom-sprites/232fc69f2c26a18833c7ddf9f2d86c01.svg';
import probo_battery_red3 from '!raw-loader!../../assets/custom-sprites/52acbc7cf44d45a0be216ad460ca5a38.svg';
import probo_beeA from '!raw-loader!../../assets/custom-sprites/9b4676430a177ed7cb93d0a33e4c9019.svg';
import probo_beeB from '!raw-loader!../../assets/custom-sprites/5e9083faa6481213a8e7b20cf31215ac.svg';
import probo_beeC from '!raw-loader!../../assets/custom-sprites/d40a1da4435707fb233f3f1d9f5fbfce.svg';
import probo_beeD from '!raw-loader!../../assets/custom-sprites/9a9e9239a9927e212242b60acaeeccfe.svg';
import probo_bow1 from '!raw-loader!../../assets/custom-sprites/7a2eafc022cebfc6afe4b8c95e7adb8b.svg';
import probo_bow2 from '!raw-loader!../../assets/custom-sprites/8868f199f824bd7690f07106baf6401e.svg';
import probo_broken_glass from '!raw-loader!../../assets/custom-sprites/acdf31df8d1a6e3ee0363132e6bb4bdb.svg';
import probo_broken_plate from '!raw-loader!../../assets/custom-sprites/cdea01e22d80a161b1d1b01b2f1fbf1b.svg';
import probo_brush1 from '!raw-loader!../../assets/custom-sprites/3dcd757429184ec2426643b48e25f5a9.svg';
import probo_brush2 from '!raw-loader!../../assets/custom-sprites/d9d97744271dd2123016cfde284d40b0.svg';
import probo_bubble from '!raw-loader!../../assets/custom-sprites/783d0691adfc5243144f454cc56933d1.svg';
import probo_bucket1 from '!raw-loader!../../assets/custom-sprites/e5df616912edefb40465535a10769d50.svg';
import probo_bucket2 from '!raw-loader!../../assets/custom-sprites/91591f12fbdfb6d2f0058119985ae5f9.svg';
import probo_bucket3 from '!raw-loader!../../assets/custom-sprites/657d97418740f63774922e97d79fec4f.svg';
import probo_bucket4 from '!raw-loader!../../assets/custom-sprites/08d50dd6586aba7f201b59f04197d32e.svg';
import probo_building_A from '!raw-loader!../../assets/custom-sprites/e5486ae37bb58a8e0da715051787a553.svg';
import probo_building_B from '!raw-loader!../../assets/custom-sprites/4127c6c534d3349ed2d400c26a6ef6a8.svg';
import probo_building_minimap from '!raw-loader!../../assets/custom-sprites/ca8e531a0717e3c0f5dd08580ba97fbf.svg';
import probo_bullet from '!raw-loader!../../assets/custom-sprites/7c76e4edfbcb25e7b68e73dd1aa23aaa.svg';
import probo_butterflyA from '!raw-loader!../../assets/custom-sprites/5544f50b9abdc6574bdff941fd432b51.svg';
import probo_butterflyB from '!raw-loader!../../assets/custom-sprites/df389c8e768a0ae1cb7baa4eb6fe83ba.svg';
import probo_butterflyC from '!raw-loader!../../assets/custom-sprites/5bd3b7989bed4477ab51e18e6db69d63.svg';
import probo_can_coke from '!raw-loader!../../assets/custom-sprites/871fd74a40238115d9c7dc729c997670.svg';
import probo_candleA from '!raw-loader!../../assets/custom-sprites/ae7daa45d1aee147dc848959daf3d0b0.svg';
import probo_candleB from '!raw-loader!../../assets/custom-sprites/7589b8f0ebc2e1e0c117ef87351eb786.svg';
import probo_candleC from '!raw-loader!../../assets/custom-sprites/55a020313885cdebb31aa80cdcdf8912.svg';
import probo_candleD from '!raw-loader!../../assets/custom-sprites/849e73b2bc8b15ed7a353d0be0e6631c.svg';
import probo_candleE from '!raw-loader!../../assets/custom-sprites/66f9163eb8cc34ffb38fbd4f9b36fb7d.svg';
import probo_cardsoldierA1 from '!raw-loader!../../assets/custom-sprites/cfa745f583156c7ea2816e6c5cee7087.svg';
import probo_cardsoldierA2 from '!raw-loader!../../assets/custom-sprites/a2367db9afc9829d89987869999d2cb5.svg';
import probo_cardsoldierB1 from '!raw-loader!../../assets/custom-sprites/cc546033ca89dd1885c6d9d911cab7fd.svg';
import probo_cardsoldierB2 from '!raw-loader!../../assets/custom-sprites/73e4759207adf43e9f174e20016a4fdf.svg';
import probo_cardsoldierC1 from '!raw-loader!../../assets/custom-sprites/6ab473c9158aeaacf55b7bf13a651054.svg';
import probo_cardsoldierC2 from '!raw-loader!../../assets/custom-sprites/531d29f426447457476d41179547c884.svg';
import probo_cardsoldierD1 from '!raw-loader!../../assets/custom-sprites/65feb9164d473fa33c8984460f38a6e4.svg';
import probo_cardsoldierD2 from '!raw-loader!../../assets/custom-sprites/e9ba44f6ba47cc3fac75af9b58ec1709.svg';
import probo_crying1 from '!raw-loader!../../assets/custom-sprites/de0e25fc11cdceb3f4bbd08257e91729.svg';
import probo_crying2 from '!raw-loader!../../assets/custom-sprites/defd253a5f7220ccb275e6923dfffdc3.svg';
import probo_flag1 from '!raw-loader!../../assets/custom-sprites/c224e5c36dc2d6d6d638032ab9447f52.svg';
import probo_flag2 from '!raw-loader!../../assets/custom-sprites/69c262189af3b409355a696fdab557d7.svg';
import probo_flag3 from '!raw-loader!../../assets/custom-sprites/075366da5f1cb2f29f7c11e54bdcbf65.svg';
import probo_flag4 from '!raw-loader!../../assets/custom-sprites/cbf084206bac79c47c613441e27a4d34.svg';
import probo_flag5 from '!raw-loader!../../assets/custom-sprites/a09634e8078dc77bb147a9726993769e.svg';
import probo_flag6 from '!raw-loader!../../assets/custom-sprites/c4452318658561eedb3b4ccd94dc8601.svg';
import probo_flag7 from '!raw-loader!../../assets/custom-sprites/3b1d2e283ba598b3441a6636d9c501f9.svg';
import probo_flag8 from '!raw-loader!../../assets/custom-sprites/5046dcb56bae349ea1a854cffea5ea9f.svg';
import probo_flag9 from '!raw-loader!../../assets/custom-sprites/79259b040d848eabd689f55e01113b06.svg';
import probo_carriageA from '!raw-loader!../../assets/custom-sprites/9a24fe892648230fcfa3d90e27bde4ba.svg';
import probo_carriageB from '!raw-loader!../../assets/custom-sprites/118504c15d7d1340c01c0143776833c5.svg';
import probo_floor_surface from '!raw-loader!../../assets/custom-sprites/fd14b92a6388b515c20ca42609be0bd7.svg';
import probo_chaging_ball1 from '!raw-loader!../../assets/custom-sprites/6b52e684317e6236599fb27ed0866ce0.svg';
import probo_chaging_ball2 from '!raw-loader!../../assets/custom-sprites/508afa890e9bbf6269a42cc4e3c02b16.svg';
import probo_chaging_ball3 from '!raw-loader!../../assets/custom-sprites/5e1f91ded4859dec41066126ef6919f2.svg';
import probo_chaging_ball4 from '!raw-loader!../../assets/custom-sprites/120084d2ec5fa08895a80862f09bbc98.svg';
import probo_chaging_ball5 from '!raw-loader!../../assets/custom-sprites/d8b4578b2955a85d6677a42d6a508877.svg';
import probo_chocolate from '!raw-loader!../../assets/custom-sprites/5f86dafbb66f62a1e5dc2db9253bdd01.svg';
import probo_cleaning_robot from '!raw-loader!../../assets/custom-sprites/42fd39bed3bd799ddb9ac7ad97753307.svg';
import probo_cleaning_robot2 from '!raw-loader!../../assets/custom-sprites/6f74b02865c3aa8ca6614f1d2e053984.svg';
import probo_cleaning_robot3 from '!raw-loader!../../assets/custom-sprites/5858bd12670d1786a5658086b7809308.svg';
import probo_cleaning_robot4 from '!raw-loader!../../assets/custom-sprites/9e825aba35bbeabe6a1d38f21c20c2c3.svg';
import probo_cleaning_robot_D from '!raw-loader!../../assets/custom-sprites/cf0ecf3f94d114a780be43c5fa2120f9.svg';
import probo_cleaning_robot_D2 from '!raw-loader!../../assets/custom-sprites/44a0ad1e27e3435473ed618c048275d7.svg';
import probo_cleaning_robot_D3 from '!raw-loader!../../assets/custom-sprites/e25ddc2cf442b7c689da5ab2d8f02d3d.svg';
import probo_cleaning_robot_D4 from '!raw-loader!../../assets/custom-sprites/82e2b55c020425723b6654297ba3d6ab.svg';
import probo_cleaning_robot_G from '!raw-loader!../../assets/custom-sprites/2a0597a7e6ecdaf3221fa7de7d9355dd.svg';
import probo_cleaning_robot_G2 from '!raw-loader!../../assets/custom-sprites/6e3658fcbfd53fa16342f21169fa7bbb.svg';
import probo_cleaning_robot_G3 from '!raw-loader!../../assets/custom-sprites/8b4dd0b00565b083322d31c5f77b1a56.svg';
import probo_cleaning_robot_G4 from '!raw-loader!../../assets/custom-sprites/3e4e230ba0cf92b604f6beedcd3d9519.svg';
import probo_cleaning_robot_P from '!raw-loader!../../assets/custom-sprites/b6d961d20a35cf7b932e11d17d603a3f.svg';
import probo_cleaning_robot_P2 from '!raw-loader!../../assets/custom-sprites/f650f89ba0e22b64651ac3a43dd34488.svg';
import probo_cleaning_robot_P3 from '!raw-loader!../../assets/custom-sprites/3b1972b11f9518d60d8efb58fc22ff94.svg';
import probo_cleaning_robot_P4 from '!raw-loader!../../assets/custom-sprites/06366d6fd874e7d7a302d4d69fcd012e.svg';
import probo_cleaning_robot_R from '!raw-loader!../../assets/custom-sprites/1187216bb1ce7b6637f19d778fe78e7d.svg';
import probo_cleaning_robot_R2 from '!raw-loader!../../assets/custom-sprites/d2811283287649162759fb8ba3c9c51b.svg';
import probo_cleaning_robot_R3 from '!raw-loader!../../assets/custom-sprites/bd782c32a9d48bc51d46bb4c23008a44.svg';
import probo_cleaning_robot_R4 from '!raw-loader!../../assets/custom-sprites/5ee721fdae307943a9e1cf3a30b66df9.svg';
import probo_cleaning_robot_Y from '!raw-loader!../../assets/custom-sprites/99cdfc2b5b86091684fc5ef564645be3.svg';
import probo_cleaning_robot_Y2 from '!raw-loader!../../assets/custom-sprites/aca6f16217927c57a53084cac5579b70.svg';
import probo_cleaning_robot_Y3 from '!raw-loader!../../assets/custom-sprites/322738caf268498387e16b8545922da4.svg';
import probo_cleaning_robot_Y4 from '!raw-loader!../../assets/custom-sprites/ed13a5e6a09e5733d0bc8486d95b8e7c.svg';
import probo_cloud_A from '!raw-loader!../../assets/custom-sprites/c23549bfa2563610a8a8478334c5a61f.svg';
import probo_cloud_B from '!raw-loader!../../assets/custom-sprites/1bb6ff3134f8b965d7e5bc61f8eac6fc.svg';
import probo_cloud_C from '!raw-loader!../../assets/custom-sprites/880d48153cb068ea77a8b4de91b24e81.svg';
import probo_cloud_D from '!raw-loader!../../assets/custom-sprites/e1e66e4fb243074311b9583ecd819412.svg';
import probo_cloud_E from '!raw-loader!../../assets/custom-sprites/a719ffd01c625ec1f24406e39b6c7f6b.svg';
import probo_cloud_F from '!raw-loader!../../assets/custom-sprites/20f7b177e98327a39889a86bd9156973.svg';
import probo_clovergarden_A from '!raw-loader!../../assets/custom-sprites/89c72a65d466c2e234b6f53239266f21.svg';
import probo_clovergarden_B from '!raw-loader!../../assets/custom-sprites/8443f793ed1d54c8fa4eb1ed5706ce21.svg';
import probo_clovergarden_C from '!raw-loader!../../assets/custom-sprites/a028e260d007dc41bb33227373b4fe6a.svg';
import probo_clovergarden_D from '!raw-loader!../../assets/custom-sprites/62a5a0320e07129ef191f65e7a80fc70.svg';
import probo_codingblock1 from '!raw-loader!../../assets/custom-sprites/ca0a8cd87e569e706d5a7c35fed24fed.svg';
import probo_codingblock2 from '!raw-loader!../../assets/custom-sprites/71522fcd71caa91dfea0c82feade8692.svg';
import probo_codingblock3 from '!raw-loader!../../assets/custom-sprites/4aae65d09008ccbbda50d927d278bd38.svg';
import probo_codingblock4 from '!raw-loader!../../assets/custom-sprites/662797f3b56ad1911813824aa54c94e1.svg';
import probo_codingblock5 from '!raw-loader!../../assets/custom-sprites/f10ce04361866a5689ed0206b3878ba3.svg';
import probo_codingblock6 from '!raw-loader!../../assets/custom-sprites/7e1609cfd6979d35c27949f1f64d83f7.svg';
import probo_codingblock7 from '!raw-loader!../../assets/custom-sprites/cd9fbcb36a02c7c21137e59036151a57.svg';
import probo_codingblock8 from '!raw-loader!../../assets/custom-sprites/b8373cc6c5b0613db4b47ce59970750f.svg';
import probo_codingblock9 from '!raw-loader!../../assets/custom-sprites/494b70e35aacd1f2a2f3b697629ad55a.svg';
import probo_codingblock10 from '!raw-loader!../../assets/custom-sprites/6224db4d458c3232df400685c3ad9bad.svg';
import probo_coin_A from '!raw-loader!../../assets/custom-sprites/37418f7708f20b29cb1774ce244dd0d5.svg';
import probo_coin_B from '!raw-loader!../../assets/custom-sprites/069a73b24c36d96eaf28ea6b42b77a35.svg';
import probo_coke from '!raw-loader!../../assets/custom-sprites/4054f1da4fe51677bd9fb44b266c1dac.svg';
import probo_coke_gauge_A from '!raw-loader!../../assets/custom-sprites/d157499d553809783c79851e9974b08a.svg';
import probo_coke_gauge_B from '!raw-loader!../../assets/custom-sprites/413a8ac43f2a1747644e8bc756195355.svg';
import probo_coke_gauge_C from '!raw-loader!../../assets/custom-sprites/046786b9a905f3d4d919a7a031b37ff5.svg';
import probo_combo from '!raw-loader!../../assets/custom-sprites/c7dca4e678eb0f51e9a43f99011d1e45.svg';
import probo_completeness from '!raw-loader!../../assets/custom-sprites/35cabfc6f116b8a28e9916f5529af544.svg';
import probo_completeness2 from '!raw-loader!../../assets/custom-sprites/dbdd79cc330d623986fec700203d0a71.svg';
import probo_condition_A from '!raw-loader!../../assets/custom-sprites/2769df75493b1eb8fb712efa87433dcb.svg';
import probo_condition_B from '!raw-loader!../../assets/custom-sprites/1b808e1c2bcf4736101709ecca61e6da.svg';
import probo_condition_C from '!raw-loader!../../assets/custom-sprites/80f13f2cfe9931818f73f29f9e142ca4.svg';
import probo_cprpersonA1 from '!raw-loader!../../assets/custom-sprites/068abd7ee7a975351daba9bcc3c351c1.svg';
import probo_cprpersonA2 from '!raw-loader!../../assets/custom-sprites/0dcd4713e4621e9954b050b6238af277.svg';
import probo_cprpersonB1 from '!raw-loader!../../assets/custom-sprites/3d4a72c78513a64c7819dafe0b191c77.svg';
import probo_cprpersonB2 from '!raw-loader!../../assets/custom-sprites/2eca7f39b20656602eb8010fa6dbbcc3.svg';
import probo_cprpersonC1 from '!raw-loader!../../assets/custom-sprites/9d86785ae27acf7f6ff111b9cb343367.svg';
import probo_cprpersonC2 from '!raw-loader!../../assets/custom-sprites/eb4e0ced5199d37ce5c42c61eca6a1fe.svg';
import probo_cprpersonD1 from '!raw-loader!../../assets/custom-sprites/b516580170a5ed5f600c88dd053d6747.svg';
import probo_cprpersonD2 from '!raw-loader!../../assets/custom-sprites/2d78bfdd6d23ed932aa948900e48926c.svg';
import probo_cprPU from '!raw-loader!../../assets/custom-sprites/e44da7b5e3971b7397f3c63618bef5eb.svg';
import probo_cprhand from '!raw-loader!../../assets/custom-sprites/7a2a8e2473c1d89a16e4f7f780cf4c03.svg';
import probo_crab_A from '!raw-loader!../../assets/custom-sprites/37ad0cd01b61e32b7ffa1542b7cee8ca.svg';
import probo_crab_B from '!raw-loader!../../assets/custom-sprites/f72923700de084fa3e3d2bb41627af07.svg';
import probo_crush1 from '!raw-loader!../../assets/custom-sprites/ce307866714c22c4e8b97c03edde5b01.svg';
import probo_crush2 from '!raw-loader!../../assets/custom-sprites/85e2128b730b6f9517ababcd329c4161.svg';
import probo_cut_avocado_A from '!raw-loader!../../assets/custom-sprites/6355773384561ed8ccd28df9b91d51ba.svg';
import probo_cut_avocado_B from '!raw-loader!../../assets/custom-sprites/61f864d2f8e8f8bdfc11eafff44a0424.svg';
import probo_cut_banana_A from '!raw-loader!../../assets/custom-sprites/63fb594761000a7ce13c73c53ea80b86.svg';
import probo_cut_banana_B from '!raw-loader!../../assets/custom-sprites/41ea5b27e87c26b192ece8c0082c61c5.svg';
import probo_cut_cabbage_A from '!raw-loader!../../assets/custom-sprites/e961a68a0bf733d5adeaa6adc5681f1e.svg';
import probo_cut_cabbage_B from '!raw-loader!../../assets/custom-sprites/4beb1171d3fdc31e9265da7176909b54.svg';
import probo_cut_carrot_A from '!raw-loader!../../assets/custom-sprites/69bf9525b44ff185984ad4b072c844e0.svg';
import probo_cut_carrot_B from '!raw-loader!../../assets/custom-sprites/5b07c4def82939318e4cba14d9bc1712.svg';
import probo_cut_grape_A from '!raw-loader!../../assets/custom-sprites/5622a7d9af14a379f1bcdd2f9b3367a2.svg';
import probo_cut_grape_B from '!raw-loader!../../assets/custom-sprites/c35193ef0a176ca9adf78b5a7d0463ae.svg';
import probo_cut_onion_A from '!raw-loader!../../assets/custom-sprites/11685ee50f3eb65ad1eaf2cc679add2c.svg';
import probo_cut_onion_B from '!raw-loader!../../assets/custom-sprites/9e72edf527a1a0e89ae5e37501767a82.svg';
import probo_cut_paprika_A from '!raw-loader!../../assets/custom-sprites/633d7e30893e46f12397121d4d1806fa.svg';
import probo_cut_paprika_B from '!raw-loader!../../assets/custom-sprites/b0abd31e29a7b29343de8e19028c0e9e.svg';
import probo_cut_watermelon_A from '!raw-loader!../../assets/custom-sprites/bc47d1d7475f31f36d839d1af0d56981.svg';
import probo_cut_watermelon_B from '!raw-loader!../../assets/custom-sprites/e1f731b9b02ae9eb1c524e4bae25e720.svg';
import probo_cutting_energy_20 from '!raw-loader!../../assets/custom-sprites/e22b73ec7661422f0ff48f06e20db6fc.svg';
import probo_cutting_energy_40 from '!raw-loader!../../assets/custom-sprites/3e1b44f5d7af5e172f207ffff6c5e883.svg';
import probo_cutting_energy_60 from '!raw-loader!../../assets/custom-sprites/c4c43745bd7d2d5ded65334d69d20f6f.svg';
import probo_cutting_energy_80 from '!raw-loader!../../assets/custom-sprites/91be4110a7b4af17a61205d693df7bf2.svg';
import probo_cutting_energy_100 from '!raw-loader!../../assets/custom-sprites/d2bfe2f5abe682eb0eedffcb3b5151dd.svg';
import probo_cutting_energy_120 from '!raw-loader!../../assets/custom-sprites/e322a86b464c9234e38930980c97e91d.svg';
import probo_cutting_energy_140 from '!raw-loader!../../assets/custom-sprites/25ed3a7a80b2ddca7626ddc247e8d602.svg';
import probo_cutting_energy_160 from '!raw-loader!../../assets/custom-sprites/46d6e9687dd6af54bd94368664e7c354.svg';
import probo_cutting_energy_180 from '!raw-loader!../../assets/custom-sprites/f9ecb72da8ecb15b79a77c9a6fd30388.svg';
import probo_cutting_energy_200 from '!raw-loader!../../assets/custom-sprites/801bff5664a059ec2b5b702c553c606d.svg';
import probo_cutting_machine from '!raw-loader!../../assets/custom-sprites/ce14dc8ae03a923031d2a28290e42799.svg';
import probo_cutting_machine2 from '!raw-loader!../../assets/custom-sprites/a1c429940f12266a7e9c2a758477ffde.svg';
import probo_circle from '!raw-loader!../../assets/custom-sprites/3dcf25e3c597461e852daa6b5367510b.svg';
import probo_dark_clouds_A from '!raw-loader!../../assets/custom-sprites/885ad902e0b311fa8f906a2b3c04bfc6.svg';
import probo_dark_clouds_B from '!raw-loader!../../assets/custom-sprites/e1feb653f81874fc7d34b24c63d81b09.svg';
import probo_dark_clouds_C from '!raw-loader!../../assets/custom-sprites/48ee4100c1ab99475f8e60d23cf8c7a8.svg';
import probo_dark_clouds_D from '!raw-loader!../../assets/custom-sprites/9197065dacc64300dbcb5e335b7e989d.svg';
import probo_dark_clouds_E from '!raw-loader!../../assets/custom-sprites/a1efdf9c30164aef7212a7fd479cf9be.svg';
import probo_dark_clouds_F from '!raw-loader!../../assets/custom-sprites/287664683bef728856b7871b8fadc125.svg';
import probo_desert_maze_A from '!raw-loader!../../assets/custom-sprites/4a04e12d7e86c6a9cbf05a70d9ee269b.svg';
import probo_desert_maze_B from '!raw-loader!../../assets/custom-sprites/b5a1e01898c8289cc2efa20d5681f212.svg';
import probo_desert_maze_C from '!raw-loader!../../assets/custom-sprites/f089bfcfba1dd1eed8a563600b2e4304.svg';
import probo_desert_maze_D from '!raw-loader!../../assets/custom-sprites/c4b7bb3e1949560e75b7ec8dc901c290.svg';
import probo_desert_maze_E from '!raw-loader!../../assets/custom-sprites/c2d6cb9b3cd9c7e5b6444c0ddbf40e05.svg';
import probo_desert_maze_F from '!raw-loader!../../assets/custom-sprites/1e81ad7b78d35a2a7c1cfc03b384dc27.svg';
import probo_desert_maze_G from '!raw-loader!../../assets/custom-sprites/c8bf67700892c9789578b610e53ea7cb.svg';
import probo_desert_maze_H from '!raw-loader!../../assets/custom-sprites/50d266419bca1654489c14994866d030.svg';
import probo_dino_radar1 from '!raw-loader!../../assets/custom-sprites/46c92ecadbaf46f7661fe26dd0964fc6.svg';
import probo_dino_radar2 from '!raw-loader!../../assets/custom-sprites/4d462e1a75750cb633f7d060df3fae5f.svg';
import probo_dinosaur1 from '!raw-loader!../../assets/custom-sprites/6d6db2bf667b3cefee58d3766ffcd7e4.svg';
import probo_dinosaur2 from '!raw-loader!../../assets/custom-sprites/f36c82a94dfd7f08862bd78f5d552743.svg';
import probo_dinosaur_back from '!raw-loader!../../assets/custom-sprites/57d0186ace227e5e6183480f97492696.svg';
import probo_dinosaur_cry from '!raw-loader!../../assets/custom-sprites/a35a6c26528ed703d4699882515dd654.svg';
import probo_driver from '!raw-loader!../../assets/custom-sprites/e1427d98e500ff7b4020c02109c0581a.svg';
import probo_doctor_A from '!raw-loader!../../assets/custom-sprites/c992c1c177504f86a82ad9601e547141.svg';
import probo_doctor_B from '!raw-loader!../../assets/custom-sprites/15b570cc561ec35fe6c5f3f39fe4f0b1.svg';
import probo_dot from '!raw-loader!../../assets/custom-sprites/5dddd23f1ee2bfc17574fc4f5fb0abf0.svg';
import probo_drone1 from '!raw-loader!../../assets/custom-sprites/4e302d78f71bcbfff3ff897283976e44.svg';
import probo_drone2 from '!raw-loader!../../assets/custom-sprites/05e6a601b0c437c083710c23c8085563.svg';
import probo_eknowandai_A from '!raw-loader!../../assets/custom-sprites/508f0d544afd19b62acb651d0b92dc0a.svg';
import probo_eknowandai_B from '!raw-loader!../../assets/custom-sprites/d84ca2129b0abadf76219f85d9792ad0.svg';
import probo_eknow_walking_a from '!raw-loader!../../assets/custom-sprites/bfb013dde173cf95b350874592c438dd.svg';
import probo_eknow_walking_b from '!raw-loader!../../assets/custom-sprites/71a6b016a8b7e17eb39df815bb88d922.svg';
import probo_pumping_eknow_A from '!raw-loader!../../assets/custom-sprites/63affca2054aabb6d82d5e6eaa8ffeab.svg';
import probo_pumping_eknow_B from '!raw-loader!../../assets/custom-sprites/c9bf8f8e809bc1a153228a8adc28e41e.svg';
import probo_pumping_eknow_C from '!raw-loader!../../assets/custom-sprites/dc94a18ae0bca8ea0d5756f07a90c76c.svg';
import probo_eknow_angry from '!raw-loader!../../assets/custom-sprites/9e8b7f4c4c1502f1d2bca6ad5f581f13.svg';
import probo_eknow_back from '!raw-loader!../../assets/custom-sprites/7f0583580661902b6cfd3ba7a76911f7.svg';
import probo_eknow_climb_a from '!raw-loader!../../assets/custom-sprites/815efa73b32422eef13c8fd11b201638.svg';
import probo_eknow_climb_b from '!raw-loader!../../assets/custom-sprites/965e31b474c67ce1fb4a2b8d04968ba4.svg';
import probo_eknow_climb_c from '!raw-loader!../../assets/custom-sprites/638caef9a70d4550e6f6628a46e23eb1.svg';
import probo_eknow_cokeA from '!raw-loader!../../assets/custom-sprites/02e0e98ac5ef68f616b57131abc320f9.svg';
import probo_eknow_cokeB from '!raw-loader!../../assets/custom-sprites/2587cb3b3b5306054ce1751eaeda6b86.svg';
import probo_eknow_crouch from '!raw-loader!../../assets/custom-sprites/e792bcf1d0d34f6dce5af7d6a30bde86.svg';
import probo_eknow_cry from '!raw-loader!../../assets/custom-sprites/2de0b31dc4f884a039951cffffe96619.svg';
import probo_eknow_damage from '!raw-loader!../../assets/custom-sprites/6a0691e49a8ef43fd1180f14674fd759.svg';
import probo_eknow_find from '!raw-loader!../../assets/custom-sprites/9493bf34f4408ebeb9de5bb15bad89d9.svg';
import probo_eknow_front from '!raw-loader!../../assets/custom-sprites/0e1e6999a341691e60b074a9deae3373.svg';
import probo_eknow_game from '!raw-loader!../../assets/custom-sprites/edfc18f460ed4132c04cc000348d2652.svg';
import probo_eknow_good from '!raw-loader!../../assets/custom-sprites/50e8a49fa13744165ba6ad30eae35b62.svg';
import probo_eknow_hammer_A from '!raw-loader!../../assets/custom-sprites/7f7173a3610d4139bfe855f00cfbe271.svg';
import probo_eknow_hammer_B from '!raw-loader!../../assets/custom-sprites/ede3c6328d9b435a8cfda47486bc15ae.svg';
import probo_eknow_hammer_C from '!raw-loader!../../assets/custom-sprites/0529171f2f340cbd50db9a24c06a8855.svg';
import probo_eknow_hammering_A from '!raw-loader!../../assets/custom-sprites/856a8101ed481a2ed2b512e2f7a58114.svg';
import probo_eknow_hammering_B from '!raw-loader!../../assets/custom-sprites/fb2ea1c938d6f131d58c7d9a08cdf9e4.svg';
import probo_eknow_hi_a from '!raw-loader!../../assets/custom-sprites/dbf2029c159dc8baefbcf067ea7ae698.svg';
import probo_eknow_hi_b from '!raw-loader!../../assets/custom-sprites/7919ffcdc68081440211335a8aa5aa7c.svg';
import probo_eknow_jumpup from '!raw-loader!../../assets/custom-sprites/8a2eb102c8532109b96ea521166bcf01.svg';
import probo_eknow_lightning from '!raw-loader!../../assets/custom-sprites/3155addea78083df1c8df1d723f27cd1.svg';
import probo_eknow_right from '!raw-loader!../../assets/custom-sprites/aabddb6216dd4e004bd9a3240b39af84.svg';
import probo_eknow_run1A from '!raw-loader!../../assets/custom-sprites/3c9841cbe70090748a9536899f063b0d.svg';
import probo_eknow_run1B from '!raw-loader!../../assets/custom-sprites/4fe498b498ae1acc0707b815066ab6d2.svg';
import probo_eknow_run2A from '!raw-loader!../../assets/custom-sprites/2c728fb99a857fe9ba556b0f9832262c.svg';
import probo_eknow_run2B from '!raw-loader!../../assets/custom-sprites/c49b02955db4608381c835f1d840c36c.svg';
import probo_eknow_run3A from '!raw-loader!../../assets/custom-sprites/02b08608c5a8b856692614c4cb225843.svg';
import probo_eknow_run3B from '!raw-loader!../../assets/custom-sprites/130c5eb117637e2b382193de7997d9dd.svg';
import probo_eknow_slide from '!raw-loader!../../assets/custom-sprites/e72d9c1cdf981cf71cb94b55867312d4.svg';
import probo_eknow_slingshot from '!raw-loader!../../assets/custom-sprites/b10dc03cbe1c8696ad2a6b6bf84c4fdb.svg';
import probo_eknow_stealth2A from '!raw-loader!../../assets/custom-sprites/e9e81a98dad1d90e0eb5785ad21b0e27.svg';
import probo_eknow_stealth2B from '!raw-loader!../../assets/custom-sprites/259ffb6982e0bfb1db1b4633086c13ca.svg';
import probo_eknow_stealthA from '!raw-loader!../../assets/custom-sprites/5d78e743c4a62c9d134bff892930c894.svg';
import probo_eknow_stealthB from '!raw-loader!../../assets/custom-sprites/7654e3adc1155b97cf5f1465e4165662.svg';
import probo_eknow_surprised from '!raw-loader!../../assets/custom-sprites/a7faecaec7f01c3b7aa7bccb191a3d1c.svg';
import probo_eknow_talking_a from '!raw-loader!../../assets/custom-sprites/38cee3891ccc2139d4762a62d7dbc76a.svg';
import probo_eknow_talking_b from '!raw-loader!../../assets/custom-sprites/c2b4e3e020f6aa5c413d7ef12a6bb68f.svg';
import probo_eknow_talking_c from '!raw-loader!../../assets/custom-sprites/ae15a082ee6c9e407c987a12cf124b9e.svg';
import probo_eknow_topview from '!raw-loader!../../assets/custom-sprites/79028323b0070087b7a7f052c2cc28c1.svg';
import probo_eknow_walkdown_a from '!raw-loader!../../assets/custom-sprites/7411a97ae33cdd2eb0f79afad25f16df.svg';
import probo_eknow_walkdown_b from '!raw-loader!../../assets/custom-sprites/a49c578f7d34a84921e6928985b65f9a.svg';
import probo_eknow_walkup_a from '!raw-loader!../../assets/custom-sprites/6deb84fa31ab2162b07ff356e134d358.svg';
import probo_eknow_walkup_b from '!raw-loader!../../assets/custom-sprites/432d4edc614318a6474ce005dd913f0f.svg';
import probo_eagleA from '!raw-loader!../../assets/custom-sprites/871207262a7560ad41969f7e5044b5ab.svg';
import probo_eagleB from '!raw-loader!../../assets/custom-sprites/999cee19358f9df567233804956d9ec4.svg';
import probo_egg_ghost_A from '!raw-loader!../../assets/custom-sprites/a9cd1b73197089d44805db9009d7bdbb.svg';
import probo_egg_ghost_B from '!raw-loader!../../assets/custom-sprites/d5f9fb677064202e0aeab72b202498c1.svg';
import probo_egg_ghost_C from '!raw-loader!../../assets/custom-sprites/e7c55c1c45e13ff01c28907091b50f36.svg';
import probo_EMP from '!raw-loader!../../assets/custom-sprites/1dc0e6f626a41f0959cc979d741a9aa7.svg';
import probo_EMP_piece from '!raw-loader!../../assets/custom-sprites/099864a4290c64ef458963745b06fdf5.svg';
import probo_escape from '!raw-loader!../../assets/custom-sprites/56f438e23a8635a7f8106cf0772f3cdf.svg';
import probo_bad from '!raw-loader!../../assets/custom-sprites/7ab7bf93fcf0d30f3eaec36fdfd97eda.svg';
import probo_good from '!raw-loader!../../assets/custom-sprites/f0229fe149cc07c32ad6773663fa1369.svg';
import probo_fast_ghost_A from '!raw-loader!../../assets/custom-sprites/7d734c76434d1d2be876b40b485214a8.svg';
import probo_fast_ghost_B from '!raw-loader!../../assets/custom-sprites/edac51567b2007151e5f521cf3cf670d.svg';
import probo_fast_ghost_C from '!raw-loader!../../assets/custom-sprites/8cbdf52fe656e2b154e86ab7f1a1f0c5.svg';
import probo_fast_ghost_D from '!raw-loader!../../assets/custom-sprites/f4e45a10f56f0ea44e3d850da1feb1ba.svg';
import probo_finger_flick1 from '!raw-loader!../../assets/custom-sprites/aff5bda9e4f128c3ecea5d9fe495c9d3.svg';
import probo_finger_flick2 from '!raw-loader!../../assets/custom-sprites/44c7fd3ad1468b226f31e89785536f6c.svg';
import probo_fire from '!raw-loader!../../assets/custom-sprites/cd234944330bc1bb364336594029aefb.svg';
import probo_fire_building from '!raw-loader!../../assets/custom-sprites/58500eb0791e1825301f394d755aa8ec.svg';
import probo_fire_extinguisher from '!raw-loader!../../assets/custom-sprites/33f961f22d9df819fd48cb5b56345c00.svg';
import probo_fire_hose from '!raw-loader!../../assets/custom-sprites/416d4d79d3b0218bc13ab30b3ab87c8c.svg';
import probo_fish1 from '!raw-loader!../../assets/custom-sprites/fe353e6647dec3cd81b6565e8df8637e.svg';
import probo_fish2 from '!raw-loader!../../assets/custom-sprites/51237252d9197c26ad652bbad8812919.svg';
import probo_fish3 from '!raw-loader!../../assets/custom-sprites/375327077b782ab51aca32faa496d29f.svg';
import probo_fish4 from '!raw-loader!../../assets/custom-sprites/4be4680aee85bdb1baa562c24a1e41f3.svg';
import probo_fish5 from '!raw-loader!../../assets/custom-sprites/03e04626f88cca0c08f94810f7c0ab68.svg';
import probo_fish6 from '!raw-loader!../../assets/custom-sprites/f2f5c7e4e80413a74c9751de925c4bc3.svg';
import probo_fish7 from '!raw-loader!../../assets/custom-sprites/a58d08973d78ade9c9c6d86b3e1dea01.svg';
import probo_fishing_rod1 from '!raw-loader!../../assets/custom-sprites/168632edce53c8245ae535e4cfca5fb5.svg';
import probo_fishing_rod2 from '!raw-loader!../../assets/custom-sprites/cdebc5dd4a290e77e8611e60adba2997.svg';
import probo_flashlight_off from '!raw-loader!../../assets/custom-sprites/0036798fd3a38db6123eb565bda70d95.svg';
import probo_flashlight_on from '!raw-loader!../../assets/custom-sprites/a639647b5dc42f9f9c73905f2e5b5d12.svg';
import probo_flowerpot_A from '!raw-loader!../../assets/custom-sprites/e7597059966255515213707b15fe0455.svg';
import probo_flowerpot_B from '!raw-loader!../../assets/custom-sprites/3d7902e0a623a4a946a7bb82fd1ff43c.svg';
import probo_fly from '!raw-loader!../../assets/custom-sprites/96cd412528f78766c71e6d8012b88d85.svg';
import probo_forest from '!raw-loader!../../assets/custom-sprites/4bd8dd2c9eda176eaceaa627c2b69856.svg';
import probo_frest2 from '!raw-loader!../../assets/custom-sprites/976427dd9f7b9a544287c639e6b7bdfb.svg';
import probo_fourleafclover1 from '!raw-loader!../../assets/custom-sprites/d05552d2db67faf69812a7bed3b0b08c.svg';
import probo_fourleafclover2 from '!raw-loader!../../assets/custom-sprites/262e727343343dba488548987918d851.svg';
import probo_giantA from '!raw-loader!../../assets/custom-sprites/aa9cacee0a2ea9b801085cbf1a736495.svg';
import probo_giantB from '!raw-loader!../../assets/custom-sprites/04edde494c2272d5635e6f435907ec63.svg';
import probo_giantC from '!raw-loader!../../assets/custom-sprites/a267b93a3fa4a0b3d748d9410212b4a3.svg';
import probo_giantD from '!raw-loader!../../assets/custom-sprites/9a96eddbadadaf2d95d6210a3e6a7689.svg';
import probo_giantE from '!raw-loader!../../assets/custom-sprites/976af9e0cf8aff3367e84d3b105e58d1.svg';
import probo_gianteyeA from '!raw-loader!../../assets/custom-sprites/a7153ff48389a5afcd6d2c902d3f8326.svg';
import probo_gianteyeB from '!raw-loader!../../assets/custom-sprites/34a05401d70e4bb97162f7d431779eef.svg';
import probo_gianteyeC from '!raw-loader!../../assets/custom-sprites/452c054e14000aca44bfb9d302af55b2.svg';
import probo_gianteyeD from '!raw-loader!../../assets/custom-sprites/7ed05b2b3547b18782572407225347ff.svg';
import probo_gianteyeE from '!raw-loader!../../assets/custom-sprites/0a064046f9ff9daffabb39c034e6f04d.svg';
import probo_gianteyeF from '!raw-loader!../../assets/custom-sprites/d2ff68e41c463eec09196d13763b6a7a.svg';
import probo_giantF from '!raw-loader!../../assets/custom-sprites/94e6eca7073ca22435e344c9ec17ca3b.svg';
import probo_graph from '!raw-loader!../../assets/custom-sprites/c908d85cdce066626cb2e4e7b183b030.svg';
import probo_hand from '!raw-loader!../../assets/custom-sprites/10feb3665f2b34cd306621d865bf2eb7.svg';
import probo_harp from '!raw-loader!../../assets/custom-sprites/13df1243d7f4e604820ce9f36d3d2bdd.svg';
import probo_harp_string_A from '!raw-loader!../../assets/custom-sprites/d265fe2b1465545eef3bb620e32c1e7e.svg';
import probo_harp_string_B from '!raw-loader!../../assets/custom-sprites/37d51a1f22e7186fe96f88d8de20f2e6.svg';
import probo_heart_beat from '!raw-loader!../../assets/custom-sprites/d01161601a0852061cdb36f12859bf84.svg';
import probo_heart_face1 from '!raw-loader!../../assets/custom-sprites/1955ee62d83ab75f5e04687ae693f8e3.svg';
import probo_heart_face2 from '!raw-loader!../../assets/custom-sprites/19ab0722eece3f9a526eed0ad90542d1.svg';
import probo_heart_face3 from '!raw-loader!../../assets/custom-sprites/7ceb10fae565af02bedf8539bd17ccd3.svg';
import probo_low_point from '!raw-loader!../../assets/custom-sprites/5eb49b448ef5b1a61c6f17fa576afbb0.svg';
import probo_high_Point from '!raw-loader!../../assets/custom-sprites/8589b884760deb5703a4f21cd0cf0387.svg';
import probo_ice_castle_A from '!raw-loader!../../assets/custom-sprites/f949fb5822bc622a4ed072952f2b270e.svg';
import probo_ice_castle_B from '!raw-loader!../../assets/custom-sprites/9deffcbad66371beaf03aafd53c244c5.svg';
import probo_ice_castle_C from '!raw-loader!../../assets/custom-sprites/2c6858fed5cda0e19e4ae12b33dd8d67.svg';
import probo_ice_castle_D from '!raw-loader!../../assets/custom-sprites/8b1c5934456cd598c96006ac3d0c5709.svg';
import probo_infomation from '!raw-loader!../../assets/custom-sprites/65299d7999a4e82ec967c8131216e45b.svg';
import probo_ink from '!raw-loader!../../assets/custom-sprites/417c24a5b1d55b900c73dcb991db97fc.svg';
import probo_ink_balloon from '!raw-loader!../../assets/custom-sprites/79637fb6ce49b7ebcc33b5a4d9130039.svg';
import probo_probo_instrumentcello from '!raw-loader!../../assets/custom-sprites/368924c78d742a151a518c2a7d5955c9.svg';
import probo_probo_instrumentelectric_guitar from '!raw-loader!../../assets/custom-sprites/3b586b2aba066ab6644f08d9ef80cd6e.svg';
import probo_probo_instrumentguitar from '!raw-loader!../../assets/custom-sprites/abbdb53bd98643abcd63294aa50c5d7f.svg';
import probo_probo_instrumentpiano from '!raw-loader!../../assets/custom-sprites/1022da822c358c0296f558b2f08fda5b.svg';
import probo_probo_instrumentSaxophone from '!raw-loader!../../assets/custom-sprites/aaa3f0ba28bc6e9b27eba5298ffcb4bc.svg';
import probo_probo_instrumenttrombone from '!raw-loader!../../assets/custom-sprites/b0e329bd8a0470a1ea1294c90ec45f3d.svg';
import probo_instrumentbassoon from '!raw-loader!../../assets/custom-sprites/d1978bb1c9b456ffa6c5b14c61fdc931.svg';
import probo_interior_spaceship2 from '!raw-loader!../../assets/custom-sprites/76b90f7490800065c397a63437ee125c.svg';
import probo_Island_maze from '!raw-loader!../../assets/custom-sprites/be2ec9cbccf782b62f8730de4174f0b2.svg';
import probo_item_clock from '!raw-loader!../../assets/custom-sprites/3d2f531ff3d271ed2130989a676bc0c4.svg';
import probo_item_seaweed from '!raw-loader!../../assets/custom-sprites/9d3f04228c418b3265e8f6a421b00969.svg';
import probo_item_vortex from '!raw-loader!../../assets/custom-sprites/600de40590ed42f51ca5bc0c815d903f.svg';
import probo_kitchen_knife from '!raw-loader!../../assets/custom-sprites/e06842f2b8fa342ebf12d8f201e0dc5c.svg';
import probo_knife_effect from '!raw-loader!../../assets/custom-sprites/881173099b1731ce4e8ba12aff598c14.svg';
import probo_landA from '!raw-loader!../../assets/custom-sprites/1f0e0c92fda2332b69e6f16a21fb59fd.svg';
import probo_landB from '!raw-loader!../../assets/custom-sprites/7ca3cb1d17f2a701def81dbcacad5457.svg';
import probo_laser_max1 from '!raw-loader!../../assets/custom-sprites/7478154c2b694854254090568dc882c7.svg';
import probo_laser_max2 from '!raw-loader!../../assets/custom-sprites/bc50cdc75762abfc249e141fe99dd569.svg';
import probo_laser5 from '!raw-loader!../../assets/custom-sprites/e7d88b6a43f310e7e37b1fdd07fe180e.svg';
import probo_laser6 from '!raw-loader!../../assets/custom-sprites/47b3709d170393366c480fa6846bbb44.svg';
import probo_laser7 from '!raw-loader!../../assets/custom-sprites/594da52dfc245f978895f5e30a168bd4.svg';
import probo_laser1 from '!raw-loader!../../assets/custom-sprites/33b50401d734e94d132962c71346cbdd.svg';
import probo_laser2 from '!raw-loader!../../assets/custom-sprites/24775cb17924c8f55393bdd4902e0728.svg';
import probo_laser3 from '!raw-loader!../../assets/custom-sprites/25e19405c613ab145d20729f851d7397.svg';
import probo_laser4 from '!raw-loader!../../assets/custom-sprites/a9bbf4c5aebda6fd287269e4f55032e1.svg';
import probo_laser_gun1 from '!raw-loader!../../assets/custom-sprites/53bb98860d24143c90673364c7df4cca.svg';
import probo_laser_gun2 from '!raw-loader!../../assets/custom-sprites/bbd00e5c2f93de58532074fff83c3486.svg';
import probo_lightning from '!raw-loader!../../assets/custom-sprites/504f21ab597ce7e64004613037447f5b.svg';
import probo_magazine_A from '!raw-loader!../../assets/custom-sprites/c430802a8dac3f3d86e0a5f7d422b299.svg';
import probo_magazine_B from '!raw-loader!../../assets/custom-sprites/4145ac357d0df3698ba3310f9d816b34.svg';
import probo_magazine_C from '!raw-loader!../../assets/custom-sprites/b6dcd22d351fcee5490dff9ccf30e96d.svg';
import probo_magazine_D from '!raw-loader!../../assets/custom-sprites/ef3a0e235bde9010b2c424db86a254df.svg';
import probo_magazine_E from '!raw-loader!../../assets/custom-sprites/6bdc3b77bb523a96c31eaa25174c406c.svg';
import probo_magazine_F from '!raw-loader!../../assets/custom-sprites/342d1641ecd9fa7e89cdd9344f54e541.svg';
import probo_magazine_G from '!raw-loader!../../assets/custom-sprites/98f75f8987c9e81165d911fb8870419f.svg';
import probo_magazine_H from '!raw-loader!../../assets/custom-sprites/62ac2ca41ded2c229aef94448eccf906.svg';
import probo_magazine_I from '!raw-loader!../../assets/custom-sprites/abe7781432d361f985a9871a53f248c4.svg';
import probo_musicnoteA from '!raw-loader!../../assets/custom-sprites/2b06a97e4ef459b17300c11332756806.svg';
import probo_musicnoteB from '!raw-loader!../../assets/custom-sprites/7e71aec7c6d9564429291df680a86d1d.svg';
import probo_melodypointer from '!raw-loader!../../assets/custom-sprites/23069ee30715a27403212542d53c7b80.svg';
import probo_meteorite from '!raw-loader!../../assets/custom-sprites/b85d45af13db533aaf4cb2f2ce0c11b0.svg';
import probo_middle_tree from '!raw-loader!../../assets/custom-sprites/145a099b5ebd36378db6961e194a33a8.svg';
import probo_mineral_energy from '!raw-loader!../../assets/custom-sprites/84654a1285020b323bfc111f4491fd9c.svg';
import probo_mineral_energy2 from '!raw-loader!../../assets/custom-sprites/21002d7866a6200ff6f1ee478257afc7.svg';
import probo_speed_item from '!raw-loader!../../assets/custom-sprites/740bc3eed3e1e289cf92d6b9498f30a6.svg';
import probo_speed_item2 from '!raw-loader!../../assets/custom-sprites/1568fd8ea9489825cca72286afa610c4.svg';
import probo_mineral_energy_bar from '!raw-loader!../../assets/custom-sprites/30ef9e344ae886a3d02bb69e956c7e13.svg';
import probo_speed_bar from '!raw-loader!../../assets/custom-sprites/746df9cda2e235edb76ef8930f9ce5e3.svg';
import probo_speed_bar2 from '!raw-loader!../../assets/custom-sprites/834ad6a688e9d82f6763358b51496664.svg';
import probo_mineral_gauge from '!raw-loader!../../assets/custom-sprites/e71b143fe8b5ceeab7c5ac7fd783998d.svg';
import probo_speed_gauge from '!raw-loader!../../assets/custom-sprites/46208d668a30957c5603a226872e3e86.svg';
import probo_minimap from '!raw-loader!../../assets/custom-sprites/dccd3d92994f0aec68be9e9b017edd56.svg';
import probo_mymap from '!raw-loader!../../assets/custom-sprites/2071fa5f2ecf9bc00c1e36c8eb9eb171.svg';
import probo_missile from '!raw-loader!../../assets/custom-sprites/46e0383f047c3f2987e08d3142460855.svg';
import probo_missile2 from '!raw-loader!../../assets/custom-sprites/f835049a627c412f6c566923c3051fcc.svg';
import probo_mission_fail from '!raw-loader!../../assets/custom-sprites/729095289f2f15c9302ba47ed8d0fe7c.svg';
import probo_mission_success from '!raw-loader!../../assets/custom-sprites/7cb25832bad9beb057a18384ede7f242.svg';
import probo_mole from '!raw-loader!../../assets/custom-sprites/5ef6159c4026313cadc731c45467f845.svg';
import probo_mole_front from '!raw-loader!../../assets/custom-sprites/e1f9e0dca1c0ccc18b74257d03af0387.svg';
import probo_mosquito_A from '!raw-loader!../../assets/custom-sprites/fb883506048521de22b819babd760c51.svg';
import probo_mosquito_B from '!raw-loader!../../assets/custom-sprites/72613da23c535e29589e7ec4a76537ed.svg';
import probo_mosquito_C from '!raw-loader!../../assets/custom-sprites/4b435f9b78ad04ea8eaebb55d0083fae.svg';
import probo_mosquito_D from '!raw-loader!../../assets/custom-sprites/4076efb331fd785d9d0f21cb2bc283c2.svg';
import probo_mosquito_E from '!raw-loader!../../assets/custom-sprites/3eb29aa473308650edc628b892331af2.svg';
import probo_mosquito_F from '!raw-loader!../../assets/custom-sprites/cb4fddd46c98ebac4fc2e2487db37a9e.svg';
import probo_mosquito_G from '!raw-loader!../../assets/custom-sprites/67b5423e8d2e467c260a480225dd77ce.svg';
import probo_mosquito_H from '!raw-loader!../../assets/custom-sprites/0fef734e602fccf81cdc6c950b41452a.svg';
import probo_mouse from '!raw-loader!../../assets/custom-sprites/1c9ea2b2f60e07015262b4624671548d.svg';
import probo_music from '!raw-loader!../../assets/custom-sprites/05d42e22da93fb9c0deba2ccf486b3dc.svg';
import probo_music_A from '!raw-loader!../../assets/custom-sprites/9e7fae30972992ea3b6a0b668bb511f6.svg';
import probo_music_B from '!raw-loader!../../assets/custom-sprites/d9b295ac6d12001f77ab04f3342fc5ce.svg';
import probo_music_C from '!raw-loader!../../assets/custom-sprites/76946dd08521a6730dc75983884d92ad.svg';
import probo_nail_blue from '!raw-loader!../../assets/custom-sprites/97b87dc6772752d608fb6cf36ca2b486.svg';
import probo_nail_green from '!raw-loader!../../assets/custom-sprites/e3d8982baeef42cd229f3605c8c8813b.svg';
import probo_nail_red from '!raw-loader!../../assets/custom-sprites/c947d2e76fecbc2b31a8e6c78ac975ca.svg';
import probo_nail_white from '!raw-loader!../../assets/custom-sprites/eeae70907eac832a8eea0280fc1d1303.svg';
import probo_nail_yellow from '!raw-loader!../../assets/custom-sprites/0b85afe4d1dc1082b59ead6b06254498.svg';
import probo_warped_nail_blue from '!raw-loader!../../assets/custom-sprites/8abdec25a2ab0debfad9647cd797a11a.svg';
import probo_warped_nail_green from '!raw-loader!../../assets/custom-sprites/6d9fc0feb44c16705ea93889ae53698a.svg';
import probo_warped_nail_red from '!raw-loader!../../assets/custom-sprites/c2e16cdd4fd615f63196dcabd14ab77f.svg';
import probo_warped_nail_white from '!raw-loader!../../assets/custom-sprites/b47d2e7eb8b147d7cd6de4bf2f8cf1f5.svg';
import probo_warped_nail_yellow from '!raw-loader!../../assets/custom-sprites/a9c83b1a711a199a562773e9de2d76d5.svg';
import probo_obstacle1 from '!raw-loader!../../assets/custom-sprites/117cc0bc92e71a6447988ec8f2b96a85.svg';
import probo_obstacle2 from '!raw-loader!../../assets/custom-sprites/aab0c4ef887bfd1fa4c882bbd039cadb.svg';
import probo_obstacle3 from '!raw-loader!../../assets/custom-sprites/c49e72bdb2c8b82871d82dc7b2c23121.svg';
import probo_obstacle4 from '!raw-loader!../../assets/custom-sprites/b20ad6fff368825665929d9cbb0973cd.svg';
import probo_obstacle5 from '!raw-loader!../../assets/custom-sprites/01793e4d5c7828067b35813d86d5952a.svg';
import probo_obstacle6 from '!raw-loader!../../assets/custom-sprites/98f0eb9d714dc2564d861ea9241d455f.svg';
import probo_obstacle7 from '!raw-loader!../../assets/custom-sprites/44e64edf892904d50e667022b62d4067.svg';
import probo_obstacle8 from '!raw-loader!../../assets/custom-sprites/6fd1c451a39d6a1a1a613962ce3d9bd2.svg';
import probo_obstacle9 from '!raw-loader!../../assets/custom-sprites/1c21a1dbe242acd9cbeb4e1865fc456e.svg';
import probo_obstacle10 from '!raw-loader!../../assets/custom-sprites/017f1107ee01a168f68562f4ad5c6de9.svg';
import probo_obstacle11 from '!raw-loader!../../assets/custom-sprites/bdd25684faf422cd41f5b891c65cc286.svg';
import probo_obstacle12 from '!raw-loader!../../assets/custom-sprites/1aae81d1131a4521dfa9cb0e9ce1274a.svg';
import probo_obstacle13 from '!raw-loader!../../assets/custom-sprites/51608cc69bc5a63870358ddf3423fe39.svg';
import probo_obstacle14 from '!raw-loader!../../assets/custom-sprites/f3beba379b1428f6e70c6a92e752fe77.svg';
import probo_obstacle15 from '!raw-loader!../../assets/custom-sprites/dc6d9f435fec5a6c35271a9fa070e052.svg';
import probo_obstacle16 from '!raw-loader!../../assets/custom-sprites/849312886aed5d3930eceffacab192ba.svg';
import probo_obstacle17 from '!raw-loader!../../assets/custom-sprites/8c30eefbdd39eb95d02bcc38eaf055a8.svg';
import probo_obstacle18 from '!raw-loader!../../assets/custom-sprites/48b4bd799db69826d0b263499f841137.svg';
import probo_obstacle19 from '!raw-loader!../../assets/custom-sprites/1d384d4653e85fd3bd8c2e18f778f355.svg';
import probo_octopus from '!raw-loader!../../assets/custom-sprites/5a385af2ed9c906d2c3cd8b3036f76f4.svg';
import probo_orgel_A from '!raw-loader!../../assets/custom-sprites/dfea4299f36f0bd0a0244b5c6ffb8ccf.svg';
import probo_orgel_B from '!raw-loader!../../assets/custom-sprites/0d386f59dc1c40a86d3ca759899e2c63.svg';
import probo_orgel_C from '!raw-loader!../../assets/custom-sprites/8cce19bd6a4b91ead93319896fa80f86.svg';
import probo_orgel_D from '!raw-loader!../../assets/custom-sprites/639721998d94c57e5820d9116f0a0064.svg';
import probo_outer_wall from '!raw-loader!../../assets/custom-sprites/20b4f7d79d45b5cfcd86b4d13b03212f.svg';
import probo_m_panel from '!raw-loader!../../assets/custom-sprites/678930a4ec996858de7a1715ca5090b1.svg';
import probo_panel from '!raw-loader!../../assets/custom-sprites/ddab13fc3cf499384cb721b2915a2bc2.svg';
import probo_perforated_wall_A_1 from '!raw-loader!../../assets/custom-sprites/c08d659c169e579c38042fab5e0d5227.svg';
import probo_perforated_wall_A_2 from '!raw-loader!../../assets/custom-sprites/e419de144e67ccc7f591784929561fe3.svg';
import probo_perforated_wall_A_3 from '!raw-loader!../../assets/custom-sprites/fd21fe84a3837c9f9e59551baf672806.svg';
import probo_perforated_wall_A_4 from '!raw-loader!../../assets/custom-sprites/de8c3ec10794e965fd87cd207510e89e.svg';
import probo_perforated_wall_A_5 from '!raw-loader!../../assets/custom-sprites/d9540f37c6f1225d5108263fd403362a.svg';
import probo_perforated_wall_A_6 from '!raw-loader!../../assets/custom-sprites/d2bf2922d373817c7787bfa495d139dd.svg';
import probo_perforated_wall_A_7 from '!raw-loader!../../assets/custom-sprites/2063292862f18f58d46784a310f99168.svg';
import probo_perforated_wall_A_8 from '!raw-loader!../../assets/custom-sprites/7ccdf3827137994f0a073e8db3ac14f2.svg';
import probo_perforated_wall_A_9 from '!raw-loader!../../assets/custom-sprites/226fcaa5e801943a4c9d6568ea902dd9.svg';
import probo_perforated_wall_B_1 from '!raw-loader!../../assets/custom-sprites/2336fcc8591dd7fb6d68d4bf5d37d9b5.svg';
import probo_perforated_wall_B_2 from '!raw-loader!../../assets/custom-sprites/2ec89c169828c4a75ec847ad34bd0688.svg';
import probo_perforated_wall_B_3 from '!raw-loader!../../assets/custom-sprites/5658f27de6a36ea23e527eb1534cedcd.svg';
import probo_perforated_wall_B_4 from '!raw-loader!../../assets/custom-sprites/3fc92e6cb6a2914e28cc58a89f3f3b34.svg';
import probo_perforated_wall_B_5 from '!raw-loader!../../assets/custom-sprites/ee282e61b867de304249bb69dfb99a02.svg';
import probo_perforated_wall_B_6 from '!raw-loader!../../assets/custom-sprites/56d9db976db85bda336f7c23746fc163.svg';
import probo_perforated_wall_B_7 from '!raw-loader!../../assets/custom-sprites/a107afd62ab46acced5fffa17a015c1d.svg';
import probo_perforated_wall_B_8 from '!raw-loader!../../assets/custom-sprites/87624197053b39f937d7ede9d52a5d59.svg';
import probo_perforated_wall_B_9 from '!raw-loader!../../assets/custom-sprites/49ff03d4d3d136ea82863acfae64ffbc.svg';
import probo_perforated_wall_C_1 from '!raw-loader!../../assets/custom-sprites/e26495c20c71f8258eae806e505f6d0a.svg';
import probo_perforated_wall_C_2 from '!raw-loader!../../assets/custom-sprites/479a6ce62f7eeb0cf7437f8837f4e39c.svg';
import probo_perforated_wall_C_3 from '!raw-loader!../../assets/custom-sprites/44bcf2770d8e9ea942b1e7cdd77a17fb.svg';
import probo_perforated_wall_C_4 from '!raw-loader!../../assets/custom-sprites/70030b3cc8d37657e9804cfdbdd56bad.svg';
import probo_perforated_wall_C_5 from '!raw-loader!../../assets/custom-sprites/43aca250d02d8c64ec5f78f9c3bc18b2.svg';
import probo_perforated_wall_C_6 from '!raw-loader!../../assets/custom-sprites/33f69090cf2f264a133f9a653d5b61d7.svg';
import probo_perforated_wall_C_7 from '!raw-loader!../../assets/custom-sprites/73fd6e1bc20ddcd006408e83397c6329.svg';
import probo_perforated_wall_C_8 from '!raw-loader!../../assets/custom-sprites/c90a31c5652f564d14dc3c47c79aebc0.svg';
import probo_perforated_wall_C_9 from '!raw-loader!../../assets/custom-sprites/3582aa9321234517591e9988efdc29ae.svg';
import probo_plate from '!raw-loader!../../assets/custom-sprites/da928926e4b628b63ba58a250136f5b4.svg';
import probo_point from '!raw-loader!../../assets/custom-sprites/36eb8da8b612e9f2a395edea71dd881b.svg';
import probo_poo from '!raw-loader!../../assets/custom-sprites/2499888e13705eb8034f03ffd471d275.svg';
import probo_car from '!raw-loader!../../assets/custom-sprites/498bba192a7481325d72e493c158047b.svg';
import probo_car_left from '!raw-loader!../../assets/custom-sprites/7d7d1c2d93cd4df3ad0c66b48e9d6398.svg';
import probo_car_non_wheel from '!raw-loader!../../assets/custom-sprites/ec83ee55abf4e7e69567faaa0117d80b.svg';
import probo_car_right from '!raw-loader!../../assets/custom-sprites/8b9927208ecd0d47ee75648a307722c6.svg';
import probo_car_top from '!raw-loader!../../assets/custom-sprites/df5fb36c4d213ea232a82d9b8726ecd6.svg';
import probo_energy from '!raw-loader!../../assets/custom-sprites/4479a965e22e5aadbd64ad03165f213b.svg';
import probo_pu1 from '!raw-loader!../../assets/custom-sprites/11ddf8cd49507f4847a9934ee4c6df17.svg';
import probo_pu2 from '!raw-loader!../../assets/custom-sprites/b591bd3e8d06531ded72448926234d5c.svg';
import probo_pu3 from '!raw-loader!../../assets/custom-sprites/a7548c451c78b3771cbcfbca71e33988.svg';
import probo_puppy_house_A from '!raw-loader!../../assets/custom-sprites/59de72842d3e9667bc35afbdcf937b5d.svg';
import probo_puppy_house_B from '!raw-loader!../../assets/custom-sprites/f262affbe469022d85c2261b6cb8b576.svg';
import probo_puppy_house_C from '!raw-loader!../../assets/custom-sprites/cc918e7c0365890c1c1f22da9f9315ef.svg';
import probo_puppy_house_D from '!raw-loader!../../assets/custom-sprites/c8015674652284f61a0ba64c0110b291.svg';
import probo_puppy_house_E from '!raw-loader!../../assets/custom-sprites/6531a2296d3dc7032da5b64d82f993f1.svg';
import probo_puppy_house_F from '!raw-loader!../../assets/custom-sprites/b4c3f0b50175ed658cd800fcfd4e644d.svg';
import probo_puppy_house_G from '!raw-loader!../../assets/custom-sprites/4819b4fb9b4def3ab803cd46a0189a7c.svg';
import probo_puppy_house_H from '!raw-loader!../../assets/custom-sprites/db382d0891e21c8f854ab69459d512d6.svg';
import probo_puppy_house_I from '!raw-loader!../../assets/custom-sprites/08e021503c6e7d5692adf980d33b569b.svg';
import probo_puppy_house_J from '!raw-loader!../../assets/custom-sprites/541606ed4aca1b9e00ee2a8c2ac48b52.svg';
import probo_rabbit_run_1 from '!raw-loader!../../assets/custom-sprites/e95a9dd7b65c4390f901f871b670dd49.svg';
import probo_rabbit_run_2 from '!raw-loader!../../assets/custom-sprites/fc7ee3bd86d858150bbce0d5d8c46a0a.svg';
import probo_rabbit_run_3 from '!raw-loader!../../assets/custom-sprites/b359330bf9d1b4578a8b3a2389bec7c3.svg';
import probo_racing_car from '!raw-loader!../../assets/custom-sprites/864f468ae1230f43c2b92642ad40c46e.svg';
import probo_racing_car1 from '!raw-loader!../../assets/custom-sprites/e228c16fb1962e9cd7de117f659b3c99.svg';
import probo_racing_car2 from '!raw-loader!../../assets/custom-sprites/48156abb478906d095c35e7b41ea4ed3.svg';
import probo_racing_car3 from '!raw-loader!../../assets/custom-sprites/353786af2cadf7405cbbf23cc03e2be1.svg';
import probo_racing_car4 from '!raw-loader!../../assets/custom-sprites/e5f3a75a7f7ba1970e79e168de8094f7.svg';
import probo_racing_car5 from '!raw-loader!../../assets/custom-sprites/e726f71e48430e869df3aac5ccd58e62.svg';
import probo_racing_car6 from '!raw-loader!../../assets/custom-sprites/4a2aa77311c8a132c73cad936bf7844a.svg';
import probo_racing_flag from '!raw-loader!../../assets/custom-sprites/039bc1cc559488a16297756317a2b815.svg';
import probo_radar_A from '!raw-loader!../../assets/custom-sprites/58814e6d7d723f7e79a259b46bca0757.svg';
import probo_radar_B from '!raw-loader!../../assets/custom-sprites/3c28e8f609e83406ce8c36767b46ac83.svg';
import probo_raindrop from '!raw-loader!../../assets/custom-sprites/6a4df6f3311c877ec02d96bb3a82c778.svg';
import probo_rednote_Do from '!raw-loader!../../assets/custom-sprites/165976f176df65eb718f2068132f6f3b.svg';
import probo_rednote_Do2 from '!raw-loader!../../assets/custom-sprites/34e1308c208014ca172391514ba990df.svg';
import probo_rednote_Fa from '!raw-loader!../../assets/custom-sprites/8362a34d95904a6eb827e2af0701aa89.svg';
import probo_rednote_La from '!raw-loader!../../assets/custom-sprites/757ce0d7137ecaddea4bb477e75c328f.svg';
import probo_rednote_Mi from '!raw-loader!../../assets/custom-sprites/bd7d086f7b652c7fc6a090464fcd53ae.svg';
import probo_rednote_Re from '!raw-loader!../../assets/custom-sprites/868e088251911fe1671f804226961bcc.svg';
import probo_rednote_Si from '!raw-loader!../../assets/custom-sprites/610592b166add2bec5cdb17c1927f7c3.svg';
import probo_rednote_Sol from '!raw-loader!../../assets/custom-sprites/46aa36721a610c4f74b63c1b6ddd8c98.svg';
import probo_road1 from '!raw-loader!../../assets/custom-sprites/3978850c020dbc4ccd842c1e12d19106.svg';
import probo_road2 from '!raw-loader!../../assets/custom-sprites/614d63294bccebba9a3d28cc9715f157.svg';
import probo_road3 from '!raw-loader!../../assets/custom-sprites/49dc8ccc7ac4896fdbd4c569a5f6312b.svg';
import probo_road4 from '!raw-loader!../../assets/custom-sprites/2beebb9cbc42be4c3340a9ebf9fe1b5a.svg';
import probo_sand_dust_A from '!raw-loader!../../assets/custom-sprites/deb5f36746be6351d529b657d9f7254c.svg';
import probo_sand_dust_B from '!raw-loader!../../assets/custom-sprites/3e8ebf62892c2292c48fb043ad1f6a93.svg';
import probo_sandstorm_A from '!raw-loader!../../assets/custom-sprites/c21d68bc517b108d889f4d172bc6b4a2.svg';
import probo_sandstorm_B from '!raw-loader!../../assets/custom-sprites/6f3d1d9cc2edd4904a5e305ffcf2929f.svg';
import probo_satellite1 from '!raw-loader!../../assets/custom-sprites/f8928c1b23f847239da5915913100359.svg';
import probo_satellite2 from '!raw-loader!../../assets/custom-sprites/eb056d9acbf7506fdb446311b6805400.svg';
import probo_satellite3 from '!raw-loader!../../assets/custom-sprites/1a6e1a1d6d0e69c37b368180f87cef35.svg';
import probo_satellite4 from '!raw-loader!../../assets/custom-sprites/c1d614a3030ad09310bd54ae8a951283.svg';
import probo_satellite5 from '!raw-loader!../../assets/custom-sprites/e3858c5ef9ea71ef9253da9113e7587d.svg';
import probo_satellite6 from '!raw-loader!../../assets/custom-sprites/a75bba70034b2be3b9c9b14a3cae3c6c.svg';
import probo_sea_wave from '!raw-loader!../../assets/custom-sprites/7b0d483a89d6caa521fd7dfd11784d63.svg';
import probo_select from '!raw-loader!../../assets/custom-sprites/eda38a00ab71cdf6783b805b7619a8fb.svg';
import probo_shapes_A from '!raw-loader!../../assets/custom-sprites/74d248ec028144cfe52b7cce6a54d60d.svg';
import probo_shapes_B from '!raw-loader!../../assets/custom-sprites/e8cf0b595fba9bde5cf14d01ecddace7.svg';
import probo_shapes_C from '!raw-loader!../../assets/custom-sprites/6d3e85a91047d5876560773f61ea6f98.svg';
import probo_shapes_D from '!raw-loader!../../assets/custom-sprites/74f8ae58217ef5a7ae8051dbc2b9a482.svg';
import probo_shapes_E from '!raw-loader!../../assets/custom-sprites/fc5469bd1712a36dec38f328e23da3bb.svg';
import probo_shapes_F from '!raw-loader!../../assets/custom-sprites/93e4b78b6b49fbbe221fd61a650ebde0.svg';
import probo_shapes_list_A from '!raw-loader!../../assets/custom-sprites/77a717232d5ab542ead900e819098837.svg';
import probo_shapes_list_B from '!raw-loader!../../assets/custom-sprites/9c0a52f6f9f7ee3ecf572ce44d1f2e3a.svg';
import probo_shapes_list_C from '!raw-loader!../../assets/custom-sprites/3c9c9d3b0cd38c0004adff245f5b7566.svg';
import probo_shapes_list_D from '!raw-loader!../../assets/custom-sprites/299dba2007959b9c57253badcbd7ed98.svg';
import probo_shapes_list_E from '!raw-loader!../../assets/custom-sprites/77ca89fc63f6df2482dadc4b11994f27.svg';
import probo_shapes_list_F from '!raw-loader!../../assets/custom-sprites/e97fb9e1b8f65a91967b29b7a16b7e89.svg';
import probo_small_boatA from '!raw-loader!../../assets/custom-sprites/f42d0f8d8f0b864a0cf4003390c602a8.svg';
import probo_small_boatB from '!raw-loader!../../assets/custom-sprites/489a75d5fd2740b73287f0fec5f99237.svg';
import probo_small_boatC from '!raw-loader!../../assets/custom-sprites/233ef5d3a0d7cd04b8f32914d6c0aeb1.svg';
import probo_small_boatD from '!raw-loader!../../assets/custom-sprites/6a20d763c9297cafe7f4141865763cb5.svg';
import probo_ship_speed_gaugeA from '!raw-loader!../../assets/custom-sprites/617f56fc3df4dcc867ab9941fa8b0822.svg';
import probo_ship_speed_gaugeB from '!raw-loader!../../assets/custom-sprites/f1d0acd134a8dacffffa22ed4fb4fd62.svg';
import probo_ship_speed_gaugeC from '!raw-loader!../../assets/custom-sprites/dc08ebe49369739aa91d5ad8dd42d056.svg';
import probo_ship_speed_gaugeD from '!raw-loader!../../assets/custom-sprites/b984873aeaf521ffcc6f684b1151b7e0.svg';
import probo_ship_speed_gaugeE from '!raw-loader!../../assets/custom-sprites/47e7b13d2b074a3f2a41ccb70c9ccfa7.svg';
import probo_ship_speed_gaugeF from '!raw-loader!../../assets/custom-sprites/0aff60e747751ac0d519b806792f14ff.svg';
import probo_sign from '!raw-loader!../../assets/custom-sprites/6d6b3f7cfbef0b73b2fdfebae65503c9.svg';
import probo_small_tree from '!raw-loader!../../assets/custom-sprites/6142cf1c4266932f95a7a30e5db740a8.svg';
import probo_smoke from '!raw-loader!../../assets/custom-sprites/7598a5a756a30853f380abc9680dd447.svg';
import probo_snake from '!raw-loader!../../assets/custom-sprites/4012e93911010720d8f5a18cd0028cba.svg';
import probo_snowball from '!raw-loader!../../assets/custom-sprites/b1b94b073deceb2ce61c55e6c8db9342.svg';
import probo_space_pirate_top1 from '!raw-loader!../../assets/custom-sprites/ab379f8fbfafe206cee11bcb7e093082.svg';
import probo_space_pirate_top2 from '!raw-loader!../../assets/custom-sprites/221c4abca56e2dba46919f59d753579e.svg';
import probo_space_pirate_top3 from '!raw-loader!../../assets/custom-sprites/40216c5e838f486b5b7b95c70ecd2577.svg';
import probo_space_pirate_top4 from '!raw-loader!../../assets/custom-sprites/ad8b144f11220635b54fc6e1405ac0af.svg';
import probo_space_pirate_top5 from '!raw-loader!../../assets/custom-sprites/ed654f142324455eae64744156639f1b.svg';
import probo_space_pirate_top6 from '!raw-loader!../../assets/custom-sprites/8dd7d225a01df0b90b47e0ab43275e9f.svg';
import probo_space_pirate1_1 from '!raw-loader!../../assets/custom-sprites/ab3b13176d70d1ee05b935d9e9a66ddb.svg';
import probo_space_pirate1_2 from '!raw-loader!../../assets/custom-sprites/485d7feeb112c20c601308d5d794d79e.svg';
import probo_space_pirate2_1 from '!raw-loader!../../assets/custom-sprites/e579683185a2137d628edf506c1f3e95.svg';
import probo_space_pirate2_2 from '!raw-loader!../../assets/custom-sprites/7fd99519d949bb46947384f203986759.svg';
import probo_space_pirate3_1 from '!raw-loader!../../assets/custom-sprites/d560792ae11c75aa4b94a9157680fbc8.svg';
import probo_space_pirate3_2 from '!raw-loader!../../assets/custom-sprites/8c8d8e461f75b93818fe4dd19e647ab3.svg';
import probo_space_pirate4_1 from '!raw-loader!../../assets/custom-sprites/70a14c6bc3d9d7a7b274066809d8591e.svg';
import probo_space_pirate4_2 from '!raw-loader!../../assets/custom-sprites/1475ab7e0493fcefc55b9646683d9c70.svg';
import probo_space_pirate5_1 from '!raw-loader!../../assets/custom-sprites/9faf7f0000b69e56808c65d293b94480.svg';
import probo_space_pirate5_2 from '!raw-loader!../../assets/custom-sprites/87a7485df6c043fedff6bec55e244543.svg';
import probo_space_pirate_boss1_1 from '!raw-loader!../../assets/custom-sprites/6b676deabc738fa564114da3ab16e5d8.svg';
import probo_space_pirate_boss1_2 from '!raw-loader!../../assets/custom-sprites/c89ba63e3fd3424cd1159262fb14d4f6.svg';
import probo_space_pirate_boss2_1 from '!raw-loader!../../assets/custom-sprites/7f93f0f81da437a1954ba25a6bd7946c.svg';
import probo_space_pirate_boss2_2 from '!raw-loader!../../assets/custom-sprites/783b8b3f7cc76cc96e80b8cc2990067c.svg';
import probo_space_station from '!raw-loader!../../assets/custom-sprites/dc1af9e535b619338b14886aa6db27d1.svg';
import probo_space_station2 from '!raw-loader!../../assets/custom-sprites/ab09eab0c30dd86b35c54fc5eac147c0.svg';
import probo_space_trash1_1 from '!raw-loader!../../assets/custom-sprites/25440f634dd0f324c041a5fe1770612f.svg';
import probo_space_trash1_2 from '!raw-loader!../../assets/custom-sprites/0750c511911782b3bced2c87580cdef0.svg';
import probo_space_trash2_1 from '!raw-loader!../../assets/custom-sprites/845c36be18b665ff718c07b3bc23b717.svg';
import probo_space_trash2_2 from '!raw-loader!../../assets/custom-sprites/038f9ad0c0b9ec4c6cb1e2791e844d4e.svg';
import probo_space_trash3_1 from '!raw-loader!../../assets/custom-sprites/e9a34119995d0e9419a0c4bbe49ba2bf.svg';
import probo_space_trash3_2 from '!raw-loader!../../assets/custom-sprites/8f23ee7278abdac41bc91575b64c98a5.svg';
import probo_space_trash4_1 from '!raw-loader!../../assets/custom-sprites/7d613b6d05dd9cd98d6937a0d1d10192.svg';
import probo_space_trash4_2 from '!raw-loader!../../assets/custom-sprites/c293fd6b7e454b52d58bcf78dec3f71b.svg';
import probo_space_trash5_1 from '!raw-loader!../../assets/custom-sprites/cb6a1527f24092e1768db5117fa7d6b6.svg';
import probo_space_trash5_2 from '!raw-loader!../../assets/custom-sprites/bedabe5afab80d08a92a86701c73af5c.svg';
import probo_spacecraft1 from '!raw-loader!../../assets/custom-sprites/c83cab96277249fc40583f688b1b9ca3.svg';
import probo_spacecraft2 from '!raw-loader!../../assets/custom-sprites/eda8f253c9f487018f464042d8b81a32.svg';
import probo_spacecraft3 from '!raw-loader!../../assets/custom-sprites/e1f434e27eac049c097dd56174f78147.svg';
import probo_spacecraft4 from '!raw-loader!../../assets/custom-sprites/5b37915bdb9745498e989c76ba1b5efd.svg';
import probo_spacecraft5 from '!raw-loader!../../assets/custom-sprites/97b6c16faf1173b6cdf362c8234ba315.svg';
import probo_spacecraft_energy from '!raw-loader!../../assets/custom-sprites/4223a692b35d09280194299d841b142b.svg';
import probo_spacecraft_energy2 from '!raw-loader!../../assets/custom-sprites/e0c49ff1f0da993f069147f62b08eb2d.svg';
import probo_spacecraft_parts1_1 from '!raw-loader!../../assets/custom-sprites/6928a01597cebc20152070b1da0d20ac.svg';
import probo_spacecraft_parts1_2 from '!raw-loader!../../assets/custom-sprites/4fb74b7b80e87923938e3f831239152f.svg';
import probo_spacecraft_parts1_3 from '!raw-loader!../../assets/custom-sprites/b637dd5490ea963a06be731a8b827eea.svg';
import probo_spacecraft_parts2_1 from '!raw-loader!../../assets/custom-sprites/a0a2b5a704ef149cecc085644a5c4cf6.svg';
import probo_spacecraft_parts2_2 from '!raw-loader!../../assets/custom-sprites/1f83755b41ab76f53d470a916a26ac9f.svg';
import probo_spacecraft_parts2_3 from '!raw-loader!../../assets/custom-sprites/6e3cfb149d05de594e8b5f736e1e10c8.svg';
import probo_spacecraft_parts3_1 from '!raw-loader!../../assets/custom-sprites/eac07c61edf0ec0abbc5478da069396b.svg';
import probo_spacecraft_parts3_2 from '!raw-loader!../../assets/custom-sprites/22b5be6795663e4270c83953316992b2.svg';
import probo_spacecraft_parts3_3 from '!raw-loader!../../assets/custom-sprites/8612289129be55141e683729308cadbd.svg';
import probo_spaceship from '!raw-loader!../../assets/custom-sprites/1a32c46893a333167d74492c7b8917b7.svg';
import probo_spaceship_back from '!raw-loader!../../assets/custom-sprites/1f2d577d86267b09014ba89589ce422b.svg';
import probo_spaceship_effect1 from '!raw-loader!../../assets/custom-sprites/98014622232eff5f5635737243c29b59.svg';
import probo_spaceship_effect2 from '!raw-loader!../../assets/custom-sprites/be5fbd5531197c906fdffc38c407c999.svg';
import probo_sparrow_A from '!raw-loader!../../assets/custom-sprites/801406c36f522740a4f29d1d600b207a.svg';
import probo_sparrow_B from '!raw-loader!../../assets/custom-sprites/5dfbb2c164b3283d1a4fd2ba3ff6494d.svg';
import probo_sparrows_A from '!raw-loader!../../assets/custom-sprites/38f55734a5d4faab3a18f8e4658c6551.svg';
import probo_sparrows_B from '!raw-loader!../../assets/custom-sprites/3977682243a2c10d3194bccc53100fb1.svg';
import probo_speed_effect from '!raw-loader!../../assets/custom-sprites/1ff2ecaadbd9f4392fba10eb59141c58.svg';
import probo_speed_Effect2 from '!raw-loader!../../assets/custom-sprites/61bdb3814e0dd818552324f1f38829fb.svg';
import probo_spider from '!raw-loader!../../assets/custom-sprites/3dffd46b98233751530b43b89bf0a849.svg';
import probo_stalactite from '!raw-loader!../../assets/custom-sprites/76541f2e19b15f0d56262f545dc87964.svg';
import probo_stalkA from '!raw-loader!../../assets/custom-sprites/1078dbd4d47a2b6c0ecb07ef1b343ea3.svg';
import probo_stalkB from '!raw-loader!../../assets/custom-sprites/be62c4b7090c7f2e6aa639a635a549d7.svg';
import probo_stalkC from '!raw-loader!../../assets/custom-sprites/bc5f72214d73cd03a0f5ccd158e7c8a7.svg';
import probo_star from '!raw-loader!../../assets/custom-sprites/6a5ec5ddf96f22a1fb444dc923d66592.svg';
import probo_stone from '!raw-loader!../../assets/custom-sprites/8b2dc900574bc04c0a8cf9659b140e6a.svg';
import probo_submarine_back from '!raw-loader!../../assets/custom-sprites/ed4f51326cc3bd07c8d43b08aeb33b1a.svg';
import probo_submarine_left_trouble from '!raw-loader!../../assets/custom-sprites/fb6d929b9902ddea3e394b3617686758.svg';
import probo_submarine_right_trouble from '!raw-loader!../../assets/custom-sprites/0ed9aec55a22002f6c8b3d3eb963600e.svg';
import probo_tank1 from '!raw-loader!../../assets/custom-sprites/1aedc133f2736ed4aa5b8b2d3bb64c97.svg';
import probo_tank2 from '!raw-loader!../../assets/custom-sprites/86f91288ed3dde95dc78e2bbee12498a.svg';
import probo_tank3 from '!raw-loader!../../assets/custom-sprites/973249ff9ffe53f2602e4df071658314.svg';
import probo_tank4 from '!raw-loader!../../assets/custom-sprites/a45c598544651423829a35cf8850d2f9.svg';
import probo_target from '!raw-loader!../../assets/custom-sprites/6ec1121028c33ddbb4d25102f1fe225a.svg';
import probo_target2 from '!raw-loader!../../assets/custom-sprites/5bd6c6b550ddf4839b364aa955936e06.svg';
import probo_target_board_A from '!raw-loader!../../assets/custom-sprites/38645a5d334c18d771d9e69383b919d8.svg';
import probo_target_board_B from '!raw-loader!../../assets/custom-sprites/803ccb639624cda7d29c9bf099a47fe4.svg';
import probo_targetboard1 from '!raw-loader!../../assets/custom-sprites/50ddfe52f5b3d3376df5953d9a7398ce.svg';
import probo_targetboard2 from '!raw-loader!../../assets/custom-sprites/c8ea970a3d65b73db2464089832b193e.svg';
import probo_targetboard3 from '!raw-loader!../../assets/custom-sprites/51724f0c533a01d0dce42d74030ddd50.svg';
import probo_targetboard4 from '!raw-loader!../../assets/custom-sprites/169583d38a3262b221dea124e73bdbb7.svg';
import probo_targetboard5 from '!raw-loader!../../assets/custom-sprites/52cfc440fad82dd6860acac7724d76e2.svg';
import probo_targetboard6 from '!raw-loader!../../assets/custom-sprites/ad50aff4c9f9a99e91d39cb0ebeeb303.svg';
import probo_targetboard7 from '!raw-loader!../../assets/custom-sprites/20cc672aa39cc68323a90f8205aa6571.svg';
import probo_targetboard8 from '!raw-loader!../../assets/custom-sprites/4749c896fcdf9a266d5642368a639c0a.svg';
import probo_targetboard9 from '!raw-loader!../../assets/custom-sprites/e21209f8e672dfdafc17d7a6a23d2fb6.svg';
import probo_targetboard10 from '!raw-loader!../../assets/custom-sprites/fd50fb9aec50a94cc97fe37588ac2c8c.svg';
import probo_targetboard11 from '!raw-loader!../../assets/custom-sprites/36b84f4909a8f1b8b2f86d356d469e19.svg';
import probo_time_machine_A from '!raw-loader!../../assets/custom-sprites/c055bd861e3b360a82617676133866f0.svg';
import probo_time_machine_B from '!raw-loader!../../assets/custom-sprites/31d9921ba63bab49a2db13461ccfda09.svg';
import probo_time_machine_C from '!raw-loader!../../assets/custom-sprites/c269c6e3362624e97db2030c469788d8.svg';
import probo_outer_lane from '!raw-loader!../../assets/custom-sprites/d300b07e114ef213fbdff7f000aa6a44.svg';
import probo_start_line from '!raw-loader!../../assets/custom-sprites/f60d8efb8e3389e70d15ae50afaa73c7.svg';
import probo_traffic_lane from '!raw-loader!../../assets/custom-sprites/13a33de1973dca9c27d618aca1729801.svg';
import probo_treasureA from '!raw-loader!../../assets/custom-sprites/422a44fb41b455223fcd65b1a7ed4b06.svg';
import probo_treasureB from '!raw-loader!../../assets/custom-sprites/f2478376630b4c36162a74ed500afbf8.svg';
import probo_triceratops_angry from '!raw-loader!../../assets/custom-sprites/3cfd33dac40d035120275a9056fc4f04.svg';
import probo_triceratops_eye from '!raw-loader!../../assets/custom-sprites/9af6609435712d590e5de1e896e09888.svg';
import probo_triceratops_sleep from '!raw-loader!../../assets/custom-sprites/b9fd1aa4114f67dc3b7943471403486b.svg';
import probo_UFO from '!raw-loader!../../assets/custom-sprites/2566e4c6bea7c098871c7989425557fa.svg';
import probo_underground_tunnel from '!raw-loader!../../assets/custom-sprites/6a3435b24416647c82c99d6f9ccda5de.svg';
import probo_avocado from '!raw-loader!../../assets/custom-sprites/0e9b7a5162d524a67d057f61074d8fb8.svg';
import probo_banana from '!raw-loader!../../assets/custom-sprites/78c88ea33b4a11eb84c09a64f6c575bc.svg';
import probo_carrot from '!raw-loader!../../assets/custom-sprites/68a8f512a2ec8bb2f54327d4c3d1105b.svg';
import probo_cabbage from '!raw-loader!../../assets/custom-sprites/7fd7b268a710ce5019218e70d9a09286.svg';
import probo_grape from '!raw-loader!../../assets/custom-sprites/94b6d4d6b35114f3b68674ad6cc3a071.svg';
import probo_onion from '!raw-loader!../../assets/custom-sprites/bb2f406eaa766bae194e2b323308b802.svg';
import probo_paprika from '!raw-loader!../../assets/custom-sprites/ae7959ffe6da2e481a5d63a0d23e85cd.svg';
import probo_watermelon from '!raw-loader!../../assets/custom-sprites/a1691e84c4843051e6c46cd908c7347e.svg';
import probo_vegetable_basket from '!raw-loader!../../assets/custom-sprites/e49a9dcbfada01ca81940585c7379837.svg';
import probo_water_A from '!raw-loader!../../assets/custom-sprites/b4c1e55cc62b1828c172be28982152e5.svg';
import probo_water_B from '!raw-loader!../../assets/custom-sprites/839c4b965fa3430318899aec4ae89a1b.svg';
import probo_water_C from '!raw-loader!../../assets/custom-sprites/7251caf89ed9a7adc86b8f88eb1e5a08.svg';
import probo_welding_machine1 from '!raw-loader!../../assets/custom-sprites/5cfac45f721b7009dc413a07fc45652e.svg';
import probo_welding_machine2 from '!raw-loader!../../assets/custom-sprites/43e4de06214a2d32dc1fcbef8043134a.svg';
import probo_car_wheel from '!raw-loader!../../assets/custom-sprites/34fc7f0e53d52c7be333430eef6dd463.svg';
import probo_wheel_line from '!raw-loader!../../assets/custom-sprites/acf7823d954429067e8ed1b2736c50d9.svg';
import probo_black_hole from '!raw-loader!../../assets/custom-sprites/7aedd4e7e41b8772dbd14e08b605ca98.svg';
import probo_white_hole from '!raw-loader!../../assets/custom-sprites/a4f3326eecffa3435399e6ab17a80b27.svg';
import probo_wild_boar_A from '!raw-loader!../../assets/custom-sprites/623b6fe3d88ea1545078dbcb87ffd2fa.svg';
import probo_wild_boar_B from '!raw-loader!../../assets/custom-sprites/9b0ed05620c3ed8dfe28a48cb7f18f81.svg';
import probo_wood_fire from '!raw-loader!../../assets/custom-sprites/fa8da39f0240a1eae930ec9f46b6074d.svg';
import probo_wood_stick from '!raw-loader!../../assets/custom-sprites/00bdd81847e18b3049d2b0c6ed0a282a.svg';
import probo_worm_hole1 from '!raw-loader!../../assets/custom-sprites/235ada608c86dfd9dcd58d4042008dd4.svg';
import probo_worm_hole2 from '!raw-loader!../../assets/custom-sprites/7809e177b3f7e5c2a2cce3af7fe53a4f.svg';
import probo_xylophone from '!raw-loader!../../assets/custom-sprites/3a383aff6cfebe6d546021c9aa697984.svg';
import probo_xylophone_stick from '!raw-loader!../../assets/custom-sprites/37f3d9f12888d4833527e0e48a6619a0.svg';
import probo_yellownote_do from '!raw-loader!../../assets/custom-sprites/8dd5ebf31f9384ae123ed85b3bd86420.svg';
import probo_yellownote_do2 from '!raw-loader!../../assets/custom-sprites/39f322c1e30a68ff1e4be1722884befb.svg';
import probo_yellownote_fa from '!raw-loader!../../assets/custom-sprites/6168a15c9b7d36a059c02ebbb05047b6.svg';
import probo_yellownote_la from '!raw-loader!../../assets/custom-sprites/6d05a101c02749a02b8535ae156b336c.svg';
import probo_yellownote_mi from '!raw-loader!../../assets/custom-sprites/c20628c34b0232903cf3d31719bcb8a1.svg';
import probo_yellownote_re from '!raw-loader!../../assets/custom-sprites/81eba9068c3b38d4b4890356ee4f3f6e.svg';
import probo_yellownote_si from '!raw-loader!../../assets/custom-sprites/1f0b726f783fbd78a12f5e75d955c663.svg';
import probo_yellownote_sol from '!raw-loader!../../assets/custom-sprites/312a2556170d843b2a80f747213e18db.svg';

const defaultProject = translator => {
    let _TextEncoder;
    if (typeof TextEncoder === 'undefined') {
        _TextEncoder = require('text-encoding').TextEncoder;
    } else {
        /* global TextEncoder */
        _TextEncoder = TextEncoder;
    }
    const encoder = new _TextEncoder();

    const projectJson = projectData(translator);
    return [{
        id: 0,
        assetType: 'Project',
        dataFormat: 'JSON',
        data: JSON.stringify(projectJson)
    }, {
        id: '83a9787d4cb6f3b7632b4ddfebf74367',
        assetType: 'Sound',
        dataFormat: 'WAV',
        data: new Uint8Array(popWav)
    }, {
        id: '83c36d806dc92327b9e7049a565c6bff',
        assetType: 'Sound',
        dataFormat: 'WAV',
        data: new Uint8Array(meowWav)
    }, {
        id: 'cd21514d0531fdffb22204e0ec5ed84a',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(backdrop)
    }, {
        id: 'bcf454acf82e4504149f7ffe07081dbc',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(costume1)
    }, {
        id: '0fb9be3e8397c983338cb71dc84d0b25',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(costume2)
    }, {
        id: '96f04159d9bd1701a9f2d936d217ad4c',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(dummy_100_75)
    }, {
        id: 'b65bdf0864f5e4b6b93bdb0013133671',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(dummy_150_112)
    }, {
        id: 'f1a0baa8c221cef78e543a69fbc7425e',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(dummy_200_150)
    }, {
        id: '4c788908f75b1fdef107a76f867a7114',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(dummy_250_186)
    }, {
        id: 'a0691f318240a1ba7d64d5c025c6c99a',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(dummy_300_223)
    }, {
        id: 'ad952d248b427b233980cebd7ce0f0f1',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(dummy_350_260)
    }, {
        id: '80e9856377cd176ae1a4e35e1a182602',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(dummy_400_297)
    }, {
        id: '1019f94340dc4fa84fc994fac4919176',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(dummy_450_334)
    }, {
        id: '2abb1a69e06dc43a6f0eee5380ad60b7',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(dummy_480_356)
    }, {
        id: '3696356a03a8d938318876a593572841',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(nemoCostume01)
    }, {
        id: '3696356a03a8d938318876a593572842',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(nemoCostume02)
    }, {
        id: '3696356a03a8d938318876a593572843',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(nemoCostume03)
    }, {
        id: '3696356a03a8d938318876a593572844',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(nemoCostume04)
    }, {
        id: '3696356a03a8d938318876a593572845',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(nemoCostume05)
    }, {
        id: '3696356a03a8d938318876a593572846',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(nemoCostume06)
    }, {
        id: '3696356a03a8d938318876a593572847',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(nemoCostume07)
    }, {
        id: '3696356a03a8d938318876a593572848',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(nemoCostume08)
    }, {
        id: '3696356a03a8d938318876a593572849',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(nemoCostume09)
    }, {
        id: '3696356a03a8d938318876a593572850',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(nemoCostume10)
    }, {
        id: '3696356a03a8d938318876a593572851',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(nemoFaceCostume)
    },
    {
        id: '3c54419cd76c0dab410ae78248c4850c',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_racing_field_A_1)
    },
    {
        id: 'bfb5098160260c8bb640d0e2d7047ad7',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_aerdrome)
    },
    {
        id: '430f2c6a220eeacdc3c7b7bdf043a26f',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_lv3C7BG_beanstalk)
    },
    {
        id: '29dff81a0c06eb213f06f2e7aaeb7fc4',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_black_hole_11)
    },
    {
        id: 'd3cf63ac0b29ab6918f9d27dedea4b43',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_chaper11_gameBG)
    },
    {
        id: '65e8ee597859272e4e80432b3eb01a98',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_Inside_the_car)
    },
    {
        id: 'ab3ba668aa9436b253d26601fe42580d',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cave)
    },
    {
        id: '9239f2a86410d490d14f36578a40c402',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_land)
    },
    {
        id: '20a3d3748662387579bd56a0cf130783',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_chaper12_gameBG)
    },
    {
        id: '50c5fe3e1024891ea05f7ff90998396f',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_lv3C8BG_cloudtop)
    },
    {
        id: '0c0a1b6d0f6d8b8415f4c484f6dd1d17',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_coke_festival_A)
    },
    {
        id: '482c20dbcc701d78cd5a9d2bf414f134',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_coke_festival_B)
    },
    {
        id: '77755176721fec5c48650dc7fa65af41',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_lv3C3BG_cprbeach)
    },
    {
        id: '012fb9fc9cc0d7736340ac8bcaf1b5cf',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_desert_road)
    },
    {
        id: '10ab6469e9827c3e7653d46bc315bc23',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_desert1)
    },
    {
        id: '5a1a16399fd3fb662e0ba900e98cbd98',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_desert2)
    },
    {
        id: '4bc334aa317fcb875d336e223955f398',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_dino_radar0)
    },
    {
        id: '47844b89d4b778cb1f9cb670a9f412d9',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_energy_storage)
    },
    {
        id: 'fe923de1a0e75587c43a1b257739f8b1',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_forest_Rain)
    },
    {
        id: '8ec7c5afd9e3350b5c0da027553064ea',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_free_field)
    },
    {
        id: 'b467dcba3ba6b0c83907b3db35a0d538',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_front_yard)
    },
    {
        id: '222a29ae9537530b8eb5d0663bae889d',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_gardenA)
    },
    {
        id: '222a29ae9537530b8eb5d0663bae889d',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_lv3C5BG_gardenA)
    },
    {
        id: '49e488cb42130f7accb26dab6840d9a1',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_lv3C6BG_gardenB)
    },
    {
        id: '774fb01aa6273827839637ae843710cd',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_lv3C9BG_gianthouse)
    },
    {
        id: '05405ca562a05987d2d856db5ab318bf',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_ground_Dino)
    },
    {
        id: '91c0484755cb9449c985045e573be982',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_ground_Fire)
    },
    {
        id: '73e0fd99f2cba6eb21bd359b6e64b900',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_ground_hit)
    },
    {
        id: '66effbe2c07e5a94837fb5a25363555d',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_heart_rate)
    },
    {
        id: '06b364adb3a2eef6bd28f4af9994e1a8',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_ice_country)
    },
    {
        id: '560f314d56e68f5cd39089399a27ddfa',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_interior_spaceship)
    },
    {
        id: 'd86a92486324fd7e6213c6686a16a9c8',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_kitchen)
    },
    {
        id: 'adba46a74b97344f6d7c55ac185e42d9',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_lv3C1BG_LinerA)
    },
    {
        id: 'e2094a2001bfd2a7953d46fc46518e16',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_lv3C2BG_LinerB)
    },
    {
        id: '4255b9dd5467d4c1d0a3c6477d0f40d7',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_m_assembly)
    },
    {
        id: 'cd3402eb69f672d6e45727f84ef48085',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_assembly)
    },
    {
        id: '2e82f4a00c354c79f5eb327aa8d47cef',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_m_energy_storage)
    },
    {
        id: '6b9cb3d67752497063446172151087ce',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_m_heart_rate)
    },
    {
        id: '9439aaf6a8dabc5b4573de8bbc0aef8a',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_mars)
    },
    {
        id: '356c5f4ddfbdee5ae43663d377bc33f4',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_maze1)
    },
    {
        id: 'dd1d84e2f1b067687e93b57def925140',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_maze2)
    },
    {
        id: '039baad3c3e4d78680cb2f194ff2e013',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_maze3)
    },
    {
        id: '558d6410c7d53eae617eb9a53b66e406',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_maze4)
    },
    {
        id: 'e4b9be3c1b195a14a6fa2cb7667e2935',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_lv3C11BG_melodyroundA)
    },
    {
        id: '2dc690cddd1dbcc1f870a4ff3fcb4cd8',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_lv3C12BG_melodyroundB)
    },
    {
        id: '580907a326a70df461259fbffc1dbf96',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_music_hall)
    },
    {
        id: '4c4dad285670e13b242547045f5de917',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_music_hall2)
    },
    {
        id: 'd4bf67b1903d4960077f8560c896e458',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_racing_field_A)
    },
    {
        id: '80300e971fda8424bc61de136c3538db',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_racing_field_B)
    },
    {
        id: '97a7e23dc34ac5e92d79affbbe006bd5',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_lv3C4BG_forest)
    },
    {
        id: '9fc0d86436292f9402cabfd19a3144b7',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_sky1)
    },
    {
        id: 'fb7040782b37c6e0b364b4734e6cf4d2',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_sky2)
    },
    {
        id: '355c9f8233c4959b5dc1cd1fabc5d057',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_space)
    },
    {
        id: 'c536e98b0f6787a5b3c1eed455ddca52',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_Space_B)
    },
    {
        id: '68432d66d515bb677935b8194a0e4195',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_stage3)
    },
    {
        id: '04a65c11fa6dc3219ffa26f298453d66',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_lv3C10BG_sunsetsea)
    },
    {
        id: 'bbb9ad359a065f72af61df0ff5580515',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_airplane_front)
    },
    {
        id: 'd758b7a24117fc72ee253d575834071b',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_airplane_left_trouble)
    },
    {
        id: '2d190d878485afc1628753af0a78c2cb',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_airplane_right_trouble)
    },
    {
        id: '57c118f0dcddc33873dd018be4416a52',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_monkey1)
    },
    {
        id: 'de9d73341bf83a72c39994026f5acf1b',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_monkey2)
    },
    {
        id: '2df6b1d11232c3e0e6d4a359ac043ad4',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_monkey3)
    },
    {
        id: '024704348e798ed5aa1b91bd7e493044',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_note)
    },
    {
        id: 'f84bb4c45844bd7b20934009059ba003',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_candy)
    },
    {
        id: '3e02698416933b7e7ab07bbd829a8553',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_car_back)
    },
    {
        id: '3de94894fb65507820f18dbb5682e53d',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_car_front)
    },
    {
        id: 'b80c487cc5ebf82c8bcf83648da1fb3d',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_car_left_trouble)
    },
    {
        id: 'a32058c6cb800623bbbfd84407c376d7',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_car_right_trouble)
    },
    {
        id: '9e493dc635299f890e4ba9a7707800f2',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_car_top_trouble)
    },
    {
        id: 'fbce43d8460500782c1009b052823af9',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_pencilcase)
    },
    {
        id: '318f60001d8c9fa5bd3643d9d0ef52b8',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_crayon)
    },
    {
        id: '8fcc50cea5202ad554805f243df95591',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_firstaid)
    },
    {
        id: 'cf3c63093ae2c7897b74e748cd959da6',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_flashlihgt)
    },
    {
        id: 'b5c54bb5bd0ec6535ef0aeb89e4189d5',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_socks)
    },
    {
        id: '3e5f882c5158e05e15c9566ec6705e23',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_submarine_front)
    },
    {
        id: 'dfb1251dfed550f95e70647d311c3375',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_submarine_left)
    },
    {
        id: 'c4fb51490737939c81d55daaf27bd427',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_submarine_right)
    },
    {
        id: '9e85f8c61f33a98294c70d4f6779a872',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_yoyo)
    },
    {
        id: '4c967617cf2db1d603af796a1b9b123d',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_hairpin)
    },
    {
        id: 'a25b4375e516ea3619123c3a8f68d28d',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_hat)
    },
    {
        id: 'b11a645ef06c6ab500469e06a7d27af1',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_ai_walking_a)
    },
    {
        id: '37b69f02336e2814f760bb6f81f37e21',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_ai_walking_b)
    },
    {
        id: 'c31d713e0bcf5a7a98a192ccaa1ac359',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_ai_walkdown_a)
    },
    {
        id: 'ae51bfcec3141064b0e1eda7415e8a69',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_ai_walkdown_b)
    },
    {
        id: 'cd422374fa8003a1d3c44ffe2c87431c',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_ai_back)
    },
    {
        id: '66725934df8641d602234874f43c62df',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_ai_cokeA)
    },
    {
        id: '173dad3581ed6c43cbbd7b3997481afe',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_ai_cokeB)
    },
    {
        id: '3d97dbe75bd9e0e707910fd9cddb3216',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_ai_damage)
    },
    {
        id: '813665469e05ec19c5576795baeaa8d3',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_ai_dont_a)
    },
    {
        id: 'dbb985f8ac08eb1dcb6aff58b327a668',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_ai_dont_b)
    },
    {
        id: 'd611f8bac1c53691bc018b6861a231cf',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_ai_find)
    },
    {
        id: 'c5772a52261e5dabe0ae29148a19b34f',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_ai_front)
    },
    {
        id: '7202d5d6788332753791d9acf00b9c19',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_ai_game)
    },
    {
        id: '019964febeb745d12d69a469ef86c965',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_ai_hi_a)
    },
    {
        id: 'f038a05b416450ffd18f63bfc6a4cc0e',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_ai_hi_b)
    },
    {
        id: '10452e225d6fa8dab9cee955ed0f6006',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_ai_lightning)
    },
    {
        id: 'd072fc5d122bdc8d2c2a8db693594c0a',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_ai_right)
    },
    {
        id: '61d51f6b403620a6f675a8420fbb5ad9',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_ai_run_a)
    },
    {
        id: 'cd970ef10946f7cea9c00fd5a80a026e',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_ai_run_b)
    },
    {
        id: '4bd5d4e29a214e97d4c6f828c915cee9',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_ai_thinking_a)
    },
    {
        id: '501d33069d70e4c74009fff375e79147',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_ai_thinking_b)
    },
    {
        id: '195f58c9dccc6f22e09cbc6aaf5cb89a',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_Ai_throw_A)
    },
    {
        id: '3596d21fb9f6595b66a69d23275b04c0',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_Ai_throw_B)
    },
    {
        id: '71a510f05d3555b3059d1fca5ed2f697',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_ai_topview)
    },
    {
        id: 'bf3cef0928bfcd3eb438714704503ebe',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_ai_walkup_a)
    },
    {
        id: 'a41402815224bacac24b0de0a153c993',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_ai_walkup_b)
    },
    {
        id: '955c9d5104ddf18822afc48fcf5d6a2d',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_pumping_ai_A)
    },
    {
        id: '3c49e285384da190385cf4b2741b1f23',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_pumping_ai_B)
    },
    {
        id: '1b917ca993c8b472e7530a2a322e463d',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_pumping_ai_C)
    },
    {
        id: '63f7167e3fa3fd3ab5c5a92878b76c34',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_airplane_back)
    },
    {
        id: 'b29f1948704bf358d19c522010335545',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_airplane_left)
    },
    {
        id: '688ce809116603f63d9fa692c23f5478',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_airplane_right)
    },
    {
        id: '9f27db197961419a1d40884727be414d',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_airplane_top)
    },
    {
        id: 'e29dad3017f04e9e82a93078300aac5a',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_all_bar)
    },
    {
        id: '942c7a0d0da1a53c61fd7f4e6f7e71f8',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_moving_point)
    },
    {
        id: 'bc0e1d12d40df54386a242b7c5f79cb3',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_spaceship_energy)
    },
    {
        id: 'e61b9228ba46b41df4559bc6b2491227',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_arrow)
    },
    {
        id: '89b9854a23aacadcb0359637756abed1',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_left_attack1)
    },
    {
        id: '6fd3c8b511ccf8d688b310dbeccfba3d',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_left_attack2)
    },
    {
        id: '48b61b50415bc5254197adc4571f9354',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_right_attack1)
    },
    {
        id: 'd9f0d3a0b0def2b21ba673eb7b3683e9',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_right_attack2)
    },
    {
        id: 'fbb91be3a3d8799be359d5cdb62b7afd',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_baby_dino)
    },
    {
        id: '9c1b8b4cd06b9804e9c4c8e23a65c819',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_bait)
    },
    {
        id: '6981f236166df42cdfc3651d0fa8b14f',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_batsA)
    },
    {
        id: 'fc25cb5061e706a1c2b545fe6875b27b',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_batsB)
    },
    {
        id: 'a9a7c509f3170a387978c53e42d23673',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_battery)
    },
    {
        id: 'b7cfce57957c44aa516a8e9b59f5eccc',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_battery_blue0)
    },
    {
        id: '9e9d3d1c4cf20941fa84779a4753103c',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_battery_blue1)
    },
    {
        id: '39d009f62bb43e53cb7b9dce95cade01',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_battery_blue2)
    },
    {
        id: 'ed945614712c6c806d96a3adb667f46c',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_battery_blue3)
    },
    {
        id: '1615bfc0bca9a17790b75e8db2f6d3bb',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_battery_red0)
    },
    {
        id: 'fa720d0e2c05100d0fe98c31b63a9062',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_battery_red1)
    },
    {
        id: '232fc69f2c26a18833c7ddf9f2d86c01',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_battery_red2)
    },
    {
        id: '52acbc7cf44d45a0be216ad460ca5a38',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_battery_red3)
    },
    {
        id: '9b4676430a177ed7cb93d0a33e4c9019',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_beeA)
    },
    {
        id: '5e9083faa6481213a8e7b20cf31215ac',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_beeB)
    },
    {
        id: 'd40a1da4435707fb233f3f1d9f5fbfce',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_beeC)
    },
    {
        id: '9a9e9239a9927e212242b60acaeeccfe',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_beeD)
    },
    {
        id: '7a2eafc022cebfc6afe4b8c95e7adb8b',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_bow1)
    },
    {
        id: '8868f199f824bd7690f07106baf6401e',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_bow2)
    },
    {
        id: 'acdf31df8d1a6e3ee0363132e6bb4bdb',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_broken_glass)
    },
    {
        id: 'cdea01e22d80a161b1d1b01b2f1fbf1b',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_broken_plate)
    },
    {
        id: '3dcd757429184ec2426643b48e25f5a9',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_brush1)
    },
    {
        id: 'd9d97744271dd2123016cfde284d40b0',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_brush2)
    },
    {
        id: '783d0691adfc5243144f454cc56933d1',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_bubble)
    },
    {
        id: 'e5df616912edefb40465535a10769d50',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_bucket1)
    },
    {
        id: '91591f12fbdfb6d2f0058119985ae5f9',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_bucket2)
    },
    {
        id: '657d97418740f63774922e97d79fec4f',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_bucket3)
    },
    {
        id: '08d50dd6586aba7f201b59f04197d32e',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_bucket4)
    },
    {
        id: 'e5486ae37bb58a8e0da715051787a553',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_building_A)
    },
    {
        id: '4127c6c534d3349ed2d400c26a6ef6a8',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_building_B)
    },
    {
        id: 'ca8e531a0717e3c0f5dd08580ba97fbf',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_building_minimap)
    },
    {
        id: '7c76e4edfbcb25e7b68e73dd1aa23aaa',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_bullet)
    },
    {
        id: '5544f50b9abdc6574bdff941fd432b51',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_butterflyA)
    },
    {
        id: 'df389c8e768a0ae1cb7baa4eb6fe83ba',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_butterflyB)
    },
    {
        id: '5bd3b7989bed4477ab51e18e6db69d63',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_butterflyC)
    },
    {
        id: '871fd74a40238115d9c7dc729c997670',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_can_coke)
    },
    {
        id: 'ae7daa45d1aee147dc848959daf3d0b0',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_candleA)
    },
    {
        id: '7589b8f0ebc2e1e0c117ef87351eb786',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_candleB)
    },
    {
        id: '55a020313885cdebb31aa80cdcdf8912',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_candleC)
    },
    {
        id: '849e73b2bc8b15ed7a353d0be0e6631c',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_candleD)
    },
    {
        id: '66f9163eb8cc34ffb38fbd4f9b36fb7d',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_candleE)
    },
    {
        id: 'cfa745f583156c7ea2816e6c5cee7087',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cardsoldierA1)
    },
    {
        id: 'a2367db9afc9829d89987869999d2cb5',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cardsoldierA2)
    },
    {
        id: 'cc546033ca89dd1885c6d9d911cab7fd',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cardsoldierB1)
    },
    {
        id: '73e4759207adf43e9f174e20016a4fdf',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cardsoldierB2)
    },
    {
        id: '6ab473c9158aeaacf55b7bf13a651054',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cardsoldierC1)
    },
    {
        id: '531d29f426447457476d41179547c884',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cardsoldierC2)
    },
    {
        id: '65feb9164d473fa33c8984460f38a6e4',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cardsoldierD1)
    },
    {
        id: 'e9ba44f6ba47cc3fac75af9b58ec1709',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cardsoldierD2)
    },
    {
        id: 'de0e25fc11cdceb3f4bbd08257e91729',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_crying1)
    },
    {
        id: 'defd253a5f7220ccb275e6923dfffdc3',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_crying2)
    },
    {
        id: 'c224e5c36dc2d6d6d638032ab9447f52',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_flag1)
    },
    {
        id: '69c262189af3b409355a696fdab557d7',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_flag2)
    },
    {
        id: '075366da5f1cb2f29f7c11e54bdcbf65',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_flag3)
    },
    {
        id: 'cbf084206bac79c47c613441e27a4d34',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_flag4)
    },
    {
        id: 'a09634e8078dc77bb147a9726993769e',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_flag5)
    },
    {
        id: 'c4452318658561eedb3b4ccd94dc8601',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_flag6)
    },
    {
        id: '3b1d2e283ba598b3441a6636d9c501f9',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_flag7)
    },
    {
        id: '5046dcb56bae349ea1a854cffea5ea9f',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_flag8)
    },
    {
        id: '79259b040d848eabd689f55e01113b06',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_flag9)
    },
    {
        id: '9a24fe892648230fcfa3d90e27bde4ba',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_carriageA)
    },
    {
        id: '118504c15d7d1340c01c0143776833c5',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_carriageB)
    },
    {
        id: 'fd14b92a6388b515c20ca42609be0bd7',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_floor_surface)
    },
    {
        id: '6b52e684317e6236599fb27ed0866ce0',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_chaging_ball1)
    },
    {
        id: '508afa890e9bbf6269a42cc4e3c02b16',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_chaging_ball2)
    },
    {
        id: '5e1f91ded4859dec41066126ef6919f2',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_chaging_ball3)
    },
    {
        id: '120084d2ec5fa08895a80862f09bbc98',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_chaging_ball4)
    },
    {
        id: 'd8b4578b2955a85d6677a42d6a508877',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_chaging_ball5)
    },
    {
        id: '5f86dafbb66f62a1e5dc2db9253bdd01',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_chocolate)
    },
    {
        id: '42fd39bed3bd799ddb9ac7ad97753307',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cleaning_robot)
    },
    {
        id: '6f74b02865c3aa8ca6614f1d2e053984',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cleaning_robot2)
    },
    {
        id: '5858bd12670d1786a5658086b7809308',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cleaning_robot3)
    },
    {
        id: '9e825aba35bbeabe6a1d38f21c20c2c3',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cleaning_robot4)
    },
    {
        id: 'cf0ecf3f94d114a780be43c5fa2120f9',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cleaning_robot_D)
    },
    {
        id: '44a0ad1e27e3435473ed618c048275d7',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cleaning_robot_D2)
    },
    {
        id: 'e25ddc2cf442b7c689da5ab2d8f02d3d',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cleaning_robot_D3)
    },
    {
        id: '82e2b55c020425723b6654297ba3d6ab',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cleaning_robot_D4)
    },
    {
        id: '2a0597a7e6ecdaf3221fa7de7d9355dd',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cleaning_robot_G)
    },
    {
        id: '6e3658fcbfd53fa16342f21169fa7bbb',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cleaning_robot_G2)
    },
    {
        id: '8b4dd0b00565b083322d31c5f77b1a56',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cleaning_robot_G3)
    },
    {
        id: '3e4e230ba0cf92b604f6beedcd3d9519',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cleaning_robot_G4)
    },
    {
        id: 'b6d961d20a35cf7b932e11d17d603a3f',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cleaning_robot_P)
    },
    {
        id: 'f650f89ba0e22b64651ac3a43dd34488',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cleaning_robot_P2)
    },
    {
        id: '3b1972b11f9518d60d8efb58fc22ff94',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cleaning_robot_P3)
    },
    {
        id: '06366d6fd874e7d7a302d4d69fcd012e',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cleaning_robot_P4)
    },
    {
        id: '1187216bb1ce7b6637f19d778fe78e7d',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cleaning_robot_R)
    },
    {
        id: 'd2811283287649162759fb8ba3c9c51b',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cleaning_robot_R2)
    },
    {
        id: 'bd782c32a9d48bc51d46bb4c23008a44',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cleaning_robot_R3)
    },
    {
        id: '5ee721fdae307943a9e1cf3a30b66df9',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cleaning_robot_R4)
    },
    {
        id: '99cdfc2b5b86091684fc5ef564645be3',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cleaning_robot_Y)
    },
    {
        id: 'aca6f16217927c57a53084cac5579b70',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cleaning_robot_Y2)
    },
    {
        id: '322738caf268498387e16b8545922da4',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cleaning_robot_Y3)
    },
    {
        id: 'ed13a5e6a09e5733d0bc8486d95b8e7c',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cleaning_robot_Y4)
    },
    {
        id: 'c23549bfa2563610a8a8478334c5a61f',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cloud_A)
    },
    {
        id: '1bb6ff3134f8b965d7e5bc61f8eac6fc',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cloud_B)
    },
    {
        id: '880d48153cb068ea77a8b4de91b24e81',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cloud_C)
    },
    {
        id: 'e1e66e4fb243074311b9583ecd819412',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cloud_D)
    },
    {
        id: 'a719ffd01c625ec1f24406e39b6c7f6b',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cloud_E)
    },
    {
        id: '20f7b177e98327a39889a86bd9156973',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cloud_F)
    },
    {
        id: '89c72a65d466c2e234b6f53239266f21',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_clovergarden_A)
    },
    {
        id: '8443f793ed1d54c8fa4eb1ed5706ce21',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_clovergarden_B)
    },
    {
        id: 'a028e260d007dc41bb33227373b4fe6a',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_clovergarden_C)
    },
    {
        id: '62a5a0320e07129ef191f65e7a80fc70',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_clovergarden_D)
    },
    {
        id: 'ca0a8cd87e569e706d5a7c35fed24fed',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_codingblock1)
    },
    {
        id: '71522fcd71caa91dfea0c82feade8692',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_codingblock2)
    },
    {
        id: '4aae65d09008ccbbda50d927d278bd38',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_codingblock3)
    },
    {
        id: '662797f3b56ad1911813824aa54c94e1',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_codingblock4)
    },
    {
        id: 'f10ce04361866a5689ed0206b3878ba3',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_codingblock5)
    },
    {
        id: '7e1609cfd6979d35c27949f1f64d83f7',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_codingblock6)
    },
    {
        id: 'cd9fbcb36a02c7c21137e59036151a57',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_codingblock7)
    },
    {
        id: 'b8373cc6c5b0613db4b47ce59970750f',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_codingblock8)
    },
    {
        id: '494b70e35aacd1f2a2f3b697629ad55a',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_codingblock9)
    },
    {
        id: '6224db4d458c3232df400685c3ad9bad',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_codingblock10)
    },
    {
        id: '37418f7708f20b29cb1774ce244dd0d5',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_coin_A)
    },
    {
        id: '069a73b24c36d96eaf28ea6b42b77a35',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_coin_B)
    },
    {
        id: '4054f1da4fe51677bd9fb44b266c1dac',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_coke)
    },
    {
        id: 'd157499d553809783c79851e9974b08a',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_coke_gauge_A)
    },
    {
        id: '413a8ac43f2a1747644e8bc756195355',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_coke_gauge_B)
    },
    {
        id: '046786b9a905f3d4d919a7a031b37ff5',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_coke_gauge_C)
    },
    {
        id: 'c7dca4e678eb0f51e9a43f99011d1e45',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_combo)
    },
    {
        id: '35cabfc6f116b8a28e9916f5529af544',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_completeness)
    },
    {
        id: 'dbdd79cc330d623986fec700203d0a71',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_completeness2)
    },
    {
        id: '2769df75493b1eb8fb712efa87433dcb',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_condition_A)
    },
    {
        id: '1b808e1c2bcf4736101709ecca61e6da',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_condition_B)
    },
    {
        id: '80f13f2cfe9931818f73f29f9e142ca4',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_condition_C)
    },
    {
        id: '068abd7ee7a975351daba9bcc3c351c1',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cprpersonA1)
    },
    {
        id: '0dcd4713e4621e9954b050b6238af277',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cprpersonA2)
    },
    {
        id: '3d4a72c78513a64c7819dafe0b191c77',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cprpersonB1)
    },
    {
        id: '2eca7f39b20656602eb8010fa6dbbcc3',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cprpersonB2)
    },
    {
        id: '9d86785ae27acf7f6ff111b9cb343367',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cprpersonC1)
    },
    {
        id: 'eb4e0ced5199d37ce5c42c61eca6a1fe',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cprpersonC2)
    },
    {
        id: 'b516580170a5ed5f600c88dd053d6747',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cprpersonD1)
    },
    {
        id: '2d78bfdd6d23ed932aa948900e48926c',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cprpersonD2)
    },
    {
        id: 'e44da7b5e3971b7397f3c63618bef5eb',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cprPU)
    },
    {
        id: '7a2a8e2473c1d89a16e4f7f780cf4c03',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cprhand)
    },
    {
        id: '37ad0cd01b61e32b7ffa1542b7cee8ca',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_crab_A)
    },
    {
        id: 'f72923700de084fa3e3d2bb41627af07',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_crab_B)
    },
    {
        id: 'ce307866714c22c4e8b97c03edde5b01',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_crush1)
    },
    {
        id: '85e2128b730b6f9517ababcd329c4161',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_crush2)
    },
    {
        id: '6355773384561ed8ccd28df9b91d51ba',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cut_avocado_A)
    },
    {
        id: '61f864d2f8e8f8bdfc11eafff44a0424',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cut_avocado_B)
    },
    {
        id: '63fb594761000a7ce13c73c53ea80b86',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cut_banana_A)
    },
    {
        id: '41ea5b27e87c26b192ece8c0082c61c5',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cut_banana_B)
    },
    {
        id: 'e961a68a0bf733d5adeaa6adc5681f1e',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cut_cabbage_A)
    },
    {
        id: '4beb1171d3fdc31e9265da7176909b54',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cut_cabbage_B)
    },
    {
        id: '69bf9525b44ff185984ad4b072c844e0',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cut_carrot_A)
    },
    {
        id: '5b07c4def82939318e4cba14d9bc1712',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cut_carrot_B)
    },
    {
        id: '5622a7d9af14a379f1bcdd2f9b3367a2',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cut_grape_A)
    },
    {
        id: 'c35193ef0a176ca9adf78b5a7d0463ae',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cut_grape_B)
    },
    {
        id: '11685ee50f3eb65ad1eaf2cc679add2c',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cut_onion_A)
    },
    {
        id: '9e72edf527a1a0e89ae5e37501767a82',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cut_onion_B)
    },
    {
        id: '633d7e30893e46f12397121d4d1806fa',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cut_paprika_A)
    },
    {
        id: 'b0abd31e29a7b29343de8e19028c0e9e',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cut_paprika_B)
    },
    {
        id: 'bc47d1d7475f31f36d839d1af0d56981',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cut_watermelon_A)
    },
    {
        id: 'e1f731b9b02ae9eb1c524e4bae25e720',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cut_watermelon_B)
    },
    {
        id: 'e22b73ec7661422f0ff48f06e20db6fc',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cutting_energy_20)
    },
    {
        id: '3e1b44f5d7af5e172f207ffff6c5e883',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cutting_energy_40)
    },
    {
        id: 'c4c43745bd7d2d5ded65334d69d20f6f',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cutting_energy_60)
    },
    {
        id: '91be4110a7b4af17a61205d693df7bf2',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cutting_energy_80)
    },
    {
        id: 'd2bfe2f5abe682eb0eedffcb3b5151dd',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cutting_energy_100)
    },
    {
        id: 'e322a86b464c9234e38930980c97e91d',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cutting_energy_120)
    },
    {
        id: '25ed3a7a80b2ddca7626ddc247e8d602',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cutting_energy_140)
    },
    {
        id: '46d6e9687dd6af54bd94368664e7c354',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cutting_energy_160)
    },
    {
        id: 'f9ecb72da8ecb15b79a77c9a6fd30388',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cutting_energy_180)
    },
    {
        id: '801bff5664a059ec2b5b702c553c606d',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cutting_energy_200)
    },
    {
        id: 'ce14dc8ae03a923031d2a28290e42799',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cutting_machine)
    },
    {
        id: 'a1c429940f12266a7e9c2a758477ffde',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cutting_machine2)
    },
    {
        id: '3dcf25e3c597461e852daa6b5367510b',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_circle)
    },
    {
        id: '885ad902e0b311fa8f906a2b3c04bfc6',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_dark_clouds_A)
    },
    {
        id: 'e1feb653f81874fc7d34b24c63d81b09',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_dark_clouds_B)
    },
    {
        id: '48ee4100c1ab99475f8e60d23cf8c7a8',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_dark_clouds_C)
    },
    {
        id: '9197065dacc64300dbcb5e335b7e989d',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_dark_clouds_D)
    },
    {
        id: 'a1efdf9c30164aef7212a7fd479cf9be',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_dark_clouds_E)
    },
    {
        id: '287664683bef728856b7871b8fadc125',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_dark_clouds_F)
    },
    {
        id: '4a04e12d7e86c6a9cbf05a70d9ee269b',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_desert_maze_A)
    },
    {
        id: 'b5a1e01898c8289cc2efa20d5681f212',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_desert_maze_B)
    },
    {
        id: 'f089bfcfba1dd1eed8a563600b2e4304',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_desert_maze_C)
    },
    {
        id: 'c4b7bb3e1949560e75b7ec8dc901c290',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_desert_maze_D)
    },
    {
        id: 'c2d6cb9b3cd9c7e5b6444c0ddbf40e05',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_desert_maze_E)
    },
    {
        id: '1e81ad7b78d35a2a7c1cfc03b384dc27',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_desert_maze_F)
    },
    {
        id: 'c8bf67700892c9789578b610e53ea7cb',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_desert_maze_G)
    },
    {
        id: '50d266419bca1654489c14994866d030',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_desert_maze_H)
    },
    {
        id: '46c92ecadbaf46f7661fe26dd0964fc6',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_dino_radar1)
    },
    {
        id: '4d462e1a75750cb633f7d060df3fae5f',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_dino_radar2)
    },
    {
        id: '6d6db2bf667b3cefee58d3766ffcd7e4',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_dinosaur1)
    },
    {
        id: 'f36c82a94dfd7f08862bd78f5d552743',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_dinosaur2)
    },
    {
        id: '57d0186ace227e5e6183480f97492696',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_dinosaur_back)
    },
    {
        id: 'a35a6c26528ed703d4699882515dd654',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_dinosaur_cry)
    },
    {
        id: 'e1427d98e500ff7b4020c02109c0581a',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_driver)
    },
    {
        id: 'c992c1c177504f86a82ad9601e547141',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_doctor_A)
    },
    {
        id: '15b570cc561ec35fe6c5f3f39fe4f0b1',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_doctor_B)
    },
    {
        id: '5dddd23f1ee2bfc17574fc4f5fb0abf0',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_dot)
    },
    {
        id: '4e302d78f71bcbfff3ff897283976e44',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_drone1)
    },
    {
        id: '05e6a601b0c437c083710c23c8085563',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_drone2)
    },
    {
        id: '508f0d544afd19b62acb651d0b92dc0a',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_eknowandai_A)
    },
    {
        id: 'd84ca2129b0abadf76219f85d9792ad0',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_eknowandai_B)
    },
    {
        id: 'bfb013dde173cf95b350874592c438dd',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_eknow_walking_a)
    },
    {
        id: '71a6b016a8b7e17eb39df815bb88d922',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_eknow_walking_b)
    },
    {
        id: '63affca2054aabb6d82d5e6eaa8ffeab',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_pumping_eknow_A)
    },
    {
        id: 'c9bf8f8e809bc1a153228a8adc28e41e',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_pumping_eknow_B)
    },
    {
        id: 'dc94a18ae0bca8ea0d5756f07a90c76c',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_pumping_eknow_C)
    },
    {
        id: '9e8b7f4c4c1502f1d2bca6ad5f581f13',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_eknow_angry)
    },
    {
        id: '7f0583580661902b6cfd3ba7a76911f7',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_eknow_back)
    },
    {
        id: '815efa73b32422eef13c8fd11b201638',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_eknow_climb_a)
    },
    {
        id: '965e31b474c67ce1fb4a2b8d04968ba4',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_eknow_climb_b)
    },
    {
        id: '638caef9a70d4550e6f6628a46e23eb1',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_eknow_climb_c)
    },
    {
        id: '02e0e98ac5ef68f616b57131abc320f9',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_eknow_cokeA)
    },
    {
        id: '2587cb3b3b5306054ce1751eaeda6b86',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_eknow_cokeB)
    },
    {
        id: 'e792bcf1d0d34f6dce5af7d6a30bde86',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_eknow_crouch)
    },
    {
        id: '2de0b31dc4f884a039951cffffe96619',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_eknow_cry)
    },
    {
        id: '6a0691e49a8ef43fd1180f14674fd759',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_eknow_damage)
    },
    {
        id: '9493bf34f4408ebeb9de5bb15bad89d9',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_eknow_find)
    },
    {
        id: '0e1e6999a341691e60b074a9deae3373',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_eknow_front)
    },
    {
        id: 'edfc18f460ed4132c04cc000348d2652',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_eknow_game)
    },
    {
        id: '50e8a49fa13744165ba6ad30eae35b62',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_eknow_good)
    },
    {
        id: '7f7173a3610d4139bfe855f00cfbe271',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_eknow_hammer_A)
    },
    {
        id: 'ede3c6328d9b435a8cfda47486bc15ae',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_eknow_hammer_B)
    },
    {
        id: '0529171f2f340cbd50db9a24c06a8855',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_eknow_hammer_C)
    },
    {
        id: '856a8101ed481a2ed2b512e2f7a58114',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_eknow_hammering_A)
    },
    {
        id: 'fb2ea1c938d6f131d58c7d9a08cdf9e4',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_eknow_hammering_B)
    },
    {
        id: 'dbf2029c159dc8baefbcf067ea7ae698',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_eknow_hi_a)
    },
    {
        id: '7919ffcdc68081440211335a8aa5aa7c',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_eknow_hi_b)
    },
    {
        id: '8a2eb102c8532109b96ea521166bcf01',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_eknow_jumpup)
    },
    {
        id: '3155addea78083df1c8df1d723f27cd1',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_eknow_lightning)
    },
    {
        id: 'aabddb6216dd4e004bd9a3240b39af84',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_eknow_right)
    },
    {
        id: '3c9841cbe70090748a9536899f063b0d',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_eknow_run1A)
    },
    {
        id: '4fe498b498ae1acc0707b815066ab6d2',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_eknow_run1B)
    },
    {
        id: '2c728fb99a857fe9ba556b0f9832262c',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_eknow_run2A)
    },
    {
        id: 'c49b02955db4608381c835f1d840c36c',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_eknow_run2B)
    },
    {
        id: '02b08608c5a8b856692614c4cb225843',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_eknow_run3A)
    },
    {
        id: '130c5eb117637e2b382193de7997d9dd',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_eknow_run3B)
    },
    {
        id: 'e72d9c1cdf981cf71cb94b55867312d4',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_eknow_slide)
    },
    {
        id: 'b10dc03cbe1c8696ad2a6b6bf84c4fdb',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_eknow_slingshot)
    },
    {
        id: 'e9e81a98dad1d90e0eb5785ad21b0e27',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_eknow_stealth2A)
    },
    {
        id: '259ffb6982e0bfb1db1b4633086c13ca',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_eknow_stealth2B)
    },
    {
        id: '5d78e743c4a62c9d134bff892930c894',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_eknow_stealthA)
    },
    {
        id: '7654e3adc1155b97cf5f1465e4165662',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_eknow_stealthB)
    },
    {
        id: 'a7faecaec7f01c3b7aa7bccb191a3d1c',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_eknow_surprised)
    },
    {
        id: '38cee3891ccc2139d4762a62d7dbc76a',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_eknow_talking_a)
    },
    {
        id: 'c2b4e3e020f6aa5c413d7ef12a6bb68f',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_eknow_talking_b)
    },
    {
        id: 'ae15a082ee6c9e407c987a12cf124b9e',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_eknow_talking_c)
    },
    {
        id: '79028323b0070087b7a7f052c2cc28c1',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_eknow_topview)
    },
    {
        id: '7411a97ae33cdd2eb0f79afad25f16df',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_eknow_walkdown_a)
    },
    {
        id: 'a49c578f7d34a84921e6928985b65f9a',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_eknow_walkdown_b)
    },
    {
        id: '6deb84fa31ab2162b07ff356e134d358',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_eknow_walkup_a)
    },
    {
        id: '432d4edc614318a6474ce005dd913f0f',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_eknow_walkup_b)
    },
    {
        id: '871207262a7560ad41969f7e5044b5ab',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_eagleA)
    },
    {
        id: '999cee19358f9df567233804956d9ec4',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_eagleB)
    },
    {
        id: 'a9cd1b73197089d44805db9009d7bdbb',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_egg_ghost_A)
    },
    {
        id: 'd5f9fb677064202e0aeab72b202498c1',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_egg_ghost_B)
    },
    {
        id: 'e7c55c1c45e13ff01c28907091b50f36',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_egg_ghost_C)
    },
    {
        id: '1dc0e6f626a41f0959cc979d741a9aa7',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_EMP)
    },
    {
        id: '099864a4290c64ef458963745b06fdf5',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_EMP_piece)
    },
    {
        id: '56f438e23a8635a7f8106cf0772f3cdf',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_escape)
    },
    {
        id: '7ab7bf93fcf0d30f3eaec36fdfd97eda',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_bad)
    },
    {
        id: 'f0229fe149cc07c32ad6773663fa1369',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_good)
    },
    {
        id: '7d734c76434d1d2be876b40b485214a8',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_fast_ghost_A)
    },
    {
        id: 'edac51567b2007151e5f521cf3cf670d',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_fast_ghost_B)
    },
    {
        id: '8cbdf52fe656e2b154e86ab7f1a1f0c5',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_fast_ghost_C)
    },
    {
        id: 'f4e45a10f56f0ea44e3d850da1feb1ba',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_fast_ghost_D)
    },
    {
        id: 'aff5bda9e4f128c3ecea5d9fe495c9d3',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_finger_flick1)
    },
    {
        id: '44c7fd3ad1468b226f31e89785536f6c',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_finger_flick2)
    },
    {
        id: 'cd234944330bc1bb364336594029aefb',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_fire)
    },
    {
        id: '58500eb0791e1825301f394d755aa8ec',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_fire_building)
    },
    {
        id: '33f961f22d9df819fd48cb5b56345c00',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_fire_extinguisher)
    },
    {
        id: '416d4d79d3b0218bc13ab30b3ab87c8c',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_fire_hose)
    },
    {
        id: 'fe353e6647dec3cd81b6565e8df8637e',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_fish1)
    },
    {
        id: '51237252d9197c26ad652bbad8812919',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_fish2)
    },
    {
        id: '375327077b782ab51aca32faa496d29f',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_fish3)
    },
    {
        id: '4be4680aee85bdb1baa562c24a1e41f3',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_fish4)
    },
    {
        id: '03e04626f88cca0c08f94810f7c0ab68',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_fish5)
    },
    {
        id: 'f2f5c7e4e80413a74c9751de925c4bc3',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_fish6)
    },
    {
        id: 'a58d08973d78ade9c9c6d86b3e1dea01',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_fish7)
    },
    {
        id: '168632edce53c8245ae535e4cfca5fb5',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_fishing_rod1)
    },
    {
        id: 'cdebc5dd4a290e77e8611e60adba2997',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_fishing_rod2)
    },
    {
        id: '0036798fd3a38db6123eb565bda70d95',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_flashlight_off)
    },
    {
        id: 'a639647b5dc42f9f9c73905f2e5b5d12',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_flashlight_on)
    },
    {
        id: 'e7597059966255515213707b15fe0455',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_flowerpot_A)
    },
    {
        id: '3d7902e0a623a4a946a7bb82fd1ff43c',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_flowerpot_B)
    },
    {
        id: '96cd412528f78766c71e6d8012b88d85',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_fly)
    },
    {
        id: '4bd8dd2c9eda176eaceaa627c2b69856',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_forest)
    },
    {
        id: '976427dd9f7b9a544287c639e6b7bdfb',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_frest2)
    },
    {
        id: 'd05552d2db67faf69812a7bed3b0b08c',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_fourleafclover1)
    },
    {
        id: '262e727343343dba488548987918d851',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_fourleafclover2)
    },
    {
        id: 'aa9cacee0a2ea9b801085cbf1a736495',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_giantA)
    },
    {
        id: '04edde494c2272d5635e6f435907ec63',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_giantB)
    },
    {
        id: 'a267b93a3fa4a0b3d748d9410212b4a3',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_giantC)
    },
    {
        id: '9a96eddbadadaf2d95d6210a3e6a7689',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_giantD)
    },
    {
        id: '976af9e0cf8aff3367e84d3b105e58d1',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_giantE)
    },
    {
        id: 'a7153ff48389a5afcd6d2c902d3f8326',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_gianteyeA)
    },
    {
        id: '34a05401d70e4bb97162f7d431779eef',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_gianteyeB)
    },
    {
        id: '452c054e14000aca44bfb9d302af55b2',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_gianteyeC)
    },
    {
        id: '7ed05b2b3547b18782572407225347ff',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_gianteyeD)
    },
    {
        id: '0a064046f9ff9daffabb39c034e6f04d',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_gianteyeE)
    },
    {
        id: 'd2ff68e41c463eec09196d13763b6a7a',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_gianteyeF)
    },
    {
        id: '94e6eca7073ca22435e344c9ec17ca3b',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_giantF)
    },
    {
        id: 'c908d85cdce066626cb2e4e7b183b030',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_graph)
    },
    {
        id: '10feb3665f2b34cd306621d865bf2eb7',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_hand)
    },
    {
        id: '13df1243d7f4e604820ce9f36d3d2bdd',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_harp)
    },
    {
        id: 'd265fe2b1465545eef3bb620e32c1e7e',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_harp_string_A)
    },
    {
        id: '37d51a1f22e7186fe96f88d8de20f2e6',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_harp_string_B)
    },
    {
        id: 'd01161601a0852061cdb36f12859bf84',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_heart_beat)
    },
    {
        id: '1955ee62d83ab75f5e04687ae693f8e3',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_heart_face1)
    },
    {
        id: '19ab0722eece3f9a526eed0ad90542d1',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_heart_face2)
    },
    {
        id: '7ceb10fae565af02bedf8539bd17ccd3',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_heart_face3)
    },
    {
        id: '5eb49b448ef5b1a61c6f17fa576afbb0',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_low_point)
    },
    {
        id: '8589b884760deb5703a4f21cd0cf0387',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_high_Point)
    },
    {
        id: 'f949fb5822bc622a4ed072952f2b270e',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_ice_castle_A)
    },
    {
        id: '9deffcbad66371beaf03aafd53c244c5',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_ice_castle_B)
    },
    {
        id: '2c6858fed5cda0e19e4ae12b33dd8d67',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_ice_castle_C)
    },
    {
        id: '8b1c5934456cd598c96006ac3d0c5709',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_ice_castle_D)
    },
    {
        id: '65299d7999a4e82ec967c8131216e45b',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_infomation)
    },
    {
        id: '417c24a5b1d55b900c73dcb991db97fc',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_ink)
    },
    {
        id: '79637fb6ce49b7ebcc33b5a4d9130039',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_ink_balloon)
    },
    {
        id: '368924c78d742a151a518c2a7d5955c9',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_probo_instrumentcello)
    },
    {
        id: '3b586b2aba066ab6644f08d9ef80cd6e',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_probo_instrumentelectric_guitar)
    },
    {
        id: 'abbdb53bd98643abcd63294aa50c5d7f',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_probo_instrumentguitar)
    },
    {
        id: '1022da822c358c0296f558b2f08fda5b',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_probo_instrumentpiano)
    },
    {
        id: 'aaa3f0ba28bc6e9b27eba5298ffcb4bc',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_probo_instrumentSaxophone)
    },
    {
        id: 'b0e329bd8a0470a1ea1294c90ec45f3d',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_probo_instrumenttrombone)
    },
    {
        id: 'd1978bb1c9b456ffa6c5b14c61fdc931',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_instrumentbassoon)
    },
    {
        id: '76b90f7490800065c397a63437ee125c',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_interior_spaceship2)
    },
    {
        id: 'be2ec9cbccf782b62f8730de4174f0b2',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_Island_maze)
    },
    {
        id: '3d2f531ff3d271ed2130989a676bc0c4',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_item_clock)
    },
    {
        id: '9d3f04228c418b3265e8f6a421b00969',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_item_seaweed)
    },
    {
        id: '600de40590ed42f51ca5bc0c815d903f',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_item_vortex)
    },
    {
        id: 'e06842f2b8fa342ebf12d8f201e0dc5c',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_kitchen_knife)
    },
    {
        id: '881173099b1731ce4e8ba12aff598c14',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_knife_effect)
    },
    {
        id: '1f0e0c92fda2332b69e6f16a21fb59fd',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_landA)
    },
    {
        id: '7ca3cb1d17f2a701def81dbcacad5457',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_landB)
    },
    {
        id: '7478154c2b694854254090568dc882c7',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_laser_max1)
    },
    {
        id: 'bc50cdc75762abfc249e141fe99dd569',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_laser_max2)
    },
    {
        id: 'e7d88b6a43f310e7e37b1fdd07fe180e',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_laser5)
    },
    {
        id: '47b3709d170393366c480fa6846bbb44',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_laser6)
    },
    {
        id: '594da52dfc245f978895f5e30a168bd4',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_laser7)
    },
    {
        id: '33b50401d734e94d132962c71346cbdd',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_laser1)
    },
    {
        id: '24775cb17924c8f55393bdd4902e0728',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_laser2)
    },
    {
        id: '25e19405c613ab145d20729f851d7397',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_laser3)
    },
    {
        id: 'a9bbf4c5aebda6fd287269e4f55032e1',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_laser4)
    },
    {
        id: '53bb98860d24143c90673364c7df4cca',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_laser_gun1)
    },
    {
        id: 'bbd00e5c2f93de58532074fff83c3486',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_laser_gun2)
    },
    {
        id: '504f21ab597ce7e64004613037447f5b',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_lightning)
    },
    {
        id: 'c430802a8dac3f3d86e0a5f7d422b299',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_magazine_A)
    },
    {
        id: '4145ac357d0df3698ba3310f9d816b34',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_magazine_B)
    },
    {
        id: 'b6dcd22d351fcee5490dff9ccf30e96d',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_magazine_C)
    },
    {
        id: 'ef3a0e235bde9010b2c424db86a254df',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_magazine_D)
    },
    {
        id: '6bdc3b77bb523a96c31eaa25174c406c',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_magazine_E)
    },
    {
        id: '342d1641ecd9fa7e89cdd9344f54e541',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_magazine_F)
    },
    {
        id: '98f75f8987c9e81165d911fb8870419f',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_magazine_G)
    },
    {
        id: '62ac2ca41ded2c229aef94448eccf906',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_magazine_H)
    },
    {
        id: 'abe7781432d361f985a9871a53f248c4',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_magazine_I)
    },
    {
        id: '2b06a97e4ef459b17300c11332756806',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_musicnoteA)
    },
    {
        id: '7e71aec7c6d9564429291df680a86d1d',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_musicnoteB)
    },
    {
        id: '23069ee30715a27403212542d53c7b80',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_melodypointer)
    },
    {
        id: 'b85d45af13db533aaf4cb2f2ce0c11b0',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_meteorite)
    },
    {
        id: '145a099b5ebd36378db6961e194a33a8',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_middle_tree)
    },
    {
        id: '84654a1285020b323bfc111f4491fd9c',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_mineral_energy)
    },
    {
        id: '21002d7866a6200ff6f1ee478257afc7',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_mineral_energy2)
    },
    {
        id: '740bc3eed3e1e289cf92d6b9498f30a6',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_speed_item)
    },
    {
        id: '1568fd8ea9489825cca72286afa610c4',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_speed_item2)
    },
    {
        id: '30ef9e344ae886a3d02bb69e956c7e13',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_mineral_energy_bar)
    },
    {
        id: '746df9cda2e235edb76ef8930f9ce5e3',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_speed_bar)
    },
    {
        id: '834ad6a688e9d82f6763358b51496664',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_speed_bar2)
    },
    {
        id: 'e71b143fe8b5ceeab7c5ac7fd783998d',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_mineral_gauge)
    },
    {
        id: '46208d668a30957c5603a226872e3e86',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_speed_gauge)
    },
    {
        id: 'dccd3d92994f0aec68be9e9b017edd56',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_minimap)
    },
    {
        id: '2071fa5f2ecf9bc00c1e36c8eb9eb171',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_mymap)
    },
    {
        id: '46e0383f047c3f2987e08d3142460855',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_missile)
    },
    {
        id: 'f835049a627c412f6c566923c3051fcc',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_missile2)
    },
    {
        id: '729095289f2f15c9302ba47ed8d0fe7c',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_mission_fail)
    },
    {
        id: '7cb25832bad9beb057a18384ede7f242',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_mission_success)
    },
    {
        id: '5ef6159c4026313cadc731c45467f845',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_mole)
    },
    {
        id: 'e1f9e0dca1c0ccc18b74257d03af0387',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_mole_front)
    },
    {
        id: 'fb883506048521de22b819babd760c51',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_mosquito_A)
    },
    {
        id: '72613da23c535e29589e7ec4a76537ed',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_mosquito_B)
    },
    {
        id: '4b435f9b78ad04ea8eaebb55d0083fae',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_mosquito_C)
    },
    {
        id: '4076efb331fd785d9d0f21cb2bc283c2',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_mosquito_D)
    },
    {
        id: '3eb29aa473308650edc628b892331af2',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_mosquito_E)
    },
    {
        id: 'cb4fddd46c98ebac4fc2e2487db37a9e',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_mosquito_F)
    },
    {
        id: '67b5423e8d2e467c260a480225dd77ce',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_mosquito_G)
    },
    {
        id: '0fef734e602fccf81cdc6c950b41452a',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_mosquito_H)
    },
    {
        id: '1c9ea2b2f60e07015262b4624671548d',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_mouse)
    },
    {
        id: '05d42e22da93fb9c0deba2ccf486b3dc',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_music)
    },
    {
        id: '9e7fae30972992ea3b6a0b668bb511f6',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_music_A)
    },
    {
        id: 'd9b295ac6d12001f77ab04f3342fc5ce',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_music_B)
    },
    {
        id: '76946dd08521a6730dc75983884d92ad',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_music_C)
    },
    {
        id: '97b87dc6772752d608fb6cf36ca2b486',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_nail_blue)
    },
    {
        id: 'e3d8982baeef42cd229f3605c8c8813b',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_nail_green)
    },
    {
        id: 'c947d2e76fecbc2b31a8e6c78ac975ca',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_nail_red)
    },
    {
        id: 'eeae70907eac832a8eea0280fc1d1303',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_nail_white)
    },
    {
        id: '0b85afe4d1dc1082b59ead6b06254498',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_nail_yellow)
    },
    {
        id: '8abdec25a2ab0debfad9647cd797a11a',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_warped_nail_blue)
    },
    {
        id: '6d9fc0feb44c16705ea93889ae53698a',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_warped_nail_green)
    },
    {
        id: 'c2e16cdd4fd615f63196dcabd14ab77f',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_warped_nail_red)
    },
    {
        id: 'b47d2e7eb8b147d7cd6de4bf2f8cf1f5',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_warped_nail_white)
    },
    {
        id: 'a9c83b1a711a199a562773e9de2d76d5',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_warped_nail_yellow)
    },
    {
        id: '117cc0bc92e71a6447988ec8f2b96a85',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_obstacle1)
    },
    {
        id: 'aab0c4ef887bfd1fa4c882bbd039cadb',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_obstacle2)
    },
    {
        id: 'c49e72bdb2c8b82871d82dc7b2c23121',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_obstacle3)
    },
    {
        id: 'b20ad6fff368825665929d9cbb0973cd',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_obstacle4)
    },
    {
        id: '01793e4d5c7828067b35813d86d5952a',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_obstacle5)
    },
    {
        id: '98f0eb9d714dc2564d861ea9241d455f',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_obstacle6)
    },
    {
        id: '44e64edf892904d50e667022b62d4067',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_obstacle7)
    },
    {
        id: '6fd1c451a39d6a1a1a613962ce3d9bd2',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_obstacle8)
    },
    {
        id: '1c21a1dbe242acd9cbeb4e1865fc456e',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_obstacle9)
    },
    {
        id: '017f1107ee01a168f68562f4ad5c6de9',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_obstacle10)
    },
    {
        id: 'bdd25684faf422cd41f5b891c65cc286',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_obstacle11)
    },
    {
        id: '1aae81d1131a4521dfa9cb0e9ce1274a',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_obstacle12)
    },
    {
        id: '51608cc69bc5a63870358ddf3423fe39',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_obstacle13)
    },
    {
        id: 'f3beba379b1428f6e70c6a92e752fe77',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_obstacle14)
    },
    {
        id: 'dc6d9f435fec5a6c35271a9fa070e052',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_obstacle15)
    },
    {
        id: '849312886aed5d3930eceffacab192ba',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_obstacle16)
    },
    {
        id: '8c30eefbdd39eb95d02bcc38eaf055a8',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_obstacle17)
    },
    {
        id: '48b4bd799db69826d0b263499f841137',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_obstacle18)
    },
    {
        id: '1d384d4653e85fd3bd8c2e18f778f355',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_obstacle19)
    },
    {
        id: '5a385af2ed9c906d2c3cd8b3036f76f4',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_octopus)
    },
    {
        id: 'dfea4299f36f0bd0a0244b5c6ffb8ccf',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_orgel_A)
    },
    {
        id: '0d386f59dc1c40a86d3ca759899e2c63',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_orgel_B)
    },
    {
        id: '8cce19bd6a4b91ead93319896fa80f86',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_orgel_C)
    },
    {
        id: '639721998d94c57e5820d9116f0a0064',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_orgel_D)
    },
    {
        id: '20b4f7d79d45b5cfcd86b4d13b03212f',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_outer_wall)
    },
    {
        id: '678930a4ec996858de7a1715ca5090b1',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_m_panel)
    },
    {
        id: 'ddab13fc3cf499384cb721b2915a2bc2',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_panel)
    },
    {
        id: 'c08d659c169e579c38042fab5e0d5227',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_perforated_wall_A_1)
    },
    {
        id: 'e419de144e67ccc7f591784929561fe3',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_perforated_wall_A_2)
    },
    {
        id: 'fd21fe84a3837c9f9e59551baf672806',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_perforated_wall_A_3)
    },
    {
        id: 'de8c3ec10794e965fd87cd207510e89e',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_perforated_wall_A_4)
    },
    {
        id: 'd9540f37c6f1225d5108263fd403362a',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_perforated_wall_A_5)
    },
    {
        id: 'd2bf2922d373817c7787bfa495d139dd',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_perforated_wall_A_6)
    },
    {
        id: '2063292862f18f58d46784a310f99168',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_perforated_wall_A_7)
    },
    {
        id: '7ccdf3827137994f0a073e8db3ac14f2',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_perforated_wall_A_8)
    },
    {
        id: '226fcaa5e801943a4c9d6568ea902dd9',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_perforated_wall_A_9)
    },
    {
        id: '2336fcc8591dd7fb6d68d4bf5d37d9b5',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_perforated_wall_B_1)
    },
    {
        id: '2ec89c169828c4a75ec847ad34bd0688',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_perforated_wall_B_2)
    },
    {
        id: '5658f27de6a36ea23e527eb1534cedcd',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_perforated_wall_B_3)
    },
    {
        id: '3fc92e6cb6a2914e28cc58a89f3f3b34',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_perforated_wall_B_4)
    },
    {
        id: 'ee282e61b867de304249bb69dfb99a02',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_perforated_wall_B_5)
    },
    {
        id: '56d9db976db85bda336f7c23746fc163',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_perforated_wall_B_6)
    },
    {
        id: 'a107afd62ab46acced5fffa17a015c1d',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_perforated_wall_B_7)
    },
    {
        id: '87624197053b39f937d7ede9d52a5d59',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_perforated_wall_B_8)
    },
    {
        id: '49ff03d4d3d136ea82863acfae64ffbc',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_perforated_wall_B_9)
    },
    {
        id: 'e26495c20c71f8258eae806e505f6d0a',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_perforated_wall_C_1)
    },
    {
        id: '479a6ce62f7eeb0cf7437f8837f4e39c',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_perforated_wall_C_2)
    },
    {
        id: '44bcf2770d8e9ea942b1e7cdd77a17fb',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_perforated_wall_C_3)
    },
    {
        id: '70030b3cc8d37657e9804cfdbdd56bad',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_perforated_wall_C_4)
    },
    {
        id: '43aca250d02d8c64ec5f78f9c3bc18b2',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_perforated_wall_C_5)
    },
    {
        id: '33f69090cf2f264a133f9a653d5b61d7',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_perforated_wall_C_6)
    },
    {
        id: '73fd6e1bc20ddcd006408e83397c6329',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_perforated_wall_C_7)
    },
    {
        id: 'c90a31c5652f564d14dc3c47c79aebc0',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_perforated_wall_C_8)
    },
    {
        id: '3582aa9321234517591e9988efdc29ae',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_perforated_wall_C_9)
    },
    {
        id: 'da928926e4b628b63ba58a250136f5b4',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_plate)
    },
    {
        id: '36eb8da8b612e9f2a395edea71dd881b',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_point)
    },
    {
        id: '2499888e13705eb8034f03ffd471d275',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_poo)
    },
    {
        id: '498bba192a7481325d72e493c158047b',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_car)
    },
    {
        id: '7d7d1c2d93cd4df3ad0c66b48e9d6398',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_car_left)
    },
    {
        id: 'ec83ee55abf4e7e69567faaa0117d80b',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_car_non_wheel)
    },
    {
        id: '8b9927208ecd0d47ee75648a307722c6',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_car_right)
    },
    {
        id: 'df5fb36c4d213ea232a82d9b8726ecd6',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_car_top)
    },
    {
        id: '4479a965e22e5aadbd64ad03165f213b',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_energy)
    },
    {
        id: '11ddf8cd49507f4847a9934ee4c6df17',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_pu1)
    },
    {
        id: 'b591bd3e8d06531ded72448926234d5c',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_pu2)
    },
    {
        id: 'a7548c451c78b3771cbcfbca71e33988',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_pu3)
    },
    {
        id: '59de72842d3e9667bc35afbdcf937b5d',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_puppy_house_A)
    },
    {
        id: 'f262affbe469022d85c2261b6cb8b576',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_puppy_house_B)
    },
    {
        id: 'cc918e7c0365890c1c1f22da9f9315ef',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_puppy_house_C)
    },
    {
        id: 'c8015674652284f61a0ba64c0110b291',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_puppy_house_D)
    },
    {
        id: '6531a2296d3dc7032da5b64d82f993f1',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_puppy_house_E)
    },
    {
        id: 'b4c3f0b50175ed658cd800fcfd4e644d',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_puppy_house_F)
    },
    {
        id: '4819b4fb9b4def3ab803cd46a0189a7c',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_puppy_house_G)
    },
    {
        id: 'db382d0891e21c8f854ab69459d512d6',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_puppy_house_H)
    },
    {
        id: '08e021503c6e7d5692adf980d33b569b',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_puppy_house_I)
    },
    {
        id: '541606ed4aca1b9e00ee2a8c2ac48b52',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_puppy_house_J)
    },
    {
        id: 'e95a9dd7b65c4390f901f871b670dd49',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_rabbit_run_1)
    },
    {
        id: 'fc7ee3bd86d858150bbce0d5d8c46a0a',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_rabbit_run_2)
    },
    {
        id: 'b359330bf9d1b4578a8b3a2389bec7c3',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_rabbit_run_3)
    },
    {
        id: '864f468ae1230f43c2b92642ad40c46e',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_racing_car)
    },
    {
        id: 'e228c16fb1962e9cd7de117f659b3c99',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_racing_car1)
    },
    {
        id: '48156abb478906d095c35e7b41ea4ed3',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_racing_car2)
    },
    {
        id: '353786af2cadf7405cbbf23cc03e2be1',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_racing_car3)
    },
    {
        id: 'e5f3a75a7f7ba1970e79e168de8094f7',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_racing_car4)
    },
    {
        id: 'e726f71e48430e869df3aac5ccd58e62',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_racing_car5)
    },
    {
        id: '4a2aa77311c8a132c73cad936bf7844a',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_racing_car6)
    },
    {
        id: '039bc1cc559488a16297756317a2b815',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_racing_flag)
    },
    {
        id: '58814e6d7d723f7e79a259b46bca0757',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_radar_A)
    },
    {
        id: '3c28e8f609e83406ce8c36767b46ac83',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_radar_B)
    },
    {
        id: '6a4df6f3311c877ec02d96bb3a82c778',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_raindrop)
    },
    {
        id: '165976f176df65eb718f2068132f6f3b',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_rednote_Do)
    },
    {
        id: '34e1308c208014ca172391514ba990df',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_rednote_Do2)
    },
    {
        id: '8362a34d95904a6eb827e2af0701aa89',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_rednote_Fa)
    },
    {
        id: '757ce0d7137ecaddea4bb477e75c328f',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_rednote_La)
    },
    {
        id: 'bd7d086f7b652c7fc6a090464fcd53ae',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_rednote_Mi)
    },
    {
        id: '868e088251911fe1671f804226961bcc',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_rednote_Re)
    },
    {
        id: '610592b166add2bec5cdb17c1927f7c3',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_rednote_Si)
    },
    {
        id: '46aa36721a610c4f74b63c1b6ddd8c98',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_rednote_Sol)
    },
    {
        id: '3978850c020dbc4ccd842c1e12d19106',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_road1)
    },
    {
        id: '614d63294bccebba9a3d28cc9715f157',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_road2)
    },
    {
        id: '49dc8ccc7ac4896fdbd4c569a5f6312b',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_road3)
    },
    {
        id: '2beebb9cbc42be4c3340a9ebf9fe1b5a',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_road4)
    },
    {
        id: 'deb5f36746be6351d529b657d9f7254c',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_sand_dust_A)
    },
    {
        id: '3e8ebf62892c2292c48fb043ad1f6a93',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_sand_dust_B)
    },
    {
        id: 'c21d68bc517b108d889f4d172bc6b4a2',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_sandstorm_A)
    },
    {
        id: '6f3d1d9cc2edd4904a5e305ffcf2929f',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_sandstorm_B)
    },
    {
        id: 'f8928c1b23f847239da5915913100359',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_satellite1)
    },
    {
        id: 'eb056d9acbf7506fdb446311b6805400',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_satellite2)
    },
    {
        id: '1a6e1a1d6d0e69c37b368180f87cef35',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_satellite3)
    },
    {
        id: 'c1d614a3030ad09310bd54ae8a951283',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_satellite4)
    },
    {
        id: 'e3858c5ef9ea71ef9253da9113e7587d',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_satellite5)
    },
    {
        id: 'a75bba70034b2be3b9c9b14a3cae3c6c',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_satellite6)
    },
    {
        id: '7b0d483a89d6caa521fd7dfd11784d63',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_sea_wave)
    },
    {
        id: 'eda38a00ab71cdf6783b805b7619a8fb',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_select)
    },
    {
        id: '74d248ec028144cfe52b7cce6a54d60d',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_shapes_A)
    },
    {
        id: 'e8cf0b595fba9bde5cf14d01ecddace7',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_shapes_B)
    },
    {
        id: '6d3e85a91047d5876560773f61ea6f98',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_shapes_C)
    },
    {
        id: '74f8ae58217ef5a7ae8051dbc2b9a482',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_shapes_D)
    },
    {
        id: 'fc5469bd1712a36dec38f328e23da3bb',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_shapes_E)
    },
    {
        id: '93e4b78b6b49fbbe221fd61a650ebde0',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_shapes_F)
    },
    {
        id: '77a717232d5ab542ead900e819098837',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_shapes_list_A)
    },
    {
        id: '9c0a52f6f9f7ee3ecf572ce44d1f2e3a',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_shapes_list_B)
    },
    {
        id: '3c9c9d3b0cd38c0004adff245f5b7566',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_shapes_list_C)
    },
    {
        id: '299dba2007959b9c57253badcbd7ed98',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_shapes_list_D)
    },
    {
        id: '77ca89fc63f6df2482dadc4b11994f27',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_shapes_list_E)
    },
    {
        id: 'e97fb9e1b8f65a91967b29b7a16b7e89',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_shapes_list_F)
    },
    {
        id: 'f42d0f8d8f0b864a0cf4003390c602a8',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_small_boatA)
    },
    {
        id: '489a75d5fd2740b73287f0fec5f99237',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_small_boatB)
    },
    {
        id: '233ef5d3a0d7cd04b8f32914d6c0aeb1',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_small_boatC)
    },
    {
        id: '6a20d763c9297cafe7f4141865763cb5',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_small_boatD)
    },
    {
        id: '617f56fc3df4dcc867ab9941fa8b0822',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_ship_speed_gaugeA)
    },
    {
        id: 'f1d0acd134a8dacffffa22ed4fb4fd62',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_ship_speed_gaugeB)
    },
    {
        id: 'dc08ebe49369739aa91d5ad8dd42d056',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_ship_speed_gaugeC)
    },
    {
        id: 'b984873aeaf521ffcc6f684b1151b7e0',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_ship_speed_gaugeD)
    },
    {
        id: '47e7b13d2b074a3f2a41ccb70c9ccfa7',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_ship_speed_gaugeE)
    },
    {
        id: '0aff60e747751ac0d519b806792f14ff',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_ship_speed_gaugeF)
    },
    {
        id: '6d6b3f7cfbef0b73b2fdfebae65503c9',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_sign)
    },
    {
        id: '6142cf1c4266932f95a7a30e5db740a8',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_small_tree)
    },
    {
        id: '7598a5a756a30853f380abc9680dd447',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_smoke)
    },
    {
        id: '4012e93911010720d8f5a18cd0028cba',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_snake)
    },
    {
        id: 'b1b94b073deceb2ce61c55e6c8db9342',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_snowball)
    },
    {
        id: 'ab379f8fbfafe206cee11bcb7e093082',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_space_pirate_top1)
    },
    {
        id: '221c4abca56e2dba46919f59d753579e',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_space_pirate_top2)
    },
    {
        id: '40216c5e838f486b5b7b95c70ecd2577',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_space_pirate_top3)
    },
    {
        id: 'ad8b144f11220635b54fc6e1405ac0af',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_space_pirate_top4)
    },
    {
        id: 'ed654f142324455eae64744156639f1b',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_space_pirate_top5)
    },
    {
        id: '8dd7d225a01df0b90b47e0ab43275e9f',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_space_pirate_top6)
    },
    {
        id: 'ab3b13176d70d1ee05b935d9e9a66ddb',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_space_pirate1_1)
    },
    {
        id: '485d7feeb112c20c601308d5d794d79e',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_space_pirate1_2)
    },
    {
        id: 'e579683185a2137d628edf506c1f3e95',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_space_pirate2_1)
    },
    {
        id: '7fd99519d949bb46947384f203986759',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_space_pirate2_2)
    },
    {
        id: 'd560792ae11c75aa4b94a9157680fbc8',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_space_pirate3_1)
    },
    {
        id: '8c8d8e461f75b93818fe4dd19e647ab3',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_space_pirate3_2)
    },
    {
        id: '70a14c6bc3d9d7a7b274066809d8591e',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_space_pirate4_1)
    },
    {
        id: '1475ab7e0493fcefc55b9646683d9c70',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_space_pirate4_2)
    },
    {
        id: '9faf7f0000b69e56808c65d293b94480',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_space_pirate5_1)
    },
    {
        id: '87a7485df6c043fedff6bec55e244543',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_space_pirate5_2)
    },
    {
        id: '6b676deabc738fa564114da3ab16e5d8',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_space_pirate_boss1_1)
    },
    {
        id: 'c89ba63e3fd3424cd1159262fb14d4f6',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_space_pirate_boss1_2)
    },
    {
        id: '7f93f0f81da437a1954ba25a6bd7946c',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_space_pirate_boss2_1)
    },
    {
        id: '783b8b3f7cc76cc96e80b8cc2990067c',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_space_pirate_boss2_2)
    },
    {
        id: 'dc1af9e535b619338b14886aa6db27d1',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_space_station)
    },
    {
        id: 'ab09eab0c30dd86b35c54fc5eac147c0',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_space_station2)
    },
    {
        id: '25440f634dd0f324c041a5fe1770612f',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_space_trash1_1)
    },
    {
        id: '0750c511911782b3bced2c87580cdef0',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_space_trash1_2)
    },
    {
        id: '845c36be18b665ff718c07b3bc23b717',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_space_trash2_1)
    },
    {
        id: '038f9ad0c0b9ec4c6cb1e2791e844d4e',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_space_trash2_2)
    },
    {
        id: 'e9a34119995d0e9419a0c4bbe49ba2bf',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_space_trash3_1)
    },
    {
        id: '8f23ee7278abdac41bc91575b64c98a5',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_space_trash3_2)
    },
    {
        id: '7d613b6d05dd9cd98d6937a0d1d10192',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_space_trash4_1)
    },
    {
        id: 'c293fd6b7e454b52d58bcf78dec3f71b',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_space_trash4_2)
    },
    {
        id: 'cb6a1527f24092e1768db5117fa7d6b6',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_space_trash5_1)
    },
    {
        id: 'bedabe5afab80d08a92a86701c73af5c',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_space_trash5_2)
    },
    {
        id: 'c83cab96277249fc40583f688b1b9ca3',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_spacecraft1)
    },
    {
        id: 'eda8f253c9f487018f464042d8b81a32',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_spacecraft2)
    },
    {
        id: 'e1f434e27eac049c097dd56174f78147',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_spacecraft3)
    },
    {
        id: '5b37915bdb9745498e989c76ba1b5efd',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_spacecraft4)
    },
    {
        id: '97b6c16faf1173b6cdf362c8234ba315',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_spacecraft5)
    },
    {
        id: '4223a692b35d09280194299d841b142b',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_spacecraft_energy)
    },
    {
        id: 'e0c49ff1f0da993f069147f62b08eb2d',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_spacecraft_energy2)
    },
    {
        id: '6928a01597cebc20152070b1da0d20ac',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_spacecraft_parts1_1)
    },
    {
        id: '4fb74b7b80e87923938e3f831239152f',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_spacecraft_parts1_2)
    },
    {
        id: 'b637dd5490ea963a06be731a8b827eea',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_spacecraft_parts1_3)
    },
    {
        id: 'a0a2b5a704ef149cecc085644a5c4cf6',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_spacecraft_parts2_1)
    },
    {
        id: '1f83755b41ab76f53d470a916a26ac9f',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_spacecraft_parts2_2)
    },
    {
        id: '6e3cfb149d05de594e8b5f736e1e10c8',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_spacecraft_parts2_3)
    },
    {
        id: 'eac07c61edf0ec0abbc5478da069396b',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_spacecraft_parts3_1)
    },
    {
        id: '22b5be6795663e4270c83953316992b2',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_spacecraft_parts3_2)
    },
    {
        id: '8612289129be55141e683729308cadbd',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_spacecraft_parts3_3)
    },
    {
        id: '1a32c46893a333167d74492c7b8917b7',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_spaceship)
    },
    {
        id: '1f2d577d86267b09014ba89589ce422b',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_spaceship_back)
    },
    {
        id: '98014622232eff5f5635737243c29b59',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_spaceship_effect1)
    },
    {
        id: 'be5fbd5531197c906fdffc38c407c999',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_spaceship_effect2)
    },
    {
        id: '801406c36f522740a4f29d1d600b207a',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_sparrow_A)
    },
    {
        id: '5dfbb2c164b3283d1a4fd2ba3ff6494d',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_sparrow_B)
    },
    {
        id: '38f55734a5d4faab3a18f8e4658c6551',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_sparrows_A)
    },
    {
        id: '3977682243a2c10d3194bccc53100fb1',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_sparrows_B)
    },
    {
        id: '1ff2ecaadbd9f4392fba10eb59141c58',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_speed_effect)
    },
    {
        id: '61bdb3814e0dd818552324f1f38829fb',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_speed_Effect2)
    },
    {
        id: '3dffd46b98233751530b43b89bf0a849',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_spider)
    },
    {
        id: '76541f2e19b15f0d56262f545dc87964',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_stalactite)
    },
    {
        id: '1078dbd4d47a2b6c0ecb07ef1b343ea3',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_stalkA)
    },
    {
        id: 'be62c4b7090c7f2e6aa639a635a549d7',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_stalkB)
    },
    {
        id: 'bc5f72214d73cd03a0f5ccd158e7c8a7',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_stalkC)
    },
    {
        id: '6a5ec5ddf96f22a1fb444dc923d66592',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_star)
    },
    {
        id: '8b2dc900574bc04c0a8cf9659b140e6a',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_stone)
    },
    {
        id: 'ed4f51326cc3bd07c8d43b08aeb33b1a',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_submarine_back)
    },
    {
        id: 'fb6d929b9902ddea3e394b3617686758',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_submarine_left_trouble)
    },
    {
        id: '0ed9aec55a22002f6c8b3d3eb963600e',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_submarine_right_trouble)
    },
    {
        id: '1aedc133f2736ed4aa5b8b2d3bb64c97',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_tank1)
    },
    {
        id: '86f91288ed3dde95dc78e2bbee12498a',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_tank2)
    },
    {
        id: '973249ff9ffe53f2602e4df071658314',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_tank3)
    },
    {
        id: 'a45c598544651423829a35cf8850d2f9',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_tank4)
    },
    {
        id: '6ec1121028c33ddbb4d25102f1fe225a',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_target)
    },
    {
        id: '5bd6c6b550ddf4839b364aa955936e06',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_target2)
    },
    {
        id: '38645a5d334c18d771d9e69383b919d8',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_target_board_A)
    },
    {
        id: '803ccb639624cda7d29c9bf099a47fe4',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_target_board_B)
    },
    {
        id: '50ddfe52f5b3d3376df5953d9a7398ce',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_targetboard1)
    },
    {
        id: 'c8ea970a3d65b73db2464089832b193e',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_targetboard2)
    },
    {
        id: '51724f0c533a01d0dce42d74030ddd50',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_targetboard3)
    },
    {
        id: '169583d38a3262b221dea124e73bdbb7',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_targetboard4)
    },
    {
        id: '52cfc440fad82dd6860acac7724d76e2',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_targetboard5)
    },
    {
        id: 'ad50aff4c9f9a99e91d39cb0ebeeb303',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_targetboard6)
    },
    {
        id: '20cc672aa39cc68323a90f8205aa6571',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_targetboard7)
    },
    {
        id: '4749c896fcdf9a266d5642368a639c0a',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_targetboard8)
    },
    {
        id: 'e21209f8e672dfdafc17d7a6a23d2fb6',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_targetboard9)
    },
    {
        id: 'fd50fb9aec50a94cc97fe37588ac2c8c',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_targetboard10)
    },
    {
        id: '36b84f4909a8f1b8b2f86d356d469e19',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_targetboard11)
    },
    {
        id: 'c055bd861e3b360a82617676133866f0',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_time_machine_A)
    },
    {
        id: '31d9921ba63bab49a2db13461ccfda09',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_time_machine_B)
    },
    {
        id: 'c269c6e3362624e97db2030c469788d8',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_time_machine_C)
    },
    {
        id: 'd300b07e114ef213fbdff7f000aa6a44',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_outer_lane)
    },
    {
        id: 'f60d8efb8e3389e70d15ae50afaa73c7',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_start_line)
    },
    {
        id: '13a33de1973dca9c27d618aca1729801',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_traffic_lane)
    },
    {
        id: '422a44fb41b455223fcd65b1a7ed4b06',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_treasureA)
    },
    {
        id: 'f2478376630b4c36162a74ed500afbf8',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_treasureB)
    },
    {
        id: '3cfd33dac40d035120275a9056fc4f04',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_triceratops_angry)
    },
    {
        id: '9af6609435712d590e5de1e896e09888',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_triceratops_eye)
    },
    {
        id: 'b9fd1aa4114f67dc3b7943471403486b',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_triceratops_sleep)
    },
    {
        id: '2566e4c6bea7c098871c7989425557fa',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_UFO)
    },
    {
        id: '6a3435b24416647c82c99d6f9ccda5de',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_underground_tunnel)
    },
    {
        id: '0e9b7a5162d524a67d057f61074d8fb8',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_avocado)
    },
    {
        id: '78c88ea33b4a11eb84c09a64f6c575bc',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_banana)
    },
    {
        id: '68a8f512a2ec8bb2f54327d4c3d1105b',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_carrot)
    },
    {
        id: '7fd7b268a710ce5019218e70d9a09286',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_cabbage)
    },
    {
        id: '94b6d4d6b35114f3b68674ad6cc3a071',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_grape)
    },
    {
        id: 'bb2f406eaa766bae194e2b323308b802',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_onion)
    },
    {
        id: 'ae7959ffe6da2e481a5d63a0d23e85cd',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_paprika)
    },
    {
        id: 'a1691e84c4843051e6c46cd908c7347e',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_watermelon)
    },
    {
        id: 'e49a9dcbfada01ca81940585c7379837',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_vegetable_basket)
    },
    {
        id: 'b4c1e55cc62b1828c172be28982152e5',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_water_A)
    },
    {
        id: '839c4b965fa3430318899aec4ae89a1b',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_water_B)
    },
    {
        id: '7251caf89ed9a7adc86b8f88eb1e5a08',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_water_C)
    },
    {
        id: '5cfac45f721b7009dc413a07fc45652e',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_welding_machine1)
    },
    {
        id: '43e4de06214a2d32dc1fcbef8043134a',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_welding_machine2)
    },
    {
        id: '34fc7f0e53d52c7be333430eef6dd463',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_car_wheel)
    },
    {
        id: 'acf7823d954429067e8ed1b2736c50d9',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_wheel_line)
    },
    {
        id: '7aedd4e7e41b8772dbd14e08b605ca98',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_black_hole)
    },
    {
        id: 'a4f3326eecffa3435399e6ab17a80b27',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_white_hole)
    },
    {
        id: '623b6fe3d88ea1545078dbcb87ffd2fa',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_wild_boar_A)
    },
    {
        id: '9b0ed05620c3ed8dfe28a48cb7f18f81',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_wild_boar_B)
    },
    {
        id: 'fa8da39f0240a1eae930ec9f46b6074d',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_wood_fire)
    },
    {
        id: '00bdd81847e18b3049d2b0c6ed0a282a',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_wood_stick)
    },
    {
        id: '235ada608c86dfd9dcd58d4042008dd4',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_worm_hole1)
    },
    {
        id: '7809e177b3f7e5c2a2cce3af7fe53a4f',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_worm_hole2)
    },
    {
        id: '3a383aff6cfebe6d546021c9aa697984',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_xylophone)
    },
    {
        id: '37f3d9f12888d4833527e0e48a6619a0',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_xylophone_stick)
    },
    {
        id: '8dd5ebf31f9384ae123ed85b3bd86420',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_yellownote_do)
    },
    {
        id: '39f322c1e30a68ff1e4be1722884befb',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_yellownote_do2)
    },
    {
        id: '6168a15c9b7d36a059c02ebbb05047b6',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_yellownote_fa)
    },
    {
        id: '6d05a101c02749a02b8535ae156b336c',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_yellownote_la)
    },
    {
        id: 'c20628c34b0232903cf3d31719bcb8a1',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_yellownote_mi)
    },
    {
        id: '81eba9068c3b38d4b4890356ee4f3f6e',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_yellownote_re)
    },
    {
        id: '1f0b726f783fbd78a12f5e75d955c663',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_yellownote_si)
    },
    {
        id: '312a2556170d843b2a80f747213e18db',
        assetType: 'ImageVector',
        dataFormat: 'SVG',
        data: encoder.encode(probo_yellownote_sol)
    }
    ];
};

export default defaultProject;
