import React from 'react';

import styles from './auto-close-switch.css';
import IconLock from './icon_lock.svg';
import IconUnlock from './icon_unlock.svg';

const AutoCloseSwitchComponent = props => (
    <div className={styles.container}>
        <label className={styles.switch}>
            <input
                className={styles.checkbox}
                type="checkbox"
                checked={props.autoClose}
                onChange={e => props.onAutoCloseChange(e.target.checked)}
                value={props.autoClose}
            />
            {props.autoClose ? <img src={IconLock} /> : <img src={IconUnlock} />}
        </label>
    </div>
);

export default AutoCloseSwitchComponent;
