import React from 'react';
import Box from '../../box/box.jsx';
import MalrangSpriteItem from '../../../containers/malrang-sprite-item.jsx';

import styles from './malrang-sprite-list.css';

const MalrangSpriteList = function (props) {
	const {
		items,
		initialSpriteId,
		isSpriteHidden,
		setSpriteVisibleOnTargetPane,
		setInitialSprite,
		type,
		isSpriteHideFromStage,
		onClickHideFromStageCheckbox,
		tooltip
	} = props;

	const [spriteWrapper, setSpriteWrapper] = React.useState(null);

	const setRef = React.useCallback((spriteWrapperNode) => {
		if (spriteWrapperNode) {
			setSpriteWrapper(spriteWrapperNode);
		}
	}, [setSpriteWrapper]);

	return (
		<Box className={styles.scrollWrapper}>
			<Box className={styles.itemsWrapper} componentRef={setRef}>
				{items.map((sprite) => {
					return (
						<MalrangSpriteItem
							key={sprite.id}
							sprite={sprite}
							initialSpriteId={initialSpriteId}
							isSpriteHidden={isSpriteHidden && isSpriteHidden(sprite.id)}
							setSpriteVisibleOnTargetPane={setSpriteVisibleOnTargetPane}
							setInitialSprite={setInitialSprite}
							type={type}
							isSpriteHideFromStage={isSpriteHideFromStage(sprite.id)}
							onClickHideFromStageCheckbox={onClickHideFromStageCheckbox}
							spriteWrapper={spriteWrapper}
							tooltip={tooltip}
						/>
					);
				})}
			</Box>
		</Box>
	);
};

export default MalrangSpriteList;
