const SET_ID = 'scratch-gui/connection-modal/setId';
const SET_UPDATE_HEX = 'scratch-gui/connection-modal/setUpdateHex';

const initialState = {
    extensionId: null,
    updateHex: false
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
    case SET_ID:
        return Object.assign({}, state, {
            extensionId: action.extensionId
        });
    case SET_UPDATE_HEX:
        return Object.assign({}, state, {
            updateHex: action.updateHex
        });
    default:
        return state;
    }
};

const setConnectionModalExtensionId = function (extensionId) {
    return {
        type: SET_ID,
        extensionId: extensionId
    };
};

const setUpdateHex = function (updateHex) {
    return {
        type: SET_UPDATE_HEX,
        updateHex: updateHex
    };
}

export {
    reducer as default,
    initialState as connectionModalInitialState,
    setConnectionModalExtensionId,
    setUpdateHex
};
