import TutorialSuccessModalComponent from "../components/stage/tutorial-success-modal.jsx";
import React from 'react';
import { connect } from "react-redux";
import { closeFailDialog, closeSuccessDialog } from "../reducers/malrang-tutorial.js";
import { requestTutorialSb3File, sendTutorialOutcomes } from "../semobae_utils/semobae_utils.js";
import bindAll from "lodash.bindall";
import PropTypes from 'prop-types';
import { setShouldPreventSetSemoclassParams } from "../reducers/semobae-state.js";

class TutorialSuccessModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLastTutorial: false,
        }
        bindAll(this, [
            'handleClickTutorialNextStepButton',
            'handleClickTutorialRestartButton',
            'sendSuccessTutorial',
        ]);
    }
    componentDidMount() {
        const { examIndexAndLength, unitIndexAndLength } = this.props.semoclassParams;
        if (examIndexAndLength && unitIndexAndLength) {
            const [currentExamIndex, examLength] = examIndexAndLength;
            const [currentUnitIndex, unitLength] = unitIndexAndLength;
            if (currentExamIndex === examLength - 1 && currentUnitIndex === unitLength - 1) {
                this.setState({ isLastTutorial: true });
            }
        }

        this.props.vm.runtime.shouldBroadcastTutorialOutcomes = false;
    }
    componentDidUpdate(prevProps) {
        if (prevProps.semoclassParams.assignmentId !== this.props.semoclassParams.assignmentId) {
            this.props.closeSuccessDialog();
        }
    }
    clearTutorialDialog() {
        this.props.closeSuccessDialog();
        this.props.closeFailDialog();
    }
    handleClickTutorialNextStepButton () {
        requestTutorialSb3File(this.props.semoclassParams);
        this.clearTutorialDialog();
        this.props.updateShouldPreventSetSemoclassParams(false);
    }
    handleClickTutorialRestartButton() {
        this.props.onStartLoadingTemplateFile();
        this.clearTutorialDialog();
    }
    sendSuccessTutorial () {
        return sendTutorialOutcomes(true, this.props.semoclassParams);
    }
    render() {
        return (
            <TutorialSuccessModalComponent 
                onClickTutorialNextStepButton={this.handleClickTutorialNextStepButton}
                onClickTutorialRestartButton={this.handleClickTutorialRestartButton}
                sendSuccessTutorial={this.sendSuccessTutorial}
                isLastTutorial={this.state.isLastTutorial}
            />
        )
    }
}
const mapStateToProps = state => ({
    openSuccessDialog: state.scratchGui.malrangTutorialState.openSuccessDialog,
    openFailDialog: state.scratchGui.malrangTutorialState.openFailDialog,
    semoclassParams: state.scratchGui.semobaeState.semoclassParams,
    vm: state.scratchGui.vm,
});

const mapDispatchToProps = dispatch => ({
    closeSuccessDialog: () => dispatch(closeSuccessDialog()),
    closeFailDialog: () => dispatch(closeFailDialog()),
    updateShouldPreventSetSemoclassParams: shouldPrevent =>
        dispatch(setShouldPreventSetSemoclassParams(shouldPrevent)),
});

TutorialSuccessModal.propTypes = {
    onStartLoadingTemplateFile: PropTypes.func,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TutorialSuccessModal);