import PropTypes from 'prop-types';
import React from 'react';
import { defineMessages, injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';

import styles from './mecanumwheel-modal.css';

import {
    MOTOR_TYPE,
    MOTOR_DIRECTION
} from 'scratch-vm/src/extensions/scratch3_mecanumWheel/constants.js';

const messages = defineMessages({
    frontLeftMotorLabel: {
        id: 'gui.mecanumWheelModal.frontLeftMotorLabel',
        defaultMessage: 'Front Left Motor',
        description: 'Label for the front left motor'
    },
    frontRightMotorLabel: {
        id: 'gui.mecanumWheelModal.frontRightMotorLabel',
        defaultMessage: 'Front Right Motor',
        description: 'Label for the front right motor'
    },
    rearLeftMotorLabel: {
        id: 'gui.mecanumWheelModal.rearLeftMotorLabel',
        defaultMessage: 'Rear Left Motor',
        description: 'Label for the rear left motor'
    },
    rearRightMotorLabel: {
        id: 'gui.mecanumWheelModal.rearRightMotorLabel',
        defaultMessage: 'Rear Right Motor',
        description: 'Label for the rear right motor'
    },
    servoMotorForwardOption: {
        id: 'gui.mecanumWheelModal.servoMotorForwardOption',
        defaultMessage: 'S Motor Forward',
        description: 'Option for the servo motor direction'
    },
    servoMotorReverseOption: {
        id: 'gui.mecanumWheelModal.servoMotorReverseOption',
        defaultMessage: 'S Motor Reverse',
        description: 'Option for the servo motor direction'
    },
    dcMotorForwardOption: {
        id: 'gui.mecanumWheelModal.dcMotorForwardOption',
        defaultMessage: 'DC Motor Forward',
        description: 'Option for the dc motor direction'
    },
    dcMotorReverseOption: {
        id: 'gui.mecanumWheelModal.dcMotorReverseOption',
        defaultMessage: 'DC Motor Reverse',
        description: 'Option for the dc motor direction'
    },
    pinNumberFirst: {
        id: 'gui.mecanumWheelModal.pinNumberFirst',
        defaultMessage: 'PinNumber 1',
        description: 'Option for the pin number'
    },
    pinNumberSecond: {
        id: 'gui.mecanumWheelModal.pinNumberSecond',
        defaultMessage: 'PinNumber 2',
        description: 'Option for the pin number'
    },
    pinNumberThird: {
        id: 'gui.mecanumWheelModal.pinNumberThird',
        defaultMessage: 'PinNumber 3',
        description: 'Option for the pin number'
    },
    pinNumberFourth: {
        id: 'gui.mecanumWheelModal.pinNumberFourth',
        defaultMessage: 'PinNumber 4',
        description: 'Option for the pin number'
    },
    duplicateWarn: {
        id: 'gui.mecanumWheelModal.warn.duplicate',
        defaultMessage: 'Duplicate',
        description: 'Warning for the duplicate pin number'
    }
});

const MOTOR_SELECTOR_CLASS_NAME = {
    [MOTOR_TYPE.DC]: 'dc-motor',
    [MOTOR_TYPE.SERVO]: 'servo-motor'
};
const MotorSelectBox = props => {
    if (props.motor === undefined) {
        return null;
    }
    const motorSelectorClassName = MOTOR_SELECTOR_CLASS_NAME[props.motor.type];
    return (
        <div className={classNames(styles.motorSelectBox, styles[props.position])}>
            <label>
                {props.intl.formatMessage(messages[`${props.position}MotorLabel`])}
            </label>
            <select
                className={classNames(styles.selectMotor, styles[motorSelectorClassName])}
                value={props.compositeMotorTypeAndDirection(props.motor.type, props.motor.direction)}
                onChange={(event) => props.onChangeWheelTypeAndDirection(event, props.position)}
            >
                <option value={props.compositeMotorTypeAndDirection(MOTOR_TYPE.SERVO, MOTOR_DIRECTION.FORWARD)}>
                    {props.intl.formatMessage(messages.servoMotorForwardOption)}
                </option>
                <option value={props.compositeMotorTypeAndDirection(MOTOR_TYPE.SERVO, MOTOR_DIRECTION.REVERSE)}>
                    {props.intl.formatMessage(messages.servoMotorReverseOption)}
                </option>
                <option value={props.compositeMotorTypeAndDirection(MOTOR_TYPE.DC, MOTOR_DIRECTION.FORWARD)}>
                    {props.intl.formatMessage(messages.dcMotorForwardOption)}
                </option>
                <option value={props.compositeMotorTypeAndDirection(MOTOR_TYPE.DC, MOTOR_DIRECTION.REVERSE)}>
                    {props.intl.formatMessage(messages.dcMotorReverseOption)}
                </option>
            </select>
            <select
                className={styles.selectNumber}
                value={props.motor.pinNumber}
                onChange={(event) => props.onChangeWheelPinNumber(event, props.position)}
            >
                <option value={1}>
                    {props.intl.formatMessage(messages.pinNumberFirst)}
                </option>
                <option value={2}>
                    {props.intl.formatMessage(messages.pinNumberSecond)}
                </option>
                <option value={3}>
                    {props.intl.formatMessage(messages.pinNumberThird)}
                </option>
                <option value={4}>
                    {props.intl.formatMessage(messages.pinNumberFourth)}
                </option>
            </select>
            {props.motor.isDuplicate ? (
                <div className={styles.duplicateWarnWrapper}>
                    <span className={styles.duplicateWarnContent}>
                        {props.intl.formatMessage(messages.duplicateWarn)}
                    </span>
                </div>
            ) : null}
        </div>
    )
};

MotorSelectBox.propTypes = {
    intl: intlShape,
    position: PropTypes.string.isRequired,
    motor: PropTypes.shape({
        type: PropTypes.string,
        direction: PropTypes.string,
        pinNumber: PropTypes.number,
        isDuplicate: PropTypes.bool
    }),
    compositeMotorTypeAndDirection: PropTypes.func.isRequired,
    onChangeWheelTypeAndDirection: PropTypes.func.isRequired,
    onChangeWheelPinNumber: PropTypes.func.isRequired,
};

MotorSelectBox.defaultProps = {};

export default injectIntl(MotorSelectBox);
