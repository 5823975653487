import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import styles from './check-button.css';
import checkIcon from './check-icon.svg';

const checkButton = props => (
    <div
        aria-label="check"
        className={classNames(
            // styles.checkButton,
            props.className
        )}
        role="button"
        tabIndex={props.tabIndex}
        onClick={props.onClick}
        style={{width: props.size, height: props.size}}
    >
        <div className={classNames(styles.checkButtonVisible, props.checked && styles.checked)} style={{width: props.size, height: props.size}}>
            <img
                className={props.checked ? styles.checkIcon : styles.noChecked }
                src={checkIcon}
                width={props.size}
                height={props.size}
            />
        </div>
        <span>{props.label}</span>
    </div>
);

checkButton.propTypes = {
    className: PropTypes.string,
    onCheckButtonClick: PropTypes.func.isRequired,
    tabIndex: PropTypes.number,
    label: PropTypes.string,
};

checkButton.defaultProps = {
    tabIndex: 0
};

export default checkButton;
