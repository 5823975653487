import React from 'react';
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import CustomInputMonitorComponent from '../components/custom-input-popup/custom-input-monitor.jsx';

import monitorAdapter from '../lib/monitor-adapter.js';

class CustomInputMonitor extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleOnClickMenu',
            'handleOnClickItem',
            'handleOnClickOutside'
        ]);
        
        this.state = {
            isMenuOpen: false
        };
    }

    handleOnClickOutside () {
        this.handleOnClickMenu();
    }

    handleOnClickMenu () {
        if (this.state.isMenuOpen) {
            document.removeEventListener('click', this.handleOnClickOutside);
        } else {
            document.addEventListener('click', this.handleOnClickOutside);
        }
        this.setState(state => {
            state.isMenuOpen = !state.isMenuOpen;
            return state;
        });
    }

    handleOnClickItem (sensor) {
        this.props.vm.runtime.monitorBlocks.changeBlock({
            id: this.props.id,
            element: 'field',
            name: 'SENSOR_SELECT',
            value: sensor
        });
        document.removeEventListener('click', this.handleOnClickOutside);
        this.setState(state => {
            state.isMenuOpen = false;
            return state;
        });
    }

    render () {
        // Cover error occuring before it fixed by malrang extension.
        if (!this.props.params.ANALOG_INPUTS) {
            this.props.params.ANALOG_INPUTS = '_';
        }
        if (!this.props.params.SENSOR_SELECT) {
            this.props.params.SENSOR_SELECT = 'SWITCH';
        }
        const monitorProps = monitorAdapter(this.props);
        // Overwrite label as analog input parameter.
        monitorProps.label = 'A'.concat(this.props.params.ANALOG_INPUTS.slice(-1)).concat(':');
        return (
            <CustomInputMonitorComponent
                {...monitorProps}
                isMenuOpen={this.state.isMenuOpen}
                sensorSelected={this.props.params.SENSOR_SELECT}
                onClickMenuHandler={this.handleOnClickMenu}
                onClickItemHandler={this.handleOnClickItem}
                monitorName={this.props.monitorName}
            />
        );
    }
}

CustomInputMonitor.propTypes = {
    id: PropTypes.string.isRequired,
    opcode: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
};

export default CustomInputMonitor;