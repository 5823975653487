import React from 'react';
import PropTypes from 'prop-types';
import bindAll from 'lodash.bindall';
import VM from 'scratch-vm';
import {connect} from 'react-redux';
import MicSnackbarComponent from '../components/mic-snackbar/mic-snackbar.jsx';

const FADEOUT_ANIMATION_DURATION = 1000;
class MicSnackbar extends React.Component {

    constructor (props) {
        super(props);

        bindAll(this, [
            '_waveUpdateListener',
            '_startRecording',
            '_stopRecording',
            '_showSnackbar',
            '_hideSnackbar'
        ]);

        this.state = {
            waveData: [],
            isRecording: false,
            isSnackbarShown: false,
            fadeout: false,
            recordSeconds: 0,
            recordProgress: 0
        };
    }

    componentDidMount () {
        this.props.vm.runtime.ioDevices.audio.attachWaveUpdateListener(this._waveUpdateListener);
        this.props.vm.runtime.addListener('MIC_RECORDING_START', this._startRecording);
        this.props.vm.runtime.addListener('MIC_RECORDING_STOP', this._stopRecording);
        this.props.vm.runtime.addListener('MIC_SNACKBAR_SHOW', this._showSnackbar);
        this.props.vm.runtime.addListener('MIC_SNACKBAR_HIDE', this._hideSnackbar);
    }

    componentWillUnmount () {
        this.props.vm.runtime.ioDevices.audio.detachWaveUpdateListener(this._waveUpdateListener);
        this.props.vm.runtime.removeListener('MIC_RECORDING_START', this._startRecording);
        this.props.vm.runtime.removeListener('MIC_RECORDING_STOP', this._stopRecording);
        this.props.vm.runtime.removeListener('MIC_SNACKBAR_SHOW', this._showSnackbar);
        this.props.vm.runtime.removeListener('MIC_SNACKBAR_HIDE', this._hideSnackbar);
    }

    _waveUpdateListener (time, data) {
        this.setState({
            // 낮은 음역대의 데이터만 사용하기위해 4로 나눠줌.
            waveData: Array.from(data.slice(0, data.length / 4))
        });
    }

    _startRecording (seconds) {
        if (this.state.isRecording) {
            return;
        }
        this.recordAnimationInterval = setInterval(() => {
            this.setState(state => {
                if (state.recordProgress < 100) {
                    return {recordProgress: state.recordProgress + 1};
                }
            });
        }, seconds * 10);
        this.setState({
            isRecording: true
        });
    }

    _stopRecording () {
        clearInterval(this.recordAnimationInterval);
        this.recordAnimationInterval = null;
        this.setState({
            isRecording: false,
            recordProgress: 0
        });
    }

    _showSnackbar () {
        this.setState({
            isSnackbarShown: true,
            fadeout: false
        });
    }

    _hideSnackbar () {
        this.setState({
            isSnackbarShown: false,
            fadeout: true
        });
        setTimeout(() => {
            this.setState({
                fadeout: false
            });
        }, FADEOUT_ANIMATION_DURATION);
    }

    render () {
        return (<MicSnackbarComponent
            waveData={this.state.waveData}
            isRecording={this.state.isRecording}
            isSnackbarShown={this.state.isSnackbarShown}
            recordProgress={this.state.recordProgress}
            fadeout={this.state.fadeout}
        />);
    }
}

MicSnackbar.propTypes = {
    vm: PropTypes.instanceOf(VM)
};

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MicSnackbar);
