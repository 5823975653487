import getUserMedia from 'get-user-media-promise';

class AudioProvider {
    constructor () {
        this.audioContext = new AudioContext();
    }

    enableAudio () {
        if (this.isEnable) {
            return Promise.resolve();
        }

        this.audioContext.resume();
        navigator.mediaDevices.ondevicechange = () => {
            this.disableAudio();
            this.enableAudio();
        };
        return getUserMedia({audio: true})
            .then(stream => {
                this.isEnable = true;
                this.stream = stream;
                this.connectedStream = this._connectStream(stream);
            });
    }

    disableAudio () {
        if (this.isEnable) {
            this.analyser.disconnect();
            this.source.disconnect();
            this.stream.getAudioTracks().forEach(track => track.stop());
            this.audioContext.suspend();
            this.recorder = null;
            navigator.mediaDevices.ondevicechange = null;
        }
        this.isEnable = false;
    }

    _connectStream (stream) {
        this.source = this.audioContext.createMediaStreamSource(stream);
        this.analyser = this.audioContext.createAnalyser();
        this.destination = this.audioContext.createMediaStreamDestination();

        this.source.connect(this.analyser);
        this.analyser.connect(this.destination);

        this.analyser.fftSize = 2048;
        const bufferLength = this.analyser.fftSize;
        const dataArray = new Uint8Array(bufferLength);

        const waveUpdate = timestamp => {
            if (!this.isEnable) {
                return;
            }
            if (this.listener) {
                this.analyser.getByteFrequencyData(dataArray);
                this.listener(timestamp, this._sectionMedians(dataArray, 128));
            }
            requestAnimationFrame(waveUpdate);
        };

        waveUpdate(performance.now());
        return this.destination.stream;
    }

    _calculateMedian (arr) {
        const sorted = [...arr].sort((a, b) => a - b);
        const mid = Math.floor(sorted.length / 2);
        return sorted.length % 2 !== 0 ? sorted[mid] : (sorted[mid - 1] + sorted[mid]) / 2;
    }

    _sectionMedians (array, finalLength) {
        if (finalLength <= 0) {
            throw new Error('Final length must be greater than 0');
        }

        const sectionSize = Math.ceil(array.length / finalLength);
        const medians = [];

        for (let i = 0; i < array.length; i += sectionSize) {
            const section = array.slice(i, Math.min(i + sectionSize, array.length));
            medians.push(this._calculateMedian(section));
        }

        return medians.slice(0, finalLength);
    }

    attachWaveUpdateListener (listener) {
        this.listener = listener;
    }

    detachWaveUpdateListener () {
        this.listener = null;
    }
    
    record (seconds, mimeType) {
        if (!this.isEnable) {
            return Promise.resolve(new Blob([]));
        }

        return new Promise(resolve => {
            const stream = this.connectedStream;
            const recorder = new MediaRecorder(stream, {mimeType});

            recorder.ondataavailable = event => {
                resolve(event.data);
            };
            recorder.start();
            setTimeout(() => {
                recorder.stop();
            }, seconds * 1000);
        });
    }

    get channelCount () {
        return this.destination.channelCount;
    }
}

export default AudioProvider;
