import React from 'react';

export const useProgress = ({ onStart, onFinished, onFail }) => {
    const PROGRESS_UPDATE_INTERVAL = 1000;
    const PROGRESS_PAUSE_POINT = 80;
    const PROGRESS_COMPLETE = 100;
    const PROGRESS_STEP = 10;

    const [progress, setProgress] = React.useState(0);

    const intervalRef = React.useRef(null);

    const start = () => {
        onStart();

        if (progress !== 0) {
            return;
        }

        intervalRef.current = setInterval(() => {
            setProgress(p => {
                if (p >= PROGRESS_PAUSE_POINT) {
                    clearInterval(intervalRef.current);
                    return PROGRESS_PAUSE_POINT;
                }
                return p + PROGRESS_STEP;
            });
        }, PROGRESS_UPDATE_INTERVAL);
    };

    const finish = () => {
        clearInterval(intervalRef.current);
        setProgress(PROGRESS_COMPLETE);
        setTimeout(() => {
            onFinished();
        }, 500);
    };

    const fail = () => {
        clearInterval(intervalRef.current);
        setProgress(0);
        onFail();
    };

    return { progress, start, finish, fail };
};