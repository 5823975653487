import React from 'react';
import style from './confidence-graph.css';

const ConfidenceGraph = ({confidence, graphColor}) => {
    const {label, value} = confidence;

    const getPercentTextPosition = () => {
        if (value > 10) {
            return {right: `${100 - value}%`};
        }
        return {left: `${value}%`};
    };

    return (<div className={style.container}>
        <div
            className={style.labelText}
            style={{color: graphColor.main}}
        >{label}</div>
        <div
            className={style.barContainer}
            style={{backgroundColor: graphColor.background}}
        >
            <div
                className={style.valueText}
                style={getPercentTextPosition()}
            >{`${value}%`}</div>
            <div
                className={style.bar}
                style={{width: `${value}%`, backgroundColor: graphColor.main}}
            />
        </div>
    </div>);
};

export default ConfidenceGraph;
