exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".class-menu_class-menu-container_2Hnx1 {\n    position: absolute;\n    z-index: 1;\n    right: 24px;\n    top: 50px;\n    width: 240px;\n    margin: 0;\n    padding-left: 0;\n    border-radius: 5px;\n    -webkit-box-shadow: 0px 2px 2px rgba(0,0,0,0.20);\n            box-shadow: 0px 2px 2px rgba(0,0,0,0.20);\n    background-color: #FFFFFF;\n    overflow: hidden;\n}\n\n.class-menu_class-menu-item_2aD71 {\n    all: unset;\n    display: -webkit-box;\n    display: -webkit-flex;\n    display: -ms-flexbox;\n    display: flex;\n    width: 100%;\n    text-align: start;\n    -webkit-box-align: center;\n    -webkit-align-items: center;\n        -ms-flex-align: center;\n            align-items: center;\n    height: 60px;\n    padding: 0 20px;\n    background-color: white;\n    color: #000000;\n}\n\n.class-menu_class-menu-item_2aD71:hover {\n    background-color: #F2F2F2;\n}\n\n.class-menu_class-menu-item_2aD71:disabled {\n    cursor: not-allowed;\n    background-color: #FFFFFF;\n    opacity: 0.3;\n}", ""]);

// exports
exports.locals = {
	"class-menu-container": "class-menu_class-menu-container_2Hnx1",
	"classMenuContainer": "class-menu_class-menu-container_2Hnx1",
	"class-menu-item": "class-menu_class-menu-item_2aD71",
	"classMenuItem": "class-menu_class-menu-item_2aD71"
};