import React from 'react';
import styles from './progress-bar.css';
import classNames from 'classnames';

const Progress = ({progress = 0}) => {
    return <div className={styles.container}>
        <div className={styles.progressBar}>
            <div className={styles.progressBarInner}>
                <div className={styles.loading} style={{
                    width: `${progress}%`,
                }}></div>
            </div>
            <div className={classNames(styles.progressText, progress >= 95 && styles.whiteText)}>{progress}%</div>
        </div>
    </div>
};

export default Progress;