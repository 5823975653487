import React from 'react';

import styles from './sprite-selector/malrang-sprite-list.css';
import tooltipTail from './tooltiptail.svg';

const TOOLTIP_OFFSET_X = 40;
const TOOLTIP_OFFSET_Y = 40;

function getOverflowX(element, leftOffset) {
    if (!element) {
        return 0;
    }

    const overflowX = element.clientWidth + leftOffset - window.innerWidth;
    return Math.max(0, overflowX);
}

const MalrangTutorialTooltip = ({ tooltip, spriteWrapper, spriteRef }) => {
    const [offset, setOffset] = React.useState({
        top: 0,
        left: 0,
    });

    const setTooltipOffset = React.useCallback(() => {
        const rect = spriteRef.current?.getBoundingClientRect();
        if (!rect || !spriteRef.current) {
            return;
        }

        setOffset({
            top: rect.top,
            left: rect.left,
        });
    }, [spriteRef]);

    React.useEffect(() => {
        setTooltipOffset();
        
        const modalElement = document.querySelector('#malrang-tutorial-modal');
        modalElement.addEventListener('scroll', setTooltipOffset);
        window.addEventListener('resize', setTooltipOffset);

        return () => {
            modalElement.removeEventListener('scroll', setTooltipOffset);
            window.removeEventListener('resize', setTooltipOffset);
        };
    }, []);

    React.useEffect(() => {
        spriteWrapper?.addEventListener('scroll', setTooltipOffset);

        return () => {
            spriteWrapper?.removeEventListener('scroll', setTooltipOffset);
        };
    }, [spriteWrapper]);

    const tooltipRef = React.useRef(null);

    const left = offset.left + TOOLTIP_OFFSET_X;
    const top = offset.top - TOOLTIP_OFFSET_Y;

    const overflowRight = getOverflowX(tooltipRef.current, left);

    return (
        <div className={styles.cannotSelect} ref={tooltipRef} style={{
            top: `${top}px`,
            left: `${left - overflowRight}px`,
        }}>
            <span>{tooltip}</span>
            <img src={tooltipTail} className={styles.tooltipTail} style={{
                transform: `translateX(${overflowRight}px)`,
            }} />
        </div>
    ); 
};

export default MalrangTutorialTooltip;