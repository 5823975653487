import React from 'react';
import { Line, LineChart, YAxis } from 'recharts';

const LineGraph = ({
    data,
    dataKey,
    wrapperClassName,
    chartClassName,
    width,
    height,
    domain,
    strokeColor,
}) => {
    return (
        <div className={wrapperClassName}>
            <LineChart
                width={width}
                height={height}
                data={data}
                className={chartClassName}
                margin={{
                    bottom: 0,
                    left: 0,
                }}
            >
                <YAxis
                    type="number"
                    domain={domain}
                    dataKey={dataKey}
                    hide
                />
                <Line
                    type="monotone"
                    dataKey="sensor"
                    stroke={strokeColor}
                    strokeWidth={2}
                    isAnimationActive={false}
                    dot={false}
                />
            </LineChart>
        </div>
    );
};

export default LineGraph;