import ScratchBlocks from 'scratch-blocks';
import { BlockXML } from '../classes';

const categorySeparator = '<sep gap="36"/>';

const generateBlocksXML = (blocks, blockXMLObject, isStage, showAllBlocks) => {
    const draggableBlocks = blockXMLObject.filterDraggableBlocks(blocks);
    if (draggableBlocks.length === 0) {
        return '';
    }

    blockXMLObject.sortBlocks(draggableBlocks);
    return blockXMLObject.blocksToRender(draggableBlocks, isStage, showAllBlocks);
};

const motion = (isInitialSetup, isStage, targetId, motionBlocks, showAllBlocks) => {
    if (!motionBlocks) {
        return '';
    }

    const stageSelected = ScratchBlocks.ScratchMsgs.translate(
        'MOTION_STAGE_SELECTED',
        'Stage selected: no motion blocks'
    );
    const { Motion } = BlockXML.motion;
    const motion = new Motion(isInitialSetup, isStage, targetId);

    const motionBlocksToRender = generateBlocksXML(motionBlocks, motion, isStage, showAllBlocks);

    return `
    <category name="%{BKY_CATEGORY_MOTION}" id="motion" colour="#4C97FF" secondaryColour="#3373CC">
        ${isStage && !showAllBlocks ? `
        <label text="${stageSelected}"></label>
        ` : `
        ${motionBlocksToRender}
        `}
        ${categorySeparator}
    </category>
    `;
};

const looks = function (isInitialSetup, isStage, targetId, costumeName, backdropName, looksBlocks, showAllBlocks) {
    if (!looksBlocks) {
        return '';
    }

    const { Looks } = BlockXML.looks;
    const looks = new Looks(isInitialSetup, isStage, targetId, costumeName, backdropName);

    const looksBlocksToRender = generateBlocksXML(looksBlocks, looks, isStage, showAllBlocks);

    return `
    <category name="%{BKY_CATEGORY_LOOKS}" id="looks" colour="#9966FF" secondaryColour="#774DCB">
        ${looksBlocksToRender}
        ${categorySeparator}
    </category>
    `;
};

const sound = function (isInitialSetup, isStage, targetId, soundName, soundBlocks, showAllBlocks) {
    if (!soundBlocks) {
        return '';
    }

    const { Sound } = BlockXML.sound;
    const sound = new Sound(isInitialSetup, isStage, targetId, soundName);

    const soundBlocksToRender = generateBlocksXML(soundBlocks, sound, isStage, showAllBlocks);

    return `
    <category name="%{BKY_CATEGORY_SOUND}" id="sound" colour="#D65CD6" secondaryColour="#BD42BD">
        ${soundBlocksToRender}
        ${categorySeparator}
    </category>
    `;
};

const events = function (isInitialSetup, isStage, targetId, eventsBlocks, showAllBlocks) {
    if (!eventsBlocks) {
        return '';
    }

    const { Events } = BlockXML.events;
    const events = new Events(isInitialSetup, isStage, targetId);

    const eventsBlocksToRender = generateBlocksXML(eventsBlocks, events, isStage, showAllBlocks);

    return `
    <category name="%{BKY_CATEGORY_EVENTS}" id="events" colour="#FFD500" secondaryColour="#CC9900">
        ${eventsBlocksToRender}
        ${categorySeparator}
    </category>
    `;
};

const control = function (isInitialSetup, isStage, targetId, controlBlocks, showAllBlocks) {
    if (!controlBlocks) {
        return '';
    }

    const { Control } = BlockXML.control;
    const control = new Control(isInitialSetup, isStage, targetId);

    const controlBlocksToRender = generateBlocksXML(controlBlocks, control, isStage, showAllBlocks);

    return `
    <category name="%{BKY_CATEGORY_CONTROL}" id="control" colour="#FFAB19" secondaryColour="#CF8B17">
        ${controlBlocksToRender}
        ${categorySeparator}
    </category>
    `;
};

const sensing = function (isInitialSetup, isStage, targetId, sensingBlocks, showAllBlocks) {
    if (!sensingBlocks) {
        return '';
    }

    const { Sensing } = BlockXML.sensing;
    const sensing = new Sensing(isInitialSetup, isStage, targetId);

    const sensingBlocksToRender = generateBlocksXML(sensingBlocks, sensing, isStage, showAllBlocks);

    return `
    <category name="%{BKY_CATEGORY_SENSING}" id="sensing" colour="#4CBFE6" secondaryColour="#2E8EB8">
        ${sensingBlocksToRender}
        ${categorySeparator}
    </category>
    `;
};

const operators = function (isInitialSetup, isStage, targetId, operatorsBlocks, showAllBlocks) {
    if (!operatorsBlocks) {
        return '';
    }

    const { Operators } = BlockXML.operators;
    const operators = new Operators(isInitialSetup, isStage, targetId);

    const operatorsBlocksToRender = generateBlocksXML(operatorsBlocks, operators, isStage, showAllBlocks);

    return `
    <category name="%{BKY_CATEGORY_OPERATORS}" id="operators" colour="#40BF4A" secondaryColour="#389438">
        ${operatorsBlocksToRender}
        ${categorySeparator}
    </category>
    `;
};

export {
    motion,
    looks,
    sound,
    events,
    control,
    sensing,
    operators
}