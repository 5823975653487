exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".confidence-graph_container_16dI5 {\n    display: -webkit-box;\n    display: -webkit-flex;\n    display: -ms-flexbox;\n    display: flex;\n    -webkit-box-orient: horizontal;\n    -webkit-box-direction: normal;\n    -webkit-flex-direction: row;\n        -ms-flex-direction: row;\n            flex-direction: row;\n    -webkit-box-align: center;\n    -webkit-align-items: center;\n        -ms-flex-align: center;\n            align-items: center;\n    gap: 10px;\n    width: 100%;\n}\n\n.confidence-graph_label-text_3pzQe {\n    display: -webkit-box;\n    width: 60px;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    word-break: break-all;\n    -webkit-line-clamp: 2;\n    -webkit-box-orient: vertical;\n    font-size: 14px;\n    font-weight: 500;\n}\n\n.confidence-graph_bar-container_Mqhvx {\n    position: relative;\n    display: -webkit-box;\n    display: -webkit-flex;\n    display: -ms-flexbox;\n    display: flex;\n    -webkit-box-align: center;\n    -webkit-align-items: center;\n        -ms-flex-align: center;\n            align-items: center;\n    width: 240px;\n    min-width: 240px;\n    height: 32px;\n    border-radius: 4px;\n}\n\n.confidence-graph_value-text_2I_Uk {\n    position: absolute;\n    font-size: 12px;\n    font-weight: 500;\n    padding-right: 4px;\n    color: white;\n    -webkit-transition: all 0.1s ease-in-out;\n    transition: all 0.1s ease-in-out;\n}\n\n.confidence-graph_bar_3FAMb {\n    height: 100%;\n    border-radius: 4px;\n    -webkit-transition: all 0.1s ease-in-out;\n    transition: all 0.1s ease-in-out;\n}", ""]);

// exports
exports.locals = {
	"container": "confidence-graph_container_16dI5",
	"label-text": "confidence-graph_label-text_3pzQe",
	"labelText": "confidence-graph_label-text_3pzQe",
	"bar-container": "confidence-graph_bar-container_Mqhvx",
	"barContainer": "confidence-graph_bar-container_Mqhvx",
	"value-text": "confidence-graph_value-text_2I_Uk",
	"valueText": "confidence-graph_value-text_2I_Uk",
	"bar": "confidence-graph_bar_3FAMb"
};