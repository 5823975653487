import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import camOnIcon from './icon--cam-on.svg';
import camOffIcon from './icon--cam-off.svg';
import styles from './camera.css';

const CameraComponent = function (props) {
    const {
        className,
        onClick,
        title,
        isCameraOn,
        ...componentProps
    } = props;
    return (
        <img
            className={classNames(
                className,
                styles.camera
            )}
            draggable={false}
            src={isCameraOn ? camOffIcon : camOnIcon}
            title={title}
            onClick={onClick}
            {...componentProps}
        />
    );
};
CameraComponent.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    title: PropTypes.string,
    isCameraOn: PropTypes.bool
};

export default CameraComponent;
