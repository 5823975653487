import PropTypes from 'prop-types';
import React from 'react';
import Box from '../box/box.jsx';
import {FormattedMessage} from 'react-intl';
import errorCircle from './error_circle.svg';
import checkCircle from './check_circle.svg';
import close from './close.svg';

import styles from './connection-check-alert.css';

const messages = {
    connected: <FormattedMessage
        defaultMessage="The device is connected successfully."
        id="gui.connection.check-alert.connected"
    />,
    disconnected: <FormattedMessage
        defaultMessage="The device has been disconnected. Please reconnect the device."
        id="gui.connection.check-alert.disconnected"
    />
};

const tmMessages = {
    connected: <FormattedMessage
        defaultMessage="The trained model has been successfully applied."
        id="gui.connection.check-alert.tmConnected"
    />,
    disconnected: <FormattedMessage
        defaultMessage="The device has been disconnected. Please reconnect the device."
        id="gui.connection.check-alert.disconnected"
    />
}

const ConnectionCheckAlert = ({extensionId, deviceState, onClickAlert, onClickCloseButton}) => (
    <Box className={styles.connectionCheckAlertsContainer} >
        <div
            className={styles.alertContainer}
        >
            {/* connected,disconnected */}
            <div
                className={styles.alert}
                onClick={() => {
                    onClickAlert();
                }}
            >
                <img
                    src={deviceState === 'connected' ? checkCircle : errorCircle}
                    className={styles.preventDrag}
                    style={{marginRight: '5px'}}
                    alt="disconnected"
                />
                {extensionId === 'teachableMachine' ? 
                <span className={[styles.alertText]}>{tmMessages[deviceState]}</span>
                :
                <span className={[styles.alertText]}>{messages[deviceState]}</span>
                }
                </div>
            <img
                className={styles.preventDrag}
                onClick={() => {
                    onClickCloseButton();
                }}
                
                src={close}
                alt="close"
            />
        </div>
    </Box>
);

ConnectionCheckAlert.propTypes = {
    deviceState: PropTypes.bool
};

ConnectionCheckAlert.defaultProps = {
};

export default ConnectionCheckAlert;
