import {defineMessages} from 'react-intl';

const messages = defineMessages({
    motion_direction: {
        defaultMessage: 'direction',
        description: 'Label for the direction monitor when shown on the stage',
        id: 'gui.opcodeLabels.direction'
    },
    motion_xposition: {
        defaultMessage: 'x position',
        description: 'Label for the x position monitor when shown on the stage',
        id: 'gui.opcodeLabels.xposition'
    },
    motion_yposition: {
        defaultMessage: 'y position',
        description: 'Label for the y position monitor when shown on the stage',
        id: 'gui.opcodeLabels.yposition'
    },

    // Looks
    looks_size: {
        defaultMessage: 'size',
        description: 'Label for the size monitor when shown on the stage',
        id: 'gui.opcodeLabels.size'
    },
    looks_costumename: {
        defaultMessage: 'costume name',
        description: 'Label for the costume name monitor when shown on the stage',
        id: 'gui.opcodeLabels.costumename'
    },
    looks_costumenumber: {
        defaultMessage: 'costume number',
        description: 'Label for the costume number monitor when shown on the stage',
        id: 'gui.opcodeLabels.costumenumber'
    },
    looks_backdropname: {
        defaultMessage: 'backdrop name',
        description: 'Label for the backdrop name monitor when shown on the stage',
        id: 'gui.opcodeLabels.backdropname'
    },
    looks_backdropnumber: {
        defaultMessage: 'backdrop number',
        description: 'Label for the backdrop number monitor when shown on the stage',
        id: 'gui.opcodeLabels.backdropnumber'
    },

    // Sound
    sound_volume: {
        defaultMessage: 'volume',
        description: 'Label for the volume monitor when shown on the stage',
        id: 'gui.opcodeLabels.volume'
    },
    sound_tempo: {
        defaultMessage: 'tempo',
        description: 'Label for the tempo monitor when shown on the stage',
        id: 'gui.opcodeLabels.tempo'
    },

    // Sensing
    sensing_answer: {
        defaultMessage: 'answer',
        description: 'Label for the answer monitor when shown on the stage',
        id: 'gui.opcodeLabels.answer'
    },
    sensing_loudness: {
        defaultMessage: 'loudness',
        description: 'Label for the loudness monitor when shown on the stage',
        id: 'gui.opcodeLabels.loudness'
    },
    sensing_username: {
        defaultMessage: 'username',
        description: 'Label for the username monitor when shown on the stage',
        id: 'gui.opcodeLabels.username'
    },
    sensing_current_year: {
        defaultMessage: 'year',
        description: 'Label for the current year monitor when shown on the stage',
        id: 'gui.opcodeLabels.year'
    },
    sensing_current_month: {
        defaultMessage: 'month',
        description: 'Label for the current month monitor when shown on the stage.',
        id: 'gui.opcodeLabels.month'
    },
    sensing_current_date: {
        defaultMessage: 'date',
        description: 'Label for the current date monitor when shown on the stage. Shows the current day of the month',
        id: 'gui.opcodeLabels.date'
    },
    sensing_current_dayofweek: {
        defaultMessage: 'day of week',
        description: 'Label for the current day of week monitor when shown on the stage',
        id: 'gui.opcodeLabels.dayofweek'
    },
    sensing_current_hour: {
        defaultMessage: 'hour',
        description: 'Label for the current hour monitor when shown on the stage',
        id: 'gui.opcodeLabels.hour'
    },
    sensing_current_minute: {
        defaultMessage: 'minute',
        description: 'Label for the current minute monitor when shown on the stage',
        id: 'gui.opcodeLabels.minute'
    },
    sensing_current_second: {
        defaultMessage: 'second',
        description: 'Label for the current second monitor when shown on the stage',
        id: 'gui.opcodeLabels.second'
    },
    sensing_timer: {
        defaultMessage: 'timer',
        description: 'Label for the timer monitor when shown on the stage',
        id: 'gui.opcodeLabels.timer'
    },

    // probo
    nemo_getAccelerationValue: {
        defaultMessage: 'acceleration',
        description: 'Label for probo sensor input acceleration value',
        id: 'gui.opcodeLabels.nemoAcceleration'
    },
    nemo_getSwitchAnalogueValue: {
        defaultMessage: 'btn_analog',
        description: 'Label for probo sensor input btn_analog value',
        id: 'gui.opcodeLabels.nemoButtonAnalog'
    },
    nemo_getCompassValue: {
        defaultMessage: 'compass',
        description: 'Label for probo sensor input compass value',
        id: 'gui.opcodeLabels.nemoCompassValue'
    },
    nemo_getCompassDirection: {
        defaultMessage: 'compass',
        description: 'Label for probo sensor input compass value',
        id: 'gui.opcodeLabels.nemoCompassDirection'
    },
    nemo_getExpansionSensorValue: {
        defaultMessage: 'expansion',
        description: 'Label for probo sensor input expansion value',
        id: 'gui.opcodeLabels.nemoExpansion'
    },

    Drone_getAttitudeValue_Roll: {
        defaultMessage: 'attitude roll',
        id: 'gui.opcodeLabels.droneAttitude.roll'
    },
    Drone_getAttitudeValue_Pitch: {
        defaultMessage: 'attitude pitch',
        id: 'gui.opcodeLabels.droneAttitude.pitch'
    },
    Drone_getAttitudeValue_Yaw: {
        defaultMessage: 'attitude yaw',
        id: 'gui.opcodeLabels.droneAttitude.yaw'
    },
    Drone_getPositionValue_x: {
        defaultMessage: 'position x',
        id: 'gui.opcodeLabels.dronePosition.x'
    },
    Drone_getPositionValue_y: {
        defaultMessage: 'position y',
        id: 'gui.opcodeLabels.dronePosition.y'
    },
    Drone_getPositionValue_z: {
        defaultMessage: 'position z',
        id: 'gui.opcodeLabels.dronePosition.z'
    },
    Drone_getAltitudeAndRangeHeight_front: {
        defaultMessage: 'front',
        id: 'gui.opcodeLabels.droneFront'
    },
    Drone_getAltitudeAndRangeHeight_height: {
        defaultMessage: 'height',
        id: 'gui.opcodeLabels.droneHeight'
    },
    Drone_getAltitudeAndRangeHeight_altitude: {
        defaultMessage: 'altitude',
        id: 'gui.opcodeLabels.droneAltitude'
    },    
    Drone_stateDrone_modeFlight: {
        defaultMessage: 'status flight',
        id: 'gui.opcodeLabels.droneState.flight'
    },
    Drone_stateDrone_modeMovement: {
        defaultMessage: 'status movement',
        id: 'gui.opcodeLabels.droneState.movement'
    },
    Drone_stateDrone_headless: {
        defaultMessage: 'status headless mode',
        id: 'gui.opcodeLabels.droneState.headless'
    },
    Drone_stateDrone_pitch: {
        defaultMessage: 'status trim pitch',
        id: 'gui.opcodeLabels.droneState.trimPitch'
    },
    Drone_stateDrone_roll: {
        defaultMessage: 'status trim roll',
        id: 'gui.opcodeLabels.droneState.trimRoll'
    },
    Drone_stateDrone_sensorOrientation: {
        defaultMessage: 'status sensor direction',
        id: 'gui.opcodeLabels.droneState.sensorDirection'
    },
    Drone_stateDrone_battery: {
        defaultMessage: 'status battery(%)',
        id: 'gui.opcodeLabels.droneState.battery'
    },
    Drone_getFlightTime: {
        defaultMessage: 'Drone flight time',
        id: 'gui.opcodeLabels.droneFlightTime'
    },
    Drone_getCountValue_countTakeOff: {
        defaultMessage: 'Number of Drone takeOff',
        id: 'gui.opcodeLabels.droneCountValue.takeOff'
    },
    Drone_getCountValue_countLanding: {
        defaultMessage: 'Number of Drone landing',
        id: 'gui.opcodeLabels.droneCountValue.landing'
    },
    Drone_getCountValue_countAccident: {
        defaultMessage: 'Number of Drone accident',
        id: 'gui.opcodeLabels.droneCountValue.accident'
    },
    Drone_controllerValueButton_button: {
        defaultMessage: 'Button',
        id: 'gui.opcodeLabels.droneControllerValue.button'
    },
    Drone_controllerValueButton_event: {
        defaultMessage: 'Button event',
        id: 'gui.opcodeLabels.droneControllerValue.event'
    },
});

class OpcodeLabels {
    constructor () {
        /**
         * Translation function for labels. By default just return the defaultMessage
         * @private
         * @param {object} message A message object compatible with react-intl formatMessage
         * @return {string} Return the default string initially
         */
        this._translator = message => message.defaultMessage;

        /**
         * Initial opcode map, with categories defined
         * @private
         */
        this._opcodeMap = {
            // Motion
            motion_direction: {category: 'motion'},
            motion_xposition: {category: 'motion'},
            motion_yposition: {category: 'motion'},

            // Looks
            looks_size: {category: 'looks'},
            looks_costumenumbername: {category: 'looks'},
            looks_backdropnumbername: {category: 'looks'},
            looks_backdropname: {category: 'looks'},

            // Data
            data_variable: {category: 'data'},
            data_listcontents: {category: 'list'},

            // Sound
            sound_volume: {category: 'sound'},
            sound_tempo: {category: 'sound'},

            // Sensing
            sensing_answer: {category: 'sensing'},
            sensing_loudness: {category: 'sensing'},
            sensing_username: {category: 'sensing'},
            sensing_current: {category: 'sensing'},
            sensing_timer: {category: 'sensing'},

            // probo
            nemo_getAccelerationValue: {category: 'extension'},
            nemo_getSwitchAnalogueValue: {category: 'extension'},
            nemo_getCompassValue: {category: 'extension'},
            nemo_getCompassDirection: {category: 'extension'},
            nemo_getExpansionSensorValue: {category: 'extension'},

            skykick_getAttitudeValue: {category: 'skykick'},
            skykick_getPositionValue: {category: 'skykick'},
            skykick_getAltitudeAndRangeHeight: {category: 'skykick'},
            skykick_stateDrone: {category: 'skykick'},

            codingRider_getAttitudeValue: {category: 'codingRider'},
            codingRider_getPositionValue: {category: 'codingRider'},
            codingRider_getAltitudeAndRangeHeight: {category: 'codingRider'},
            codingRider_stateDrone: {category: 'codingRider'},
            codingRider_getFlightTime: {category: 'codingRider'},
            codingRider_getCountValue: {category: 'codingRider'},

            codingDrone_getAttitudeValue: {category: 'codingDrone'},
            codingDrone_getPositionValue: {category: 'codingDrone'},
            codingDrone_getAltitudeAndRangeHeight: {category: 'codingDrone'},
            codingDrone_stateDrone: {category: 'codingDrone'},
            codingDrone_getFlightTime: {category: 'codingDrone'},
            codingDrone_getCountValue: {category: 'codingDrone'},
            codingDrone_controllerValueButton: {category: 'codingDrone'},
        };

        // Initialize opcodeMap with default strings
        this._refreshOpcodeMap();
    }

    /**
     * Set the translation function for monitor labels. The function should accept
     * a message object as defined by react-intl defineMessages
     * @param {function} translator the function to use for localization
     */
    setTranslatorFunction (translator) {
        this._translator = translator;
        this._refreshOpcodeMap();
    }

    /**
     * Internal function to update opcode Map when translation function is defined
     * @private
     */
    _refreshOpcodeMap () {
        // Motion
        this._opcodeMap.motion_direction.labelFn = () => this._translator(messages.motion_direction);
        this._opcodeMap.motion_xposition.labelFn = () => this._translator(messages.motion_xposition);
        this._opcodeMap.motion_yposition.labelFn = () => this._translator(messages.motion_yposition);

        // Looks
        this._opcodeMap.looks_size.labelFn = () => this._translator(messages.looks_size);
        this._opcodeMap.looks_costumenumbername.labelFn = params => {
            if (params.NUMBER_NAME === 'number') {
                return this._translator(messages.looks_costumenumber);
            }
            return this._translator(messages.looks_costumename);
        };
        this._opcodeMap.looks_backdropnumbername.labelFn = params => {
            if (params.NUMBER_NAME === 'number') {
                return this._translator(messages.looks_backdropnumber);
            }
            return this._translator(messages.looks_backdropname);
        };
        this._opcodeMap.looks_backdropname.labelFn = () => this._translator(messages.looks_backdropname);

        // Data
        this._opcodeMap.data_variable.labelFn = params => params.VARIABLE;
        this._opcodeMap.data_listcontents.labelFn = params => params.LIST;

        // Sound
        this._opcodeMap.sound_volume.labelFn = () => this._translator(messages.sound_volume);
        this._opcodeMap.sound_tempo.labelFn = () => this._translator(messages.sound_tempo);

        // Sensing
        this._opcodeMap.sensing_answer.labelFn = () => this._translator(messages.sensing_answer);
        this._opcodeMap.sensing_loudness.labelFn = () => this._translator(messages.sensing_loudness);
        this._opcodeMap.sensing_username.labelFn = () => this._translator(messages.sensing_username);
        this._opcodeMap.sensing_current.labelFn = params => {
            switch (params.CURRENTMENU.toLowerCase()) {
            case 'year':
                return this._translator(messages.sensing_current_year);
            case 'month':
                return this._translator(messages.sensing_current_month);
            case 'date':
                return this._translator(messages.sensing_current_date);
            case 'dayofweek':
                return this._translator(messages.sensing_current_dayofweek);
            case 'hour':
                return this._translator(messages.sensing_current_hour);
            case 'minute':
                return this._translator(messages.sensing_current_minute);
            case 'second':
                return this._translator(messages.sensing_current_second);
            }
        };
        this._opcodeMap.sensing_timer.labelFn = () => this._translator(messages.sensing_timer);

        // probo
        this._opcodeMap.nemo_getAccelerationValue.labelFn = () => this._translator(messages.nemo_getAccelerationValue);
        this._opcodeMap.nemo_getSwitchAnalogueValue.labelFn = () => this._translator(messages.nemo_getSwitchAnalogueValue);
        this._opcodeMap.nemo_getCompassValue.labelFn = () => this._translator(messages.nemo_getCompassValue);
        this._opcodeMap.nemo_getCompassDirection.labelFn = () => this._translator(messages.nemo_getCompassDirection);
        this._opcodeMap.nemo_getExpansionSensorValue.labelFn = () => this._translator(messages.nemo_getExpansionSensorValue);

        this._opcodeMap.codingDrone_getAttitudeValue.labelFn = 
        this._opcodeMap.skykick_getAttitudeValue.labelFn =
        this._opcodeMap.codingRider_getAttitudeValue.labelFn =
        params => {
            switch (params) {
            case 'angleRoll':
                return this._translator(messages.Drone_getAttitudeValue_Roll);
            case 'anglePitch':
                return this._translator(messages.Drone_getAttitudeValue_Pitch);
            case 'angleYaw':
                return this._translator(messages.Drone_getAttitudeValue_Yaw);
            }
        };

        this._opcodeMap.codingDrone_getPositionValue.labelFn = 
        this._opcodeMap.skykick_getPositionValue.labelFn =
        this._opcodeMap.codingRider_getPositionValue.labelFn =
        params => {
            switch (params) {
            case 'x':
                return this._translator(messages.Drone_getPositionValue_x);
            case 'y':
                return this._translator(messages.Drone_getPositionValue_y);
            case 'z':
                return this._translator(messages.Drone_getPositionValue_z);
            }
        };

        this._opcodeMap.codingDrone_getAltitudeAndRangeHeight.labelFn = 
        this._opcodeMap.skykick_getAltitudeAndRangeHeight.labelFn =
        this._opcodeMap.codingRider_getAltitudeAndRangeHeight.labelFn =
        params => {
            switch (params) {
            case 'front':
                return this._translator(messages.Drone_getAltitudeAndRangeHeight_front);
            case 'rangeHeight':
                return this._translator(messages.Drone_getAltitudeAndRangeHeight_height);
            case 'altitude':
                return this._translator(messages.Drone_getAltitudeAndRangeHeight_altitude);
            }
        };

        this._opcodeMap.codingDrone_stateDrone.labelFn = 
        this._opcodeMap.skykick_stateDrone.labelFn =
        this._opcodeMap.codingRider_stateDrone.labelFn =
        params => {
            switch (params) {
            case 'modeFlight':
                return this._translator(messages.Drone_stateDrone_modeFlight);
            case 'modeMovement':
                return this._translator(messages.Drone_stateDrone_modeMovement);
            case 'headless':
                return this._translator(messages.Drone_stateDrone_headless);
            case 'pitch':
                return this._translator(messages.Drone_stateDrone_pitch);
            case 'roll':
                return this._translator(messages.Drone_stateDrone_roll);
            case 'sensorOrientation':
                return this._translator(messages.Drone_stateDrone_sensorOrientation);
            case 'battery':
                return this._translator(messages.Drone_stateDrone_battery);
            }
        };

        this._opcodeMap.codingDrone_getFlightTime.labelFn = 
        this._opcodeMap.codingRider_getFlightTime.labelFn =
        () => this._translator(messages.Drone_getFlightTime);

        this._opcodeMap.codingDrone_getCountValue.labelFn = 
        this._opcodeMap.codingRider_getCountValue.labelFn =
        params => {
            switch (params) {
            case 'countTakeOff':
                return this._translator(messages.Drone_getCountValue_countTakeOff);
            case 'countLanding':
                return this._translator(messages.Drone_getCountValue_countLanding);
            case 'countAccident':
                return this._translator(messages.Drone_getCountValue_countAccident);
            }
        };

        this._opcodeMap.codingDrone_controllerValueButton.labelFn = params => {
            switch (params) {
            case 'button':
                return this._translator(messages.Drone_controllerValueButton_button);
            case 'event':
                return this._translator(messages.Drone_controllerValueButton_event);
            }
        };
    }

    /**
     * Return the label for an opcode
     * @param {string} opcode the opcode you want a label for
     * @return {object} object with  label and category
     */
    getLabel (opcode) {
        if (opcode in this._opcodeMap) return this._opcodeMap[opcode];
        return {
            category: 'extension',
            label: opcode
        };
    }
}

export default new OpcodeLabels();
