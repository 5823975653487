import React from 'react';
import Box from '../box/box.jsx';

import styles from './semobae-browser-message.css';

const SemobaeBrowserMessage = props => (
    <div className={styles.crashWrapper}>
        <Box className={styles.body}>
            <h2>
                세모배 앱에서 스크래치를 계속 사용할 수 있습니다.
            </h2>
            <p>
                브라우저로 접속한 경우 이용할 수 없습니다.
                <br/>
                궁금한 사항은 세모배.com로 연락주세요.
            </p>
        </Box>
    </div>
);

export default SemobaeBrowserMessage;
