import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styles from './carousel.css';

class Carousel extends Component {

    constructor (props) {
        super(props);
        this.imgWidth = 472;
        this.transformTime = 300;
        this.interval = null;
        this.array = [this.props.imgs.at(-1), ...this.props.imgs, this.props.imgs[0]];
        this.state = {
            index: 1
        };
        this.wrapperRef = React.createRef();
    }

    componentDidMount () {
        this.setTransition('transform 0s');
        this.setTransform(`translateX(-${this.state.index*this.imgWidth}px)`);
        this.interval = setInterval(() => { this.increaseIndex() }, this.props.duration);
    }

    componentWillUnmount () {
        clearInterval(this.interval);
    }

    increaseIndex = () => {
        this.setState({index: this.state.index + 1});
        if (this.state.index === this.array.length-1){
            setTimeout(() => {
                this.setState({index: 1});
                this.setTransition('transform 0s');
                this.setTransform(`translateX(-${this.imgWidth}px)`);
            }, this.transformTime);
        }
        this.setTransition('transform 0.3s');
        this.setTransform(`translateX(-${this.state.index * this.imgWidth}px)`);
    }

    setTransition (transition) {
        this.wrapperRef.current.style.transition = transition;
    }

    setTransform (transform) {
        this.wrapperRef.current.style.transform = transform;
    }

    render (){
        return (
            <div className={styles.carouselContainer}>
                <div className={styles.carouselWrapper}
                    ref={this.wrapperRef}>
                    {this.array.map((img, index) => (
                        <div key={index} className={styles.carouselSlide}>
                            <img className={styles.carouselImg} src={img} />
                        </div>
                    ))}
                </div>
            </div>
        )
    }
}

Carousel.defaultProps = {
    duration: 4500
};

Carousel.PropTypes = {
    duration: PropTypes.number,
    imgs: PropTypes.array.isRequired
};
export default Carousel;
