const SET_AUTO_CLOSE = 'scratch-gui/auto-close/SET_AUTO_CLOSE';
const SET_AUTO_CLOSE_CHANGE_HANDLER = 'scratch-gui/auto-close/SET_AUTO_CLOSE_CHANGE_HANDLER';

const initialState = {
    autoClose: true,
    onAutoCloseChange: () => {}
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
    case SET_AUTO_CLOSE:
        state.onAutoCloseChange(action.autoClose);
        return Object.assign({}, state, {
            autoClose: action.autoClose
        });
    case SET_AUTO_CLOSE_CHANGE_HANDLER:
        return Object.assign({}, state, {
            onAutoCloseChange: action.onAutoCloseChange
        });
    default:
        return state;
    }
}

const setAutoClose = function (autoClose) {
    return {
        type: SET_AUTO_CLOSE,
        autoClose: autoClose
    };
};

const setAutoCloseChangeHandler = function (onAutoCloseChange) {
    if (!onAutoCloseChange) {
        throw new Error('Must provide a handler for auto close change')
    };
    return {
        type: SET_AUTO_CLOSE_CHANGE_HANDLER,
        onAutoCloseChange: onAutoCloseChange
    };
};

export {
    reducer as default,
    initialState as autoCloseInitialState,
    setAutoClose,
    setAutoCloseChangeHandler
};
