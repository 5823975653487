exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".carousel_carousel-container_27Cwo {\n    overflow: hidden;\n    width:472px;\n    height:165px;\n}\n\n.carousel_carousel-wrapper_1OguL {\n    display: -webkit-box;\n    display: -webkit-flex;\n    display: -ms-flexbox;\n    display: flex;\n    -webkit-box-orient: horizontal;\n    -webkit-box-direction: normal;\n    -webkit-flex-direction: row;\n        -ms-flex-direction: row;\n            flex-direction: row;\n}\n\n.carousel_carousel-slide_2qe6f {\n    -webkit-box-flex: 0;\n    -webkit-flex: 0 0 472px;\n        -ms-flex: 0 0 472px;\n            flex: 0 0 472px;\n    position: relative;\n}\n\n.carousel_carousel-img_2uvol {\n    display: block;\n    width: 100%;\n    height: 100%;\n    -o-object-fit: cover;\n       object-fit: cover;\n}\n", ""]);

// exports
exports.locals = {
	"carousel-container": "carousel_carousel-container_27Cwo",
	"carouselContainer": "carousel_carousel-container_27Cwo",
	"carousel-wrapper": "carousel_carousel-wrapper_1OguL",
	"carouselWrapper": "carousel_carousel-wrapper_1OguL",
	"carousel-slide": "carousel_carousel-slide_2qe6f",
	"carouselSlide": "carousel_carousel-slide_2qe6f",
	"carousel-img": "carousel_carousel-img_2uvol",
	"carouselImg": "carousel_carousel-img_2uvol"
};