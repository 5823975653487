import React from 'react';
import style from './image-sample.css';
import DeleteIcon from './delete-icon.svg';

const ImageSample = props => {
    const {src, onDelete} = props;

    return (<div className={style.sampleContainer}>
        <button
            className={style.deleteButton}
            onClick={onDelete}
        >
            <img src={DeleteIcon} />
        </button>
        <img
            src={src}
            className={style.image}
        />
    </div>);
};

export default ImageSample;
