import React from 'react';
import bindAll from 'lodash.bindall';
import styles from './class-menu.css';

class ClassMenu extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleClickOutside',
            'delete',
            'clear'
        ]);
    }

    componentDidMount () {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount () {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside (event) {
        if (!this.props.parentRef.contains(event.target)) {
            this.props.close();
        }
    }

    delete (event) {
        event.stopPropagation();
        if (this.props.canDelete) {
            this.props.delete();
            this.props.close();
        }
    }

    clear (event) {
        event.stopPropagation();
        if (this.props.canClear) {
            this.props.clear();
            this.props.close();
        }
    }

    render () {
        return (
            <div className={styles.classMenuContainer}>
                <button
                    className={styles.classMenuItem}
                    disabled={!this.props.canDelete}
                    onClick={this.delete}
                >
                    클래스 삭제
                </button>
                <button
                    className={styles.classMenuItem}
                    disabled={!this.props.canClear}
                    onClick={this.clear}
                >
                    모든 샘플 삭제
                </button>
            </div>
        );
    }
}

export default ClassMenu;
