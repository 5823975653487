const MIC_ACTIVE = 'scratch-gui/mic-active';
const MIC_CONNECTED = 'scratch-gui/mic-connected';

const initialState = {
    micActive: false,
    isMicConnected: false
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
    case MIC_ACTIVE:
        return Object.assign({}, state, {
            micActive: action.active,
        });
    case MIC_CONNECTED:
        return Object.assign({}, state, {
            isMicConnected: action.isConnected,
        });
    default:
        return state;
    }
};

const setMicActive = function (active) {
    return {
        type: MIC_ACTIVE,
        active: active
    };
};

const setIsMicConnected = function (isMicConnected) {
    return {
        type: MIC_CONNECTED,
        isConnected: isMicConnected
    };
};

export {
    reducer as default,
    initialState as micActiveInitialState,
    setMicActive,
    setIsMicConnected,
};
