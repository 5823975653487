export const mapLinkToExtensionId = {
    '@semobae-ex/byrobot-drone-link': 'codingRider',
    '@semobae-ex/coding-drone-link': 'codingDrone',
    '@semobae-ex/malrang-link': 'malrang',
    '@semobae-ex/nemo-link': 'nemo',
    '@semobae-ex/probo-technic-link': 'technic',
    '@semobae-ex/skykick-link': 'skykick',
    '@semobae-ex/mc-link': 'markCoding',
}

function invertMap(map) {
    return Object.keys(map).reduce((acc, key) => {
        acc[map[key]] = key;
        return acc;
    }, {});
}

export const mapExtensionIdToLink = invertMap(mapLinkToExtensionId);