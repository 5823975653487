import React, { useEffect } from 'react';
import UpdateHexModal from './update-hex-modal.jsx';
import { useProgress } from './hooks/useProgress.js';
import { HEX_IMAGES, HEX_BUTTON_TEXT, HEX_DESCRIPTION } from './constants/hex-progress-status.js';

const NemoUpdateHex = ({ vm, extensionId, updateHex, handleHexUpdated, onCancel, onConnected }) => {

    const {
        start,
        finish,
        fail,
        progress
    } = useProgress({
        onStart: updateHex,
        onFinished: handleHexUpdated,
        onFail: () => {
            alert('네모 펌웨어 업데이트에 실패했습니다. 네모와 PC의 연결상태를 확인해주세요.');
        }
    });

    useEffect(() => {
        const handleFinish = (id) => {
            if (id === extensionId) {
                finish();
            }
        };
        
        const handleFail = (id) => {
            if (id === extensionId) {
                fail();
            }
        };

        vm.on('HEX_UPDATED', handleFinish);
        vm.on('HEX_UPDATE_FAILED', handleFail);

        return () => {
            vm.removeListener('HEX_UPDATED', handleFinish);
            vm.removeListener('HEX_UPDATE_FAILED', handleFail);
        }
    }, []);

    const onDone = () => {
        onConnected();
        onCancel();
    };

    const assets = {
        dialogImage: HEX_IMAGES[extensionId],
        buttonText: HEX_BUTTON_TEXT[extensionId],
        description: HEX_DESCRIPTION[extensionId]
    }

    return <UpdateHexModal
        progress={progress}
        updateHex={start}
        onDone={onDone}
        assets={assets}
    />;
};

export default NemoUpdateHex;