import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';
import keyMirror from 'keymirror';
import classNames from 'classnames';

import Box from '../box/box.jsx';
import Dots from './dots.jsx';

import closeIcon from '../close-button/icon--close.svg';

import radarIcon from './icons/searching.png';
import backIcon from './icons/back.svg';

import markImg from './img/minecraft.png';

import styles from './connection-modal.css';

const PHASES = keyMirror({
    prescan: null,
    pressbutton: null,
    notfound: null,
    setPort: null
});


const MarkCodingAutoScanningStep = props => (
    <Box className={styles.body}>
        <Box className={styles.activityArea}>
            <div className={styles.activityAreaInfo}>
                <div className={styles.centeredRow}>
                    {props.phase === PHASES.prescan && (
                        <React.Fragment>
                            <img
                                className={styles.connectionImage}
                                src={markImg}
                            />
                        </React.Fragment>
                    )}

                    {props.phase === PHASES.pressbutton && (
                        <React.Fragment>
                            <img
                                className={classNames(styles.radarBig, styles.radarSpin)}
                                src={radarIcon}
                            />
                            <img
                                className={styles.connectionTipIcon}
                                src={props.connectionTipIconURL}
                                width={60}
                            />
                        </React.Fragment>
                    )}
                    {props.phase === PHASES.notfound && (
                        <Box className={styles.instructions}>
                            <FormattedMessage
                                defaultMessage="No devices found"
                                description="Text shown when no devices could be found"
                                id="gui.connection.auto-scanning.noPeripheralsFound"
                            />
                        </Box>
                    )}
                </div>
            </div>
        </Box>
        <Box className={styles.bottomArea}>
            <Box className={classNames(styles.bottomAreaItem, styles.instructions)}>
                {props.phase === PHASES.prescan && (
                    <span>마크링크가 실행되어 있는지 확인하고 연결을 시작하세요.</span>
                )}

                {(props.phase === PHASES.pressbutton) && (
                    <span>마크링크를 찾고있습니다 <br />마크링크가 실행되어있는지 확인해주세요!</span>
                )}

            </Box>
            <Dots
                className={styles.bottomAreaItem}
                counter={0}
                total={3}
            />
           
            <Box className={classNames(styles.bottomAreaItem, styles.buttonRow)}>
                {(props.phase === PHASES.setPort || props.phase === PHASES.prescan) && (
                    <button
                        className={styles.connectionButton}
                        onClick={props.phase === PHASES.prescan ? props.onStartScan : props.onSetNemoSerialPort}
                    >
                        <span>연결 시작하기</span>
                    </button>
                )}
               
                {props.phase === PHASES.pressbutton && (
                    <div className={styles.segmentedButton}>
                        <button
                            disabled
                            className={styles.connectionButton}
                        >
                            <FormattedMessage
                                defaultMessage="Searching..."
                                description="Label indicating that search is in progress"
                                id="gui.connection.connecting-searchbutton"
                            />
                        </button>
                        <button
                            className={styles.connectionButton}
                            onClick={props.onRefresh}
                        >
                            <img
                                className={styles.abortConnectingIcon}
                                src={closeIcon}
                            />
                        </button>
                    </div>
                )}
                {props.phase === PHASES.notfound && (
                    <button
                        className={styles.connectionButton}
                        onClick={props.onRefresh}
                    >
                        <img
                            className={styles.buttonIconLeft}
                            src={backIcon}
                        />
                        <FormattedMessage
                            defaultMessage="Try again"
                            description="Button in prompt for trying a device search again"
                            id="gui.connection.auto-scanning.try-again"
                        />
                    </button>
                )}
            </Box>
        </Box>
    </Box>
);

MarkCodingAutoScanningStep.propTypes = {
    connectionTipIconURL: PropTypes.string,
    onRefresh: PropTypes.func,
    onStartScan: PropTypes.func,
    phase: PropTypes.oneOf(Object.keys(PHASES)),
    title: PropTypes.string,
    onShowPortInput: PropTypes.func,
    onChange: PropTypes.func,
    onSetNemoSerialPort: PropTypes.func
};

MarkCodingAutoScanningStep.defaultProps = {
    phase: PHASES.prescan
};

export {
    MarkCodingAutoScanningStep as default
};
