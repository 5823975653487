import React, { useEffect } from 'react';
import styles from './connection-modal.css';

import Progress from './progress.jsx';
import { PROGRESS_STATUS, HEX_IMAGES, HEX_BUTTON_TEXT, HEX_DESCRIPTION } from './constants/hex-progress-status.js';

const defaultAssets = {
    dialogImage: HEX_IMAGES['default'],
    buttonText: HEX_BUTTON_TEXT['default'],
    description: HEX_DESCRIPTION['default']
};

const UpdateHexModal = ({ updateHex, progress, autoStart, onDone, assets }) => {
    useEffect(() => {
        if (autoStart) {
            updateHex();
        }
    }, []);

    const getStatus = () => {
        if (progress === 100) {
            return PROGRESS_STATUS.DONE;
        }
        if (autoStart || progress >= 1) {
            return PROGRESS_STATUS.IN_PROGRESS;
        }
        return PROGRESS_STATUS.IDLE;
    }

    const status = getStatus();

    let dialogImage = defaultAssets.dialogImage;
    let buttonText = defaultAssets.buttonText[status];
    let description = defaultAssets.description[status];

    if (assets) {
        dialogImage = assets.dialogImage || dialogImage;
        if (assets.buttonText) {
            buttonText = assets.buttonText[status] || buttonText;
        }
        if (assets.description) {
            description = assets.description[status] || description;
        }
    }

    const handleClick = () => {
        if (status === PROGRESS_STATUS.IN_PROGRESS) {
            return;
        }

        if (status === PROGRESS_STATUS.DONE) {
            onDone();
            return;
        }
        
        if (status === PROGRESS_STATUS.IDLE) {
            updateHex();
        }
    };

    return <div className={styles.hexUpdateModal}>
        <img className={styles.hexUpdateImage} src={dialogImage} />
        <div className={styles.hexUpdateContent}>
            <div className={styles.hexUpdateDescription}>
                {description}
            </div>
            <Progress progress={progress} />
            <button
                onClick={handleClick}
                disabled={status === PROGRESS_STATUS.IN_PROGRESS}
                className={styles.hexUpdateButton}
            >
                {status === PROGRESS_STATUS.IN_PROGRESS ? (
                    <div className={styles.firmwareUpdateInProgress}>
                        <div className={styles.dotsWrapper}>
                            <div className={styles.dot} />
                            <div className={styles.dot} />
                            <div className={styles.dot} />
                        </div>
                    </div>
                ) : buttonText}
            </button>
        </div>
    </div>
};

export default UpdateHexModal;