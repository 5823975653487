import bindAll from "lodash.bindall";
import TutorialFailModalComponent from "../components/stage/tutorial-fail-modal.jsx";
import React from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { closeSuccessDialog, closeFailDialog } from "../reducers/malrang-tutorial.js";
import { showMalrangTutorialHelpDialog } from "../semobae_utils/semobae_utils.js";

class TutorialFailModal extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            'handleClickTutorialRestartButton',
            'handleClickTutorialHelpButton',
        ]);
    }
    componentDidMount() {
        this.props.vm.runtime.shouldBroadcastTutorialOutcomes = false;
    }
    componentDidUpdate(prevProps) {
        if (prevProps.semoclassParams.assignmentId !== this.props.semoclassParams.assignmentId) {
            this.props.closeFailDialog();
        }
    }
    clearTutorialDialog() {
        this.props.closeSuccessDialog();
        this.props.closeFailDialog();
    }
    handleClickTutorialRestartButton() {
        this.clearTutorialDialog();
        this.props.vm.runtime.resetGreenFlagClickCount();
    }
    handleClickTutorialHelpButton() {
        this.clearTutorialDialog();
        showMalrangTutorialHelpDialog();
    }
    render() {
        return (
            <TutorialFailModalComponent 
                onClickTutorialRestartButton={this.handleClickTutorialRestartButton}
                onClickTutorialHelpButton={this.handleClickTutorialHelpButton}
                failType={this.props.failType}
            />
        )
    }
}
const mapStateToProps = state => ({
    semoclassParams: state.scratchGui.semobaeState.semoclassParams,
    vm: state.scratchGui.vm,
});

const mapDispatchToProps = dispatch => ({
    closeSuccessDialog: () => dispatch(closeSuccessDialog()),
    closeFailDialog: () => dispatch(closeFailDialog()),
});

TutorialFailModal.propTypes = {
    closeFailDialog: PropTypes.func,
    closeSuccessDialog: PropTypes.func,
    semoclassParams: PropTypes.object,
    vm: PropTypes.object,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TutorialFailModal);