exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".dropdown_dropdown-container_3VdVX {\n    position: relative;\n}\n\n.dropdown_dropdown_2cQyf {\n    width: 100%;\n    height: 30px;\n    border-radius: 8px;\n    border: 1px solid #DDD;\n    background: #FFF;\n    -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);\n            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);\n}\n\n.dropdown_open_2-EkP {\n    border-radius: 8px 8px 0px 0px;\n    border: 1px solid #DDD;\n    border-bottom: none;\n}\n\n.dropdown_selected_1bzn_ {\n    display: -webkit-box;\n    display: -webkit-flex;\n    display: -ms-flexbox;\n    display: flex;\n    -webkit-box-align: center;\n    -webkit-align-items: center;\n        -ms-flex-align: center;\n            align-items: center;\n    height: 100%;\n    gap: 12px;\n    padding: 4px 12px;\n    font-size: 12px;\n    font-weight: 600;\n    color: #000;\n}\n\n.dropdown_dropdown-list_2GHwX {\n    position: absolute;\n    display: -webkit-box;\n    display: -webkit-flex;\n    display: -ms-flexbox;\n    display: flex;\n    -webkit-box-orient: vertical;\n    -webkit-box-direction: normal;\n    -webkit-flex-direction: column;\n        -ms-flex-direction: column;\n            flex-direction: column;\n    -webkit-box-flex: 1;\n    -webkit-flex: 1;\n        -ms-flex: 1;\n            flex: 1;\n    top: 100%;\n    left: 0;\n    width: 100%;\n    z-index: 100;\n    border-radius: 0 0 8px 8px;\n    border: 1px solid #DDD;\n    background: #FFF;\n}\n\n.dropdown_button_1N1XY {\n    width: 100%;\n    height: 30px;\n    text-align: left;\n    padding: 4px 12px;\n    background-color: white;\n    border: 1px solid #DDD;\n    border-width: 1px 0 0 0;\n    font-size: 12px;\n    color: #999;\n\n    &:first-child {\n        border-top: none;\n    }\n\n    &:last-child {\n        border-radius: 0px 0px 8px 8px;\n    }\n}\n", ""]);

// exports
exports.locals = {
	"dropdown-container": "dropdown_dropdown-container_3VdVX",
	"dropdownContainer": "dropdown_dropdown-container_3VdVX",
	"dropdown": "dropdown_dropdown_2cQyf",
	"open": "dropdown_open_2-EkP",
	"selected": "dropdown_selected_1bzn_",
	"dropdown-list": "dropdown_dropdown-list_2GHwX",
	"dropdownList": "dropdown_dropdown-list_2GHwX",
	"button": "dropdown_button_1N1XY"
};