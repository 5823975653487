import { Blocks } from "../../constant/index.js";
import { getOpcodesInRuntime } from "../../utils/malrangHideBlock.js";

class MalrangTutorialBlockService {
    constructor(malrangTutorialModal) {
        this.malrangTutorialModal = malrangTutorialModal;

        this.opcodesInRuntime = getOpcodesInRuntime(this.malrangTutorialModal.props.vm.runtime);
        this.activatedDefaultBlockCategories = this.getActivatedDefaultBlockCategories();
        this.activatedExtensionBlockCategories = this.getActivatedExtensionBlockCategories();
        this.totalBlockCategories = this.getTotalBlockCategories();
        this.blocklyBlocksOpcode = this.getBlocklyBlocksOpcode();
        this.visibleBlocks = this.getVisibleBlocks();
    }

    getActivatedDefaultBlockCategories() {
        const activatedBlockCategorySet = new Set();

        Blocks.DefaultBlockCategories.forEach((blockCategory) => {
            const showBlockCategory = this.opcodesInRuntime
                .some((opcode) => {
                    return opcode.startsWith(blockCategory);
                });

            if (showBlockCategory) {
                activatedBlockCategorySet.add(blockCategory);
            }
        });
        return Array.from(activatedBlockCategorySet);
    }

    getActivatedExtensionBlockCategories() {
        return this.malrangTutorialModal.props.vm.runtime._blockInfo
            .map(info => {
                return info.id;
            })
            .filter((blockId) => {
                return this.opcodesInRuntime
                    .some((opcode) => {
                        return opcode.startsWith(blockId);
                    });
            });
    }

    getTotalBlockCategories() {
        return this.activatedDefaultBlockCategories.concat(this.activatedExtensionBlockCategories);
    }

    getBlocklyBlocksOpcode() {
        const blocklyBlocksOpcodeSet = new Set();
        this.opcodesInRuntime.forEach((opcode) => {
            const isBlockly = this.totalBlockCategories
                .some((blockCategory) => {
                    return opcode.startsWith(blockCategory);
                });

            if (isBlockly) {
                blocklyBlocksOpcodeSet.add(opcode);
            }
        });
        return Array.from(blocklyBlocksOpcodeSet);
    }

    getVisibleBlocks() {
        const visibleBlocks = {};
        this.totalBlockCategories.forEach((category) => {
            visibleBlocks[category] = [];
            this.blocklyBlocksOpcode.forEach((opcode) => {
                if (opcode.startsWith(category)) {
                    visibleBlocks[category].push(opcode);
                }
            })
        });
        return visibleBlocks;
    }
}

export default MalrangTutorialBlockService;