import React from "react";
import UpdateHexModal from "./update-hex-modal.jsx";
import { useProgress } from "./hooks/useProgress.js";
import { HEX_IMAGES, HEX_BUTTON_TEXT, HEX_DESCRIPTION } from './constants/hex-progress-status.js';

const MalrangUpdateHex = ({ vm, extensionId, handleHexUpdated, updateHex, onScanning }) => {
    const {
        start,
        finish,
        fail,
        progress
    } = useProgress({ 
            onStart: updateHex, 
            onFinished: handleHexUpdated, 
            onFail: () => {
                alert('스크래치 모드 활성화에 실패했습니다. 커넥트와 PC의 연결상태를 확인해주세요.');
            }
        });

    React.useEffect(() => {
        const handleFinish = (id) => {
            if (id === extensionId) {
                finish();
            }
        };
    
        const handleFail = (id) => {
            if (id === extensionId) {
                fail();
            }
        };

        vm.on('HEX_UPDATED', handleFinish);
        vm.on('HEX_UPDATE_FAILED', handleFail);

        return () => {
            vm.removeListener('HEX_UPDATED', handleFinish);
            vm.removeListener('HEX_UPDATE_FAILED', handleFail);
        }
    }, []);

    const assets = {
        dialogImage: HEX_IMAGES[extensionId],
        buttonText: HEX_BUTTON_TEXT['default'],
        description: HEX_DESCRIPTION['default']
    }

    return <UpdateHexModal
        updateHex={start}
        progress={progress}
        onDone={onScanning}
        assets={assets}
    />;
};

export default MalrangUpdateHex;