const SKYKICK_EXTENSION_ID = 'skykick';

const CODING_RIDER_EXTENSION_ID = 'codingRider';

const CODING_DRONE_EXTENSION_ID = 'codingDrone';

const byrobotDroneExtensionIds = [SKYKICK_EXTENSION_ID, CODING_RIDER_EXTENSION_ID, CODING_DRONE_EXTENSION_ID];

const isByrobotDroneExtension = (id) => {
    return byrobotDroneExtensionIds.includes(id);
}

export default isByrobotDroneExtension;
export {
    SKYKICK_EXTENSION_ID,
    CODING_RIDER_EXTENSION_ID,
    isByrobotDroneExtension
};
