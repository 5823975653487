import OpcodeLabels from './opcode-labels.js';

const isUndefined = a => typeof a === 'undefined';

/**
 * Convert monitors from VM format to what the GUI needs to render.
 * - Convert opcode to a label and a category
 * @param {string} block.id - The id of the monitor block
 * @param {string} block.spriteName - Present only if the monitor applies only to the sprite
 *     with given target ID. The name of the target sprite when the monitor was created
 * @param {string} block.opcode - The opcode of the monitor
 * @param {object} block.params - Extra params to the monitor block
 * @param {string|number|Array} block.value - The monitor value
 * @param {VirtualMachine} block.vm - the VM instance which owns the block
 * @return {object} The adapted monitor with label and category
 */

// probo
const nemoOpcodes = ['nemo_getAccelerationValue', 'nemo_getSwitchAnalogueValue',
    'nemo_getCompassValue', 'nemo_getCompassDirection', 'nemo_getExpansionSensorValue'];

const droneExtensionIds = ['codingDrone', 'codingRider', 'skykick'];

export default function ({id, spriteName, opcode, params, value, vm}) {
    // Extension monitors get their labels from the Runtime through `getLabelForOpcode`.
    // Other monitors' labels are hard-coded in `OpcodeLabels`.
    let {label, category, labelFn} = (vm && vm.runtime.getLabelForOpcode(opcode)) || OpcodeLabels.getLabel(opcode);

    // Use labelFn if provided for dynamic labelling (e.g. variables)
    if (!isUndefined(labelFn)) label = labelFn(params);

    // Append sprite name for sprite-specific monitors
    if (spriteName) {
        label = `${spriteName}: ${label}`;
    }

    // If value is a number, round it to six decimal places
    if (typeof value === 'number') {
        value = Number(value.toFixed(6));
    }

    // Turn the value to a string, for handle boolean values
    if (typeof value === 'boolean') {
        value = value.toString();
    }

    // Lists can contain booleans, which should also be turned to strings
    if (Array.isArray(value)) {
        value = value.map(item => item.toString());
    }

    // (probo)
    if (nemoOpcodes.indexOf(opcode) > -1) {
        const labelInfo = OpcodeLabels.getLabel(opcode);
        if (labelInfo && !isUndefined(labelInfo.labelFn)) {
            label = labelInfo.labelFn();
        }

        if (opcode === 'nemo_getExpansionSensorValue' ||
            opcode === 'nemo_getCompassValue') {
            const idx = parseInt(params[Object.keys(params)[0]]);
            switch (idx) {
            case 1:
                label = `${label} : ` + `값`;
                break;
            case 2:
                label = `${label} : ` + `각도`;
                break;
            case 3:
                label = `${label} : ` + `절대각도`;
                break;
            case 4:
                label = `${label} : ` + `회전 수`;
                break;
            default:
                label = `${label} : ${params[Object.keys(params)[0]]}`;
                break;
            }
        } else if (opcode === 'nemo_getCompassDirection') {
            const idx = parseInt(params[Object.keys(params)[0]]);
            switch (idx) {
            case 2:
                label = `${label} : ` + `서`;
                break;
            case 3:
                label = `${label} : ` + `북`;
                break;
            case 0:
                label = `${label} : ` + `동`;
                break;
            case 1:
                label = `${label} : ` + `남`;
                break;
            default:
                label = `${label} : ${params[Object.keys(params)[0]]}`;
                break;
            }
        } else {
            label = `${label} : ${params[Object.keys(params)[0]]}`;
        }

        if (Object.keys(params)[1]) {
            label = `${label}_${params[Object.keys(params)[1]]}`;
        }
    }

    if (droneExtensionIds.some(id => opcode.startsWith(id + '_'))) {
        const labelInfo = OpcodeLabels.getLabel(opcode);
        if (labelInfo && !isUndefined(labelInfo.labelFn)) {
            if (Object.keys(params)[0]) {
                label = labelInfo.labelFn(params[Object.keys(params)[0]]);
            } else {
                label = labelInfo.labelFn();
            }
        } else {
            label = `${label}`;
        }
    }

    return {id, label, category, value};
}
