import React from 'react';

const CopyUrlButtonIcon = () => {
    return (
        <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.64706 16C5.12941 16 4.68627 15.8042 4.31765 15.4125C3.94902 15.0208 3.76471 14.55 3.76471 14V2C3.76471 1.45 3.94902 0.979167 4.31765 0.5875C4.68627 0.195833 5.12941 0 5.64706 0H14.1176C14.6353 0 15.0784 0.195833 15.4471 0.5875C15.8157 0.979167 16 1.45 16 2V14C16 14.55 15.8157 15.0208 15.4471 15.4125C15.0784 15.8042 14.6353 16 14.1176 16H5.64706ZM5.64706 14H14.1176V2H5.64706V14ZM1.88235 20C1.36471 20 0.921569 19.8042 0.552941 19.4125C0.184314 19.0208 0 18.55 0 18V5C0 4.71667 0.0901961 4.47917 0.270588 4.2875C0.45098 4.09583 0.67451 4 0.941176 4C1.20784 4 1.43137 4.09583 1.61176 4.2875C1.79216 4.47917 1.88235 4.71667 1.88235 5V18H11.2941C11.5608 18 11.7843 18.0958 11.9647 18.2875C12.1451 18.4792 12.2353 18.7167 12.2353 19C12.2353 19.2833 12.1451 19.5208 11.9647 19.7125C11.7843 19.9042 11.5608 20 11.2941 20H1.88235Z" fill="#999999"/>
        </svg>
    );
};

export default CopyUrlButtonIcon;