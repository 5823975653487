import React from 'react';
import styles from './connection-modal.css';
import { requestToSemobaeExtension, SEMOBAE_DATA_TRANSFER_CHANNEL, SEMOBAE_DATA_TRANSFER_DESTINATION } from '../../semobae_utils/semobae_utils';
import { mapExtensionIdToLink } from '../../lib/utils/mapLinkToExtensionId';
import { TechnicHex } from './technic-update-hex.jsx';
import classNames from 'classnames';
import Dropdown from '../dropdown/Dropdown.jsx';

const SelectPortStep = ({
    image,
    description,
    onSubmit,
    extensionId,
    submitButtonText,
    selectedTechnicHex,
    setSelectedTechnicHex
}) => {
    const [serialPortList, setSerialPortList] = React.useState([]);

    const [selectedPort, setSelectedPort] = React.useState(null);    

    React.useEffect(() => {
        requestToSemobaeExtension(SEMOBAE_DATA_TRANSFER_CHANNEL.SERIAL_PORT_INFO, mapExtensionIdToLink[extensionId], SEMOBAE_DATA_TRANSFER_DESTINATION.WORKER)
            .then((ports) => {
                const portPathList = ports.map(portinfo => portinfo.path);
                setSerialPortList(portPathList);
                setSelectedPort(portPathList[0]);
            })
            .catch(err => {
                console.error(err);
            });
    }, []);

    const submit = () => {
        onSubmit({
            selectedPort,
            selectedTechnicHex,
        });
    };

    const portOptions = serialPortList.map(port => ({
        label: port,
        value: port,
    }));
    const selectedPortIndex = serialPortList.findIndex(port => port === selectedPort);
    const isDisabled = !selectedPort;

    return (
        <div>
            <img className={styles.hexUpdateImage} src={image} />
            <div className={classNames(styles.selectPortContent)}>
                <div className={styles.hexUpdateDescription}>
                    {description}
                </div>
        
                <div className={styles.portDialogContainer}>
                    {
                        extensionId === 'technic' && (
                            <Dropdown
                                onChange={(value) => {
                                    setSelectedTechnicHex(value);
                                }} 
                                optionList={TechnicHex}
                                valueIndex={TechnicHex.findIndex(hex => hex.value === selectedTechnicHex)} 
                            />
                        )
                    }

                    <Dropdown
                        onChange={(value) => {
                            setSelectedPort(value);
                        }}
                        optionList={portOptions}
                        valueIndex={selectedPortIndex}
                    />
                </div>
                
                <button
                    onClick={submit}
                    className={styles.connectionButton}
                    disabled={isDisabled}
                >
                    {submitButtonText}
                </button>
            </div>
        </div>
    );
}

export default SelectPortStep;
