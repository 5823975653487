import ScratchBlocks from 'scratch-blocks';
import { BlockXMLBase } from './BlockXMLBase';

export class Operators extends BlockXMLBase {
    constructor () {
        super();

        const apple = ScratchBlocks.ScratchMsgs.translate('OPERATORS_JOIN_APPLE', 'apple');
        const banana = ScratchBlocks.ScratchMsgs.translate('OPERATORS_JOIN_BANANA', 'banana');
        const letter = ScratchBlocks.ScratchMsgs.translate('OPERATORS_LETTEROF_APPLE', 'a');
    
        this.operator_join = `
            <block type="operator_join">
                <value name="STRING1">
                    <shadow type="text">
                        <field name="TEXT">${apple} </field>
                    </shadow>
                </value>
                <value name="STRING2">
                    <shadow type="text">
                        <field name="TEXT">${banana}</field>
                    </shadow>
                </value>
            </block>`;

        this.operator_letter_of = `
            <block type="operator_letter_of">
                <value name="LETTER">
                    <shadow type="math_whole_number">
                        <field name="NUM">1</field>
                    </shadow>
                </value>
                <value name="STRING">
                    <shadow type="text">
                        <field name="TEXT">${apple}</field>
                    </shadow>
                </value>
            </block>`;

        this.operator_length = `
            <block type="operator_length">
                <value name="STRING">
                    <shadow type="text">
                        <field name="TEXT">${apple}</field>
                    </shadow>
                </value>
            </block>`;

        this.operator_contains = `
            <block type="operator_contains" id="operator_contains">
                <value name="STRING1">
                <shadow type="text">
                    <field name="TEXT">${apple}</field>
                </shadow>
                </value>
                <value name="STRING2">
                <shadow type="text">
                    <field name="TEXT">${letter}</field>
                </shadow>
                </value>
            </block>`;
    }

    blockOptions = {
        operator_add: {
            order: 0,
            stage: true,
            sprite: true
        },
        operator_subtract: {
            order: 1,
            stage: true,
            sprite: true
        },
        operator_multiply: {
            order: 2,
            stage: true,
            sprite: true
        },
        operator_divide: {
            order: 3,
            stage: true,
            sprite: true
        },
        operator_random: {
            order: 4,
            stage: true,
            sprite: true
        },
        operator_gt: {
            order: 5,
            stage: true,
            sprite: true
        },
        operator_lt: {
            order: 6,
            stage: true,
            sprite: true
        },
        operator_equals: {
            order: 7,
            stage: true,
            sprite: true
        },
        operator_and: {
            order: 8,
            stage: true,
            sprite: true
        },
        operator_or: {
            order: 9,
            stage: true,
            sprite: true
        },
        operator_not: {
            order: 10,
            stage: true,
            sprite: true
        },
        operator_join: {
            order: 11,
            stage: true,
            sprite: true
        },
        operator_letter_of: {
            order: 12,
            stage: true,
            sprite: true
        },
        operator_length: {
            order: 13,
            stage: true,
            sprite: true
        },
        operator_contains: {
            order: 14,
            stage: true,
            sprite: true
        },
        operator_mod: {
            order: 15,
            stage: true,
            sprite: true
        },
        operator_round: {
            order: 16,
            stage: true,
            sprite: true
        },
        operator_mathop: {
            order: 17,
            stage: true,
            sprite: true
        }
    };

    operator_add = `
        <block type="operator_add">
            <value name="NUM1">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
            <value name="NUM2">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
        </block>`;

    operator_subtract = `
        <block type="operator_subtract">
            <value name="NUM1">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
            <value name="NUM2">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
        </block>`;
    
    operator_multiply = `
        <block type="operator_multiply">
            <value name="NUM1">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
            <value name="NUM2">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
        </block>`;

    operator_divide = `
        <block type="operator_divide">
            <value name="NUM1">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
            <value name="NUM2">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
        </block>`;

    operator_random = `
        <block type="operator_random">
            <value name="FROM">
                <shadow type="math_number">
                    <field name="NUM">1</field>
                </shadow>
            </value>
            <value name="TO">
                <shadow type="math_number">
                    <field name="NUM">10</field>
                </shadow>
            </value>
        </block>`;

    operator_gt = `
        <block type="operator_gt">
            <value name="OPERAND1">
                <shadow type="text">
                    <field name="TEXT"/>
                </shadow>
            </value>
            <value name="OPERAND2">
                <shadow type="text">
                    <field name="TEXT">50</field>
                </shadow>
            </value>
        </block>`;

    operator_lt = `
        <block type="operator_lt">
            <value name="OPERAND1">
                <shadow type="text">
                    <field name="TEXT"/>
                </shadow>
            </value>
            <value name="OPERAND2">
                <shadow type="text">
                    <field name="TEXT">50</field>
                </shadow>
            </value>
        </block>`;

    operator_equals = `
        <block type="operator_equals">
            <value name="OPERAND1">
                <shadow type="text">
                    <field name="TEXT"/>
                </shadow>
            </value>
            <value name="OPERAND2">
                <shadow type="text">
                    <field name="TEXT">50</field>
                </shadow>
            </value>
        </block>`;

    operator_and = `
        <block type="operator_and"/>`;

    operator_or = `
        <block type="operator_or"/>`;

    operator_not = `
        <block type="operator_not"/>`;

    operator_mod = `
        <block type="operator_mod">
            <value name="NUM1">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
            <value name="NUM2">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
        </block>`;

    operator_round = `
        <block type="operator_round">
            <value name="NUM">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
        </block>`;

    operator_mathop = `
        <block type="operator_mathop">
            <value name="NUM">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
        </block>`;
}