import React from 'react';
import getCostumeUrl from '../../../lib/get-costume-url.js';
import styles from './malrang-sprite-list.css';
import classNames from 'classnames';

const MalrangSpriteItemComponent = function (props) {
    const {
        sprite,
        clicked,
        onClick,
        spriteHiddenStyle
    } = props;

    return (
        <div className={styles.spriteWrapper}>
            <div className={classNames(styles.spriteSelectorItem, clicked ? styles.clickedSpriteSelectorItem : '')} onClick={onClick}>
                <div className={styles.spriteImageOuter}>
                    <div className={styles.spriteImageInner}>
                        <img className={styles.spriteImage} draggable={false} src={getCostumeUrl(sprite.costume.asset)}/>
                    </div>
                </div>
                {spriteHiddenStyle !== null ? (
                    <div className={spriteHiddenStyle}>
                        <div className={styles.spriteName}>무대 숨기기</div>
                    </div>
                ) : (
                    <div className={styles.spriteInfo}>
                        <div className={styles.spriteName}>{sprite.name}</div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default MalrangSpriteItemComponent;