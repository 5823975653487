import PropTypes from 'prop-types';
import React from 'react';
import bindAll from 'lodash.bindall';
import MecanumWheelModalComponent from '../components/mecanumwheel-modal/macanumwheel-modal.jsx';
import VM from 'scratch-vm';
import {connect} from 'react-redux';
import {closeMecanumWheelModal} from '../reducers/modals';
import { MOTOR_POSITION } from 'scratch-vm/src/extensions/scratch3_mecanumWheel/constants.js';

const SEPERATOR = ':';

class MecanumWheelModal extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleClose',
            'handleSave',
            'handleChangeWheelTypeAndDirection',
            'handleChangeWheelPinNumber',
            'updateDuplicateWheels',
            'canSave',
        ]);
        this.state = {};
    }

    componentDidMount () {
        const updates = MOTOR_POSITION.map(position => {
            return this.props.vm.extensionManager.requestDispatchCall('mecanumWheel', 'getWheel', {
                MOTOR_POSITION: position
            });
        });
        Promise.all(updates)
            .then(results => {
                this.setState({
                    frontLeft: {
                        ...results[0],
                        isDuplicate: false
                    },
                    frontRight: {
                        ...results[1],
                        isDuplicate: false
                    },
                    rearLeft: {
                        ...results[2],
                        isDuplicate: false
                    },
                    rearRight: {
                        ...results[3],
                        isDuplicate: false
                    }
                });
            })
            .catch(console.warn);
    }

    componentDidUpdate (prevProps, prevState) {
        for (const position of MOTOR_POSITION) {
            const prevWheel = prevState[position];
            const wheel = this.state[position];
            if (!prevWheel || !wheel) {
                continue;
            }
            if (prevWheel.type !== wheel.type || prevWheel.pinNumber !== wheel.pinNumber) {
                this.updateDuplicateWheels();
                break;
            }
        }
    }

    compositeMotorTypeAndDirection (...strs) {
        return strs.join(SEPERATOR);
    }

    updateDuplicateWheels () {
        const duplicates = new Set();
        for (let i = 0; i < MOTOR_POSITION.length - 1; ++i) {
            const position = MOTOR_POSITION[i];
            const wheel = this.state[position];
            const duplicatedMotorPosition = MOTOR_POSITION.slice(i + 1).find(p => {
                const otherWheel = this.state[p];
                return wheel.type === otherWheel.type && wheel.pinNumber === otherWheel.pinNumber;
            });
            if (duplicatedMotorPosition) {
                duplicates.add(position);
                duplicates.add(duplicatedMotorPosition);
            }
        }
        for (const position of MOTOR_POSITION) {
            this.setState({
                [position]: {
                    ...this.state[position],
                    isDuplicate: duplicates.has(position)
                }
            });
        }
    }

    canSave () {
        return MOTOR_POSITION.every(position => {
            const wheel = this.state[position];
            return wheel && !wheel.isDuplicate;
        });
    }

    handleChangeWheelTypeAndDirection (event, position) {
        const wheelStateStr = event.target.value;
        const [type, direction] = wheelStateStr.split(SEPERATOR);
        this.setState({
            [position]: {
                ...this.state[position],
                type,
                direction
            }
        });
    }

    handleChangeWheelPinNumber (event, position) {
        this.setState({
            [position]: {
                ...this.state[position],
                pinNumber: Number(event.target.value)
            }
        });
    }

    handleClose () {
        this.props.onClose();
    }

    handleSave () {
        const updates = MOTOR_POSITION.map(position => {
            const wheel = this.state[position];
            return this.props.vm.extensionManager.requestDispatchCall('mecanumWheel', 'setWheel', {
                MOTOR_POSITION: position,
                MOTOR_TYPE: wheel.type,
                MOTOR_DIRECTION: wheel.direction,
                PIN_NUMBER: wheel.pinNumber
            });
        });
        return Promise.all(updates)
            .catch(console.warn)
            .finally(() => {
                this.props.onClose();
            });
    }

    render () {
        return (
            <MecanumWheelModalComponent
                canSave={this.canSave()}
                motors={{
                    frontLeft: this.state.frontLeft,
                    frontRight: this.state.frontRight,
                    rearLeft: this.state.rearLeft,
                    rearRight: this.state.rearRight
                }}
                compositeMotorTypeAndDirection={this.compositeMotorTypeAndDirection}
                onCancel={this.handleClose}
                onSave={this.handleSave}
                onChangeWheelTypeAndDirection={this.handleChangeWheelTypeAndDirection}
                onChangeWheelPinNumber={this.handleChangeWheelPinNumber}
                {...this.props}
            />
        );
    }
}

MecanumWheelModal.propTypes = {
    vm: PropTypes.instanceOf(VM).isRequired
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
    onClose: () => {
        dispatch(closeMecanumWheelModal());
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MecanumWheelModal);
