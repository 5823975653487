import React, {Component} from 'react';
import bindAll from 'lodash.bindall';
import style from './camera-selector.css';
import arrowDownIcon from './arrow-down.svg';
import arrowUpIcon from './arrow-up.svg';
class CameraSelector extends Component {

    constructor (props) {
        super(props);
        this.state = {
            cameraDevices: [],
            selectBoxOpened: false
        };
        bindAll(this, [
            'handleDeviceChange',
            'handleSelectWrapperClick',
            'handleBlurSelect'
        ]);
    }

    componentDidMount () {
        navigator.mediaDevices.enumerateDevices().then(devices => {
            const cameraDevices = devices.filter(device => device.kind === 'videoinput');
            this.setState({cameraDevices});
        });
    }

    handleDeviceChange (e) {
        this.props.onDeviceChange(e.target.value);
    }

    handleSelectWrapperClick () {
        this.setState({selectBoxOpened: !this.state.selectBoxOpened});
    }

    handleBlurSelect () {
        this.setState({selectBoxOpened: false});
    }


    render () {
        return (<div
            className={style.cameraSelectorWrapper}
            onClick={this.handleSelectWrapperClick}
        >
            <select
                className={style.cameraSelector}
                onChange={this.handleDeviceChange}
                onBlur={this.handleBlurSelect}
            >
                {this.state.cameraDevices.map((device, index) => (<option
                    key={index}
                    value={device.deviceId}
                >{device.label}</option>))}
            </select>
            <img
                className={style.arrowDownIcon}
                src={this.state.selectBoxOpened ? arrowUpIcon : arrowDownIcon}
            />
        </div>);
    }
}

export default CameraSelector;
