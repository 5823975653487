import PropTypes from 'prop-types';
import React from 'react';
import Box from '../box/box.jsx';
import {connect} from 'react-redux';
import errorCircle from './error_circle.svg';
import {injectIntl, FormattedMessage} from 'react-intl';
import styles from './reject-remove-extension-alert.css';

const HEIGHT_OF_TAB = 48;

const RejectRemoveExtensionAlertComponent = ({positionY, message}) => {
    const offsetY = positionY - HEIGHT_OF_TAB;
    
    return (<Box className={styles.rejectRemoveExtensionAlertContainer} >
        <div
            className={styles.alertContainer}
            style={{top: offsetY}}
        >
            <div className={styles.alert} >
                <img
                    className={styles.alertIconImage}
                    src={errorCircle}
                    style={{marginRight: '5px'}}
                    alt="remove extension alert"
                />
                <div className={styles.alertText}>
                    {message === 'blockIsUsed' && (
                        <FormattedMessage
                            defaultMessage="This extension is currently in use and cannot be removed."
                            description="Message indicating that an extension block is used in the project"
                            id="gui.alerts.blockIsUsed"
                        />
                    )}
                    {message === 'peripheralIsConnected' && (
                        <FormattedMessage
                            defaultMessage="This extension is currently connected to a device and cannot be removed."
                            description="Message indicating that an extension peripheral is connected"
                            id="gui.alerts.peripheralIsConnected"
                        />
                    )}
                </div>
            </div>
        </div>
    </Box>);
};

RejectRemoveExtensionAlertComponent.propTypes = {
    positionY: PropTypes.number,
    message: PropTypes.string
};

RejectRemoveExtensionAlertComponent.defaultProps = {
};


const mapStateToProps = state => ({
    positionY: state.scratchGui.removeExtensionState.positionYofAlert
});


export default injectIntl(connect(
    mapStateToProps,
)(RejectRemoveExtensionAlertComponent));
