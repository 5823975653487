import React from 'react';
import PropTypes from 'prop-types';
import CustomInputMenuItem from '../../containers/custom-input-menu-item.jsx';

import styles from './custom-input-popup.css';
import LineGraph from '../graph/line-graph.jsx';
import { useLineGraph } from '../graph/useLineGraph.js';

const SENSOR_NAME = {
    SWITCH: '스위치',
    INFRARED: '적외선',
    MAGNET: '자석',
    ROTATION: '회전',
    COLOR: '컬러',
    ULTRASONIC: '초음파',
    HEARTRATE: '심박',
    ILLUMINANCE: '조도',
    SOUND: '소리',
    TILT: '기울기',
    PRESSURE: '압력',
    MULTIKEY: '멀티키',
    COMPASS: '나침반',
    TRIAXIS: '3가속',
    DIVIDER: '분배기',
    ACCELEROMETER: '가속도'
};

const CATEGORY_COLOR = {
    malrang_monitor: '#0FBD8C',
    technic_monitor: '#FFB13C'
};

const STROKE_COLOR = {
    malrang_monitor: '#0FBD8C',
    technic_monitor: '#DD8400'
};

const CustomInputMonitorComponent = props => {
    const categoryColor = CATEGORY_COLOR[props.monitorName] ?? CATEGORY_COLOR.malrang_monitor;
    const strokeColor = STROKE_COLOR[props.monitorName] ?? STROKE_COLOR.malrang_monitor;

    const { lineData, addData, clearData } = useLineGraph({
        initLineDataset: [],
        option: {
            maxDataLength: 10,
            drawInitialLine: true,
            name: props.label,
        }
    });

    React.useEffect(() => {
        addData({ name: props.label, sensor: props.value });

        const interval = setInterval(() => {
            addData({ name: props.label, sensor: props.value });
        }, 100);

        return () => {
            clearInterval(interval);
        };
    }, [props.value]);

    React.useEffect(() => {
        clearData();
    }, [props.sensorSelected]);

    return (
        <div className={styles.customInputMonitor} >
            <div className={styles.defaultMonitor} >
                <div className={styles.row} >
                    <div className={styles.label} >
                        {props.label}
                    </div>
                    <div className={styles.menu} >
                        <div
                            className={styles.topItem}
                            onClick={props.onClickMenuHandler}
                        >
                            {SENSOR_NAME[props.sensorSelected] + '▼'}
                        </div>
                    </div>
                    <div
                        className={styles.value}
                        style={{background: categoryColor}}
                    >
                        {props.value}
                    </div>
                </div>
            </div>
            {props.isMenuOpen ? (
                <>
                    <div className={styles.verticalDivider} />
                    <div className={styles.itemContainer}>
                        {Object.keys(SENSOR_NAME).map(item => (
                            <CustomInputMenuItem
                                item={item}
                                itemName={SENSOR_NAME[item]}
                                onClickItemHandler={props.onClickItemHandler}
                            />
                        ))}
                    </div>
                </>
            ) : null}
            <div className={styles.lineGraphPosition}>
                <LineGraph
                    data={lineData}
                    dataKey={'sensor'}
                    wrapperClassName={styles.graphWrapper}
                    chartClassName={styles.graph}
                    domain={[0, 255]}
                    height={50}
                    width={50}
                    strokeColor={strokeColor}
                />
            </div>
        </div>
    );
};

CustomInputMonitorComponent.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    onClickMenuHandler: PropTypes.func.isRequired,
    onClickItemHandler: PropTypes.func.isRequired
};

export default CustomInputMonitorComponent;