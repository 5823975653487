import bindAll from 'lodash.bindall';
import React from 'react';
import {connect} from 'react-redux';

import MalrangSpriteListComponent from '../components/malrang-tutorial-modal/sprite-selector/malrang-sprite-list.jsx';
import { setHiddenSpriteFromStage } from '../reducers/malrang-tutorial.js';

class MalrangSpriteList extends React.PureComponent {
	constructor (props) {
		super(props);
		bindAll(this, [
			'isSpriteHideFromStage',
		]);
	}

	isSpriteHideFromStage (spriteId) {
		return this.props.hiddenSpriteIdsFromStage.has(spriteId);
	}

	render () {
		const {
			items,
			initialSpriteId,
			isSpriteHidden,
			setSpriteVisibleOnTargetPane,
			setInitialSprite,
			type,
			handleClickHideFromStageCheckbox,
			tooltip
		} = this.props;

		return (
			<MalrangSpriteListComponent
				items={items}
				initialSpriteId={initialSpriteId}
				isSpriteHidden={isSpriteHidden}
				setSpriteVisibleOnTargetPane={setSpriteVisibleOnTargetPane}
				setInitialSprite={setInitialSprite}
				type={type}
				isSpriteHideFromStage={this.isSpriteHideFromStage}
				onClickHideFromStageCheckbox={handleClickHideFromStageCheckbox}
				tooltip={tooltip}
			/>
		);
	}
}

const mapStateToProps = state => ({
	hiddenSpriteIdsFromStage: state.scratchGui.malrangTutorialState.hiddenSpriteIdsFromStage,
});

const mapDispatchToProps = dispatch => ({
	handleClickHideFromStageCheckbox: (spriteId) => {
		dispatch(setHiddenSpriteFromStage(spriteId));
	}
});

const ConnectedComponent = connect(
	mapStateToProps,
	mapDispatchToProps
)(MalrangSpriteList);

export default ConnectedComponent;
