import PropTypes from 'prop-types';
import React from 'react';
import { defineMessages, injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import bindAll from 'lodash.bindall';

import Box from '../box/box.jsx';
import Modal from '../../containers/modal.jsx';

import styles from './mecanumwheel-modal.css';

import MotorSelectBox from './motor-selectbox.jsx';

import mecanumwheelImgKo from './mecanumwheel-ko.png';

import { MOTOR_POSITION } from 'scratch-vm/src/extensions/scratch3_mecanumWheel/constants.js';

const messages = defineMessages({
    modalTitle: {
        id: 'gui.mecanumWheelModal.title',
        defaultMessage: 'MecanumWheel',
        description: 'Title for the mecanumwheel modal'
    },
    saveButton: {
        id: 'gui.mecanumWheelModal.saveButton',
        defaultMessage: 'Save',
        description: 'Label for the save button'
    }
});

const FULLSCREEN_MODAL_BOUNDARY = 600;

class MecanumWheelModalComponent extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleResize'
        ]);
        this.state = {
            windowHeight: window.innerHeight
        };
    }

    componentDidMount () {
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount () {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize () {
        this.setState({
            windowHeight: window.innerHeight
        });
    }

    render () {
        const {
            intl,
            onCancel,
            motors,
            compositeMotorTypeAndDirection,
            onChangeWheelTypeAndDirection,
            onChangeWheelPinNumber,
            onSave
        } = this.props;

        const [FRONT_LEFT, FRONT_RIGHT, REAR_LEFT, REAR_RIGHT] = MOTOR_POSITION;

        return (
            <Modal
                className={classNames(styles.modalContent, window.innerHeight > FULLSCREEN_MODAL_BOUNDARY ? styles.fullScreen : null)}
                contentLabel={intl.formatMessage(messages.modalTitle)}
                headerClassName={styles.header}
                headerImage=''
                id='mecanumwheelModal'
                onRequestClose={onCancel}
            >
                <Box className={styles.body}>
                    <Box className={styles.activityArea}>
                        <Box className={styles.activityAreaItem}>
                            <div className={styles.mecanumwheelSettingBox}>
                                <MotorSelectBox
                                    position={FRONT_LEFT}
                                    motor={motors[FRONT_LEFT]}
                                    compositeMotorTypeAndDirection={compositeMotorTypeAndDirection}
                                    onChangeWheelTypeAndDirection={onChangeWheelTypeAndDirection}
                                    onChangeWheelPinNumber={onChangeWheelPinNumber}
                                />
                                <MotorSelectBox
                                    position={FRONT_RIGHT}
                                    motor={motors[FRONT_RIGHT]}
                                    compositeMotorTypeAndDirection={compositeMotorTypeAndDirection}
                                    onChangeWheelTypeAndDirection={onChangeWheelTypeAndDirection}
                                    onChangeWheelPinNumber={onChangeWheelPinNumber}
                                />
                                <MotorSelectBox
                                    position={REAR_LEFT}
                                    motor={motors[REAR_LEFT]}
                                    compositeMotorTypeAndDirection={compositeMotorTypeAndDirection}
                                    onChangeWheelTypeAndDirection={onChangeWheelTypeAndDirection}
                                    onChangeWheelPinNumber={onChangeWheelPinNumber}
                                />
                                <MotorSelectBox
                                    position={REAR_RIGHT}
                                    motor={motors[REAR_RIGHT]}
                                    compositeMotorTypeAndDirection={compositeMotorTypeAndDirection}
                                    onChangeWheelTypeAndDirection={onChangeWheelTypeAndDirection}
                                    onChangeWheelPinNumber={onChangeWheelPinNumber}
                                />
                                <img
                                    className={styles.mecanumwheelImg}
                                    src={mecanumwheelImgKo}
                                    alt='mecanumwheel image'
                                />
                            </div>
                        </Box>
                    </Box>
                    <Box className={styles.bottomArea}>
                        <Box className={classNames(styles.bottomAreaItem, styles.buttonRow)}>
                            <button
                                className={styles.saveButton}
                                disabled={!this.props.canSave}
                                onClick={onSave}
                            >
                                {intl.formatMessage(messages.saveButton)}
                            </button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        );
    }
}

MecanumWheelModalComponent.propTypes = {
    intl: intlShape,
    canSave: PropTypes.bool.isRequired,
    motors: PropTypes.shape({
        frontLeft: PropTypes.shape({
            type: PropTypes.string,
            direction: PropTypes.string,
            pinNumber: PropTypes.number,
            isDuplicate: PropTypes.bool
        }),
        frontRight: PropTypes.shape({
            type: PropTypes.string,
            direction: PropTypes.string,
            pinNumber: PropTypes.number,
            isDuplicate: PropTypes.bool
        }),
        rearLeft: PropTypes.shape({
            type: PropTypes.string,
            direction: PropTypes.string,
            pinNumber: PropTypes.number,
            isDuplicate: PropTypes.bool
        }),
        rearRight: PropTypes.shape({
            type: PropTypes.string,
            direction: PropTypes.string,
            pinNumber: PropTypes.number,
            isDuplicate: PropTypes.bool
        })
    }).isRequired,
    compositeMotorTypeAndDirection: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onChangeWheelTypeAndDirection: PropTypes.func.isRequired,
    onChangeWheelPinNumber: PropTypes.func.isRequired
};

MecanumWheelModalComponent.defaultProps = {};

export default injectIntl(MecanumWheelModalComponent);
