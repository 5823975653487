import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import styles from './coding-play-button.css';

const CodingPlayButtonComponent = ({
    isPlaying,
    playLinkCoding,
    stopLinkCoding,
    ...props
}) => (
    <div
        className={styles.codingPlayButton}
        onClick={isPlaying ? stopLinkCoding : playLinkCoding}
        {...props}
    >
        <button
            className={classNames(styles.btnPlay, {
                [styles.playing]: isPlaying
            })}
        >
            <i
                className={classNames(styles.iconPlay, {
                    [styles.iconStop]: isPlaying
                })}
                draggable={false}
            />
        </button>
    </div>
);

CodingPlayButtonComponent.propTypes = {
    isPlaying: PropTypes.bool.isRequired,
    playLinkCoding: PropTypes.func.isRequired,
    stopLinkCoding: PropTypes.func.isRequired
};

export default CodingPlayButtonComponent;
