export class BlockXMLBase {
    blockSeparator = '<sep gap="36"/>';

    sortBlocks = (blocks) => {
        return blocks.sort((a, b) => {
            return this.blockOptions[a].order - this.blockOptions[b].order;
        });
    };

    blocksToRender = (blocks, isStage, showAllBlocks) => {
        return blocks.filter((opcode) => {
            if (showAllBlocks) { 
                return true;
            }

            if (isStage) {
                return this.blockOptions[opcode].stage;
            } else {
                return this.blockOptions[opcode].sprite;
            }
        })
        .map((opcode) => {
            return this[opcode];
        })
        .join('');
    };

    filterDraggableBlocks = (blocks) => {
        return blocks.filter((opcode) => {
            return this.blockOptions[opcode];
        });
    };
}