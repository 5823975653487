import { BlockXMLBase } from './BlockXMLBase';

export class Events extends BlockXMLBase {
    constructor () {
        super();
    }

    blockOptions = {
        event_whenflagclicked: {
            sprite: true,
            stage: true,
            order: 0
        },
        event_whenkeypressed: {
            sprite: true,
            stage: true,
            order: 1
        },
        event_whenstageclicked: {
            sprite: false,
            stage: true,
            order: 2
        },
        event_whenthisspriteclicked: {
            sprite: true,
            stage: false,
            order: 3
        },
        event_whenbackdropswitchesto: {
            sprite: true,
            stage: true,
            order: 4
        },
        event_whengreaterthan: {
            sprite: true,
            stage: true,
            order: 5
        },
        event_whenbroadcastreceived: {
            sprite: true,
            stage: true,
            order: 6
        },
        event_broadcast_menu: {
            sprite: true,
            stage: true,
            order: 7
        },
        event_broadcastandwait: {
            sprite: true,
            stage: true,
            order: 8
        }
    };

    event_whenflagclicked = `
        <block type="event_whenflagclicked"/>`;

    event_whenkeypressed = `
        <block type="event_whenkeypressed"/>`;

    event_whenstageclicked = `
        <block type="event_whenstageclicked"/>`;

    event_whenthisspriteclicked = `
        <block type="event_whenthisspriteclicked"/>`;

    event_whenbackdropswitchesto = `
        <block type="event_whenbackdropswitchesto"/>`;

    event_whengreaterthan = `
        <block type="event_whengreaterthan">
            <value name="VALUE">
                <shadow type="math_number">
                    <field name="NUM">10</field>
                </shadow>
            </value>
        </block>`;

    event_whenbroadcastreceived = `
        <block type="event_whenbroadcastreceived"/>`;

    event_broadcast_menu = `
        <block type="event_broadcast">
            <value name="BROADCAST_INPUT">
                <shadow type="event_broadcast_menu"></shadow>
            </value>
        </block>`;

    event_broadcastandwait = `
        <block type="event_broadcastandwait">
            <value name="BROADCAST_INPUT">
                <shadow type="event_broadcast_menu"></shadow>
            </value>
        </block>`;
}