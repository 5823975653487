import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';
import keyMirror from 'keymirror';
import classNames from 'classnames';

import Box from '../box/box.jsx';
import Dots from './dots.jsx';

import closeIcon from '../close-button/icon--close.svg';
import Carousel from '../carousel/Carousel.jsx';
import radarIcon from './icons/searching.png';
import nemoIcon from './icons/icon_nemo_black.svg';
import bluetoothIcon from './icons/bluetooth-white.svg';
import backIcon from './icons/back.svg';

import nemoImg from './img/nemo.png';
import malrangNotFoundImg from './img/malrang_not_found.png';
import malrangBlueToothConnect from './img/malrang_bluetooth_connect.png';
import malrangUsbConnect from './img/malrang_usb_connect.png';
import technicBlueToothConnect from './img/technic_bluetooth_connect.png';
import technicUsbConnect from './img/technic_usb_connect.png';
import droneKoreanConnectDialog from './img/drone_dialog_korean.png';
import droneEnglishConnectDialog from './img/drone_dialog_english.png';
import droneJapaneseConnectDialog from './img/drone_dialog_japanese.png';

import styles from './connection-modal.css';
import MarkCodingAutoScanningStep from './mark-coding-auto-scanning-step.jsx';

import {isByrobotDroneExtension} from '../../lib/isByrobotDroneEx.js';

const PHASES = keyMirror({
    prescan: null,
    pressbutton: null,
    notfound: null,
    setPort: null
});

const bluetoothIcons = {
    nemo: ''
};

const connectionImgs = {
    nemo: nemoImg,
    malrangBlueTooth: malrangBlueToothConnect,
    malrangUsb: malrangUsbConnect,
    technicBlueTooth: technicBlueToothConnect,
    technicUsb: technicUsbConnect,
};

const imgSlideDuration = 4500;

const isMarkCoding = props => props.title === 'markCoding';

const getDotProgress = (phase) => {
    if (phase === PHASES.prescan || phase === PHASES.setPort) {
        return 0;
    }
    if (phase === PHASES.pressbutton) {
        return 1;
    }
    return -1;
}

const AutoScanningStep = props => {
    if (isMarkCoding(props)) {
        return <MarkCodingAutoScanningStep {...props} />;
    }

    const droneConnectionSrc = {
        ko: droneKoreanConnectDialog,
        en: droneEnglishConnectDialog,
        ja: droneJapaneseConnectDialog
    };

    return (
        <Box className={styles.body}>
            <Box className={styles.activityArea}>
                <div className={styles.activityAreaInfo}>
                    <div className={styles.centeredRow}>
                        {(props.phase === PHASES.prescan || props.phase === PHASES.setPort) && (
                            <React.Fragment>
                                {props.title in connectionImgs
                                    ?
                                        <img
                                            className={styles.connectionImage}
                                            src={connectionImgs[props.title]}
                                        />
                                    :
                                    (
                                        props.title === 'malrang' ?
                                            <Carousel
                                                className={styles.connectionImage}
                                                imgs={[connectionImgs.malrangBlueTooth, connectionImgs.malrangUsb]}
                                                duration={imgSlideDuration}
                                            />
                                        : 
                                        props.title === 'technic' ? 
                                            <Carousel
                                                className={styles.connectionImage}
                                                imgs={[connectionImgs.technicBlueTooth, connectionImgs.technicUsb]}
                                                duration={imgSlideDuration}
                                            />
                                        :
                                        isByrobotDroneExtension(props.title) ?
                                            <img
                                                className={styles.connectionImage}
                                                src={droneConnectionSrc[document.documentElement.lang] ?? droneConnectionSrc.en}
                                            />
                                        :
                                            <img
                                                className={styles.radarBig}
                                                src={radarIcon}
                                            />
                                    )
                                }

                                {(props.title === 'malrang' || props.title === 'technic' || isByrobotDroneExtension(props.title) || props.title in connectionImgs) ? '' :
                                    <img
                                        className={classNames(styles.bluetoothCenteredIcon, styles[`${props.title}-bluetooth-centered-icon`])}
                                        src={bluetoothIcons[props.title] ? bluetoothIcons[props.title] : bluetoothIcon}
                                    />}

                            </React.Fragment>
                        )}

                        {props.phase === PHASES.pressbutton && (
                            <React.Fragment>
                                <img
                                    className={classNames(styles.radarBig, styles.radarSpin)}
                                    src={radarIcon}
                                />
                                {props.title === 'nemo' && (
                                    <img
                                        className={styles.nemoBluetoothCenteredIcon}
                                        src={nemoIcon}
                                    />
                                )}
                                <img
                                    className={styles.connectionTipIcon}
                                    src={props.connectionTipIconURL}
                                />
                            </React.Fragment>
                        )}
                        {props.phase === PHASES.notfound && (
                            <Box className={styles.instructions}>
                                {props.title === 'malrang' ? (
                                    <img
                                        className={styles.malrangNotFoundImage}
                                        src={malrangNotFoundImg}
                                        alt="Malrang Not Found"
                                    />
                                ) : (
                                    <FormattedMessage
                                        defaultMessage="No devices found"
                                        description="Text shown when no devices could be found"
                                        id="gui.connection.auto-scanning.noPeripheralsFound"
                                    />
                                )}
                            </Box>
                        )}
                    </div>
                </div>
            </Box>
            <Box className={styles.bottomArea}>
                <Box className={classNames(styles.bottomAreaItem, styles.instructions)}>
                    {props.phase === PHASES.prescan && (
                        <FormattedMessage
                            defaultMessage="Have your device nearby, then begin searching."
                            description="Prompt for beginning the search"
                            id="gui.connection.auto-scanning.prescan"
                        />
                    )}
                    {(props.phase === PHASES.pressbutton && props.title !== 'nemo') && (
                        <FormattedMessage
                            defaultMessage="Press the button on your device."
                            description="Prompt for pushing the button on the device"
                            id="gui.connection.auto-scanning.pressbutton"
                        />
                    )}
                    {(props.phase === PHASES.pressbutton && props.title === 'nemo') && (
                        <>
                            <FormattedMessage
                                defaultMessage="Search takes a long time."
                                description="Search takes a long time"
                                id="gui.connection.auto-scanning.search-takes-a-long-time"
                            />
                            <br />
                            <FormattedMessage
                                defaultMessage="Press the button on your device."
                                description="Prompt for pushing the button on the device"
                                id="gui.connection.auto-scanning.pressbutton"
                            />
                        </>
                    )}
                </Box>
                {props.title !== 'nemo' && (
                    <Dots
                        className={styles.bottomAreaItem}
                        error={props.phase === PHASES.notfound}
                        counter={getDotProgress(props.phase)}
                        total={3}
                    />
                )}
                {props.phase === PHASES.setPort && (
                    <Box className={styles.instructions}>
                        <input
                            className={styles.nemoPortInput}
                            autoFocus
                            type="text"
                            onChange={props.onChange}
                            placeholder={'네모링크에 표시된 포트번호를 입력해주세요.'}
                        />
                    </Box>
                )}
                <Box className={classNames(styles.bottomAreaItem, styles.buttonRow)}>
                    {(props.phase === PHASES.setPort || props.phase === PHASES.prescan) && (
                        <button
                            className={styles.connectionButton}
                            onClick={props.phase === PHASES.prescan ? props.onStartScan : props.onSetNemoSerialPort}
                        >
                            <FormattedMessage
                                defaultMessage="Start Searching"
                                description="Button in prompt for starting a search"
                                id="gui.connection.auto-scanning.start-search"
                            />
                        </button>
                    )}
                    {(props.phase === PHASES.prescan && props.title === 'technic') && (
                        <button
                            className={classNames(styles.nemo_port_button, styles.connectionButton)}
                            onClick={props.handleOpenScratchHexModal}
                        >
                            스크래치 모드 활성화
                        </button>
                    )}
                    {(props.phase === PHASES.setPort && props.title === 'nemo') && (
                        <button
                            className={classNames(styles.nemo_port_button, styles.connectionButton)}
                            onClick={props.onClickGoBackButton}
                        >
                            {'뒤로가기'}
                        </button>
                    )}
                    {(props.phase === PHASES.prescan && props.title === 'nemo') && (
                        <button
                            className={classNames(styles.nemo_port_button, styles.connectionButton)}
                            onClick={props.onShowPortInput}
                        >
                            {'포트번호 입력하기'}
                        </button>
                    )}
                    {props.phase === PHASES.pressbutton && (
                        <div className={styles.segmentedButton}>
                            <button
                                disabled
                                className={styles.connectionButton}
                            >
                                <FormattedMessage
                                    defaultMessage="Searching..."
                                    description="Label indicating that search is in progress"
                                    id="gui.connection.connecting-searchbutton"
                                />
                            </button>
                            <button
                                className={styles.connectionButton}
                                onClick={props.onRefresh}
                            >
                                <img
                                    className={styles.abortConnectingIcon}
                                    src={closeIcon}
                                />
                            </button>
                        </div>
                    )}
                    {props.phase === PHASES.notfound && (
                        <button
                            className={styles.connectionButton}
                            onClick={props.onRefresh}
                        >
                            <img
                                className={styles.buttonIconLeft}
                                src={backIcon}
                            />
                            <FormattedMessage
                                defaultMessage="Try again"
                                description="Button in prompt for trying a device search again"
                                id="gui.connection.auto-scanning.try-again"
                            />
                        </button>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

AutoScanningStep.propTypes = {
    connectionTipIconURL: PropTypes.string,
    onRefresh: PropTypes.func,
    onStartScan: PropTypes.func,
    phase: PropTypes.oneOf(Object.keys(PHASES)),
    title: PropTypes.string,
    onShowPortInput: PropTypes.func,
    onChange: PropTypes.func,
    onSetNemoSerialPort: PropTypes.func,
    onNemoConnectionModalClosed: PropTypes.func,
    onClickGoBackButton: PropTypes.func
};

AutoScanningStep.defaultProps = {
    phase: PHASES.prescan
};

export {
    AutoScanningStep as default,
    PHASES
};
