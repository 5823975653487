import React from 'react';
import classNames from 'classnames';
import Box from '../box/box.jsx';
import Dots from './dots.jsx';
import radarIcon from './icons/searching.png';

import styles from './connection-modal.css';


const MarkCodingWindowSelectingStep = props => {
    return (
        <Box className={styles.body}>
            <Box className={styles.activityArea}>
                <div className={styles.activityAreaInfo}>
                    <div className={styles.centeredRow}>
                        <React.Fragment>
                            <img
                                className={classNames(styles.radarBig, styles.radarSpin)}
                                src={radarIcon}
                            />
                            <img
                                className={styles.connectionTipIcon}
                                src={props.connectionTipIconURL}
                                width={60}
                            />
                        </React.Fragment>

                    </div>
                </div>
            </Box>
            <Box className={styles.bottomArea}>
                <Box className={classNames(styles.bottomAreaItem, styles.instructions)}>
                    <span>아래 선택하기 버튼을 누르고<br />마인크래프트 화면을 선택해주세요</span>
                </Box>
                <Dots
                    className={styles.bottomAreaItem}
                    counter={1}
                    total={3}
                />
                <Box className={classNames(styles.bottomAreaItem, styles.buttonRow)}>
                    <button
                        className={classNames(styles.connectionButton)}
                        onClick={props.onStartMcStreaming}
                    >
                        {'선택하기'}
                    </button>
                </Box>
            </Box>
        </Box>
    );
}

export {
    MarkCodingWindowSelectingStep as default
};
