import React from 'react';
import styles from './malrang-tutorial-modal.css';
import warning from './warning_icon.svg';

const MalrangTutorialGuideWarning = () => {
    return <div className={styles.warningContainer}>
            <img src={warning} width={17} />
            학생들이 블록 코딩을 시작할 때 보일 블록만 남기고 나머지 블록은 삭제하시고, 제한 사항 설정 완료 후 저장해주세요.
        </div>

};

export default MalrangTutorialGuideWarning;