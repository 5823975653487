import React from 'react';
import styles from './connection-modal.css';
import classNames from 'classnames';

import updateMalrangHex from './img/malrang-update-hex.png';
import Progress from './progress.jsx';

const PROGRESS_UPDATE_INTERVAL = 1000;
const PROGRESS_PAUSE_POINT = 80;
const PROGRESS_COMPLETE = 100;
const PROGRESS_STEP = 10;

const UpdateHexStep = ({ updateHex, handleHexUpdated, vm }) => {
    const [progress, setProgress] = React.useState(0);

    const intervalRef = React.useRef(null);

    const onCLickUpdateHex = () => {
        updateHex();

        if (progress !== 0) {
            return;
        }

        intervalRef.current = setInterval(() => {
            setProgress(p => {
                if (p >= PROGRESS_PAUSE_POINT) {
                    clearInterval(intervalRef.current);
                    return PROGRESS_PAUSE_POINT;
                }
                return p + PROGRESS_STEP;
            });
        }, PROGRESS_UPDATE_INTERVAL);
    };

    React.useEffect(() => {
        const onUpdated = (extensionId) => {
            clearInterval(intervalRef.current);
            setProgress(PROGRESS_COMPLETE);
            setTimeout(() => {
                handleHexUpdated();
            }, 500);
        };
    
        const onUpdatedFailed = () => {
            alert('스크래치 모드 활성화에 실패했습니다. 커넥트와 PC의 연결상태를 확인해주세요.');
            clearInterval(intervalRef.current);
            setProgress(0);
        };

        vm.on('HEX_UPDATED', onUpdated);
        vm.on('HEX_UPDATE_FAILED', onUpdatedFailed);

        return () => {
            vm.removeListener('HEX_UPDATED', onUpdated);
            vm.removeListener('HEX_UPDATE_FAILED', onUpdatedFailed);
        }
    }, []);

    return (
        <div className={styles.hexUpdateModal}>
            <img className={styles.hexUpdateImage} src={updateMalrangHex} />
            <div className={styles.hexUpdateContent}>
                <div className={styles.hexUpdateDescription}>
                    CPU 블록을 사용하려면 아래 '활성화 시작' 버튼을 클릭하세요
                </div>
                <Progress progress={progress} />
                <button
                    onClick={onCLickUpdateHex}
                    className={classNames(styles.bottomAreaItem, styles.connectionButton)}
                >활성화시작</button>
            </div>
        </div>
    );
}

export default UpdateHexStep;
