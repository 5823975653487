import React, { useState } from "react";
import UpdateHexModal from "./update-hex-modal.jsx";
import SelectPortStep from "./select-port-step.jsx";
import { requestToSemobaeExtension, SEMOBAE_DATA_TRANSFER_CHANNEL, SEMOBAE_DATA_TRANSFER_DESTINATION } from "../../semobae_utils/semobae_utils.js";
import { mapExtensionIdToLink } from "../../lib/utils/mapLinkToExtensionId.js";
import { useProgress } from "./hooks/useProgress.js";
import { HEX_IMAGES, HEX_BUTTON_TEXT, HEX_DESCRIPTION } from './constants/hex-progress-status.js';
import technicSelectPort from './img/technic-select-port.png'

export const TechnicHex = [
    {
        label: '일반 CPU',
        value: 'NORMAL'
    },
    {
        label: '파워 CPU',
        value: 'POWER'
    }
];

const TechnicUpdateHex = ({ extensionId, handleHexUpdated, onScanning }) => {
    const [isStarted, setIsStarted] = useState(false);
    const [selectedPort, setSelectedPort] = React.useState(null);
    const [selectedTechnicHex, setSelectedTechnicHex] = React.useState(TechnicHex[0].value);

    const changeUpdateHexStep = ({selectedPort, selectedTechnicHex}) => {
        setSelectedPort(selectedPort);
        setSelectedTechnicHex(selectedTechnicHex);
        setIsStarted(true);
    };

    const updateHex = () => {
        requestToSemobaeExtension(
            SEMOBAE_DATA_TRANSFER_CHANNEL.UPDATE_SCRATCH_HEX,
            mapExtensionIdToLink[extensionId],
            SEMOBAE_DATA_TRANSFER_DESTINATION.WORKER,
            {
                port: selectedPort,
                hex: selectedTechnicHex,
            }
        )
        .then((data) => {
            if (data.type === 'success') {
                finish();
            } else {
                fail();
                console.error(data.message);        
            }
        })
        .catch(err => {
            fail();
            console.error('err: ',err);
        });
    };

    const {
        start,
        finish,
        fail,
        progress
    } = useProgress({
            onStart: updateHex,
            onFinished: handleHexUpdated,
            onFail: () => {
                alert('스크래치 모드 활성화에 실패했습니다. 테크닉과 PC의 연결상태를 확인해주세요.');
                setIsStarted(false);
            }
        });

    if (!isStarted) {
        return <SelectPortStep
                    image={technicSelectPort}
                    description={'연결된 CPU와 포트 번호를 확인해주세요.'}
                    submitButtonText={'활성화하기'}
                    onSubmit={changeUpdateHexStep}
                    extensionId={extensionId}
                    selectedTechnicHex={selectedTechnicHex}
                    setSelectedTechnicHex={setSelectedTechnicHex}
                />;
    }

    const assets = {
        dialogImage: HEX_IMAGES[extensionId],
        description: HEX_DESCRIPTION['technic']
    };

    return <UpdateHexModal
                progress={progress}
                updateHex={start}
                autoStart
                onDone={onScanning}
                assets={assets}
            />;
};

export default TechnicUpdateHex;