import React from 'react';
import StartingScreenComponent from '../components/starting-screen/starting-screen.jsx';

class StartingScreen extends React.Component {
    render () {
        return <StartingScreenComponent />;
    }
}

export default StartingScreen;
