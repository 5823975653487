import Modal from '../../containers/modal.jsx';
import React, { useCallback, useEffect, useRef } from 'react';
import blockStyles from '../blocks/blocks.css';
import MalrangSpriteList from '../../containers/malrang-sprite-list.jsx';
import { SPRITE_LIST_TYPE } from '../../lib/constant/malrangTutorial.js';
import styles from './malrang-tutorial-modal.css';
import classNames from 'classnames';
import Box from '../box/box.jsx';
import MalrangTutorialGuideTip from './malrang-tutorial-guide-tip.jsx';
import MalrangTutorialGuideWarning from './malrang-tutorial-warning.jsx';
import SaveTutorialSb3 from './save-tutorial-sb3.jsx';
import RestrictionOptions from './restriction-options.jsx';
import CheckButton from '../check-button/check-button.jsx';
import useBlockStacks from './hooks/useBlockStacks.js';
import { Direction } from './restriction-options.jsx';

const preventClick = (event) => {
    event.stopPropagation();
}

const MalrangTutorialModalComponent = ({ 
    onRequestClose,
    showAllBlockCategories,
    toggleShowAllBlockCategories,
    toolbox,
    palette,
    paletteScrollbar,
    updateBlocksFiltering,
    editingTarget,
    hoveredTarget,
    sprites,
    raised,
    selectedId,
    onDrop,
    isSpriteHidden,
    setSpriteVisibleOnTargetPane,
    initialSpriteId,
    setInitialSprite,
    shouldPreventDragSprite,
    shouldPreventEditSprite,
    shouldPreventAddSprite,
    shouldPreventDeleteSprite,
    shouldPreventSelectSprite,
    shouldPreventDeleteStage,
    shouldHideCostumeTab,
    shouldHideSoundTab,
    shouldHideExtensionButton,
    shouldHideFileMenu,
    shouldHideEditMenu,
    isEverySpriteConfigsChecked,
    isEveryStageConfigsChecked,
    isEveryMenuConfigsChecked,
    isEveryPreventBlockConfigsChecked,
    onRequestDownloadProject,
    shouldPreventClickStage,
    shouldPreventAddStage,
    onSaveFinished,
    maximumGreenFlagClickCount,
    blockSvgGroups,
    hiddenBlockIdsInWorkspace,
    eventHandler,
    flyout,
    shouldPreventExecuteBlockWithClick,
    setShouldPreventExecuteBlockWithClick,
    tooltip
}) => {
    const blockStacksListRef = useRef(null);

    useBlockStacks(
        blockSvgGroups,
        blockStacksListRef,
        eventHandler.onClickHideBlockStackCheckbox,
        hiddenBlockIdsInWorkspace
    );

    const paletteRef = useCallback((node) => {
        if (node === null) return;
        node.appendChild(toolbox);
        node.appendChild(palette);
        node.appendChild(paletteScrollbar);
    }, []);

    useEffect(() => {
        const flyoutWorkspace = flyout.getElementsByClassName('blocklyWorkspace')[0];
        flyoutWorkspace.setAttribute('clip-path', '');
        return () => {
            flyoutWorkspace.style.clipPath = '';
        };
    }, []);

    useEffect(() => {
        flyout.addEventListener('mousedown', preventClick, true);
        flyout.style.overflow = 'hidden';

        return () => {
            flyout.removeEventListener('mousedown', preventClick, true);
            flyout.style.overflow = '';
        }
    }, []);

    const ref = useRef(null);

    const handleFlyoutResize = () => {
        if(ref.current === null) {
            return;
        }

        const path = flyout.querySelector('.blocklyFlyoutBackground');
        
        const modalContentHeight = ref.current.offsetHeight;
        const d = path.getAttribute('d');

        path.setAttribute('d', d.replace(/v\s*\d+/, `v ${modalContentHeight}`));
        flyout.setAttribute('height', `${modalContentHeight}`);
        toolbox.setAttribute('style', `height: ${modalContentHeight}px !important;`);
    };

    useEffect(() => {
        window.addEventListener('resize', handleFlyoutResize);

        return () => {
            window.removeEventListener('resize', handleFlyoutResize);
        };
    }, []);

    const onUpdate = () => {
        updateBlocksFiltering();

        setTimeout(() => {
            handleFlyoutResize();
        }, 100);
    };

    const onClickShowAllBlockCategories = () => {
        toggleShowAllBlockCategories();
        handleFlyoutResize();
    }

    return (<Modal
            fullScreen
            onRequestClose={onRequestClose}
            contentLabel={'튜토리얼 설정 및 저장'}
            boxClassName={styles.modalFlex}
            id={'malrang-tutorial-modal'}
        >
            <div className={styles.modalContent} ref={ref}>
                <div className={styles.paletteContainer}>
                    <div
                        className={blockStyles.blocks}
                        ref={paletteRef}
                    />
                </div>
                <div className={styles.configContainer}>
                    <div className={styles.infoContainer}>
                        <MalrangTutorialGuideWarning />
                        <SaveTutorialSb3 onSaveFinished={onSaveFinished} onRequestDownloadProject={onRequestDownloadProject} />
                    </div>

                    <div className={styles.infoContainer}>
                        <MalrangTutorialGuideTip />
                        <SaveTutorialSb3 onSaveFinished={onSaveFinished} onRequestDownloadProject={onRequestDownloadProject} />
                    </div>
                    <div className={classNames(styles.container, styles.blockOptionContainer)}>
                        <div className={styles.blockOptionInnerTitleContainer}>
                            <div className={styles.configTitleContainer}>
                                <h2 className={styles.blockOptionTitle}>튜토리얼 카테고리,블록 설정</h2>
                            </div>
                            <div className={styles.showAllBlockContainer}>
                                <CheckButton
                                    className={classNames(styles.restrictionCheckbox, showAllBlockCategories ? styles.restrictionLabelChecked : styles.restrictionLabel)}
                                    onClick={onClickShowAllBlockCategories}
                                    checked={showAllBlockCategories}
                                    label="전체 블록 카테고리 보이기"
                                />
                            </div>
                        </div>
                        <div className={styles.blockOptionInnerContainer}>
                            <div className={styles.blockOptionDescription}>
                                업데이트 버튼 클릭 시 현재 설정한 코딩 기준으로 튜토리얼에 표시될 블록 카테고리와 블록이 설정됩니다.
                                <div className={classNames(styles.blockOptionDescription, styles.blockOptionDescriptionGray)}>
                                    <span>[전체 블록 카테고리 보이기]</span>
                                    를 체크 하면 해당 기능은 사용할 수 없습니다. 
                                </div>
                            </div>
                            <button
                                onClick={onUpdate}
                                className={classNames(styles.downloadSb3Button, styles.updateButton)}
                                disabled={showAllBlockCategories}
                            >
                                업데이트
                            </button>
                        </div>
                    </div>

                    <div className={styles.optionListContainer}>
                        <div className={classNames(styles.listVertical, styles.spriteContainer)}>
                            <div className={styles.container}>
                                <div className={styles.configTitleContainer}>
                                    <h2 className={styles.blockOptionTitle}>스프라이트 숨기기</h2>
                                </div>
                                <div className={styles.configDescriptionContainer}>
                                    <h4>스프라이트를 한 번 클릭하면 스프라이트 목록에서 숨길 수 있습니다.</h4>
                                    <h4>두 번 클릭하면 스프라이트 목록과 무대에서 숨길 수 있습니다.</h4>
                                </div>
                                <Box className={classNames(styles.spriteSelector, styles.fullyBorderRadius)}>
                                    <MalrangSpriteList
                                        items={Object.keys(sprites).map(id => sprites[id])}
                                        isSpriteHidden={isSpriteHidden}
                                        setSpriteVisibleOnTargetPane={setSpriteVisibleOnTargetPane}
                                        type={SPRITE_LIST_TYPE.HIDE}
                                        tooltip={tooltip}
                                    />
                                </Box>
                            </div>
                            <div className={styles.container}>
                                <div className={styles.configTitleContainer}>
                                    <h2 className={styles.blockOptionTitle}>시작 스프라이트</h2>
                                </div>
                                <div className={styles.configDescriptionContainer}>
                                    <h4>튜토리얼 시작 시 선택되어 있는 스프라이트를 설정합니다.</h4>
                                </div>
                                <Box className={classNames(styles.spriteSelector, styles.fullyBorderRadius)}>
                                    <MalrangSpriteList
                                        items={Object.keys(sprites).map(id => sprites[id])}
                                        initialSpriteId={initialSpriteId}
                                        setInitialSprite={setInitialSprite}
                                        type={SPRITE_LIST_TYPE.SET_INITIAL}
                                        tooltip={tooltip}
                                    />
                                </Box>
                            </div>
                            <div className={styles.container}>
                                <div className={styles.configTitleContainer}>
                                    <h2 className={styles.blockOptionTitle}>블록 숨기기</h2>
                                </div>
                                <div className={styles.configDescriptionContainer}>
                                    <h4>튜토리얼에서 숨겨질 블록을 선택합니다.</h4>
                                </div>
                                <div ref={blockStacksListRef} className={styles.blockStacksList} />
                            </div>
                        </div>
                        <div className={classNames(styles.container, styles.listVertical, styles.restrictionContainer)}>
                            <RestrictionOptions 
                                isEveryConfigsChecked={isEveryMenuConfigsChecked}
                                onClickSelectAll={eventHandler.onUpdateBatchSelectionMenuConfigs}
                                title="탭 / 메뉴 숨기기"
                                restrictionOptions={[
                                    {
                                        id: 'preventCostumeTab',
                                        isChecked: shouldHideCostumeTab,
                                        onChange: eventHandler.onUpdateHideCostumeTab,
                                        label: '모양',
                                    },
                                    {
                                        id: 'preventSoundTab',
                                        isChecked: shouldHideSoundTab,
                                        onChange: eventHandler.onUpdateHideSoundTab,
                                        label: '소리',
                                    },
                                    {
                                        id: 'preventFileMenu',
                                        isChecked: shouldHideFileMenu,
                                        onChange: eventHandler.onUpdateHideFileMenu,
                                        label: '파일',
                                    },
                                    {
                                        id: 'preventEditMenu',
                                        isChecked: shouldHideEditMenu,
                                        onChange: eventHandler.onUpdateHideEditMenu,
                                        label: '편집',
                                    },
                                    {
                                        id: 'preventExtension',
                                        isChecked: shouldHideExtensionButton,
                                        onChange: eventHandler.onUpdateHideExtensionButton,
                                        label: '확장블록',
                                    },
                                ]}
                            />
                            <RestrictionOptions
                                isEveryConfigsChecked={isEverySpriteConfigsChecked}
                                onClickSelectAll={eventHandler.onUpdateBatchSelectionSpriteConfigs}
                                title="스프라이트"
                                restrictionOptions={[
                                    {
                                        id: 'preventDragSprite',
                                        isChecked: shouldPreventDragSprite,
                                        onChange: eventHandler.onUpdatePreventDragSprite,
                                        label: '드래그',
                                    },
                                    {
                                        id: 'preventEditSprite',
                                        isChecked: shouldPreventEditSprite,
                                        onChange: eventHandler.onUpdatePreventEditSprite,
                                        label: '수정',
                                    },
                                    {
                                        id: 'preventAddSprite',
                                        isChecked: shouldPreventAddSprite,
                                        onChange: eventHandler.onUpdatePreventAddSprite,
                                        label: '추가',
                                    },
                                    {
                                        id: 'preventRemoveSprite',
                                        isChecked: shouldPreventDeleteSprite,
                                        onChange: eventHandler.onUpdatePreventDeleteSprite,
                                        label: '삭제',
                                    },
                                    {
                                        id: 'preventSelectSprite',
                                        isChecked: shouldPreventSelectSprite,
                                        onChange: eventHandler.onUpdatePreventSelectSprite,
                                        label: '포커싱',
                                    },
                                ]}
                            />
                            <RestrictionOptions
                                isEveryConfigsChecked={isEveryStageConfigsChecked}
                                onClickSelectAll={eventHandler.onUpdateBatchSelectionStageConfigs}
                                title="배경"
                                restrictionOptions={[
                                    {
                                        id: 'preventAddStage',
                                        isChecked: shouldPreventAddStage,
                                        onChange: eventHandler.onClickAddStageCheckbox,
                                        label: '추가',
                                    },
                                    {
                                        id: 'preventDeleteStage',
                                        isChecked: shouldPreventDeleteStage,
                                        onChange: eventHandler.onClickDeleteStageCheckbox,
                                        label: '삭제',
                                    },
                                    {
                                        id: 'preventClickStage',
                                        isChecked: shouldPreventClickStage,
                                        onChange: eventHandler.onClickPreventStageCheckbox,
                                        label: '포커싱',
                                    }
                                ]}
                            />
                            <RestrictionOptions
                                onClickSelectAll={eventHandler.onUpdateBatchSelectionEtcConfigs}
                                isEveryConfigsChecked={isEveryPreventBlockConfigsChecked}
                                title="기타"
                                restrictionOptions={[
                                    {
                                        id: 'preventRunBlockByClick',
                                        isChecked: shouldPreventExecuteBlockWithClick,
                                        onChange: eventHandler.onClickPreventExecuteBlockWithClick,
                                        label: '블록 클릭으로 실행 금지'
                                    }
                                ]}
                                direction={Direction.Vertical}
                            />
                            <div>
                                <div className={classNames(styles.restrictionTitleContainer, styles.restrictionFlagClickTitle)}>
                                    <h2 className={styles.restrictionTitle}>최대 깃발 클릭 횟수</h2>
                                </div>
                                <hr className={styles.restrictionLine}></hr>
                                <input
                                    className={styles.restrictionInput}
                                    value={maximumGreenFlagClickCount}
                                    type="text"
                                    onChange={eventHandler.onChangeMaximumGreenFlagInput}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
};

export default MalrangTutorialModalComponent;
