import React from 'react';
import PropTypes from 'prop-types';

import styles from './custom-input-popup.css';

const CustomInputMenuItemComponent = props => {
    return (
        <div
            className={styles.item}
            onClick={props.onClickHandler}
        >
            {props.item}
        </div>
    );
}

CustomInputMenuItemComponent.propTypes = {
    item: PropTypes.string.isRequired,
    onClickHandler: PropTypes.func.isRequired
};

export default CustomInputMenuItemComponent;