exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".camera-view_camera-view-wrapper_9XYt0 {\n    position: relative;\n    width: 100%;\n    height: 320px;\n}\n\n.camera-view_camera-view_1B9Od {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    border-radius: 5px;\n    -o-object-fit: cover;\n       object-fit: cover;\n}\n\n.camera-view_camera-view_1B9Od.camera-view_mirror_bILOl {\n    -webkit-transform: scaleX(-1);\n        -ms-transform: scaleX(-1);\n            transform: scaleX(-1);\n}\n\n.camera-view_camera-view_1B9Od.camera-view_normal_2_KMw {\n    -webkit-transform: scaleX(1);\n        -ms-transform: scaleX(1);\n            transform: scaleX(1);\n}\n\n.camera-view_mirror-button_3HihO {\n    position: absolute;\n    top: 0;\n    right: 0;\n    margin: 10px;\n    border: none;\n    background-color: transparent;\n    z-index: 1;\n}", ""]);

// exports
exports.locals = {
	"camera-view-wrapper": "camera-view_camera-view-wrapper_9XYt0",
	"cameraViewWrapper": "camera-view_camera-view-wrapper_9XYt0",
	"camera-view": "camera-view_camera-view_1B9Od",
	"cameraView": "camera-view_camera-view_1B9Od",
	"mirror": "camera-view_mirror_bILOl",
	"normal": "camera-view_normal_2_KMw",
	"mirror-button": "camera-view_mirror-button_3HihO",
	"mirrorButton": "camera-view_mirror-button_3HihO"
};