import ScratchBlocks from 'scratch-blocks';
import { BlockXMLBase } from './BlockXMLBase';
export class Sensing extends BlockXMLBase {
    constructor () {
        super();
    
        const name = ScratchBlocks.ScratchMsgs.translate('SENSING_ASK_TEXT', 'What\'s your name?');

        this.sensing_askandwait = `
        <block id="askandwait" type="sensing_askandwait">
            <value name="QUESTION">
                <shadow type="text">
                    <field name="TEXT">${name}</field>
                </shadow>
            </value>
        </block>`;
    }
    
    blockOptions = {
        sensing_touchingobject: {
            order: 0,
            stage: false,
            sprite: true
        },
        sensing_touchingcolor: {
            order: 1,
            stage: false,
            sprite: true
        },
        sensing_coloristouchingcolor: {
            order: 2,
            stage: false,
            sprite: true
        },
        sensing_distanceto: {
            order: 3,
            stage: false,
            sprite: true
        },
        sensing_askandwait: {
            order: 4,
            stage: true,
            sprite: true
        },
        sensing_answer: {
            order: 5,
            stage: true,
            sprite: true
        },
        sensing_keypressed: {
            order: 6,
            stage: true,
            sprite: true
        },
        sensing_mousedown: {
            order: 7,
            stage: true,
            sprite: true
        },
        sensing_mousex: {
            order: 8,
            stage: true,
            sprite: true
        },
        sensing_mousey: {
            order: 9,
            stage: true,
            sprite: true
        },
        sensing_setdragmode: {
            order: 10,
            stage: false,
            sprite: true
        },
        sensing_loudness: {
            order: 11,
            stage: true,
            sprite: true
        },
        sensing_timer: {
            order: 12,
            stage: true,
            sprite: true
        },
        sensing_resettimer: {
            order: 13,
            stage: true,
            sprite: true
        },
        sensing_of: {
            order: 14,
            stage: false,
            sprite: true
        },
        sensing_current: {
            order: 15,
            stage: false,
            sprite: true
        },
        sensing_dayssince2000: {
            order: 16,
            stage: true,
            sprite: true
        },
        sensing_username: {
            order: 17,
            stage: true,
            sprite: true
        }
    };

    sensing_touchingobject = `
        <block type="sensing_touchingobject">
                <value name="TOUCHINGOBJECTMENU">
                    <shadow type="sensing_touchingobjectmenu"/>
                </value>
            </block>`;

    sensing_touchingcolor = `
        <block type="sensing_touchingcolor">
            <value name="COLOR">
                <shadow type="colour_picker"/>
            </value>
        </block>`;

    sensing_coloristouchingcolor = `
        <block type="sensing_coloristouchingcolor">
            <value name="COLOR">
                <shadow type="colour_picker"/>
            </value>
            <value name="COLOR2">
                <shadow type="colour_picker"/>
            </value>
        </block>`;

    sensing_distanceto = `
        <block type="sensing_distanceto">
            <value name="DISTANCETOMENU">
                <shadow type="sensing_distancetomenu"/>
            </value>
        </block>`;


    sensing_answer = `
        <block id="answer" type="sensing_answer"/>`;

    sensing_keypressed = `
        <block type="sensing_keypressed">
            <value name="KEY_OPTION">
                <shadow type="sensing_keyoptions"/>
            </value>
        </block>`;

    sensing_mousedown = `
        <block type="sensing_mousedown"/>`;

    sensing_mousex = `
        <block type="sensing_mousex"/>`;

    sensing_mousey = `
        <block type="sensing_mousey"/>`;

    sensing_setdragmode = `
        <block type="sensing_setdragmode" id="sensing_setdragmode"/>`;

    sensing_loudness = `
        <block id="loudness" type="sensing_loudness"/>`;

    sensing_timer = `
        <block id="timer" type="sensing_timer"/>`;
    
    sensing_resettimer = `
        <block type="sensing_resettimer"/>`;

    sensing_of = `
        <block id="of" type="sensing_of">
            <value name="OBJECT">
                <shadow id="sensing_of_object_menu" type="sensing_of_object_menu"/>
            </value>
        </block>`;

    sensing_current = `
        <block id="current" type="sensing_current"/>`;

    sensing_dayssince2000 = `
        <block type="sensing_dayssince2000"/>`;

    sensing_username = `
        <block type="sensing_username"/>`;
}
