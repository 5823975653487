import { BlockXMLBase } from './BlockXMLBase';

export class Control extends BlockXMLBase {
    constructor () {
        super();
    }

    blockOptions = {
        control_wait: {
            sprite: true,
            stage: true,
            order: 0
        },
        control_repeat: {
            sprite: true,
            stage: true,
            order: 1
        },
        control_forever: {
            sprite: true,
            stage: true,
            order: 2
        },
        control_break: {
            sprite: true,
            stage: true,
            order: 3
        },
        control_if: {
            sprite: true,
            stage: true,
            order: 4
        },
        control_if_else: {
            sprite: true,
            stage: true,
            order: 5
        },
        control_wait_until: {
            sprite: true,
            stage: true,
            order: 6
        },
        control_repeat_until: {
            sprite: true,
            stage: true,
            order: 7
        },
        control_stop: {
            sprite: true,
            stage: true,
            order: 8
        },
        control_start_as_clone: {
            sprite: true,
            stage: false,
            order: 9
        },
        control_create_clone_of: {
            sprite: true,
            stage: true,
            order: 10
        },
        control_delete_this_clone: {
            sprite: true,
            stage: false,
            order: 11
        }
    };

    control_wait = `
        <block type="control_wait">
            <value name="DURATION">
                    <shadow type="math_positive_number">
                    <field name="NUM">1</field>
                    </shadow>
            </value>
        </block>`;

    control_repeat = `
        <block type="control_repeat">
            <value name="TIMES">
                    <shadow type="math_whole_number">
                    <field name="NUM">10</field>
                    </shadow>
            </value>
        </block>`;

    control_forever = `
        <block id="forever" type="control_forever"/>`;

    control_break = `
        <block type="control_break"/>`;

    control_if = `
        <block type="control_if"/>`;

    control_if_else = `
        <block type="control_if_else"/>`;

    control_wait_until = `
        <block id="wait_until" type="control_wait_until"/>`;

    control_repeat_until = `
        <block id="repeat_until" type="control_repeat_until"/>`;

    control_stop = `
        <block type="control_stop"/>`;

    control_create_clone_of = `
        <block type="control_create_clone_of">
                <value name="CLONE_OPTION">
                    <shadow type="control_create_clone_of_menu"/>
                </value>
        </block>`;

    control_start_as_clone = `
        <block type="control_start_as_clone"/>`;

    control_delete_this_clone = `
        <block type="control_delete_this_clone"/>`;
}