const SET_WORKSPACE = 'scratch-gui/workspace/setWorkspace';
const initialState = {
    scratchWorkspace: {}
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;

    switch(action.type) {
        case SET_WORKSPACE: 
            return {
                ...state,
                scratchWorkspace: action.scratchWorkspace
            }
        default:
            return state;
    }
}

const setWorkspace = (scratchWorkspace) =>{
    return {
        type: SET_WORKSPACE,
        scratchWorkspace
    };
}

export {
    reducer as default,
    initialState as workspaceInitialState,
    setWorkspace
};
