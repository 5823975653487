import React from 'react';
import PropTypes from 'prop-types';
import CustomInputPopupComponent from '../components/custom-input-popup/custom-input-popup.jsx';
import VM from 'scratch-vm';

const MONITOR_NAMES = ['malrang_monitor', 'technic_monitor'];
class CustomInputPopup extends React.Component {
    constructor (props) {
        super(props);
    }

    splitMonitors (monitors) {
        const splitMonitor = {};
        
        MONITOR_NAMES.forEach(monitorName => {
            splitMonitor[monitorName] = monitors.filter(
                m => m.id.includes(monitorName)
            );
        }
        );

        return splitMonitor;
    }

    render () {
        const monitors = this.props.vm.runtime.getMonitorState()
            .filter(
                m => m.id.includes('malrang_monitor') || m.id.includes('technic_monitor')
            );

        const splitedMonitors = this.splitMonitors(monitors);

        return (
            Object.keys(splitedMonitors).map(splitMonitorKey => (
                splitedMonitors[splitMonitorKey].size ?
                    <CustomInputPopupComponent
                        monitors={splitedMonitors[splitMonitorKey]}
                        vm={this.props.vm}
                        splitMonitorKey={splitMonitorKey}
                    /> : null
            ))
        );
    }
}

CustomInputPopup.propTypes = {
    vm: PropTypes.instanceOf(VM).isRequired
};

export default CustomInputPopup;
